/* eslint-disable no-unused-vars */
import React from 'react';

export enum ETagSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  X_SMALL = 'xsmall',
}

export enum ETagState {
  ENABLED = 'enabled',
  ACTIVED = 'actived',
  DISABLED = 'disabled'
}

export enum ETagType {
  CONTAINED = 'contained',
  OUTLINED = 'outlined'
}

export enum ETagColor {
  PRIMARY = 'primary',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

export type ECustomColors = {
  textColor: string,
  backgroundColor: string
}

export interface ITagProps {
  children: string | JSX.Element
  type: ETagType
  size: ETagSize
  color?: ETagColor
  state?: ETagState
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  className?: string,
  customColors?: ECustomColors,
  withIconRight?: boolean,
  withIconLeft?: boolean
}
