import locale from './locale';
import {QUESTION_TYPE} from './question';

export const getNumberOfDaysFormatted = days => `${days} ${locale.translate('numberOfDays', days)}`;

export const getFormattedPrice = price => {
  const validPrice = Number(Number(price).toFixed(2).replace('.00', '')); // avoid trailing zeros

  if (validPrice % 1 !== 0) {
    return validPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  return validPrice.toLocaleString();
};

export const getNumberWithLeadingZero = value => {
  const validValue = Math.abs(value);

  return validValue < 10 ? `0${validValue}` : `${validValue}`;
};

export const getAnswerFormatted = (answer, question) => {
  if (question.type === QUESTION_TYPE.ADDRESS) {
    const {street, city, postalCode} = {...answer};

    return {
      street: street && street.trim() || '',
      city: city && city.trim() || '',
      postalCode: postalCode && postalCode.trim() || ''
    };
  }

  return answer && answer.trim() || '';
};

export const getPhoneNumberFormatted = (phoneNumber, phonePrefix) => {
  const formattedPhoneNumber = phoneNumber.split(' ').join('');

  return `+${phonePrefix}${formattedPhoneNumber}`;
};
