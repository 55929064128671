const getDiscountState = state => state.discount || null;

export const getDiscountStatus = state => {
  const discountState = getDiscountState(state);

  return discountState?.status || null;
};

export const getDiscount = state => {
  const discountState = getDiscountState(state);

  return discountState?.data || null;
};

export const getDiscountCode = state => {
  const discountState = getDiscountState(state);

  return discountState?.code || null;
};
