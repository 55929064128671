import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getParsedAdmissionDuration} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import ActivityContent, {SPACING_TYPE} from '@Components/activities/activity/ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

const ActivityMultiple = ({admissionPolicy}) => {
  const {durationInMinutes, useLimit, useLimitOncePerDay} = admissionPolicy;
  const parsedDuration = getParsedAdmissionDuration(durationInMinutes);
  const durationInfo = parsedDuration?.length ? parsedDuration.join(' ') : locale.translate('durationUnlimited');
  const useLimitText = useLimitOncePerDay ?
    locale.translate('oncePerDay') : useLimit ?
      `${locale.translate('useNumber')}: ${useLimit}` :
      locale.translate('withoutLimit');

  return (
    <div>
      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <LayersOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          {useLimitText}
          {
            durationInMinutes ? ` x ${durationInfo}` : null
          }
        </Text>
      </ActivityContent>
    </div>
  );
};

export default ActivityMultiple;
