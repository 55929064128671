import {IConfigurationStore} from '../store/types';
import {isWidgetBusiness, isWidgetBusinessPreview, isWidgetInternal, isWidgetInternalPreview} from './widgetType';

export const getSendOrderUrl = (
  {widgetType, facilityId}: IConfigurationStore,
  orderId: string
) => {
  if (isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/orders/${orderId}/send`;
  }

  if (isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/orders/${orderId}/send`;
  }

  return `user-api/facilities/${facilityId}/orders/${orderId}/send`;
};
