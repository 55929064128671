import {getParams, getQueryStringParameters} from '@Utils/query';
import {EWidgetType} from '@Consts/globals';
import {EStorageKeys} from '@Consts/storageConsts';
import dayjs from 'dayjs';
import {setStorage} from './storage';
import {AFFILIATION_HASH_STORAGE_TIME} from '@Consts/timeDurations';
import {getNamespace} from '../storage/namespacedStorage';

interface IAffiliationHashItem {
  onlineGroupId: number,
  item: string,
  timestamp: number
}

export const CONFIG_PARAMS = [
  'facilityId',
  'groupId',
  'previewOnlineGroupId',
  'onlineGroupId',
  'businessGroupId',
  'internalBuyerFacilityId',
  'affiliationHash',
  'authToken',
  'internal',
  'companyEmail',
  'userName',
  'companyName',
  'companyUserId',
  'companyId',
  'lockBasket',
  'defaultLanguage',
  'hideLanguagePicker'
];

export const getWidgetType = ({
  previewOnlineGroupId,
  businessGroupId,
  internalBuyerFacilityId,
  authToken,
  internal
}: {
  previewOnlineGroupId?: number,
  businessGroupId?: number,
  internalBuyerFacilityId?: number,
  authToken?: string,
  internal?: boolean
}) => {
  if (internal) {
    if (internalBuyerFacilityId) {
      return EWidgetType.INTERNAL_CLIENT_PREVIEW;
    }

    return EWidgetType.INTERNAL_CLIENT;
  }

  if (previewOnlineGroupId) {
    return EWidgetType.ONLINE_CLIENT_PREVIEW;
  }

  if (businessGroupId) {
    return EWidgetType.BUSINESS_CLIENT_PREVIEW;
  }

  if (authToken) {
    return EWidgetType.BUSINESS_CLIENT;
  }

  return EWidgetType.ONLINE_CLIENT;
};

export const getWidgetConfiguration = () => {
  const parameters = getQueryStringParameters();
  const configParams = getParams(CONFIG_PARAMS, parameters);
  const {
    groupId: paramGroupId,
    facilityId: paramFacilityId,
    previewOnlineGroupId: paramPreviewOnlineGroupId,
    onlineGroupId: paramOnlineGroupId,
    businessGroupId: paramBusinessGroupId,
    internalBuyerFacilityId: paramInternalBuyerFacilityId,
    affiliationHash,
    internal,
    authToken: paramAuthToken,
    companyEmail: paramCompanyEmail,
    userName: paramUserName,
    companyName: paramCompanyName,
    companyUserId,
    companyId,
    lockBasket,
    hideLanguagePicker
  } = configParams;
  const previewOnlineGroupId = paramPreviewOnlineGroupId ? parseInt(paramPreviewOnlineGroupId, 10) : null;
  const groupIdDeprecated = paramGroupId ? parseInt(paramGroupId, 10) : null;
  const onlineGroupIdParam = paramOnlineGroupId || previewOnlineGroupId;
  const onlineGroupId = onlineGroupIdParam ? parseInt(onlineGroupIdParam, 10) : null;
  const businessGroupId = paramBusinessGroupId ? parseInt(paramBusinessGroupId, 10) : null;
  const facilityId = paramFacilityId ? parseInt(paramFacilityId, 10) : null;
  const internalBuyerFacilityId = paramInternalBuyerFacilityId ? parseInt(paramInternalBuyerFacilityId, 10) : null;
  const widgetType = getWidgetType(configParams);

  const storageNamespace = facilityId ? getNamespace({
    facilityId,
    onlineGroupId,
    groupId: groupIdDeprecated,
    businessGroupId,
    companyUserId,
    internalBuyerFacilityId,
    internal,
    previewOnlineGroupId
  }) : null;

  return {
    onlineGroupId,
    businessGroupId,
    internalBuyerFacilityId,
    facilityId,
    groupId: groupIdDeprecated,
    userName: paramUserName ? decodeURIComponent(paramUserName) : null,
    companyName: paramCompanyName ? decodeURIComponent(paramCompanyName) : null,
    companyUserId: companyUserId || null,
    companyId: companyId || null,
    companyEmail: paramCompanyEmail ? decodeURIComponent(paramCompanyEmail) : null,
    internal: !!internal,
    affiliationHash: affiliationHash || null,
    widgetType,
    parentUrl: document.referrer,
    authToken: paramAuthToken || null,
    lockBasket: lockBasket === 'true' ?? false,
    hideLanguagePicker: hideLanguagePicker === 'true' ?? false,
    storageNamespace,
    previewOnlineGroupId
  };
};

export const getAffiliationHashByOnlineGroupId = (
  urlAffiliationHash: string | null,
  storage: {[EStorageKeys.AFFILIATION_HASHES]: IAffiliationHashItem[] | []} | {},
  onlineGroupId: number
) => {
  const storageObj = EStorageKeys.AFFILIATION_HASHES in storage ? storage : {[EStorageKeys.AFFILIATION_HASHES]: []};
  const {affiliationHashes} = storageObj;
  const validHashItemIndex = affiliationHashes?.findIndex(i => i.onlineGroupId === onlineGroupId);
  let isOutdatedHash = false;

  if (urlAffiliationHash) {
    const newHashItem = {
      onlineGroupId,
      item: urlAffiliationHash,
      timestamp: dayjs().valueOf()
    };
    let affiliationHashesToStorage = [...affiliationHashes ?? [], newHashItem];

    if (validHashItemIndex > -1) {
      affiliationHashesToStorage = affiliationHashes.map((item, index) => (
        validHashItemIndex === index ? newHashItem : item
      ));
    }

    setStorage(EStorageKeys.AFFILIATION_HASHES, affiliationHashesToStorage);
    return newHashItem.item;
  } else if (validHashItemIndex > -1) {
    const {timestamp} = affiliationHashes[validHashItemIndex];
    const deadline = timestamp + AFFILIATION_HASH_STORAGE_TIME;

    isOutdatedHash = dayjs().isAfter(deadline);
  }

  return isOutdatedHash ? null : affiliationHashes?.[validHashItemIndex]?.item;
};
