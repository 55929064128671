import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
    text: string;
}

const CalendarSectionHeader = ({text}: Props) => (
  <Stack direction="row" spacing={1} sx={{alignItems: 'center', mb: 2}}>
    <Box sx={{width: 6, height: 20, borderRadius: 0.5, backgroundColor: 'success.main'}} />
    <Typography variant="h6" sx={{fontSize: '20px', color: 'text.primary'}}>
      {text}
    </Typography>
  </Stack>
);

export default CalendarSectionHeader;
