import React, {forwardRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import TicketSeparator from './TicketSeparator';
import Loader, {LOADER_TYPE} from '@Components/common/loader/Loader';

const Ticket = forwardRef(({topContent, bottomContent, isLoading, ...rest}, ref) => (
  <div {...rest} ref={ref}>
    <div className="c-ticket c-ticket--top" >
      {topContent}
    </div>

    {
      isLoading && <Loader type={LOADER_TYPE.COVER} />
    }

    <TicketSeparator />

    <div className="c-ticket c-ticket--bottom">
      {bottomContent}
    </div>
  </div>
));

Ticket.displayName = 'Ticket';

export default Ticket;
