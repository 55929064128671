import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './tooltip.scss';

export const TOOLTIP_POSITION = {
  RIGHT: 'right'
};
const TOOLTIP_TIMEOUT = 1000;

const Tooltip = ({children, text, position}) => {
  const [isActive, setIsActive] = useState(false);

  function handleOnClick() {
    !isActive && setIsActive(true);
  }

  useEffect(() => {
    let timer;

    if (isActive) {
      timer = setTimeout(() => {
        setIsActive(false);
      }, TOOLTIP_TIMEOUT);
    }

    return () => clearTimeout(timer);
  }, [isActive]);

  return (
    <div
      className={classNames(
        {
          'dl-tooltip__wrapper': !isActive,
          'dl-tooltip__wrapper-active': isActive
        }
      )}
      onClick={handleOnClick}
    >
      {children}

      {
        text && (
          <span
            className={classNames(
              'dl-tooltip',
              {
                [`dl-tooltip--${position}`]: position
              }
            )}
          >
            {text}
          </span>
        )
      }
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  position: PropTypes.oneOf(Object.values(TOOLTIP_POSITION))
};

export default Tooltip;
