import {IBasketItem} from '@Consts/types';
import {IConfigurationStore} from '../store/types';
import {getUrlIds} from './api/apiUtils';
import {getActivitiesUniquesIds} from './basket';
import {isWidgetOnlineClientPreview} from './widgetType';
import locale from './locale';

export const getCrossSellUrl = (
  {widgetType, facilityId}: Pick<IConfigurationStore, 'widgetType' | 'facilityId'>,
  code?: string,
  basket?: IBasketItem[]
) => {
  if (isWidgetOnlineClientPreview(widgetType)) {
    const activitiesUniquesIds = getActivitiesUniquesIds(basket);

    const urlIds = getUrlIds(activitiesUniquesIds);

    return `company-api/online/facilities/${facilityId}/cross_sell/preview?activityIds=${urlIds}&locale=${locale.locale}`; // eslint-disable-line max-len
  }

  return `user-api/orders/${code}/cross_sell`;
};
