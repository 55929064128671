import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import ActivitiesContent from '@Components/activities/ActivitiesContent';
import {getActivities} from '@Features/activities/activitiesSelectors';
import PageError from '@Components/error/PageError';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {isFailure} from '@Utils/status';
import {resetBasketSelections, updateBasketItems} from '@Features/basket/basketActions';
import {resetDiscount} from '@Features/discount/discountActions';
import {getBasketItems} from '@Features/basket/basketSelectors';
import locale from '@Utils/locale';
import {fetchActivities, setActivities} from '@Features/activities/activitiesActions';
import {resetShippingMethod} from '@Features/configuration/configurationActions';
import ActivitiesListingSkeleton from '@Components/activities/activitiesListSkeleton/ActivitiesListSkeleton';
import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {initializeBasketFromStorage} from '@Features/basket/basketUtils';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {setUpsellActivities, setUpsellStatus} from '@Features/upsell/upsellActions';
import {useMatch, useNavigate} from 'react-router-dom';
import {CHECKOUT_PATH, HOME_PATH} from '@Utils/routerUtils';
import {getBasketItemsWithoutUpsell} from '@Features/basket/basketItemsUtils';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';

const ActivitiesView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCheckout = useMatch(CHECKOUT_PATH);
  const configuration = useSelector(getConfiguration);
  const {onlineGroupId, widgetType} = configuration;
  const activities = useSelector(getActivities);
  const basketItems = useSelector(getBasketItems);
  const [status, setStatus] = useState(activities && basketItems ? SUCCESS : null);
  const facility = useSelector(getFacility);
  const storageBasketItems = useSelector(getStorageBasketItems) || [];

  async function handleFetchActivities() {
    setStatus(IN_PROGRESS);

    dispatch(setActivities(null));

    try {
      const activities = await dispatch(fetchActivities());

      dispatch(initializeBasketFromStorage({
        activities,
        onlineGroupId,
        basketItems,
        storageBasketItems,
        configuration,
        facility,
        isCheckout
      }));
      setStatus(SUCCESS);

    } catch (error) {
      setStatus(FAILURE);
    }
  }

  function resetSelectionsEffect() {
    dispatch(resetShippingMethod());

    if (basketItems?.length) {
      dispatch(resetBasketSelections());
      dispatch(resetDiscount());
    }
  }

  function resetUpsell() {
    if (isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType)) {
      dispatch(setUpsellStatus(null));
    }
  }

  function clearBasketFromUpsell() {
    const basketItemsWithoutUpsell = getBasketItemsWithoutUpsell(basketItems);

    dispatch(updateBasketItems(basketItemsWithoutUpsell));
  }

  React.useEffect(() => {
    clearBasketFromUpsell();
    handleFetchActivities();
    resetSelectionsEffect();
    resetUpsell();
    dispatch(setUpsellActivities([]));
  }, []);

  React.useEffect(() => {
    if (status === SUCCESS && !basketItems.length) {
      navigate(HOME_PATH);
    }
  }, [status, basketItems]);

  /* eslint-disable react/no-multi-comp */
  function handleRenderContent() {
    if (activities !== null && status === SUCCESS) {
      return <ActivitiesContent activities={activities} />;
    }

    if (isFailure(status)) {
      return (
        <PageError
          message={locale.translate('activitiesFetchError')}
          clearError={handleFetchActivities}
          clearable
        />
      );
    }

    return <ActivitiesListingSkeleton />;
  }

  return (
    <div className="dl-layout__content">
      {
        handleRenderContent()
      }
    </div>
  );
};

export default ActivitiesView;
