import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FlexBox from '@Components/common/flexBox/FlexBox';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import {DEFAULT_PRIVACY_POLICY_URL} from '@Consts/urls';
import ReactMarkdown from "react-markdown";

const SalePolicy = ({facility, smallText = false}) => {
  const {company, salePolicy, email} = facility;
  const {privacyPolicyURL} = salePolicy;
  const {name: companyName, address, nip, buyingDisclaimer} = company;
  const {formattedAddressLine1, formattedAddressLine2} = address;
  const companyAddress = `${formattedAddressLine1}, ${formattedAddressLine2}`;
  const companyNip = nip || '-';
  const privacyPolicy = privacyPolicyURL || DEFAULT_PRIVACY_POLICY_URL;
  const companyNameBold = <span className="dl-text--emphasized">{companyName}</span>;
  const privacyPolicyLink = (
    <a
      key="privacyPolicy"
      className="dl-text--emphasized"
      href={privacyPolicy}
      target="_blank"
      rel="noopener noreferrer"
    >
      {locale.translate('privacyPolicy')}
    </a>
  );

  return (
    <FlexBox>
      <div
        className={classNames('dl-text', {
          'dl-text--xsmall dl-text--light': smallText
        })}
      >
        {
          buyingDisclaimer
            ? <>
              <ReactMarkdown linkTarget="_blank">{buyingDisclaimer}</ReactMarkdown>
            </>
            : <>
              {locale.translate('sellerIs')}&nbsp;
              {locale.translate('administratorOfThePersonalData')}&nbsp;
              {companyNameBold}&nbsp;
              {companyAddress} NIP: {companyNip}.&nbsp;
              {locale.translate('sellerCopy')}&nbsp;
              {locale.translate('infoAboutPrivacyPolicy')}&nbsp;
              {privacyPolicyLink}.&nbsp;
              {
                email &&
                replaceByElement(
                  locale.translate('ifYouNeedHelpAssociatedWithOffer'),
                  <a key="emailTo" href={`mailto:${email}`}>{email}</a>
                )
              }&nbsp;
            </>
        }
      </div>
    </FlexBox>
  );
};

SalePolicy.propTypes = {
  facility: PropTypes.object.isRequired,
  smallText: PropTypes.bool
};

export default SalePolicy;
