import {removeStorage} from '@Features/storage/storageActions';
import {invoiceFormFieldsNames} from '@Consts/storageConsts';
import {apiGet} from '@Utils/api/api';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {CompanyInvoiceData} from '@Consts/apiGlobals';
import {AppThunk} from '@Features/types';

export const clearInvoiceData = (): AppThunk => async dispatch => {
  invoiceFormFieldsNames.forEach(fieldName => {
    dispatch(removeStorage(fieldName));
  });
};

export const fetchInvoiceData = (
  nip: string
): AppThunk<Promise<CompanyInvoiceData>> => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());

  const searchDataUrl = `user-api/search_company_data?nip=${nip}`;

  try {
    const invoiceData: CompanyInvoiceData = await apiGet(searchDataUrl, configuration);

    return invoiceData;
  } catch (error: unknown) {
    throw error;
  }
};
