import React from 'react';
import locale from '@Utils/locale';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import pubsub from '@Utils/pubsub';
import {EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {useSummaryData} from '../../../hooks/useSummaryData';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';

const TicketsContent = () => {
  const {documentAddress, isPreviewMode, latestOrderStorageData} = useSummaryData();

  const handleDownloadTickets = () => {
    if (documentAddress) {
      pubsub.trigger(EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, {
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
      window.open(documentAddress);
      window.focus();
    }
  };

  return (
    <Stack sx={{flexDirection: 'column', alignItems: 'center'}}>
      <Typography sx={{my: 2, fontWeight: 500}} variant="h5">
        {locale.translate('pdfDocumentIsReady')}
      </Typography>
      <Typography sx={{mb: 4, maxWidth: 200, textAlign: 'center', color: 'text.secondary'}} >
        {locale.translate('clickForPdfDocument')}
      </Typography>
      <FollowCursorTooltip
        title={locale.translate('actionDisabledInPreviewMode')}
        enable={isPreviewMode}
        styles={{width: {xs: 1, sm: 'auto'}}}
      >
        <Button
          color="inherit"
          variant={!isPreviewMode ? 'outlined' : 'contained'}
          onClick={handleDownloadTickets}
          sx={{width: 1}}
          size="large"
          disabled={isPreviewMode}
          endIcon={<FileDownloadOutlinedIcon />}
        >
          {locale.translate('download')}
        </Button>
      </FollowCursorTooltip>
    </Stack>
  );
};

export default TicketsContent;
