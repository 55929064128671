import {IAdmissionDate} from '@Consts/apiGlobals';
import {IActivityAdmissionDatesApiParams} from './types';

export const getActivityAdmissionDatesApiParams = ({
  affiliationHash,
  locale,
  sinceDate,
  uniltDate,
  activityVariantsIds
}: IActivityAdmissionDatesApiParams) => {
  const urlParams = new URLSearchParams();

  if (activityVariantsIds) {
    urlParams.append('activityVariants', activityVariantsIds.toString());
  }

  if (affiliationHash) {
    urlParams.append('affiliationHash', affiliationHash.toString());
  }

  if (locale) {
    urlParams.append('locale', locale.toString());
  }

  if (sinceDate) {
    urlParams.append('sinceDate', sinceDate.toString());
  }

  if (uniltDate) {
    urlParams.append('untilDate', uniltDate.toString());
  }

  return urlParams.toString();
};

export const filterAvailableDates = (
  admissionDatesChunk: IAdmissionDate[]
) => admissionDatesChunk.filter(date => !!date.isAvailable);
