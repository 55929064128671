import React from 'react';
import CompanyNipField from './formFields/CompanyNipField';
import Alert from '@mui/material/Alert';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import locale from '@Utils/locale';
import InvoiceCountryField from './formFields/InvoiceCountryField';
import Stack from '@mui/material/Stack';

const CompanySimplifiedInvoiceForm = () => (
  (
    <Stack spacing={3} sx={{mt: 3}}>
      <InvoiceCountryField saveInStorage />
      <CompanyNipField saveInStorage />
      <Alert severity="info" icon={<InfoOutlined sx={{fontSize: 22, mr: 0.5}} />} >
        {locale.translate('youWillReceiveInvoiceSimplified')}
      </Alert>
    </Stack>
  )
);

export default CompanySimplifiedInvoiceForm;
