import React, {useEffect} from 'react';
import locale from '@Utils/locale';
import {HOME_PATH} from '@Utils/routerUtils';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import pubsub from '@Utils/pubsub';
import {useDispatch, useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import {
  EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_APPEARS,
  EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CONFIRMED,
  EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CANCELED
} from '@Utils/events';
import useFrameMessage from '@Hooks/useFrameMessage';
import {clearBasketItemsInStorage} from '@Features/basket/basketUtils';
import {getOnlineGroupId} from '@Features/configuration/configurationSelectors';
import {getStorageBasketItems} from '@Features/storage/storageSelectors';
import {clearBasket} from '@Features/basket/basketActions';

interface ChangeActivitiesConfirmationDialogProps {
  isOpen: boolean,
  onCancel: () => void,
  source?: 'button' | 'navbar',
  clearActivities?: boolean
}

const ClearOrChangeActivitiesConfirmation = ({
  isOpen, onCancel, source, clearActivities}: ChangeActivitiesConfirmationDialogProps) => {
  const onlineGroupId = useSelector(getOnlineGroupId);
  const storageBasketItems = useSelector(getStorageBasketItems) || [];
  const facility = useSelector(getFacility);
  const {id: facilityId, name: facilityName} = {...facility};
  const navigate = useNavigate();
  const frameMessage = useFrameMessage();
  const dispatch = useDispatch();
  const sendAmplitudeEvent = (event: string, source?: 'button' | 'navbar') => {
    pubsub.trigger(event, {
      facilityId,
      facilityName,
      url: window.location.href,
      source
    });
  };

  const handleClearOrChangeActivities = () => {
    sendAmplitudeEvent(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CONFIRMED);
    onCancel();
    if (clearActivities) {
      if (onlineGroupId) {
        dispatch(clearBasketItemsInStorage(onlineGroupId, storageBasketItems));
      } else {
        dispatch(clearBasket());
      }
      frameMessage({name: 'scrollTop', offset: 0});
      navigate(HOME_PATH);
    } else {
      navigate(HOME_PATH, {state: {scrollToActivities: true}});
    }
  };

  const handleContinueShopping = () => {
    sendAmplitudeEvent(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_CANCELED);
    onCancel();
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => frameMessage({name: 'scrollCenter'}), 200);
      sendAmplitudeEvent(EVENT_BUY_CLOSING_BUYING_PREVIEW_DIALOG_APPEARS, source);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm-change-dialog-title"
      aria-describedby="confirm-change-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="confirm-change-dialog-title">
        {clearActivities ? locale.translate('removeSelectedServicesQuestion') : locale.translate('notSavedData')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="inherit" id="confirm-change-dialog-description">
          {clearActivities ? locale.translate('allServicesWillBeRemoved') :
            locale.translate('notSavedDataDesc')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            color={clearActivities ? 'inherit' : 'error'}
            onClick={clearActivities ? handleContinueShopping : handleClearOrChangeActivities}
          >
            {clearActivities ? locale.translate('cancel') : locale.translate('changeActivities')}
          </Button>
          <Button
            color={clearActivities ? 'error' : 'inherit'}
            onClick={clearActivities ? handleClearOrChangeActivities : handleContinueShopping}
            autoFocus
          >
            {clearActivities ? locale.translate('remove') : locale.translate('continueShopping')}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ClearOrChangeActivitiesConfirmation;
