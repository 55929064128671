import {TAgreementsFormValues} from '@Components/checkoutPages/agreements/types';
import {EStorageKeys} from '@Consts/storageConsts';
import {removeStorage, setStorage} from '@Features/storage/storageActions';
import {ThunkDispatch} from 'redux-thunk';
import {IStore} from 'src/js/store/types';
import {Action} from 'redux';
import {clearInvoiceData} from '@Features/invoice/InvoiceActions';

export const mutateOrderStorage = (
  orderData: Partial<TAgreementsFormValues>,
  withContactPhoneNumber: boolean
) => (dispatch: ThunkDispatch<IStore, void, Action>) => {
  const {
    email,
    phonePrefix,
    phoneNumber,
    firstName,
    lastName,
    sendInvoice
  } = orderData;

  if (email) {
    dispatch(setStorage(EStorageKeys.EMAIL, email, true));
  }

  if (phonePrefix?.value) {
    dispatch(setStorage(EStorageKeys.PHONE_PREFIX, phonePrefix.value, true));
  }

  if (phoneNumber && withContactPhoneNumber) {
    dispatch(setStorage(EStorageKeys.PHONE_NUMBER, phoneNumber, true));
  } else if (phoneNumber && !withContactPhoneNumber) {
    dispatch(removeStorage(EStorageKeys.PHONE_NUMBER));
    dispatch(removeStorage(EStorageKeys.PHONE_PREFIX));
  }

  if (firstName) {
    dispatch(setStorage(EStorageKeys.FIRST_NAME, firstName, true));
  }

  if (lastName) {
    dispatch(setStorage(EStorageKeys.LAST_NAME, lastName, true));
  }

  if (!sendInvoice) {
    dispatch(clearInvoiceData());
  }
};
