/* global easyPack */

import React, {useEffect, useCallback} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {PARCELS_PROVIDER_CONFIG} from './config';
import './ParcelsProvider.scss';

const SCRIPT_ID = 'deliveryProviderScript';
const LINK_ID = 'deliveryProviderStyles';
const EASY_PACK_CONFIG = {
  points: {
    types: ['parcel_locker_only']
  },
  map: {
    initialTypes: ['parcel_locker_only']
  }
};

const ParcelsProvider = ({onSelect}) => {
  const loadStyles = useCallback(
    () => {
      if (!document.getElementById(SCRIPT_ID)) {
        const script = document.createElement('script');

        script.setAttribute('async', true);
        script.setAttribute('id', SCRIPT_ID);
        script.setAttribute('src', PARCELS_PROVIDER_CONFIG.scriptUrl);

        document.head.appendChild(script);
      }
    },
    []
  );

  const loadScript = useCallback(
    () => {
      if (!document.getElementById(LINK_ID)) {
        const link = document.createElement('link');

        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('id', LINK_ID);
        link.setAttribute('href', PARCELS_PROVIDER_CONFIG.stylesUrl);

        document.head.appendChild(link);
      }
    },
    []
  );

  const clear = scriptsTimeout => {
    const parcelScript = document.getElementById(SCRIPT_ID);
    const parcelLink = document.getElementById(LINK_ID);

    if (parcelScript) {
      parcelScript.remove();
    }

    if (parcelLink) {
      parcelLink.remove();
    }

    if (scriptsTimeout) {
      clearTimeout(scriptsTimeout);
    }
  };

  const handlePickupPointClick = point => {

    onSelect(point);
  };

  useEffect(
    () => {
      const scriptsTimeout = setTimeout(() => {
        loadStyles();
        loadScript();
      }, 100);

      window.easyPackAsyncInit = function() {
        easyPack.init(EASY_PACK_CONFIG);
        easyPack.mapWidget('easypack-map', handlePickupPointClick);
      };

      return () => clear(scriptsTimeout);
    },
    [loadStyles, loadScript]
  );

  return (
    <div className="dl-parcels-provider">
      <div id="easypack-map" className="easypack-widget" />
    </div>
  );
};

ParcelsProvider.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export {
  ParcelsProvider
};
