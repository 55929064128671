import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import SkeletonBox from '@Components/common/skeletonBox/SkeletonBox';
import uuidV4 from 'uuid/v4';
import './activitiesListSkeleton.scss';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EAlignItems, EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const ActivitiesListSkeleton = () => (
  <FlexBox
    data-testid="skeleton-loader"
    direction={EFlexDirection.COLUMN}
    justify={EJustifyContent.SPACE_BETWEEN}
    style={{gap: '24px'}}
    className="dl-activities-list"
  >
    {[...Array(6)].map(() => (
      <FlexBox
        key={uuidV4()}
        justify={EJustifyContent.SPACE_BETWEEN}
        className="dl-activities-list__item-wrapper"
      >
        <div className="dl-activities-list__image-wrapper">
          <SkeletonBox width="100%" height="100%" borderRadius="4px 0 0 4px" />
        </div>
        <FlexBox
          justify={EJustifyContent.SPACE_BETWEEN}
          className="dl-activities-list__content-wrapper"
        >
          <FlexBox
            direction={EFlexDirection.COLUMN}
            className="dl-activities-list__desc-wrapper"
          >
            <SkeletonBox width="70%" height="20px" />
            <SkeletonBox width="60%" height="20px" />
          </FlexBox>
          <div className="dl-activities-list__mobile-image-wrapper">
            <SkeletonBox width="100%" height="200px" borderRadius="0" />
          </div>
          <FlexBox
            justify={EJustifyContent.FLEX_END}
            align={EAlignItems.FLEX_END}
            direction={EFlexDirection.COLUMN}
            className="dl-activities-list__price-section"
          >
            <SkeletonBox width="92px" height="20px" />
            <SkeletonBox width="134px" height="48px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    ))}
  </FlexBox>
);

export default ActivitiesListSkeleton;
