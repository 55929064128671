import React from 'react';
import locale from '@Utils/locale';
import FormInput from '@Components/formComponents/FormInput';
import {string} from 'yup';
import {MAX_LENGTH, MIN_LENGTH} from '@Utils/checkout';

export const FIELD_NAME = 'invoiceFirstName';

interface Props {
  name?: string,
  label?: string,
  saveInStorage?: boolean
}

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .matches(/^.{3,}$/, {message: locale.translate('minLength', {length: MIN_LENGTH}), excludeEmptyString: true})
  .max(255, locale.translate('maxLength', {length: MAX_LENGTH}));

const InvoiceFirstNameField = ({
  name = FIELD_NAME,
  label = locale.translate('name'),
  saveInStorage
}: Props) => (
  <FormInput
    name={name}
    componentProps={{label, sx: {width: 1}, inputProps: {'data-testid': 'invoice-first-name'}}}
    saveInStorage={saveInStorage}
  />
);

export default InvoiceFirstNameField;
