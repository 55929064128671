import {IStore} from 'src/js/store/types';

export const getAdmissionDatesState = (state: IStore) => state.admissionDates || null;

export const getAdmissionDates = (state: IStore) => {
  const admissionDates = getAdmissionDatesState(state);

  return admissionDates?.dates;
};

export const getAdmissionDatesStatus = (state: IStore) => {
  const admissionDates = getAdmissionDatesState(state);

  return admissionDates?.datesStatus;
};

export const getDayOffers = (state: IStore) => {
  const admissionDates = getAdmissionDatesState(state);

  return admissionDates?.dayOffers;
};
