import React from 'react';
import locale from '@Utils/locale';
import HighlightedSection from './HighlightedSection';
import Typography from '@mui/material/Typography';

const SecureFormDescription = () => (
  <HighlightedSection>
    <Typography variant="body2">
      {locale.translate('secureFormDescription')}
    </Typography>
  </HighlightedSection>
);

export default SecureFormDescription;
