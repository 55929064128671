
import {apiGet} from '@Utils/api/api';
import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getQuestionsEndpoint} from './questionsUtils';
import {setActivityQuestions} from '@Features/activities/activitiesActions';
import pubsub from '@Utils/pubsub';
import {EVENT_QUESTIONS_ERROR} from '@Utils/events';
import {setPickedUpsellActivityQuestions, setUpsellActivityQuestions} from '@Features/upsell/upsellActions';
import {getBasketItemsActivitiesIds} from '@Features/basket/basketSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';

export const SET_QUESTIONS_STATUS = 'SET_QUESTIONS_STATUS';
export const CLEAR_QUESTIONS_STATUS = 'CLEAR_QUESTIONS_STATUS';

export const setQuestionsStatus = (status, id) => ({
  type: SET_QUESTIONS_STATUS,
  payload: {status, id}
});

export const clearQuestionsStatus = () => ({
  type: CLEAR_QUESTIONS_STATUS
});

export const refetchQuestions = language => async (dispatch, getState) => {
  const activitiesFromBasket = getBasketItemsActivitiesIds(getState());
  const facility = getFacility(getState());

  activitiesFromBasket.forEach(activityId => {
    dispatch(fetchQuestions(activityId, facility, language));
  });
};

export const fetchQuestions = (activityId, facility, language) => async (dispatch, getState) => {
  const {id, name} = facility;
  const configuration = getConfiguration(getState());
  const fetchingStart = new Date();

  dispatch(setQuestionsStatus(IN_PROGRESS, activityId));

  try {
    const questions = await apiGet(getQuestionsEndpoint(configuration, activityId, language), configuration);

    dispatch(setActivityQuestions(activityId, questions));
    dispatch(setUpsellActivityQuestions(activityId, questions));
    dispatch(setPickedUpsellActivityQuestions(activityId, questions));
    dispatch(setQuestionsStatus(SUCCESS, activityId));

    return questions;
  } catch (error) {
    dispatch(setQuestionsStatus(FAILURE, activityId));

    const fetchingEnd = new Date();
    const fetchingTime = fetchingEnd - fetchingStart;

    pubsub.trigger(EVENT_QUESTIONS_ERROR, {
      id,
      name,
      activityId,
      error,
      fetchingTime
    });

    throw error;
  }
};
