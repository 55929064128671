import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {apiPost} from '@Utils/api/api';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from 'react-redux';

export const useFetchOrderUse = (orderId: string | null) => {
  const config = useSelector(getConfiguration);
  const {facilityId} = useSelector(getConfiguration);
  const url = `company-api/internal/facilities/${facilityId}/orders/${orderId}/use`;

  const {mutate, isLoading, isSuccess, isError, error, reset} = useMutation({
    mutationFn: () => apiPost(url, {...config})
  });

  const handleUseOrder = () => {
    mutate();
  };

  return {
    handleUseOrder,
    isSuccess,
    isError,
    error,
    isLoading,
    reset
  };
};
