/* global __API_ADDRESS__ */
import {isWidgetClientUser} from '../widgetType';
import {handleResponseError} from './errors/handleResponseError';

const API_ADDRESS = __API_ADDRESS__;

export const GET = 'GET';
export const POST = 'POST';

export const getOptions = ({facilityId, widgetType, authToken, body}) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  if (facilityId) {
    headers['X-Facility-Id'] = facilityId;
  }

  if (isWidgetClientUser(widgetType)) {
    headers['X-Auth-Token'] = authToken;
    headers['X-Auth-Client-Type'] = 'COMPANY-USER';
  }

  return {
    headers,
    body: JSON.stringify(body)
  };
};

export const apiFetch = async (url, options) => {
  const response = await fetch(`${API_ADDRESS}/${url}`, options);
  const status = response.status;
  let responseData;

  try {
    responseData = await response.json();
  } catch (e) {
    responseData = {};
  }

  if (response.ok) {
    return responseData;
  }

  return handleResponseError(status, responseData);
};

export const apiGet = (url, requestOptions) => {
  const options = {
    method: GET,
    ...getOptions(requestOptions)
  };

  return apiFetch(url, options);
};

export const apiPost = (url, requestOptions) => {
  const options = {
    method: POST,
    ...getOptions(requestOptions)
  };

  return apiFetch(url, options);
};
