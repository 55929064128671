import {getBasketItemDiscountedStatus, itemHasSelectedAdditions} from '@Utils/basket';

export const getPreviewIndex = (previews, basketItem) => {
  if (basketItem?.selections?.numberOfSeats) {
    return null;
  }

  if (previews.length) {
    let previewsFiltered = [];

    previewsFiltered = previews.filter(
      preview => preview.activityId === basketItem.activityId
    );

    if (!previewsFiltered?.length) {
      return null;
    }

    previewsFiltered = previewsFiltered.filter(
      preview => preview.variantId === basketItem.variantId
    );

    if (!previewsFiltered?.length) {
      return null;
    }

    const questions = basketItem.variant?.questions;

    if (questions?.length) {
      const ticketTypeQuestion = questions.find(({answerScope}) => answerScope === 'ticket');

      if (ticketTypeQuestion) {
        return null;
      }
    }

    const additionsPreviews = previewsFiltered.filter(
      preview => preview.additionsSelected
    );

    const additionsLessPreviews = previewsFiltered.filter(
      preview => !preview.additionsSelected
    );

    if (itemHasSelectedAdditions(basketItem)) {
      return null;
    }

    if (additionsPreviews?.length && !itemHasSelectedAdditions(basketItem)) {
      previewsFiltered = additionsLessPreviews;
    }

    const isBasketItemDiscounted = getBasketItemDiscountedStatus(basketItem);
    const relevantPreview = previewsFiltered.find(preview => {
      const isPreviewDiscounted = Boolean(preview.isDiscounted);

      return isBasketItemDiscounted === isPreviewDiscounted;
    });

    return relevantPreview?.id || null;
  }

  return null;
};

export const appendBasketItem = (previews, relevantPreviewIndex, basketItem) => {
  const previewIndex = previews.findIndex(({id}) => id === relevantPreviewIndex);

  previews[previewIndex]?.items.push(basketItem);
};

export const getPreviewViews = basketItems => {
  const previews = [];

  basketItems.forEach(basketItem => {
    const relevantPreviewIndex = getPreviewIndex(previews, basketItem);

    if (relevantPreviewIndex) {
      appendBasketItem(previews, relevantPreviewIndex, basketItem);
    } else {
      const id = previews?.length + 1;
      const isDiscounted = getBasketItemDiscountedStatus(basketItem);
      const additionsSelected = itemHasSelectedAdditions(basketItem);

      previews.push({
        id,
        items: [basketItem],
        activityId: basketItem.activityId,
        variantId: basketItem.variantId,
        isDiscounted,
        additionsSelected
      });
    }
  });

  return previews;
};
