import {EWidgetType} from '@Consts/globals';
import {
  SET_SCRIPT_DATA,
  UPDATE_ORDER_SHIPPING_METHOD,
  RESET_ORDER_SHIPPING_METHOD
} from './configurationActions';

export const configurationInitialState = {
  facilityId: null,
  onlineGroupId: null,
  groupId: null, // TODO(mp): deprecated, verify if can be safely removed
  affiliationHash: null,
  authToken: null,
  internal: false,
  companyEmail: null,
  businessGroupId: null,
  userName: null,
  companyName: null,
  widgetType: EWidgetType.ONLINE_CLIENT,
  language: null,
  hideLanguagePicker: false,
  parentUrl: 'https://localhost/',
  scriptInitialized: false,
  shippingMethod: null,
  isModal: false,
  internalBuyerFacilityId: null,
  indicatedActivityId: null,
  lockBasket: false,
  abTest: null,
  parentReferrer: ''
};

const configurationReducer = (state = configurationInitialState, action = {type: null}) => {
  switch (action.type) {
    case UPDATE_ORDER_SHIPPING_METHOD: {
      const data = action.payload.data;

      return {
        ...state,
        shippingMethod: {
          ...data
        }
      };
    }

    case RESET_ORDER_SHIPPING_METHOD: {
      return {
        ...state,
        shippingMethod: null
      };
    }

    case SET_SCRIPT_DATA: {
      const {
        parentUrl,
        authToken,
        affiliationHash,
        indicatedActivityId,
        isModal,
        parentReferrer
      } = action.payload.data;

      return {
        ...state,
        parentUrl,
        affiliationHash: affiliationHash || state.affiliationHash,
        authToken: authToken || state.authToken,
        indicatedActivityId,
        scriptInitialized: true,
        isModal,
        parentReferrer
      };
    }

    default: {
      return state;
    }
  }
};

export default configurationReducer;
