import React, {SyntheticEvent, useEffect} from 'react';
import locale from '@Utils/locale';
import TermsOfService from './TermsOfService';
import {IFacility} from '@Consts/apiGlobals';
import MarketingConsents from './MarketingConsents';
import Box from '@mui/material/Box';
import {useFormContext} from 'react-hook-form';
import SelectAllConsentsField, {FIELD_NAME as ALL_CONSENTS_FIELD_NAME} from './formFields/SelectAllConsentsField';
import {FIELD_NAME as TERMS_OF_SERVICE_FIELD_NAME} from '../../../formFields/TermsOfServiceField';
import {FIELD_NAME as MARKETING_CONSENTS_FIELD_NAME} from './formFields/MarketingConsentsField';

interface ISelectAllConsentsProps {
  facility: IFacility
}

const SelectAllConsents = ({
  facility
}: ISelectAllConsentsProps) => {
  const {watch, setValue} = useFormContext();
  const [
    termsOfService,
    isAgreeMarketingConsents
  ] = watch([TERMS_OF_SERVICE_FIELD_NAME, MARKETING_CONSENTS_FIELD_NAME]);

  useEffect(() => {
    if (termsOfService && isAgreeMarketingConsents) {
      setValue(ALL_CONSENTS_FIELD_NAME, true);
      return;
    }

    setValue(ALL_CONSENTS_FIELD_NAME, false);
  }, [termsOfService, isAgreeMarketingConsents]);

  function onCheckAllAgreements(event: SyntheticEvent<Element, Event>, checked: boolean) {
    setValue(TERMS_OF_SERVICE_FIELD_NAME, checked);
    setValue(MARKETING_CONSENTS_FIELD_NAME, checked);
  }

  return (
    <>
      <Box sx={{mb: 1}} >
        <SelectAllConsentsField
          label={locale.translate('setAllConsents')}
          indeterminate={termsOfService !== isAgreeMarketingConsents}
          onChangeEffect={onCheckAllAgreements}
        />
      </Box>
      <Box sx={{ml: 3}} >
        <Box sx={{mb: 1}} >
          <TermsOfService
            facility={facility}
          />
        </Box>
        <MarketingConsents
          facility={facility}
        />
      </Box>
    </>
  );
};

export default SelectAllConsents;
