export const REQUEST_VALIDATION_ERROR = 'requestValidationError';

export const ERROR_TYPE_MISSING_FIELD = 'missingField';
export const ERROR_TYPE_BLANK_FIELD = 'isBlank';
export const ERROR_TYPE_INVALID_TYPE = 'invalidType';
export const ERROR_TYPE_INVALID_VALUE = 'invalidValue';
export const ERROR_TYPE_TOO_SHORT = 'tooShort';
export const ERROR_TYPE_TOO_LONG = 'tooLong';
export const ERROR_TYPE_INVALID_EMAIL = 'invalidEmail';
export const ERROR_TYPE_INVALID_EMAIL_FORMAT = 'invalidEmailFormat';
export const ERROR_TYPE_INVALID_PHONE_FORMAT = 'invalidPhoneNumberFormat';
export const ERROR_TYPE_TOO_HIGH_TOTAL_PRICE = 'tooHigh';
export const ERROR_TYPE_INVALID_NIP_CHECKSUM = 'invalidNipChecksum';
export const ERROR_TYPE_PAYMENT_COMPLETE_UNTIL_DAYS_LIMIT = 'paymentCompleteUntilDaysLimitExceeded';
export const ERROR_TYPE_PAYMENT_COMPLETE_BEFORE_MINIMUM_DAYS = 'paymentCompleteUntilBeforeMinimumDateTime';
export const ERROR_TYPE_INVALID_COUNTRY_POSTAL_CODE = 'invalidCountryPostalCode';
export const ERROR_TYPE_INVALID_RECIPIENT_COUNTRY_POSTAL_CODE = 'invalidRecipientCountryPostalCode';
export const ERROR_TYPE_INVALID_COUNTRY_NIP = 'invalidCountryNip';
