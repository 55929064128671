import ExitConfirmation from '@Components/confirmations/ExitConfirmation';
import useFrameMessage from '@Hooks/useFrameMessage';
import locale from '@Utils/locale';
import {isCheckoutPath} from '@Utils/routerUtils';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const widgetCloseRequestMessage = 'widgetCloseRequest';
export const backdropClickEvent = 'backdropClick';

const CloseWidget: React.FC = () => {
  const [openConfirmExit, setOpenConfirmExit] = React.useState(false);
  const location = useLocation();
  const frameMessage = useFrameMessage();

  const messageCloseWidget = (message: { data: { name: string; }; }) => {
    if (message.data.name === backdropClickEvent && isCheckoutPath(location.pathname)) {
      setOpenConfirmExit(true);
    } else if (message.data.name === backdropClickEvent) {
      frameMessage({name: widgetCloseRequestMessage});
    }
  };

  const handleCloseOnClick = () => {
    if (isCheckoutPath(location.pathname)) {
      setOpenConfirmExit(true);
    } else {
      frameMessage({name: widgetCloseRequestMessage});
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageCloseWidget);
    return () => window.removeEventListener('message', messageCloseWidget);
  }, [location.pathname]);

  return (
    <>
      <button
        className="dl-navigation__close"
        type="button"
        aria-label={locale.translate('close')}
        onClick={handleCloseOnClick}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 8 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line
          d="M7.57643 7.93925C7.3427 8.16105 6.97341 8.15138 6.75161 7.91765L0.401874 1.22642C0.180072 0.992692 0.189742 0.62341 0.423473 0.401608V0.401608C0.657204 0.179806 1.02649 0.189476 1.24829 0.423206L7.59803 7.11443C7.81983 7.34816 7.81016 7.71745 7.57643 7.93925V7.93925Z"
            fill="#5A5A5A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line
          d="M0.422861 7.93926C0.189131 7.71745 0.17946 7.34817 0.401262 7.11444L6.751 0.423213C6.9728 0.189482 7.34209 0.179812 7.57582 0.401614V0.401614C7.80955 0.623416 7.81922 0.992698 7.59742 1.22643L1.24768 7.91766C1.02587 8.15139 0.656592 8.16106 0.422861 7.93926V7.93926Z"
            fill="#5A5A5A"
          />
        </svg>
      </button>
      <ExitConfirmation
        isOpen={openConfirmExit}
        onCancel={() => setOpenConfirmExit(false)}
      />
    </>
  );
};

export default CloseWidget;
