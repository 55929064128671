import locale from '@Utils/locale';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import {IConfigurationStore} from 'src/js/store/types';

export const getFacilityShippingMethods = (
  {
    facilityId,
    onlineGroupId,
    widgetType,
    internalBuyerFacilityId,
    businessGroupId
  }: IConfigurationStore
) => {
  const localeParam = `locale=${locale.language}`;
  const onlineGroupIdParam = `onlineGroupId=${onlineGroupId}`;

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/facility_shipping_methods?${localeParam}`;
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/facility_shipping_methods/preview?${localeParam}`; // eslint-disable-line max-len
  }

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/facility_shipping_methods?${localeParam}`;
  }

  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/facility_shipping_methods/preview?${localeParam}`; // eslint-disable-line max-len
  }

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${onlineGroupId}/facility_shipping_methods/preview?${localeParam}`; // eslint-disable-line max-len
  }

  return `user-api/facilities/${facilityId}/facility_shipping_methods?${localeParam}&${onlineGroupIdParam}`;
};
