import locale from '@Utils/locale';
import React, {ChangeEventHandler, FocusEventHandler} from 'react';
import TextField from '@mui/material/TextField';
import {FormHelperText, Stack, Theme} from '@mui/material';

export const FIELD_NAME = 'discountCode';

const helperStyles = (theme: Theme) => ({
  color: theme.palette.error.main
});

  interface IDiscountCodeField {
    value: string,
    onChange: ChangeEventHandler<HTMLInputElement>,
    onFocus: FocusEventHandler<HTMLInputElement>,
    errorMessage?: JSX.Element | string | null,
}

const DiscountCodeField = ({
  value,
  onChange,
  errorMessage,
  onFocus
}: IDiscountCodeField) => (
  <Stack>
    <TextField
      inputProps={{
        'data-testid': 'discount-code'
      }}
      name={FIELD_NAME}
      label={`${locale.translate('discountCode')} (${locale.translate('optional')})`}
      variant="outlined"
      value={value}
      sx={{width: 1}}
      onChange={onChange}
      onFocus={onFocus}
      error={!!errorMessage}
    />
    <FormHelperText sx={helperStyles} component="span">{errorMessage}</FormHelperText>
  </Stack>
);

export default DiscountCodeField;
