import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';

export const handleChangeCheckoutViews = (
  checkoutViewsState,
  checkoutViews,
  activeView,
  setActiveView,
  setFullCheckoutProgress,
  upsellViewResetDate
) => {
  if (checkoutViews) {
    const upsellIndex = checkoutViews.findIndex(view => view.type === ECheckoutViewsTypes.UPSELL);

    if (upsellIndex !== -1) {
      const checkoutViewsFromUpsell = checkoutViews.slice(upsellIndex + 1);
      const upsellItems = checkoutViewsState ? checkoutViewsState.slice(upsellIndex + 1) : checkoutViewsState;

      checkoutViewsFromUpsell.forEach((currentlyUpsellItem, index) => {
        if (currentlyUpsellItem?.items.length !== upsellItems[index]?.items.length &&
           (
             currentlyUpsellItem.type === ECheckoutViewsTypes.TIMES ||
             currentlyUpsellItem.type === ECheckoutViewsTypes.DATES
           )
        ) {
          return upsellViewResetDate(currentlyUpsellItem.items);
        }
      });
    }
  }

  const addCheckouts = (checkoutViewsState?.length || 0) < (checkoutViews?.length || 0);
  const removeCheckouts = (checkoutViewsState?.length || 0) > (checkoutViews?.length || 0);

  if (addCheckouts) {
    setFullCheckoutProgress(false);

    if (checkoutViewsState?.length && !activeView) {
      return setActiveView(checkoutViews[checkoutViewsState?.length]);
    }

    if (!checkoutViewsState?.length && checkoutViews?.length) {
      return setActiveView(checkoutViews[0]);
    }
  }

  if (removeCheckouts) {
    if (!checkoutViews?.length) {
      return setActiveView(null);
    }

    const isActiveViewValid = activeView && checkoutViews.some(({id}) => activeView?.id === id);

    if (activeView && !isActiveViewValid) {
      setActiveView(checkoutViews[checkoutViews?.length - 1]);
    }
  }
};

export const getMissingActivitiesWithReqVariants = (basketItems, activitiesWithVariants) => {
  const reqVariantsByBasketItems = basketItems.map(({variant}) =>
    ({vIds: variant.requiredActivityVariants})
  );
  const addedVariantIds = basketItems.map(({variantId}) => variantId);

  const missingVariantIds = reqVariantsByBasketItems.filter(({vIds}) =>
    !vIds.some(id => addedVariantIds.includes(id))
  );

  const missingActivities = activitiesWithVariants.filter(({variants}) =>
    variants
      .map(({id}) => id)
      .some(id => missingVariantIds.flatMap(i => i.vIds).includes(id))
  );

  const missingActivitiesWithReq = missingActivities.map(a => ({
    ...a,
    variants: a.variants.filter(v =>
      missingVariantIds.flatMap(i => i.vIds).includes(v.id)
    )
  }));

  return missingActivitiesWithReq;
};
