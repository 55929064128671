let lastTime = 0;
const vendors = ['ms', 'moz', 'webkit', 'o'];

if (!window.requestAnimationFrame) {
  vendors.forEach(vendor => {
    window.requestAnimationFrame = window.requestAnimationFrame ?
      window.requestAnimationFrame : window[`${vendor}RequestAnimationFrame`];
    window.cancelAnimationFrame = window.cancelAnimationFrame ?
      window.cancelAnimationFrame : window[`${vendor}CancelAnimationFrame`] ||
      window[`${vendor}CancelRequestAnimationFrame`];
  });
}

if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = callback => {
    const currTime = new Date().getTime();
    const timeToCall = Math.max(0, 16 - (currTime - lastTime));
    const id = window.setTimeout(() => callback(currTime + timeToCall), timeToCall);

    lastTime = currTime + timeToCall;

    return id;
  };
}

if (!window.cancelAnimationFrame) {
  window.cancelAnimationFrame = id => {
    clearTimeout(id);
  };
}
