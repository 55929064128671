import React, {ReactElement} from 'react';
import Paper from '@mui/material/Paper';
import {SxProps} from '@mui/material/styles';

const formSectionStyles = {
  p: 3,
  minWidth: 320,
  flex: '1 1',
  display: 'flex',
  justifyContent: 'center'
};

type ICarouselItem = {
  children: ReactElement,
  styles?: SxProps,
}

const CarouselItem = ({children, styles}: ICarouselItem) => (
  <Paper
    elevation={0}
    sx={{...formSectionStyles, ...styles}}
  >
    {children}
  </Paper>
);

export default CarouselItem;
