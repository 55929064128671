import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {apiPost} from '@Utils/api/api';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from 'react-redux';

type TUseSendOrder = {
  orderId?: string
  facilityId?: number
}

export const useSendCancelWaitingForPayment = ({
  orderId,
  facilityId
}: TUseSendOrder) => {
  const config = useSelector(getConfiguration);
  const url = orderId && facilityId ? `company-api/internal/facilities/${facilityId}/orders/${orderId}/cancel` : null;

  const {mutate, isSuccess, isError} = useMutation({
    mutationFn: () => apiPost(url, {...config})
  });

  const sendCancelWaitingForPayment = () => {
    mutate();
  };

  return {
    sendCancelWaitingForPayment,
    sendCancelIsSuccess: isSuccess,
    sendCancelIsError: isError
  };
};
