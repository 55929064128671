import {createStore, compose, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import orderReducer from '@Features/order/orderReducer';
import configurationReducer from '@Features/configuration/configurationReducer';
import facilityReducer from '@Features/facility/facilityReducer';
import activitiesReducer from '@Features/activities/activitiesReducer';
import basketReducer from '@Features/basket/basketReducer';
import discountReducer from '@Features/discount/discountReducer';
import {getWidgetConfiguration} from '@Utils/configurationUtils';
import storageReducer from '@Features/storage/storageReducer';
import additionsReducer from '@Features/activityAdditions/additionsReducer';
import questionsReducer from '@Features/activityQuestions/questionsReducer';
import admissionDatesReducer from '@Features/activityAdmissionDates/activityAdmissionDatesReducer';
import shippingMehtodsReducer from '@Features/shippingMethods/shippingMethodsReducer';
import upsellOfferReducer from '@Features/upsell/upsellReducer';

export const createAppStore = (preloadedState = {}) => {
  const composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const configuration = getWidgetConfiguration();

  const initialState = {
    ...preloadedState,
    configuration: {
      ...configuration,
      ...preloadedState.configuration
    }
  };

  return createStore(
    combineReducers({
      configuration: configurationReducer,
      facility: facilityReducer,
      activities: activitiesReducer,
      basket: basketReducer,
      order: orderReducer,
      discount: discountReducer,
      storage: storageReducer,
      additions: additionsReducer,
      questions: questionsReducer,
      admissionDates: admissionDatesReducer,
      shippingMethods: shippingMehtodsReducer,
      upsell: upsellOfferReducer
    }),
    initialState,
    composeEnhancer(applyMiddleware(thunk))
  );
};
