import {IPhonePrefix} from '@Components/formComponents/FormPrefixInput';
import {CountryType} from '@Consts/invoiceCountries';
import {EInvoiceFormType} from '@Components/invoice/types';
import {Dayjs} from 'dayjs';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import {IBasketItem} from '@Consts/types';

export type TInternalCheckoutFormValues = Partial<{
    email: string | null,
    firstName: string,
    lastName: string,
    paymentMethod: string,
    phoneNumber: string,
    phonePrefix: Partial<IPhonePrefix>,
    paymentCompleteUntil: Dayjs | string,
    isPaymentOnline: boolean,
    sendInvoice: boolean,
    invoiceFormType: EInvoiceFormType
    invoiceAddressCity: string,
    invoiceAddressStreet: string,
    invoiceAddressPostalCode: string,
    invoiceAddressCountry: Partial<CountryType>,
    invoiceCompanyName: string,
    invoiceCompanyNip: string,
    invoiceFirstName: string,
    invoiceLastName: string,
    isInvoiceRecipient: boolean,
    invoiceRecipientName: string,
    invoiceRecipientAddressStreet: string,
    invoiceRecipientAddressCity: string,
    invoiceRecipientAddressPostalCode: string,
    invoiceRecipientAddressCountry: Partial<CountryType>
  }>;

interface IOnSubmitData {
    continueUrl: string,
    locale: string,
    items: [{activityId: number, variantId: number}],
    email?: string,
    firstName?: string,
    lastName?: string,
    paymentMethod?: string,
    phoneNumber?: string,
    totalPrice: {amount: number, currency: string}
  }

export interface ICheckoutGenerate {
    onSubmit: (data: IOnSubmitData) => void
  }

export enum EPaymentMethod {
    OTHER = 'other',
    PAYMENT_CART = 'payment_card',
    CASH = 'cash',
    BANK_TRANSFER = 'bank_transfer',
    BLIK = 'blik',
    SECURE_FORM = 'secure_form',
  }

export interface ICheckoutInvoiceSection {
  isOnlinePaymentReceiptEnabled: boolean,
  isInternalPaymentReceiptEnabled: boolean
}

export type ActiveView = {
  id: number,
  type: ECheckoutViewsTypes,
  numberOfAdmissionDatesMin?: number,
  numberOfAdmissionDatesMax?: number,
  items: IBasketItem[],
  nextViewId: number | null,
  previousViewId: number | null,
  questionId?: number,
}
