export enum WidgetMode {
  inline = 'inline',
  modal = 'modal',
}

type WidgetConfigId = string | number;
type AllowedLanguage = 'en' | 'pl' | 'cs' | 'de' | 'es' | 'it' | 'sk' | 'uk' | 'hu';
export interface ScriptConfig {
  // widget behaviour
  element?: HTMLElement | null;
  mode: WidgetMode;
  selector: string;
}
export interface WidgetConfig extends ScriptConfig {
  // widget configuration
  // head to https://github.com/Droplabs/droplabs-widget#configuration to learn more about these

  // TODO(MP): why optional? it currently makes no sense throwing errors for existing users
  facilityId?: WidgetConfigId;
  groupId?: WidgetConfigId; // This field appears to be deprecated
  onlineGroupId?: WidgetConfigId;
  previewOnlineGroupId?: WidgetConfigId;
  businessGroupId?: WidgetConfigId;
  authToken?: string;
  internal?: boolean;
  internalBuyerFacilityId?: WidgetConfigId;
  defaultLanguage?: AllowedLanguage;
  affiliationHash?: string;
  lockBasket?: boolean
  dlWidgetScriptId?: string
  companyEmail?: string;
  companyName?: string;
  userName?: string;

  // TODO(MP): is this used? how to include that in the configuration
  // daid: string;
}
