const getOrderState = state => state.order || null;

export const getOrderStatus = state => {
  const orderState = getOrderState(state);

  return orderState?.status || null;
};

export const getOrder = state => {
  const orderState = getOrderState(state);

  return orderState?.data || null;
};

export const getSubmitOrderStatus = state => {
  const orderState = getOrderState(state);

  return orderState?.submitOrderStatus || null;
};

export const getSubmitOrderError = state => {
  const orderState = getOrderState(state);

  return orderState?.submitOrderError || null;
};
