import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Header from '@Components/common/header/Header';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import TimePicker from '@Components/timePicker/TimePicker';
import {COLON_HOUR_FORMAT} from '@Utils/dayjs/const';
import {EVENT_BUY_ADMISSION_DATES_SEES_HOURS, EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR} from '@Utils/events';
import {getDatesIdxWithMinPrice} from './admissionRealizationUtils';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getFormattedDate, getTimezoneDate, isSameDate} from '@Utils/dayjs/dayjsUtils';
import CalendarSectionHeader from '@Components/calendar/CalendarSectionHeader';
import Box from '@mui/material/Box';
import {getAbTestType} from '@Features/storage/storageSelectors';

const AdmissionTimePicker = ({
  selectedTime,
  activeViewBasketItems,
  selectedDayDates,
  setError,
  sendAmplitudeEvent,
  admissionPolicy,
  showPrices,
  isSelectErrorComponent,
  handleRemoveSelectError,
  isTestB
}) => {
  const {isOneAdmissionDateAvailable, firstAdmissionDate} = admissionPolicy;
  const timezone = useSelector(getFacilityTimezone);
  const currentAbTestType = useSelector(getAbTestType);

  const handleSendEvent = () => {
    const dateAvaliableHours = selectedDayDates[0].group.filter(({isOutOfLimit}) => !isOutOfLimit);

    sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SEES_HOURS, {
      numberOfAvailableHours: dateAvaliableHours.length,
      abTest: currentAbTestType
    });
  };

  useEffect(() => {
    handleSendEvent();
  }, []);

  const dispatch = useDispatch();
  const {group: datesGroup, metaData} = selectedDayDates[0];
  const {canShowItemsLeft} = metaData;
  const datesGroupWithoutSoldOut = datesGroup.filter(({isOutOfLimit}) => !isOutOfLimit);
  const datesIdxWithMinPrice = getDatesIdxWithMinPrice(datesGroup);
  const datesWithGreenPrice = datesGroup.map((date, idx) => ({
    ...date,
    isBargainPrice: datesIdxWithMinPrice.includes(idx)
  }
  ));
  const timePickerOptions =
    datesGroupWithoutSoldOut.length !== datesIdxWithMinPrice.length ?
      datesWithGreenPrice : datesGroup;

  function handleSelectTime(selectedOption) {
    const {isOutOfLimit, numberOfSeats, date, description} = selectedOption;

    if (isOutOfLimit) {
      const soldOutText = isTestB ? 'givenTimeIsSoldOutTestB' : 'givenTimeIsSoldOut';
      const isOutOfLimitText = isTestB ? 'setDifferentHour' : 'isOutOfLimit';
      const errorText = numberOfSeats ? isOutOfLimitText : soldOutText;

      dispatch(updateBasketItemsSelections(activeViewBasketItems, {time: null}));

      return setError(
        locale.translate(errorText, {
          time: getFormattedDate(getTimezoneDate(date, timezone), COLON_HOUR_FORMAT),
          ...isTestB && numberOfSeats && {numberOfSeats}
        })
      );
    }

    setError(null);

    // send event on select available time
    sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_HOUR, {
      admissionDateHour: getFormattedDate(getTimezoneDate(date, timezone), COLON_HOUR_FORMAT),
      admissionDateDescription: description,
      abTest: currentAbTestType
    });

    return dispatch(updateBasketItemsSelections(
      activeViewBasketItems,
      {time: selectedOption}
    ));
  }

  React.useEffect(() => {
    const firstDateTime = timePickerOptions[0];

    const isSameHourAsFirstAdmission = isSameDate(
      getTimezoneDate(firstAdmissionDate, timezone),
      getTimezoneDate(firstDateTime.originalDate, timezone),
      'hour'
    );

    if (isOneAdmissionDateAvailable && isSameHourAsFirstAdmission) {
      handleSelectTime(firstDateTime);
    }
  }, []);

  return (
    <Box
      sx={{
        mb: isTestB ? 2 : 0,
        mt: isTestB ? 3 : 0
      }}
    >
      {isTestB ? (
        <CalendarSectionHeader text={locale.translate('chooseTime')} />
      ) : (
        <Header>{locale.translate('chooseTime')}</Header>
      )}

      <TimePicker
        options={timePickerOptions}
        value={selectedTime}
        onChange={handleSelectTime}
        canShowItemsLeft={canShowItemsLeft}
        showPrices={showPrices}
        isSelectErrorComponent={isSelectErrorComponent}
        handleRemoveSelectError={handleRemoveSelectError}
        isTestB={isTestB}
      />
    </Box>
  );
};

AdmissionTimePicker.propTypes = {
  activeViewBasketItems: PropTypes.array.isRequired,
  setError: PropTypes.func.isRequired,
  selectedDayDates: PropTypes.array,
  selectedTime: PropTypes.object
};

export default AdmissionTimePicker;
