import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export type ButtonWrapperProps = {
  disableReason?: string;
}

const ButtonWrapper = ({children, disableReason}: React.PropsWithChildren<ButtonWrapperProps>) => (
  <Tooltip
    title={disableReason}
    placement="top"
    enterTouchDelay={50}
    arrow
  >
    <Box component="span">
      {children}
    </Box>
  </Tooltip>
);

export default ButtonWrapper;
