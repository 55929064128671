import {setStorage as setBrowserStorage, removeStorage as removeBrowserStorage} from '@Utils/storage';
import {getNamespace} from '../../storage/namespacedStorage';
import {clearOldInvoiceStorageData} from '@Utils/invoice';

export const SET_STORAGE_TO_STATE = 'SET_STORAGE_TO_STATE';
export const SET_STORAGE_ITEMS_TO_STATE = 'SET_STORAGES_TO_STATE';
export const REMOVE_STORAGE_FROM_STATE = 'REMOVE_STORAGE_FROM_STATE';

export const setStorage = (key, data, saveToState = false) => (dispatch, getState) => {
  const namespace = getState().configuration.storageNamespace;

  setBrowserStorage(key, data, namespace);

  if (saveToState) {
    dispatch(setStorageItemToState(key, data));
  }
};

export const removeStorage = key => (_, getState) => {
  const namespace = getState().configuration.storageNamespace;

  removeBrowserStorage(key, namespace);
};

export const setStorageItemToState = (key, value) => ({
  type: SET_STORAGE_TO_STATE,
  payload: {key, value}
});

export const setStorageItemsToState = (storageItems, nsStorageItems = {}) => (dispatch, getState) => {
  const configuration = getState().configuration;
  const namespace = getNamespace(configuration);

  const isNsStorage = !!nsStorageItems[namespace];
  const legacyStorage = {...storageItems, isNsStorage};

  clearOldInvoiceStorageData();

  // it appears that some of data (form fields, affiliation hash) is still using legacy storage, merging it here
  const nsStorage = {...legacyStorage, ...nsStorageItems[namespace], isNsStorage};

  const storage = isNsStorage ? nsStorage : legacyStorage;

  dispatch({
    type: SET_STORAGE_ITEMS_TO_STATE,
    payload: {storageItems: storage}
  });
};

export const removeStorageItemFromState = key => ({
  type: REMOVE_STORAGE_FROM_STATE,
  payload: {key}
});
