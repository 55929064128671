import {IFacility, IOrderSavedData} from '@Consts/apiGlobals';
import directFrameMessage from '@Utils/directFrameMessage';
import {EGtmEvents, GTM_MESSAGE_NAME} from './core';
import {TPurchaseOrderData} from './types';

interface ProductsKeys {
  idKey?: string,
  nameKey?: string,
  categoryKey?: string,
  priceKey?: string,
  quantityKey?: string
}

export const getOrderData = (order: TPurchaseOrderData, savedData: IOrderSavedData) => {
  const {firstName, lastName, phoneNumber} = savedData;
  const {orderId, email, completedAt} = order;

  return {
    attributes: {
      order_number: orderId,
      date: completedAt
    },
    customer: {
      billing: {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phoneNumber
      }
    },
    items: getItemsMutable(order, {
      idKey: 'id',
      nameKey: 'name',
      priceKey: 'price',
      quantityKey: 'quantity'
    })
  };
};

export const getItemsMutable = (order: TPurchaseOrderData, config: ProductsKeys) => {
  const {tickets} = order;
  const {
    idKey,
    nameKey,
    categoryKey,
    priceKey,
    quantityKey
  } = config;

  return tickets.map(({id, activityName, price, formattedName}) => ({
    ...idKey && {[idKey]: id},
    ...nameKey && {[nameKey]: formattedName},
    ...categoryKey && {[categoryKey]: activityName},
    ...priceKey && {[priceKey]: price},
    ...quantityKey && {[quantityKey]: 1}
  }));
};

export const getBaseData = (order: TPurchaseOrderData) => {
  const {orderId, tax, price} = order;

  return {
    transactionId: orderId,
    transactionAffiliation: 'Droplabs',
    transactionTotal: price,
    transactionTax: tax,
    transactionProducts: getItemsMutable(order, {
      idKey: 'sku',
      nameKey: 'name',
      categoryKey: 'category',
      priceKey: 'price',
      quantityKey: 'quantity'
    })
  };
};

export const getEcommerceData = (order: TPurchaseOrderData) => {

  const {orderId, price, tax, currency} = order;

  return {
    transaction_id: orderId,
    affiliation: 'Droplabs',
    value: price,
    tax,
    currency,
    items: getItemsMutable(order, {
      idKey: 'item_id',
      nameKey: 'item_name',
      categoryKey: 'item_category',
      priceKey: 'price',
      quantityKey: 'quantity'
    })
  };
};

export const getGtmData = ({order, savedData}: {
  order: TPurchaseOrderData,
  savedData: IOrderSavedData
}) => ({
  event: EGtmEvents.GTM_PURCHASE,
  orderData: getOrderData(order, savedData),
  ...getBaseData(order),
  ecommerce: getEcommerceData(order)
});

const addPurchase = (facility: IFacility, order: TPurchaseOrderData, savedData: IOrderSavedData) => {
  const {isGoogleTagManagerEnabled} = facility;

  if (!isGoogleTagManagerEnabled) {
    return;
  }

  directFrameMessage({
    name: GTM_MESSAGE_NAME,
    gtmData: getGtmData({order, savedData})
  });
};

export default {
  addPurchase: (
    facility: IFacility,
    order: TPurchaseOrderData,
    savedData: IOrderSavedData
  ) => addPurchase(facility, order, savedData)
};
