import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import CalendarHeader from './CalendarHeader';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getDateData} from '@Features/activityAdmissionDates/activityAdmissionDatesUtils';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import Box from '@mui/material/Box';
import {getDaysInMonth, getTimezoneDateOrNull} from '@Utils/dayjs/dayjsUtils';
import {Dayjs} from 'dayjs';

type Props = {
  weeks: (Dayjs | null)[][];
  datesGroupedByDay: IDatesGroupedByDay[] | null;
  dayOffers: DayOffersProcessed[] | null;
  onDayClick: (selectedDate: string) => void;
  showPrices?: boolean;
  showAvailableSeatsForDay?: boolean;
  isTestB: boolean
};

const CalendarDays = ({
  weeks,
  datesGroupedByDay,
  dayOffers,
  onDayClick,
  showPrices,
  showAvailableSeatsForDay,
  isTestB
}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const firstDayInMonth = weeks[0].find(day => day !== null);
  const lastDayInMonth = getDaysInMonth(firstDayInMonth);

  return (
    <Box className={isTestB ? '' : 'dl-calendar__days'}>
      <CalendarHeader isLower={undefined} isTestB={isTestB} />
      {
        weeks.map((week, weekIndex) => (
          <CalendarRow
            key={`week-${weekIndex}`}
            header={undefined}
            className={undefined}
            showPrices={showPrices}
            showAvailableSeatsForDay={showAvailableSeatsForDay}
            isTestB={isTestB}
          >
            {
              week.map((day, dayIndex) => (
                <CalendarCell
                  calendarDay={getTimezoneDateOrNull(day, timezone)}
                  cellData={getDateData(day, dayOffers, datesGroupedByDay, timezone)}
                  onClick={onDayClick}
                  key={`day-${dayIndex}`}
                  showPrices={showPrices}
                  showAvailableSeatsForDay={showAvailableSeatsForDay}
                  lastDayInMonth={lastDayInMonth}
                  isTestB={isTestB}
                />
              ))
            }
          </CalendarRow>
        ))
      }
    </Box>
  );
};

export default CalendarDays;
