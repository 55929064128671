import React from 'react';
import locale from '@Utils/locale';
import FormCountryInput from '@Components/formComponents/FormCountryInput';
import {object, string} from 'yup';
import {getCountries} from '@Utils/invoiceCountries';

interface InvoiceCountryFieldProps {
  name?: string,
  label?: string,
  saveInStorage?: boolean
}

export const FIELD_NAME = 'invoiceAddressCountry';
export const schema = () => object()
  .shape({
    code: string().required(),
    label: string().required()
  })
  .required(locale.translate('requiredField'));

const InvoiceCountryField = ({
  name = FIELD_NAME,
  label = locale.translate('country'),
  saveInStorage = false
}: InvoiceCountryFieldProps) => (
  <FormCountryInput
    name={name}
    label={label}
    items={getCountries()}
    saveInStorage={saveInStorage}
  />
);

export default InvoiceCountryField;
