import React, {useEffect} from 'react';
import TicketsLoadingContent from './TicketsLoadingContent';
import TicketsOnlineContent from './TicketsOnlineContent';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';
import TicketsContent from './TicketsContent';
import {useSummaryData} from '../../../hooks/useSummaryData';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {EVENT_SHOW_SUCCESS_SCREEN_TICKETS} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import CarouselItem from '@Components/carousel/CarouselItem';

type Props = {
  isMoreSectionsInCarousel?: boolean
}

const Tickets = ({isMoreSectionsInCarousel}: Props) => {
  const {
    documentAddress,
    isDocumentAddressAvailable,
    widgetType,
    isPreviewMode,
    latestOrderStorageData
  } = useSummaryData();
  const isWidgetOnline = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);

  if (!isPreviewMode && !isDocumentAddressAvailable) {
    return null;
  }

  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);

  const showLoader = !documentAddress && !isPreviewMode;

  useEffect(() => {
    if (!isPreviewMode && !showLoader) {
      const {
        isEventsSent
      } = latestOrderStorageData;
      const {id: facilityId, name: facilityName, company} = {...facility};
      const {industry} = {...company};

      pubsub.trigger(EVENT_SHOW_SUCCESS_SCREEN_TICKETS, {
        facilityId,
        facilityName,
        industry,
        affiliationHash,
        language: parseLanguage(locale.locale),
        isFirstLoading: !isEventsSent
      });
    }
  }, [showLoader]);

  return (
    <CarouselItem styles={{px: {xs: 2, md: 3}, minWidth: isMoreSectionsInCarousel ? 320 : 'auto'}} >
      <>
        {
          isWidgetOnline && !showLoader &&
          <TicketsOnlineContent />
        }
        {
          !isWidgetOnline && !showLoader &&
          <TicketsContent />
        }
        {showLoader && <TicketsLoadingContent />}
      </>
    </CarouselItem>
  );
};

export default Tickets;
