import locale from '@Utils/locale';
import {isInteger} from '@Utils/typeGuards';

export type ValidateInputValueProps = {
  inputValue: number | '',
  isGroupVariantType: boolean,
  basketItemExists: boolean,
  minValue: number,
  maxValue: number
}

export const validateInputValue = ({
  inputValue,
  isGroupVariantType,
  basketItemExists,
  minValue,
  maxValue
}: ValidateInputValueProps) => {
  if (inputValue === 0) {
    return true;
  }

  const isValueInRange = isInteger(inputValue) ? inputValue <= maxValue && inputValue >= minValue : true;

  // For group variant type, we need to check if basket item exists
  // On the other hand, counter will apply value even if order limit is reached
  // minValue is always defined by min number of seats or participants
  if (isGroupVariantType) {
    return minValue === 1 ? isValueInRange : basketItemExists && isValueInRange;
  }

  return isValueInRange;
};

export type DisableIncreasePickerReasonProps = {
  basketItemExists: boolean,
  disableReason: string | null,
  isGroupVariantType: boolean,
  isMaxValueReached: boolean
}

export const getDisableIncreasePickerReason = ({
  basketItemExists,
  disableReason,
  isGroupVariantType,
  isMaxValueReached
}: DisableIncreasePickerReasonProps) => {
  if (isGroupVariantType && !basketItemExists) {
    return !basketItemExists ? disableReason : null;
  }

  return isMaxValueReached ? locale.translate('canNotBuyMoreTickets') : null;
};

export type InvalidFieldMessageProps = {
  maxValue: number;
  minValue: number;
  inputValue: number | '';
};

export const getInvalidFieldMessage = (
  {maxValue, minValue, inputValue}: InvalidFieldMessageProps
) => {
  if (maxValue === 0 && inputValue) {
    return locale.translate('maxValueError', {value: maxValue});
  }

  if (isInteger(inputValue) && inputValue > 0 && inputValue < minValue) {
    return locale.translate('minValueError', {value: minValue});
  }

  if (inputValue > maxValue) {
    return locale.translate('maxValueError', {value: maxValue});
  }

  return null;
};
