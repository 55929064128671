import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {apiPost} from '@Utils/api/api';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from 'react-redux';

export const usePaymentUrl = (ticketCode?: string) => {
  const config = useSelector(getConfiguration);
  const {facilityId} = config;
  const url = `user-api/facilities/${facilityId}/orders/tickets/${ticketCode}/payment`;

  const {mutate, data, isLoading, isSuccess, isError, error} = useMutation({
    mutationFn: () => apiPost(url, {...config})
  });

  const handleFetchNewPaymentUrl = () => {
    if (!isLoading) {
      mutate();
    }
  };

  return {
    handleFetchNewPaymentUrl,
    payUrl: data?.payUrl ?? null,
    isSuccess,
    isError,
    error,
    isLoading
  };
};
