import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

interface IWaitingDescription {
  isFetchingOrderError: boolean
}

const WaitingDescription = ({isFetchingOrderError}: IWaitingDescription) => (
  <>
    {isFetchingOrderError ? (
      <>
        <Box
          component="img"
          src="img/Error.svg"
          alt="error"
          sx={{width: '100%', maxWidth: 128, mb: 4}}
        />
        <Typography variant="h4" sx={{textAlign: 'center', fontWeight: 500, mt: 1.5}}>
          {locale.translate('somethingIsWrong')}
        </Typography>
        <Typography sx={{my: 3, textAlign: 'center', color: 'text.secondary', maxWidth: 578}}>
          {locale.translate('waitingForPaymentError')}
        </Typography>
      </>
    ) : (
      <>
        <CircularProgress size={32} />
        <Typography variant="h4" sx={{textAlign: 'center', fontWeight: 500, mt: 1.5}}>
          {locale.translate('waitingForPayment')}
        </Typography>
        <Typography sx={{my: 3, textAlign: 'center', color: 'text.secondary', maxWidth: 578}}>
          {locale.translate('waitingForPaymentDesc')}
        </Typography>
      </>
    )}
  </>
);

export default WaitingDescription;
