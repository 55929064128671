import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '@Components/common/text/Text';
import locale from '@Utils/locale';
import {ETextColor} from '@Components/common/text/types';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {IAdmissionPolicyProcessed} from '@Consts/globals';

type Props = {
  admissionPolicy: IAdmissionPolicyProcessed;
}

const ActivityEventInfo = ({admissionPolicy}: Props) => {
  const {minNumberOfParticipants, maxNumberOfParticipants} = admissionPolicy;

  const text = minNumberOfParticipants === maxNumberOfParticipants ?
    `${locale.translate('numberOfParticipants')}: ${minNumberOfParticipants}` :
    locale.translate('numberOfParticipantsBetween', {min: minNumberOfParticipants, max: maxNumberOfParticipants});

  return (
    <div className="dl-activity__content">
      <PeopleAltOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

      <Text color={ETextColor.LIGHT}>
        {text}
      </Text>
    </div>
  );
};

export default ActivityEventInfo;
