import {useScroll} from './useScroll';

export const useNextStepValidationScroll = () => {
  const {scrollToElement} = useScroll({enableForModal: true});

  const triggerScrollToError = () => {
    const $firstCounterPickerValidationError = document.querySelectorAll('.counter-input-error')[0];

    if ($firstCounterPickerValidationError) {
      scrollToElement($firstCounterPickerValidationError);
    }
  };

  return {triggerScrollToError};
};
