import FormInput from '@Components/formComponents/FormInput';
import locale from '@Utils/locale';
import React from 'react';
import {string} from 'yup';

const PHONE_MASK = [
  /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
];

export const PHONE_REGEX = /^\d{3}[ ]?\d{3}[ ]?\d{2,7}$/;
export const FIELD_NAME = 'phoneNumber';
export const schema = () => string()
  .required(locale.translate('requiredField'))
  .matches(PHONE_REGEX, {message: locale.translate('invalidPhone')});

interface Props {
    name?: string,
}

const PhoneNumberField = ({name = FIELD_NAME}: Props) => (
  <FormInput
    componentProps={{label: locale.translate('phoneNumber'), inputProps: {type: 'tel'}}}
    name={name}
    mask={PHONE_MASK}
  />
);

export default PhoneNumberField;
