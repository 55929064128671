import React from 'react';
import {useDisclosure} from '@Hooks/useDisclosure';
import {ClickAwayListener} from '@mui/base/ClickAwayListener';
import {TooltipBase} from './TooltipBase';

export const TooltipMobile = () => {
  const [isOpen, {toggle, onClose}] = useDisclosure();

  return (
    <ClickAwayListener onClickAway={onClose}>
      <TooltipBase isOpen={isOpen} toggle={toggle} />
    </ClickAwayListener>
  );
};
