import React, {useEffect, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch, useSelector} from 'react-redux';
import Gradient from '../common/gradient/Gradient';
import CheckoutWrapper, {CHECKOUT_TYPE} from './CheckoutWrapper';
import CheckoutContent from './CheckoutContent';
import {getBasketItems} from '@Features/basket/basketSelectors';
import CheckoutPagesList from './CheckoutPagesList';
import ActivitiesPreviewList from '@Components/activitiesPreview/ActivitiesPreviewList';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import Agreements from '@Components/checkoutPages/agreements/Agreements';
import './checkout.scss';
import CheckoutGenerate from './CheckoutGenerate';
import {setSubmitOrderStatus, fetchOrderSubmit} from '@Features/order/orderActions';
import {isFailure, isProgress} from '@Utils/status';
import Loader, {LOADER_THEME, LOADER_TYPE} from '@Components/common/loader/Loader';
import PageError from '@Components/error/PageError';
import {useHandleChangeCheckoutViews} from '@Utils/hooks/useHandleChangeCheckoutViews';
import {getSubmitOrderError, getSubmitOrderStatus} from '@Features/order/orderSelectors';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import CheckoutOnlineSaleFooter from './CheckoutOnlineSaleFooter';
import {getMissingActivitiesWithReqVariants} from './checkoutUtils';
import {useNavigate} from 'react-router-dom';
import ClearActivities from './ClearActivities';
import {getAbTestType} from '@Features/storage/storageSelectors';

const Checkout = ({activities}) => {
  const navigate = useNavigate();
  const basketItems = useSelector(getBasketItems);
  const submitCheckoutRef = useRef(submitCheckoutRef);
  const dispatch = useDispatch();
  const submitOrderStatus = useSelector(getSubmitOrderStatus);
  const submitOrderError = useSelector(getSubmitOrderError);
  const {widgetType} = useSelector(getConfiguration);
  const {
    checkoutViews,
    activeView,
    fullCheckoutProgress,
    requestedCheckoutPageId,
    isUpsellUpdated,
    handleChangeView,
    handleCloseActivePage,
    handleClickHeader,
    rejectCheckoutPageRequest,
    handleSetUpsellUpdated
  } = useHandleChangeCheckoutViews({basketItems, submitCheckoutRef});
  const [missingActivities, setMissingActivities] = useState([]);
  const basketItemsVariants = basketItems.map(({variantId}) => variantId);
  const isOnline = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const isBusiness = isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType);
  const currentAbTestType = useSelector(getAbTestType);

  const handleNavigate = href => {
    navigate(href);
  };

  useEffect(() => {
    if (isOnline || isBusiness) {
      const activitiesWithVariants = activities.flatMap(i => {
        const variants = i.variants.map(({id, name}) => ({id, name}));

        return {variants, activityName: i.name, activityId: i.id};
      });

      const missingActivities = getMissingActivitiesWithReqVariants(basketItems, activitiesWithVariants);

      if (missingActivities.length > 0) {
        setMissingActivities(missingActivities);
      }
    }
  }, [basketItems]);

  function clearStatus() {
    return dispatch(setSubmitOrderStatus(null));
  }

  async function handleSubmit(data) {
    await dispatch(fetchOrderSubmit({
      data,
      basketItems,
      handleNavigate,
      currentAbTestType
    }));
  }

  if (!basketItems.length) {
    return null;
  }

  return (
    <div className="dl-checkout">
      <Gradient />

      {
        isProgress(submitOrderStatus) &&
          <Loader theme={LOADER_THEME.DARK} type={LOADER_TYPE.COVER} />
      }

      {
        isFailure(submitOrderStatus) &&
          <PageError message={submitOrderError} clearError={clearStatus} />
      }

      <CheckoutWrapper
        type={CHECKOUT_TYPE.NARROW}
        style={{marginBotttom: '24px'}}
      >
        <ActivitiesPreviewList
          basketItems={basketItems}
          activeView={activeView}
        />

        <ClearActivities />

      </CheckoutWrapper>

      <CheckoutWrapper type={CHECKOUT_TYPE.WIDE}>
        <CheckoutContent>
          {
            (checkoutViews?.length || activeView) && (
              <CheckoutPagesList
                activeView={activeView}
                checkoutViews={checkoutViews}
                onHeaderClick={handleClickHeader}
                onChangeView={handleChangeView}
                fullCheckoutProgress={fullCheckoutProgress}
                onPageClose={handleCloseActivePage}
                requestedCheckoutPageId={requestedCheckoutPageId}
                rejectCheckoutPageRequest={rejectCheckoutPageRequest}
                isUpsellUpdated={isUpsellUpdated}
                handleSetUpsellUpdated={handleSetUpsellUpdated}
              />
            )
          }
          {
            !activeView && (
              <>
                {
                  isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType) ?
                    <CheckoutGenerate onSubmit={handleSubmit} submitCheckoutRef={submitCheckoutRef} /> :
                    <Agreements
                      onSubmit={handleSubmit}
                      missingActivities={missingActivities}
                      chosenVariants={basketItemsVariants}
                    />
                }
              </>
            )
          }
        </CheckoutContent>
      </CheckoutWrapper>
      {
        !activeView &&
          (
            isWidgetOnlineClient(widgetType) ||
            isWidgetOnlineClientPreview(widgetType)
          ) && (
          <CheckoutWrapper type={CHECKOUT_TYPE.UPSELL}>
            <CheckoutOnlineSaleFooter />
          </CheckoutWrapper>
        )
      }
    </div>
  );
};

export default Checkout;
