import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import Header from '@Components/common/header/Header';
import locale from '@Utils/locale';
import PropTypes from 'prop-types';
import Text from '@Components/common/text/Text';
import FlexBox from '@Components/common/flexBox/FlexBox';
import InputMask from 'react-input-mask';
import {ETextColor} from '@Components/common/text/types';
import {EFlexDirection, EFlexWrap} from '@Components/common/flexBox/types';

const DeliveryFormSection = ({form, setFieldValue, resetError}) => {
  const {values, errors} = form;

  function handleFocus({target}) {
    const {name} = target;

    return resetError(name);
  }

  function handleChange({target}) {
    const {name, value} = target;

    return setFieldValue(name, value);
  }

  return (
    <FlexBox wrap={EFlexWrap.WRAP} direction={EFlexDirection.COLUMN}>
      <div className="dl-dotpay__two-in-row-content">
        <div className="dl-dotpay__two-in-row-column">
          <div className="dl-mb-1">
            <Header>{locale.translate('name')}</Header>
          </div>
          <div className="dl-mb-3">
            <input
              name="firstName"
              className={classNames(
                'dl-dotpay__input',
                {
                  'dl-dotpay__input--invalid': errors.firstName
                })
              }
              type="text"
              value={values.firstName ?? ''}
              onFocus={handleFocus}
              onChange={handleChange}
            />

            {
              errors.firstName && (
                <Text color={ETextColor.ERROR}>
                  {errors.firstName}
                </Text>
              )
            }
          </div>
        </div>
        <div className="dl-dotpay__two-in-row-column">
          <div className="dl-mb-1">
            <Header>{locale.translate('surname')}</Header>
          </div>
          <div className="dl-mb-3">
            <input
              name="lastName"
              className={classNames(
                'dl-dotpay__input',
                {
                  'dl-dotpay__input--invalid': errors.lastName
                })
              }
              type="text"
              onFocus={handleFocus}
              value={values.lastName ?? ''}
              onChange={handleChange}
            />
            {
              errors.lastName && (
                <Text color={ETextColor.ERROR}>
                  {errors.lastName}
                </Text>
              )
            }
          </div>
        </div>
      </div>
      <div className="dl-mb-1">
        <Header>{locale.translate('address')}</Header>
      </div>
      <div className="dl-mb-3">
        <input
          name="street"
          className={classNames(
            'dl-dotpay__input',
            {
              'dl-dotpay__input--invalid': errors.street
            })
          }
          type="text"
          value={values.street ?? ''}
          onFocus={handleFocus}
          onChange={handleChange}
        />

        {
          errors.street && (
            <Text color={ETextColor.ERROR}>
              {errors.street}
            </Text>
          )
        }
      </div>

      <div className="dl-dotpay__two-in-row-content">
        <div className="dl-dotpay__two-in-row-column">
          <div className="dl-mb-1">
            <Header>{locale.translate('city')}</Header>
          </div>

          <div className="dl-mb-3">
            <input
              name="city"
              className={classNames(
                'dl-dotpay__input',
                {
                  'dl-dotpay__input--invalid': errors.city
                })
              }
              type="text"
              value={values.city ?? ''}
              onFocus={handleFocus}
              onChange={handleChange}
            />

            {
              errors.city && (
                <Text color={ETextColor.ERROR}>
                  {errors.city}
                </Text>
              )
            }
          </div>
        </div>

        <div className="dl-dotpay__two-in-row-column">
          <div className="dl-mb-1">
            <Header>{locale.translate('postalCode')}</Header>
          </div>

          <div className="dl-mb-3">
            <InputMask
              name="postalCode"
              className={classNames(
                'dl-dotpay__input',
                {
                  'dl-dotpay__input--invalid': errors.postalCode
                })
              }
              mask="99-999"
              maskChar={null}
              value={values.postalCode ?? ''}
              onFocus={handleFocus}
              onChange={handleChange}
            />

            {
              errors.postalCode && (
                <Text color={ETextColor.ERROR}>
                  {errors.postalCode}
                </Text>
              )
            }
          </div>
        </div>
      </div>

    </FlexBox>
  );
};

DeliveryFormSection.propTypes = {
  form: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired
};

export default DeliveryFormSection;
