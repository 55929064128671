export const HOME_PATH = '/home';
export const CHECKOUT_PATH = '/checkout';
export const SUMMARY_PATH = '/summary';
export const INTERNAL_PAYMENT_LINK_PATH = '/internal-payment-link';
export const WAITING_FOR_PAYMENT_PATH = '/waiting-for-payment';

export const isCheckoutPath = path => path === CHECKOUT_PATH;
export const isSummaryPath = path => path === SUMMARY_PATH;
export const isHomePath = path => !path ||
(!isCheckoutPath(path) && !isSummaryPath(path) && !isInternalPaymentPath(path));
export const isInternalPaymentPath = path => path === INTERNAL_PAYMENT_LINK_PATH;
export const isWaitingForPaymentPath = path => path === WAITING_FOR_PAYMENT_PATH;

