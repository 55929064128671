import React from 'react';
import locale from '@Utils/locale';
import {string} from 'yup';
import {ALPHANUMERIC_PATTERN_WITH_DASH_SPACE, ALPHANUMERIC_POSTAL_CODE_MASK} from '../patterns';
import FormInput from '@Components/formComponents/FormInput';

export const FIELD_NAME = 'invoiceAddressPostalCode';
export const MIN_LENGTH = 3;
export const MAX_LENGTH = 10;

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .min(MIN_LENGTH, locale.translate('minLength', {length: MIN_LENGTH}))
  .max(MAX_LENGTH, locale.translate('maxLength', {length: MAX_LENGTH}))
  .matches(ALPHANUMERIC_PATTERN_WITH_DASH_SPACE, {message: locale.translate('invalidPostalCode')});

const InvoicePostalCodeSimpleField = ({name = FIELD_NAME, saveInStorage = false}) => (
  <FormInput
    componentProps={{
      label: locale.translate('postalCode'),
      sx: {width: 1},
      inputProps: {maxLength: MAX_LENGTH}
    }}
    name={name}
    mask={ALPHANUMERIC_POSTAL_CODE_MASK}
    saveInStorage={saveInStorage}
  />
);

export default InvoicePostalCodeSimpleField;
