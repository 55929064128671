import React from 'react';
import {EInvoiceFormType} from '../types';
import locale from '@Utils/locale';
import FormRadioGroup from '@Components/formComponents/FormRadioGroup';

export const FIELD_NAME = 'invoiceFormType';

interface Props {
  saveInStorage?: boolean
}

const InvoiceFormTypeField = ({saveInStorage}: Props) => {
  const formTypesRadioInputs = [
    {value: EInvoiceFormType.COMPANY, label: locale.translate('company')},
    {value: EInvoiceFormType.PRIVATE_PERSON, label: locale.translate('privatePerson')}
  ];

  return (
    <FormRadioGroup
      groupName="invoiceFormType"
      radioInputs={formTypesRadioInputs}
      styles={{mb: 2}}
      saveInStorage={saveInStorage}
      row
    />
  );
};

export default InvoiceFormTypeField;
