const getIframe = () => {
  let iframeByDomain;

  if (__IS_PRODUCTION__) {
    iframeByDomain = document.querySelector('iframe[src^="https://widget.droplabs.pl/index.html"]');
  } else {
    iframeByDomain =
        document.querySelector('frame[src^="index.html"') ||
        document.querySelector('frame[src^="https://widget-sandbox.droplabs.pl/index.html"') ||
        document.querySelector('frame[src^="https://widget-demo.droplabs.pl/index.html"');
  }

  if (iframeByDomain) {
    return iframeByDomain;
  }

  return document.querySelector('#dl-widget');
};

export default getIframe;
