import React, {useContext, useRef, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useMatch} from 'react-router-dom';
import LanguagePickerItem from './LanguagePickerItem';
import './language-picker.scss';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {LanguageContext} from '@Utils/LanguageProvider';
import {EAlignItems, EJustifyContent} from '@Components/common/flexBox/types';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import Box from '@mui/material/Box';
import {ELocale} from '@Consts/globals';

const LanguagePicker = () => {
  const pickerRef = useRef<HTMLElement>();
  const [isVisible, setVisibility] = useState(false);
  const {language, setLanguage} = useContext(LanguageContext);
  const facility = useSelector(getFacility);

  if (!facility) {
    return null;
  }
  const additionalLangs = facility?.company?.additionalLangs;
  const companyLanguage = facility?.company?.locale;
  const languageSet = [companyLanguage, ...additionalLangs];
  const isCheckout = !!useMatch(CHECKOUT_PATH);

  function handleHideMenu() {
    return setVisibility(false);
  }

  function handleMenuClick(event: Event) {
    event.stopPropagation();

    if (!pickerRef?.current) {
      return;
    }

    const wasClickedInside = pickerRef.current?.children[0].contains(event.target as Node);

    if (!wasClickedInside) {
      return handleHideMenu();
    }
  }

  function handleToggleMenu() {
    if (!isVisible) {
      document.addEventListener('click', handleMenuClick);
    } else {
      document.removeEventListener('click', handleMenuClick);
    }

    return setVisibility(!isVisible);
  }

  function handleSelectLanguage(language: ELocale) {
    document.removeEventListener('click', handleMenuClick);
    setVisibility(false);

    return setLanguage(language, isCheckout);
  }

  return (
    <Box className="dl-language-picker" ref={pickerRef}>
      <FlexBox
        data-testid="picker-toggler"
        onClick={handleToggleMenu}
        justify={EJustifyContent.CENTER}
        align={EAlignItems.CENTER}
        className="dl-language-picker__toggler dl-language-picker__item dl-cursor-pointer dl-h-100"
      >
        <img
          src={`img/icons/flags/${language}.svg`}
          className="dl-language-picker__menu-icon"
          alt={`selected-${language}`}
        />
        <KeyboardArrowDownOutlinedIcon />
      </FlexBox>

      {
        isVisible && (
          <div className="dl-language-picker__menu">
            {
              languageSet?.map(language => (
                <LanguagePickerItem
                  language={language}
                  onChange={handleSelectLanguage}
                  key={language}
                />
              ))
            }
          </div>
        )
      }
    </Box>
  );
};

export default LanguagePicker;
