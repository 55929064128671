import React from 'react';
import {ReactElement} from 'react-markdown/lib/react-markdown';
import FormCheckbox from '@Components/formComponents/FormCheckbox';

export const FIELD_NAME = 'isAgreeMarketingConsents';

interface IMarketingConsentsFieldProps {
  label: JSX.Element,
  helperText: string | ReactElement
}

const MarketingConsentsField = ({
  label,
  helperText
}: IMarketingConsentsFieldProps) => (
  <FormCheckbox
    name={FIELD_NAME}
    label={label}
    helperText={helperText}
    labelStyles={{
      wordBreak: 'break-word'
    }}
  />
);

export default MarketingConsentsField;
