import {useState} from 'react';
import {useDispatch} from 'react-redux';

export const useCancellableAction = (callback: Function, ...args: any[]) => {
  const [timeoutId, setTimeoutId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();

  function handleCancel() {
    clearTimeout(timeoutId);
  }

  function action() {
    return dispatch(callback(...args, setTimeoutId));
  }

  return [handleCancel, action];
};
