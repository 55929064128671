import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '../../utils/locale';
import Text from '../common/text/Text';
import {getNarrowedDateDescription} from './utils';
import ActivityContent, {SPACING_TYPE} from '../activities/activity/ActivityContent';
import {getParsedAdmissionDuration} from '@Utils/activity/activity';
import {ETextColor} from '@Components/common/text/types';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';

const ActivitySingleHotelNarrowed = ({basketItem}) => {
  const timezone = useSelector(getFacilityTimezone);
  const {admissionPolicy} = basketItem.activity;
  const {durationInMinutes} = admissionPolicy;
  const parsedDuration = getParsedAdmissionDuration(durationInMinutes);

  return (
    <div>
      {
        durationInMinutes ? (
          <ActivityContent spacing={SPACING_TYPE.TOP}>
            <AccessTimeOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
            <Text color={ETextColor.LIGHT}>
              {
                parsedDuration?.length ?
                  parsedDuration.join(' ') : locale.translate('durationUnlimitedSentence')
              }
            </Text>
          </ActivityContent>
        ) : null
      }

      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          {
            getNarrowedDateDescription(basketItem, timezone)
          }
        </Text>
      </ActivityContent>
    </div>
  );
};

export default ActivitySingleHotelNarrowed;
