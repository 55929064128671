import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';

const Select = ({name, onChange, options, className, selected}) => (
  <div
    className={classNames(
      'dl-select-wrapper',
      className
    )}
  >
    <select
      data-testid={name}
      name={name}
      className="dl-select"
      onChange={onChange}
      defaultValue={selected}
    >
      {
        options.map(({text, value, disabled}) => (
          <option
            value={value}
            key={`${value}+${text}`}
            disabled={disabled}
          >
            {text}
          </option>
        ))
      }
    </select>
  </div>
);

export default Select;
