import * as amplitude from '@amplitude/analytics-browser';

export const initializeConfig = (amplitudeId: string, optOut = true) => {
  const amplitudeApiKey = amplitudeId;

  // `defaultTracking: false` is not enough to cut
  //  extra user properties (web attributions: utm, google click, fb click)
  amplitude.init(amplitudeApiKey, '', {
    defaultTracking: {
      attribution: false,
      fileDownloads: false,
      formInteractions: false,
      sessions: false, // seesions will be enabled by session replay plugin in default
      pageViews: false
    },
    minIdLength: 1,
    optOut,
    flushIntervalMillis: 100
  });
};

export const disableEvents = () => {
  amplitude.setOptOut(true);
};

export const activateEvents = () => {
  amplitude.setOptOut(false);
};
