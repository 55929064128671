import {WidgetMode} from '../../widget/types';

const getScriptSrc = () => {
  if (__IS_PRODUCTION__) {
    return 'https://widget.droplabs.pl/widget.js';
  }

  if (__IS_DEMO__) {
    return 'https://widget-demo.droplabs.pl/widget.js';
  }

  // This source required mounted bubble only with same domain as sandbox or local build.
  // Source https://widget-demo.droplabs.pl/widget.js doesn't work with E2E tests.
  return 'widget.js';
};

function createWindowConfig(facilityId: number, onlineGroupId: number, dlWidgetScriptId: string = 'dlModal') {
  window.dlConfig = [
    ...window.dlConfig ?? [],
    {
      mode: WidgetMode.modal,
      selector: '.dl-bubble, .dl-bubble-tab, .dl-bubble-tab-mobile',
      facilityId,
      onlineGroupId,
      dlWidgetScriptId
    }
  ];
}

function mountScript() {
  const scriptSrc = getScriptSrc();
  let $script = document.querySelector(`script[src^="${scriptSrc}"]`);

  if (!$script) {
    $script = document.createElement('script');

    $script.setAttribute('src', scriptSrc);
    $script.setAttribute('class', 'dl-widget-script');
    $script.setAttribute('mode', 'modal');
    document.body.appendChild($script);
  }

  // To zadziała, dlaczego? Mutation Observer z widget.js wychwyci poniższą mutację, nawet jeśli atrybut jest identyczny
  $script.setAttribute('class', 'dl-widget-script');
}

export function mountWidget(facilityId: number, onlineGroupId: number, dlWidgetScriptId: string) {
  if (!window) {
    return null;
  }

  createWindowConfig(facilityId, onlineGroupId, dlWidgetScriptId);
  mountScript();
}
