import {createCurrentDate, createDate, getDatesDifference, getStartOf} from './dates/timezoneDates';

export const getBookingWindow = (date?: string) =>
  (date ? Math.abs(
    getDatesDifference(
      getStartOf(createCurrentDate(), 'day'),
      getStartOf(createDate(date), 'day'),
      'day'
    )
  ) : null);
