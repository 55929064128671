import {TFormData} from '@Components/summary/sendOrderForm/types';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {apiPost} from '@Utils/api/api';
import {getSendOrderUrl} from '@Utils/sendOrderForm';
import {useMutation} from '@tanstack/react-query';
import {useSelector} from 'react-redux';

type TUseSendOrder = {
  orderId: string | null
}

export const useSendOrder = ({
  orderId
}: TUseSendOrder) => {
  const config = useSelector(getConfiguration);
  const url = orderId ? getSendOrderUrl(config, orderId) : null;

  const {mutate, isLoading, isSuccess, isError, reset} = useMutation({
    mutationFn: (email: string) => apiPost(url, {...config, body: {email}})
  });

  const handleSendOrder = (data: TFormData) => {
    mutate(data.email);
  };

  return {
    handleSendOrder,
    isSuccess,
    isError,
    isLoading,
    reset
  };
};
