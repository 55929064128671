import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import './skeletonBox.scss';

const SkeletonBox = ({
  width = '100%',
  height = '30px',
  borderRadius = '4px'
}) => (
  <div
    className="dl-skeleton"
    style={{
      height,
      width,
      borderRadius
    }}
  />
);

SkeletonBox.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string
};

export default SkeletonBox;
