import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';

export const getAdditionsState = state => state.additions || null;

export const getAdditionsStatus = (state, activityId) => {
  const additionsState = getAdditionsState(state);

  return additionsState[activityId] || null;
};

export const getAdditionsFetchingInProgressStatus = state => {
  const additionsState = getAdditionsState(state);

  const additionsFetchingState = Object.values(additionsState).find(additionState => additionState === IN_PROGRESS);

  return additionsFetchingState || null;
};

export const getActivityAdditionsStatus = (state, activityId) => {
  const questionsState = getAdditionsState(state);

  return questionsState[activityId] || null;
};

export const getAdditionsFetchingEverySuccessStatus = state => {
  const additionsState = getAdditionsState(state);

  const additionsFetchingState = Object.values(additionsState).every(additionState => additionState === SUCCESS);

  return additionsFetchingState || null;
};

export const getAdditionsFetchingFailureStatus = state => {
  const additionsState = getAdditionsState(state);

  const additionsFetchingState = Object.values(additionsState).every(additionState => additionState === FAILURE);

  return additionsFetchingState || null;
};
