import FormMaskInput from '@Components/formComponents/FormMaskInput';
import {BLIK_CODE_MASK, BLIK_CODE_PATTERN} from '@Components/invoice/patterns';
import locale from '@Utils/locale';
import React from 'react';
import {string} from 'yup';

type Props = {
  name?: string,
  saveInStorage?: boolean
}

export const schema = () => string()
  .required(locale.translate('typeBlikCode'))
  .matches(BLIK_CODE_PATTERN, {message: locale.translate('blikCodePatternError')});

export const FIELD_NAME = 'blikCode';

const BlikField = ({name = FIELD_NAME, saveInStorage}: Props) => (
  <FormMaskInput
    name={name}
    saveInStorage={saveInStorage}
    componentProps={{
      label: locale.translate('blikCode'),
      inputMode: 'numeric',
      inputProps: {
        autoComplete: 'off'
      }
    }}
    mask={BLIK_CODE_MASK}
  />
);

export default BlikField;
