import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import EmailInfo from './EmailInfo';
import {useSummaryData} from '../../hooks/useSummaryData';

const orderIdTemplate = 'DROP/PID/0000000000';

const PaymentComplete = () => {
  const {
    onlineOrder,
    businessOrder,
    internalOrder,
    paymentState,
    isPreviewMode,
    previewOrderEmail,
    isWigetOnlineType
  } = useSummaryData();

  if (!onlineOrder && !businessOrder && !internalOrder && !paymentState) {
    return null;
  }

  const previewModeFormattedOrderId = isPreviewMode ? orderIdTemplate : null;

  const orderFormattedId = onlineOrder?.orderFormattedId ??
    businessOrder?.orderFormattedId ??
    internalOrder?.orderFormattedId ??
    previewModeFormattedOrderId;
  const email = onlineOrder?.orderBuyer?.email ??
    businessOrder?.orderBuyer?.email ??
    (isPreviewMode && previewOrderEmail);

  return (
    <>
      <CheckCircleOutlineIcon sx={{color: 'success.main', width: 40, height: 40, mb: 1.5, align: 'center'}} />
      <Typography variant="h4" sx={{mb: {xs: 2, md: 4}, fontWeight: 500, textAlign: 'center'}}>
        {
          paymentState === 'completed' ? locale.translate('paymentAccepted') :
            paymentState === 'skipped' || isPreviewMode ? locale.translate('orderCreated') :
              null
        }
      </Typography>
      <Stack spacing={2}>
        {
          orderFormattedId && (
            <Typography data-testid="order-number" sx={{color: 'text.secondary', textAlign: 'center'}}>
              {locale.translate('orderNumber')}{' '}{orderFormattedId}
            </Typography>
          )
        }
        {
          email && (
            <Typography data-testid="order-buyer-email" sx={{color: 'text.secondary', textAlign: 'center'}} >
              {locale.translate('orderSendOnEmail')}{' '}
              <Typography component="span" sx={{fontWeight: 700}}>{email}</Typography>
            </Typography>
          )
        }
        {
          !!onlineOrder || (isPreviewMode && isWigetOnlineType) ?
            <EmailInfo /> : null
        }
      </Stack>
    </>
  );
};

export default PaymentComplete;
