import {activateEvents, disableEvents} from '@Utils/amplitude/config';
import {useEffect, useState} from 'react';

export type CookieConsents = {
  statistics?: boolean;
}

type UpdateConsentsMessage = {
  data: {
    name: string;
    cookieConsents: CookieConsents
  }
}

export const updateConsentsEvent = 'updateConsentsEvent';

export const useAmplitude = () => {
  const [cookieConsents, setCookieConsents] = useState<CookieConsents | null>(null);

  const handleMessage = (message: UpdateConsentsMessage | MessageEvent) => {
    if (message.data.name === updateConsentsEvent) {
      setCookieConsents(message.data.cookieConsents);
    }
  };

  useEffect(() => {
    if (cookieConsents) {
      const consentsFulfilled = cookieConsents?.statistics;

      if (consentsFulfilled) {
        activateEvents();
      } else {
        disableEvents();
      }
    }
  }, [cookieConsents]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleAmplitudeInitialize = (cookieConsents?: CookieConsents) => {
    if (cookieConsents) {
      setCookieConsents(cookieConsents);
    } else {
      activateEvents();
    }
  };

  return {
    handleAmplitudeInitialize
  };
};
