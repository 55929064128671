import {setStorage} from '@Features/storage/storageActions';
import Stack from '@mui/material/Stack';
import {ResponsiveStyleValue} from '@mui/system';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {Theme, SxProps} from '@mui/material/styles';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {useDispatch} from 'react-redux';

export interface IRadioInput {
  value: string,
  label: string,
  children?: React.JSX.Element,
  labelAdornment?: React.JSX.Element
}

export interface IFormRadioGroup {
  groupName: string,
  radioInputs: IRadioInput[],
  row?: boolean,
  styles?: SxProps<Theme>,
  saveInStorage?: boolean,
  spacing?: ResponsiveStyleValue<number | string>
}

const FormRadioGroup = ({
  groupName,
  radioInputs,
  row,
  styles,
  saveInStorage,
  spacing = 0
}: IFormRadioGroup) => {
  const {control} = useFormContext();
  const dispatch = useDispatch();

  function handleChange(value: string) {

    if (saveInStorage) {
      dispatch(setStorage(groupName, value, true));
    }
  }

  return (
    <Controller
      control={control}
      name={groupName}
      render={({field: {value, onChange}}) => (
        <RadioGroup
          value={value}
          onChange={(event, value) => {onChange(event, value), handleChange(value);}}
          sx={styles}
        >
          <Stack spacing={spacing} direction={row ? 'row' : 'column'}>
            {
              radioInputs.map(radioInput => (
                <Box key={radioInput.value}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <FormControlLabel
                      key={radioInput.value}
                      value={radioInput.value}
                      control={<Radio />}
                      label={radioInput.label}
                      sx={{width: 1}}
                    />
                    {radioInput.labelAdornment}
                  </Box>
                  {
                    radioInput.value === value && !!radioInput?.children && (
                      <>
                        {radioInput.children}
                      </>
                    )
                  }
                </Box>
              ))
            }
          </Stack>
        </RadioGroup>
      )}
    />
  );
};

export default FormRadioGroup;
