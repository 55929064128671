import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '@Components/common/text/Text';
import {getAffiliationHash, getWidgetType} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {EVENT_DISCOUNT_RESULT, EVENT_UNFOLD_DISCOUNT_BOX} from '@Utils/events';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {isProgress, isSuccess} from '@Utils/status';
import {useDispatch, useSelector} from 'react-redux';
import {calculateDiscount} from '@Features/discount/discountActions';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getBasketValue, getDiscountList, getDiscountTextMessage} from '@Utils/basket';
import {getDiscountItems} from '@Utils/checkout';
import {EStatus} from '@Consts/status';
import {isWidgetPreview} from '@Utils/widgetType';
import DiscountMessage from './DiscountMessage';
import {getDiscount} from '@Features/discount/discountSelectors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DiscountCodeField from './formFields/DiscountCodeField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import DiscountGenericError from './DiscountGenericError';
import TextButton from '@Components/textButton/TextButton';
import {IBasketItem} from '@Consts/types';

const Discounts = () => {
  const dispatch = useDispatch();
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const widgetType = useSelector(getWidgetType);
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const discount = useSelector(getDiscount);
  const {id: facilityId, name: facilityName, company} = {...facility};
  const [code, setCode] = useState('');
  const [isExpanded, setExpand] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [showGenericError, setShowGenericError] = useState(false);
  const [status, setStatus] = useState<EStatus | null>(null);
  const withAdditions = basketItems.some(item => item.selections.additions?.length);
  const isWidgetTypePreview = isWidgetPreview(widgetType);

  function handleShowDiscounts() {
    pubsub.trigger(EVENT_UNFOLD_DISCOUNT_BOX,
      {
        facilityId,
        facilityName,
        industry: company?.industry,
        affiliationHash
      }
    );

    setExpand(true);
    setMessage(null);
    setShowGenericError(false);
    setStatus(null);
  }

  function showSuccessInfo(code: string) {
    const itemsDiscountsData = getDiscountList(basketItems);
    const message = getDiscountTextMessage(itemsDiscountsData, code, withAdditions);

    pubsub.trigger(EVENT_DISCOUNT_RESULT, {
      facilityId,
      facilityName,
      industry: company?.industry,
      affiliationHash,
      result: 'ok',
      message
    });
    setMessage(message);
  }

  function logError(message: string) {
    pubsub.trigger(EVENT_DISCOUNT_RESULT, {
      facilityId,
      facilityName,
      industry: company?.industry,
      affiliationHash,
      result: 'error',
      message
    });
  }

  function handleDiscountApply() {
    const priceAfterDiscount = getBasketValue(basketItems, true);
    const priceBeforeDiscount = getBasketValue(basketItems, false);

    if (priceBeforeDiscount > 0 && priceAfterDiscount >= priceBeforeDiscount) {
      const errorMessage = locale.translate('invalidDiscountCode', {code});

      logError(errorMessage);
      setMessage(errorMessage);
      setShowGenericError(false);
      setStatus(EStatus.FAILURE);
    } else {
      setStatus(EStatus.SUCCESS);
      showSuccessInfo(code);
    }
  }

  async function handleUseDiscount(
    event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) {
    event.preventDefault();

    if (code) {
      setStatus(EStatus.IN_PROGRESS);
      try {
        await dispatch(calculateDiscount(code, getDiscountItems(basketItems)));
        handleDiscountApply();
      } catch (error) {
        setShowGenericError(true);
        setMessage('');
        logError(error instanceof Error ? error?.message : '');
        setStatus(EStatus.FAILURE);
      }
    }
  }

  function handleOnFocus() {
    setMessage(null);
    setShowGenericError(false);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value: code} = event.target;

    return setCode(code.toUpperCase());
  }

  return (
    <Box mt={3} mb={1}>
      {
        isExpanded ? (
          <>
            {
              !isSuccess(status) && !isWidgetTypePreview && (
                <Stack spacing={2}>
                  <DiscountCodeField
                    value={code}
                    errorMessage={showGenericError ? <DiscountGenericError /> : message}
                    onChange={handleInputChange}
                    onFocus={handleOnFocus}
                  />
                  <Button
                    component="button"
                    data-testid="discount-submit-button"
                    variant="outlined"
                    onClick={handleUseDiscount}
                    color="success"
                    {...isProgress(status) && {endIcon: <CircularProgress size={18} />}}
                  >
                    {locale.translate('useDiscountCode')}
                  </Button>
                </Stack>
              )
            }
            {
              isWidgetTypePreview && (
                <Text >
                  {locale.translate('discountsAreDisabledInPreview')}
                </Text>
              )
            }

            {
              (isSuccess(status) && discount) &&
                <DiscountMessage
                  data-testid="discount-message"
                  basketItems={basketItems}
                  withAdditions={withAdditions}
                  code={code}
                />
            }
          </>
        ) : (
          <>
            <Stack direction="row" alignItems="center" data-testid="add-discount" >
              <Typography>{locale.translate('withDiscountLabel')}</Typography>
              <TextButton
                styles={{ml: 0.5}}
                onClick={handleShowDiscounts}
              >
                {locale.translate('add')}
              </TextButton>

            </Stack>
          </>
        )
      }
    </Box>
  );
};

export default Discounts;
