
import dayjs, {ConfigType, Dayjs, ManipulateType, OpUnitType, UnitType, isDayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timeZonePlugin from 'dayjs/plugin/timezone';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import {isUTCDateString} from '@Utils/typeGuards';

// locales are needed for set dayjs locale
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import 'dayjs/locale/cs';
import 'dayjs/locale/sk';
import 'dayjs/locale/it';
import 'dayjs/locale/uk';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import locale from '@Utils/locale';
import {
  DOT_DAY_FORMAT_REVERSED,
  DOT_DAY_FORMAT_REVERSED_HOUR_WITH_DATE,
  DOT_DAY_FORMAT_REVERSED_HOUR_WITH_DAY,
  DOT_DAY_FORMAT_REVERSED_WITH_DAY
} from '@Utils/dayjs/const';

dayjs.extend(utc);
dayjs.extend(timeZonePlugin);
dayjs.extend(isTomorrowPlugin);

export type DateType = Dayjs;

export const setDefaultTimezone = (timezone: string) => {
  dayjs.tz.setDefault(timezone);
};

export const changeDatesLocale = (locale: string) => {
  dayjs.locale(locale);
};

export const createDate = (
  date: string
) => {
  if (isUTCDateString(date)) {
    // It is utc date that returned from api
    return dayjs(date).tz();
  }

  return dayjs.tz(date);
};

export const createCurrentDate = () => dayjs.tz();

export const isSameDate = (firstDate: Dayjs, secondDate: ConfigType, unit: OpUnitType = 'day') =>
  firstDate.isSame(secondDate, unit);

export const isTomorrow = (date: Dayjs) => isSameDate(date, createCurrentDate().add(1, 'day'));

export const isToday = (date: Dayjs) => isSameDate(date, createCurrentDate());

export const getYear = (date: Dayjs) => date.year();

export const getMonth = (date: Dayjs) => date.month();

export const getDate = (date: Dayjs) => date.date();

export const getHours = (date: Dayjs) => date.hour();

export const getMinutes = (date: Dayjs) => date.minute();

export const getFormattedDate = (date: string | Dayjs, template?: string) => {
  if (isDayjs(date)) {
    return date.format(template);
  }

  return createDate(date).format(template);
};

export const getDaysInMonth = (date: Dayjs) => date.daysInMonth();

export const getFirstOfMonth = (date: Dayjs) => date.startOf('month');

export const getStartOf = (date: Dayjs, unit: OpUnitType) => date.startOf(unit);

const getTimezoneOffsetChange = (firstDate: Dayjs, secondDate: Dayjs) =>
  secondDate.utcOffset() - firstDate.utcOffset();

const correctTimezoneOffset = (dateToCorrect: Dayjs, baseDate: Dayjs) => {
  const offsetChange = getTimezoneOffsetChange(dateToCorrect, baseDate);

  if (offsetChange > 0) {
    return dateToCorrect.add(offsetChange, 'minutes').tz();
  }

  if (offsetChange < 0) {
    return dateToCorrect.subtract(-offsetChange, 'minutes').tz();
  }

  return dateToCorrect;
};

export const addToDate = (
  date: Dayjs,
  amount: number,
  unit: ManipulateType
) => {
  const newDateTz = date.utc().add(amount, unit).tz();

  return correctTimezoneOffset(newDateTz, date);
};

export const substructFromDate = (
  date: Dayjs,
  amount: number,
  unit: ManipulateType
) => {
  const newDateTz = date.utc().subtract(amount, unit).tz();

  return correctTimezoneOffset(newDateTz, date);
};

export const getStartOfYesterday = () =>
  getStartOf(substructFromDate(createCurrentDate(), 1, 'days'), 'day');

export const isAfter = (date: Dayjs, dateToCompare: ConfigType, unit: OpUnitType = 'day') =>
  date.isAfter(dateToCompare, unit);

export const isBefore = (date: Dayjs, dateToCompare: ConfigType, unit: OpUnitType = 'day') =>
  date.isBefore(dateToCompare, unit);

export const isDateInRange = (date: Dayjs, startDate: Dayjs, endDate: Dayjs, unit: OpUnitType = 'day') => {
  const minDateExceded = isBefore(date, startDate, unit);
  const maxDateExceded = isAfter(date, endDate, unit);

  return !minDateExceded && !maxDateExceded;
};

export const getDatesDifference = (firstDate: Dayjs, secondDate: Dayjs, unit: OpUnitType = 'day') =>
  secondDate.diff(firstDate, unit);

export const setDate = (date: Dayjs, unit: UnitType, value: number) =>
  date.set(unit, value).tz();

export const getFormattedDateWithTime = (date: DateType, dateTime: string | null) => {
  const time = dateTime || '00:00';
  const [hours, minutes] = time.split(':');

  const dateWithHours = setDate(date, 'hours', +hours);
  const dateWithTime = setDate(dateWithHours, 'minutes', +minutes);

  return getFormattedDate(dateWithTime);
};

export const getFormattedDaysAndTime = (date: Dayjs, format = DOT_DAY_FORMAT_REVERSED_HOUR_WITH_DAY) => {
  if (isToday(date)) {
    return `${locale.translate('today')}, ${getFormattedDate(date, DOT_DAY_FORMAT_REVERSED_HOUR_WITH_DATE)}`;
  }

  if (isTomorrow(date)) {
    return `${locale.translate('tomorrow')}, ${getFormattedDate(date, DOT_DAY_FORMAT_REVERSED_HOUR_WITH_DATE)}`;
  }

  return getFormattedDate(date, format);
};

export const getFormattedDays = (date: Dayjs, format = DOT_DAY_FORMAT_REVERSED_WITH_DAY) => {
  if (isToday(date)) {
    return `${locale.translate('today')}, ${getFormattedDate(date, DOT_DAY_FORMAT_REVERSED)}`;
  }

  if (isTomorrow(date)) {
    return `${locale.translate('tomorrow')}, ${getFormattedDate(date, DOT_DAY_FORMAT_REVERSED)}`;
  }

  return getFormattedDate(date, format);
};
