export enum ECheckoutViewsTypes {
  PARTICIPANTS = 'PARTICIPANTS',
  DATES = 'DATES',
  TIMES = 'TIMES',
  QUESTIONS = 'QUESTIONS',
  ADDITIONS = 'ADDITIONS',
  HOTEL_NARROWED = 'HOTEL_NARROWED',
  SHIPPING_METHODS = 'SHIPPING_METHODS',
  PARTICIPANTS_GROUP = 'PARTICIPANTS_GROUP',
  UPSELL = 'UPSELL'
}

export const ANSWER_MAX_LENGTH = 255;
