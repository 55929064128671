import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars

import locale from '../../utils/locale';
import {DEFAULT_PRIVACY_POLICY_URL, ONLINE_SALE_TERMS_OF_SERVICE_URL} from '@Consts/urls';
import Box from '@mui/material/Box';
import TextLink from '@Components/common/TextLink';

const TermsOfServiceLabel = ({facility}) => {
  const {name: facilityName, company, salePolicy} = facility;
  const {termsOfServiceURL: facilityTos, privacyPolicyURL, termsOfSaleURL: facilityTermsOfSale} = salePolicy;
  const {onlineSaleTermsOfServiceUrl} = company;
  const tos = facilityTermsOfSale || onlineSaleTermsOfServiceUrl || ONLINE_SALE_TERMS_OF_SERVICE_URL;
  const privacyPolicy = privacyPolicyURL || DEFAULT_PRIVACY_POLICY_URL;
  const termsOfServiceLink =
    <TextLink href={tos}>{locale.translate('termsOfService')}</TextLink>;
  const privacyPolicyLink =
    <TextLink href={privacyPolicy}>{locale.translate('privacyPolicyAccept')}</TextLink>;
  const facilityTermsOfServiceLink =
    <TextLink href={facilityTos}>{locale.translate('facilityTermsOfService', {facilityName})}</TextLink>;

  return (
    <Box component="span" data-testid="terms-of-service-label">
      {locale.translate('termsOfServiceAgreement')}{' '}
      <Box component="span">
        {
          facilityTos ? (
            <>
              {termsOfServiceLink},{' '}
              {privacyPolicyLink}{' '}
              {locale.translate('and')}{' '}
              {facilityTermsOfServiceLink}
            </>
          ) : (
            <>
              {termsOfServiceLink}{' '}
              {locale.translate('and')}{' '}
              {privacyPolicyLink}
            </>
          )
        }
      </Box>
    </Box>
  );
};

export default TermsOfServiceLabel;
