import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import RadioButton from '../common/form/RadioButton';

const QuestionChoice = ({
  value,
  checked,
  setAnswer,
  setInvalid
}) => {
  function handleChange() {
    setInvalid(false);

    return setAnswer(value);
  }

  return (
    <li className="dl-dotpay__choice" key={value}>
      <RadioButton
        id={`answer-${value}`}
        name="answer"
        value={value}
        label={value}
        checked={checked}
        onChange={handleChange}
      />
    </li>
  );
};

export default QuestionChoice;
