import {useState} from 'react';

export const useDisclosure = (initialValue = false):
[boolean, {toggle: VoidFunction, onClose: VoidFunction}] => {
  const [isOpen, setIsOpen] = useState(initialValue);

  const toggle = () => setIsOpen(value => !value);
  const onClose = () => setIsOpen(false);

  return [isOpen, {toggle, onClose}];
};
