import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {EVENT_SEES_SHIPPING_METHODS, EVENT_SELECTS_SHIPPING_METHOD} from '@Utils/events';
import pubsub from '@Utils/pubsub';
import ShippingMethodChoice from './ShippingMethodChoice';

const ShippingMethodSelect = ({
  selectedShippingMethodId,
  handleChangeMethod,
  shippingMethods,
  analyticsData
}) => {

  useEffect(() => {
    pubsub.trigger(EVENT_SEES_SHIPPING_METHODS, analyticsData);
  }, []);

  useEffect(() => {
    // The fixed digital shipping method with ID 0 is not being sent to analytics
    if (selectedShippingMethodId) {
      pubsub.trigger(EVENT_SELECTS_SHIPPING_METHOD, {...analyticsData, selectedShippingMethodId});
    }
  }, [selectedShippingMethodId]);

  return (
    <ul className="dl-dotpay__choices-list dl-dotpay__content">
      {
        shippingMethods && shippingMethods.map(({name, price, id}) => (
          <ShippingMethodChoice
            value={id}
            checked={selectedShippingMethodId === id}
            price={price}
            label={name}
            onMethodChange={handleChangeMethod}
            key={id}
          />
        ))
      }

    </ul>
  );
};

export default ShippingMethodSelect;
