import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from '@Components/common/header/Header';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';

const AdmissionComment = ({description, className}) => (
  <div className={className}>
    <Header>
      {locale.translate('attention')}
    </Header>

    <Text
      wrap
      className={classNames(
        'dl-time-picker__option',
        'dl-time-picker__option--selected',
        'dl-time-picker__option--single',
        'dl-time-picker__option--tall'
      )}
    >
      {description}
    </Text>
  </div>
);

AdmissionComment.propTypes = {
  description: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default AdmissionComment;
