import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars

const TicketSeparator = () => (
  <div className="c-ticket__separator">
    <div className="c-ticket__separator-corner c-ticket__separator-corner--left" />
    <div className="c-ticket__separator-center">
      <div className="c-ticket__separator-divider" />
      <div className="c-ticket__separator-divider" />
    </div>
    <div className="c-ticket__separator-corner c-ticket__separator-corner--right" />
  </div>
);

export default TicketSeparator;
