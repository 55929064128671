import React, {useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PaymentDetails from '@Components/summary/PaymentDetails';
import GoToActivities from '@Components/navigation/GoToActivities';
import NewOrderButton from '@Components/summary/NewOrderButton';
import Stack from '@mui/material/Stack';
import Tickets from '@Components/summary/tickets/Tickets';
import Carousel from '@Components/carousel/Carousel';
import SendOrderForm from '@Components/summary/sendOrderForm/SendOrderForm';
import {useSummaryData} from '../../hooks/useSummaryData';
import OrderMore from '@Components/summary/OrderMore';
import CrossSellOffer from '@Components/summary/crossSellOffer/CrossSellOffer';
import InternalOrderSummary from '@Components/summary/internalOrderSummary/InternalOrderSummary';
import {EPaymentMethod} from '@Components/checkout/types';
import BlikPaymentSummary from '@Components/summary/blikPayment/BlikPaymentSummary';
import {useScroll} from '@Utils/hooks/useScroll';

const SummaryPage = () => {
  const {
    isInitialLoading,
    isWigetOnlineType,
    crossSellOffer,
    isWigetInternalType,
    isWidgetBusinessType,
    paymentMethod,
    isPaymentComplete
  } = useSummaryData();
  const SummaryPageRef = useRef<HTMLElement>();
  const {scrollToElement} = useScroll();

  function scrollEffect() {
    if (SummaryPageRef?.current) {
      scrollToElement(SummaryPageRef.current);
    }
  }

  useEffect(() => {
    setTimeout(scrollEffect, 550);
  }, []);

  if (isInitialLoading) {
    return (
      <Box ref={SummaryPageRef} sx={{p: 3, bgcolor: 'background.main'}} >
        {isWigetOnlineType && <GoToActivities />}
        <Stack alignItems="center">
          <CircularProgress size={32} sx={{my: 1.5}} />
        </Stack>
      </Box>
    );
  }

  if (paymentMethod === EPaymentMethod.BLIK && !isPaymentComplete) {
    return (<Box ref={SummaryPageRef}><BlikPaymentSummary /></Box>);
  }

  const isMoreSectionsInCarousel = !isWigetOnlineType || (isWigetOnlineType && !!crossSellOffer);

  return (
    <Box ref={SummaryPageRef} sx={{p: {xs: 1.25, md: 3}, bgcolor: 'background.main'}} >
      {isWigetOnlineType && <GoToActivities />}
      <PaymentDetails />
      {!isWigetOnlineType && <NewOrderButton isWigetInternalType={isWigetInternalType} />}
      {
        isWigetInternalType ? <InternalOrderSummary /> : null
      }
      {
        isWigetOnlineType || isWidgetBusinessType ? (
          <>
            <Carousel carouselStyles={{mt: 6}}>
              <>
                <Tickets isMoreSectionsInCarousel={isMoreSectionsInCarousel} />
                {
                  !isWigetOnlineType &&
                  <SendOrderForm />
                }
                {
                  isWigetOnlineType && !!crossSellOffer &&
                  <CrossSellOffer crossSellOffer={crossSellOffer} />
                }
              </>
            </Carousel>
            {isWigetOnlineType && <OrderMore />}
          </>

        ) : null
      }

    </Box>
  );
};

export default SummaryPage;

