import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';

export const getQuestionsState = state => state.questions || null;

export const getQuestionsStatus = (state, activityId) => {
  const questionsState = getQuestionsState(state);

  return questionsState[activityId] || null;
};

export const getQuestionsFetchingInProgressStatus = state => {
  const questionsState = getQuestionsState(state);

  const questionsFetchingState = Object.values(questionsState).find(questionState => questionState === IN_PROGRESS);

  return questionsFetchingState || null;
};

export const getActivityQuestionsStatus = (state, activityId) => {
  const questionsState = getQuestionsState(state);

  return questionsState[activityId] || null;
};

export const getQuestionsFetchingEverySuccessStatus = state => {
  const questionsState = getQuestionsState(state);

  const questionsFetchingState = Object.values(questionsState).every(questionState => questionState === SUCCESS);

  return questionsFetchingState || null;
};

export const getQuestionsFetchingFailureStatus = state => {
  const questionsState = getQuestionsState(state);

  const questionsFetchingState = Object.values(questionsState).every(questionState => questionState === FAILURE);

  return questionsFetchingState || null;
};
