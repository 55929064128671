import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import CalendarPanel from './CalendarPanel';
import CalendarDays from './CalendarDays';
import {getMonthDates, LAST_MONTH_INDEX} from '@Utils/date';
import './calendar.scss';
import Loader, {LOADER_TYPE, LOADER_THEME} from '@Components/common/loader/Loader';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';

const Calendar = ({
  value,
  datesGroupedByDay,
  dayOffers,
  onChange,
  onMonthChange,
  showPrices,
  showAvailableSeatsForDay,
  isTestB
}) => {
  const timezone = useSelector(getFacilityTimezone);
  const [selectedYear, setSelectedYear] = useState(value.get('year'));
  const [selectedMonth, setSelectedMonth] = useState(value.get('month'));
  const datesGroupedByWeeks = useMemo(() => getMonthDates(selectedYear, selectedMonth, timezone), [selectedMonth]);

  React.useEffect(() => {
    onMonthChange({month: selectedMonth, year: selectedYear});
  }, [selectedMonth]);

  function handleSelectPrevMonth() {
    if (selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
      return setSelectedMonth(LAST_MONTH_INDEX);
    }

    return setSelectedMonth(selectedMonth - 1);
  }

  function handleSelectNextMonth() {
    if (selectedMonth === LAST_MONTH_INDEX) {
      setSelectedYear(selectedYear + 1);
      return setSelectedMonth(0);
    }

    return setSelectedMonth(selectedMonth + 1);
  }

  return (
    <Box
      sx={{position: 'relative'}}
      className={isTestB ? '' : 'dl-calendar'}
    >
      <CalendarPanel
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        onPrevMonthClick={handleSelectPrevMonth}
        onNextMonthClick={handleSelectNextMonth}
        isTestB={isTestB}
      />

      <CalendarDays
        weeks={datesGroupedByWeeks}
        datesGroupedByDay={datesGroupedByDay}
        dayOffers={dayOffers}
        onDayClick={onChange}
        showPrices={showPrices}
        showAvailableSeatsForDay={showAvailableSeatsForDay}
        isTestB={isTestB}
      />

      {!datesGroupedByDay && !dayOffers ? (
        <>
          {isTestB ? (
            <Box sx={loaderStyles}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Loader type={LOADER_TYPE.COVER} theme={LOADER_THEME.DARK} />
          )}
        </>
      ) : null}

    </Box>
  );
};

Calendar.propTypes = {
  value: PropTypes.instanceOf(dayjs).isRequired,
  onChange: PropTypes.func
};

const loaderStyles = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  backgroundColor: '#FFFFFFCC',
  color: 'grey.400'
};

export default Calendar;
