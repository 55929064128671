import {ActiveView} from '@Components/checkout/types';
import {getCalendarValue} from '@Components/checkoutPages/admissionRealization/utils';
import {IBasketItem} from '@Consts/types';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';

export const useAdmissionRealization = (activeView: ActiveView) => {
  const basketItemsState: IBasketItem[] = useSelector(getBasketItems);
  const {items: activeViewBasketItems, type, id} = activeView;
  const basketItems = basketItemsState.filter(
    basketItemState => activeViewBasketItems.find(({uid}) => uid === basketItemState?.uid)
  );
  const itemsVariants = basketItems.map(({variantId}) => variantId);
  const basketItem = basketItems[0];
  const {variant, selections, activity} = basketItem;
  const {numberOfAdmissionDatesMax} = variant;
  const {id: activityId, name: activityName, salePolicy, admissionPolicy, passType} = activity;
  const {firstAdmissionDate} = admissionPolicy;
  const {canShowItemsLeft} = salePolicy;
  const {time: selectedTime, dates: selectedDates, dayOffer: selectedDayOffer} = selections;
  const selectedDate = selectedDates?.length && selectedDates[0] || null;
  const itemsUniqueVariantsIds = [...new Set(itemsVariants)];
  const timezone = useSelector(getFacilityTimezone)!;
  const calendarValue = getCalendarValue(timezone, firstAdmissionDate, selectedDate?.date);

  return {
    id,
    numberOfAdmissionDatesMax,
    activityId,
    activityName,
    passType,
    selectedTime,
    itemsUniqueVariantsIds,
    calendarValue,
    itemsVariants,
    selectedDates,
    selectedDayOffer,
    canShowItemsLeft,
    items: basketItems,
    type,
    firstAdmissionDate,
    selectedDate,
    variant,
    selections
  };
};
