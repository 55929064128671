import locale from '@Utils/locale';
import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import QuestionChoice from './QuestionChoice';

const QuestionChoices = ({
  value,
  choices,
  invalid,
  setAnswer,
  setInvalid
}) => (
  <ul className="dl-dotpay__choices-list">
    {
      choices.map(({name}) => (
        <QuestionChoice
          value={name}
          checked={value === name}
          setAnswer={setAnswer}
          setInvalid={setInvalid}
          key={name}
        />
      ))
    }

    {
      invalid && (
        <div className="dl-text--error">
          {locale.translate('pleaseChooseOneOption')}
        </div>
      )
    }
  </ul>
);

export default QuestionChoices;
