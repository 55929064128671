import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './loader.scss';
import FlexBox from '../flexBox/FlexBox';
import {EAlignItems, EJustifyContent} from '../flexBox/types';

export const LOADER_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const LOADER_TYPE = {
  DEFAULT: 'default',
  COVER: 'cover',
  withNavigation: 'with-navigation'
};

const Loader = ({theme = LOADER_THEME.LIGHT, type = LOADER_TYPE.DEFAULT, withNavigation, anchor}) => {
  const Content = (
    <FlexBox
      justify={EJustifyContent.CENTER}
      align={EAlignItems.CENTER}
      className={classNames(
        'dl-loading',
        {
          [`dl-loading--${theme}`]: theme,
          [`dl-loading--${type}`]: type,
          [`dl-loading--${withNavigation}`]: withNavigation
        }
      )}
    >
      <div className="dl-loading__spinner" />
    </FlexBox>
  );

  if (anchor) {
    return (
      <div className="dl-loading-anchor">
        {Content}
      </div>
    );
  }

  return Content;
};

Loader.propTypes = {
  theme: PropTypes.oneOf(Object.values(LOADER_THEME)),
  type: PropTypes.oneOf(Object.values(LOADER_TYPE)),
  anchor: PropTypes.bool
};

export default Loader;
