import React from 'react';
import {useFormContext} from 'react-hook-form';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';
import InvoicePostalCodeField from './formFields/InvoicePostalCodeField';
import InvoicePostalCodeSimpleField from './formFields/InvoicePostalCodeSimpleField';

export const FIELD_NAME = 'invoiceAddressPostalCode';
export const COUNTRY_FIELD_NAME = 'invoiceAddressCountry';

const InvoicePostalCodeSection = ({
  fieldName = FIELD_NAME,
  countryFieldName = COUNTRY_FIELD_NAME,
  saveInStorage = false
}) => {
  const {watch} = useFormContext();
  const country: CountryType = watch(countryFieldName);

  return country.code !== InvoiceAddressCountry.PL ?
    <InvoicePostalCodeSimpleField name={fieldName} saveInStorage={saveInStorage} /> :
    <InvoicePostalCodeField name={fieldName} saveInStorage={saveInStorage} />;
};

export default InvoicePostalCodeSection;
