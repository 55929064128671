import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '@Components/common/flexBox/FlexBox';
import Text from '@Components/common/text/Text';
import {ETextModifier, ETextTransform} from '@Components/common/text/types';
import {EAlignItems, EJustifyContent} from '@Components/common/flexBox/types';
import {ELocale} from '@Consts/globals';

type Props = {
  language: ELocale,
  onChange: (locale: ELocale) => void
}

const LanguagePickerItem = ({language, onChange}: Props) => {
  function handleClick() {
    return onChange(language);
  }

  return (
    <FlexBox
      align={EAlignItems.CENTER}
      justify={EJustifyContent.FLEX_START}
      onClick={handleClick}
      className="dl-language-picker__menu-item"
    >
      <img
        src={`img/icons/flags/${language}.svg`}
        alt={`language ${language}`}
        className="dl-language-picker__menu-icon"
      />

      <Text
        transform={ETextTransform.UPPERCASE}
        modifier={ETextModifier.CLICKABLE}
        className="dl-language-picker__menu-text"
      >
        {language}
      </Text>
    </FlexBox>
  );
};

export default LanguagePickerItem;
