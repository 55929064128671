import React, {FocusEvent} from 'react';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {isInteger} from '@Utils/typeGuards';
import Box from '@mui/system/Box';
import locale from '@Utils/locale';
import FormHelperText from '@mui/material/FormHelperText';

type Props = {
  minValue?: number;
  maxValue?: number;
  disableIncreaseReason?: string | null;
  value: number | '';
  maxInputLength?: number;
  onDecrease: () => void;
  onIncrease: () => void;
  onChange: (value: number | '') => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  invalidFieldMessage?: string | null;
  showErrorAbsolute?: boolean;
  disabled: boolean;
  textFieldAriaLabel: string;
}

function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
  if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === '+') {
    e.preventDefault();
  }
}

const getButtonStyles = {
  borderRadius: '50%',
  minWidth: 44,
  minHeight: 44
};

const absoluteErrorPositionStyles = {
  position: 'absolute',
  top: '44px',
  mt: 0,
  mr: 0.5
};

const CounterInput = ({
  minValue = 0,
  maxValue = 999,
  disableIncreaseReason,
  value,
  maxInputLength = 3,
  onDecrease,
  onIncrease,
  onChange,
  onBlur,
  onFocus,
  invalidFieldMessage,
  showErrorAbsolute,
  disabled,
  textFieldAriaLabel
}: Props) => {
  const increaseAriaLabel = locale.translate('increase');
  const decreaseAriaLabel = locale.translate('decrease');

  const handleDecrease = () => {
    onDecrease();
  };

  const handleIncrease = () => {
    onIncrease();
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);

    if (isNaN(value)) {
      return onChange('');
    }

    if (isInteger(value) && value.toString().length <= maxInputLength) {
      onChange(value);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        position: 'relative'
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content'
        }}
      >
        <Button
          variant="contained"
          aria-label={decreaseAriaLabel}
          size="small"
          color="primary"
          onClick={handleDecrease}
          disabled={disabled || isInteger(value) ? value <= minValue : false}
          sx={getButtonStyles}
        >
          <RemoveOutlinedIcon />
        </Button>
        <TextField
          onKeyDown={handleKeyDown}
          sx={{mx: 1, width: 54}}
          size="small"
          onChange={handleOnChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          error={!!invalidFieldMessage}
          disabled={disabled}
          inputProps={{
            'aria-label': textFieldAriaLabel,
            min: minValue,
            max: maxValue,
            step: 'any',
            style: {textAlign: 'center'},
            inputMode: 'numeric',
            disabled
          }}
        />
        <Tooltip
          title={disabled ? null : disableIncreaseReason}
          enterTouchDelay={50}
          arrow
        >
          <span>
            <Button
              variant="contained"
              aria-label={increaseAriaLabel}
              size="small"
              color="primary"
              onClick={handleIncrease}
              disabled={disabled || !!disableIncreaseReason}
              sx={getButtonStyles}
            >
              <AddOutlinedIcon />
            </Button>
          </span>
        </Tooltip>
      </Stack>
      {
        invalidFieldMessage &&
        <FormHelperText
          sx={{...showErrorAbsolute ? absoluteErrorPositionStyles : {mr: 0.5}}}
          className="counter-input-error"
          error
        >
          {invalidFieldMessage}
        </FormHelperText>
      }
    </Box>
  );
};

export default CounterInput;
