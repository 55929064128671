import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '../../utils/locale';
import Text from '../common/text/Text';
import {getNarrowedDateDescription} from './utils';
import ActivityContent, {SPACING_TYPE} from '../activities/activity/ActivityContent';
import {getParsedAdmissionDuration} from '@Utils/activity/activity';
import {ETextColor} from '@Components/common/text/types';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';

const ActivityMultipleHotelNarrowed = ({basketItem}) => {
  const timezone = useSelector(getFacilityTimezone);
  const {admissionPolicy} = basketItem.activity;
  const {durationInMinutes, useLimit, useLimitOncePerDay} = admissionPolicy;
  const parsedDuration = getParsedAdmissionDuration(durationInMinutes);
  const durationInfo = parsedDuration?.length ? parsedDuration.join(' ') : locale.translate('durationUnlimited');
  const useLimitText = useLimitOncePerDay ?
    locale.translate('oncePerDay') : useLimit ?
      `${locale.translate('useNumber')}: ${useLimit}` :
      locale.translate('withoutLimit');

  return (
    <div>
      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <AccessTimeOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          {useLimitText}
          {
            durationInMinutes ? ` x ${durationInfo}` : null
          }
        </Text>
      </ActivityContent>

      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          {
            getNarrowedDateDescription(basketItem, timezone)
          }
        </Text>
      </ActivityContent>
    </div>
  );
};

export default ActivityMultipleHotelNarrowed;
