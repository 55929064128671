import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useNavigate} from 'react-router-dom';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash, getConfiguration} from '@Features/configuration/configurationSelectors';
import pubsub from '@Utils/pubsub';
import {EVENT_SHOW_BUYING_PREVIEW} from '@Utils/events';
import facebookPixel from '@Utils/facebookPixel';
import {
  getActivitiesVariantUniquesIds,
  getBasketRawValue,
  prepareBasket
} from '@Utils/basket';
import Checkout from '@Components/checkout/Checkout';
import {useDispatch, useSelector} from 'react-redux';
import locale from '@Utils/locale';
import Loader, {LOADER_TYPE} from '@Components/common/loader/Loader';
import {getActivities} from '@Features/activities/activitiesSelectors';
import {updateBasketItems} from '@Features/basket/basketActions';
import {getAdditionsFetchingInProgressStatus} from '@Features/activityAdditions/additionsSelectors';
import {getQuestionsFetchingInProgressStatus} from '@Features/activityQuestions/questionsSelectors';
import {isProgress, isSuccess} from '@Utils/status';
import {parseLanguage} from '@Utils/eventsUtils';
import {getShippingMethodsStatus} from '@Features/shippingMethods/shippingMethodsSelectors';
import {fetchUpsellOffer} from '@Features/upsell/upsellActions';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';
import {CHECKOUT_VIEW_PREPARATION_TIMEOUT} from '@Consts/timeDurations';
import {getPickedUpsellActivities, getUpsellActivities, getUpsellStatus} from '@Features/upsell/upsellSelectors';
import {HOME_PATH, SUMMARY_PATH} from '@Utils/routerUtils';
import {prepareUpsellItemsSelections} from '@Utils/basketUtils';
import {getAbTestType, getStorageLatestOrders} from '@Features/storage/storageSelectors';
import {findLatestOrder} from '@Features/order/orderUtils';
import {getBasketItemPrice} from '@Utils/basketItemPrice';
import useFrameMessage from '@Hooks/useFrameMessage';

const CheckoutView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const frameMessage = useFrameMessage();
  const activities = useSelector(getActivities);
  const upsellActivities = useSelector(getUpsellActivities);
  const pickedUpsellActivities = useSelector(getPickedUpsellActivities);
  const upsellActivitiesFetchingStatus = useSelector(getUpsellStatus);
  const basketItems = useSelector(getBasketItems);
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);
  const {widgetType, onlineGroupId} = useSelector(getConfiguration);
  const latestOrders = useSelector(getStorageLatestOrders);
  const latestOrder = findLatestOrder(latestOrders, onlineGroupId, widgetType, facilityId);
  const numberOfBasketItems = basketItems?.length;
  const {id: facilityId, name: facilityName, company, isMarketingConsentsEnabled} = facility;
  const [isTimeout, setIsTimeout] = useState(false);
  const additionsFetchingInProgressStatus = useSelector(getAdditionsFetchingInProgressStatus);
  const questionsFetchingInProgressStatus = useSelector(getQuestionsFetchingInProgressStatus);
  const shippingMethodsStatus = useSelector(getShippingMethodsStatus);
  const fetchForUpsell = (isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType)) && onlineGroupId;
  const isUpsellReady = fetchForUpsell ? isSuccess(upsellActivitiesFetchingStatus) : true;
  const someExtensionsStillFetching = !isTimeout ?
    isProgress(additionsFetchingInProgressStatus) ||
    isProgress(questionsFetchingInProgressStatus) ||
    isProgress(shippingMethodsStatus) ||
    !isUpsellReady :
    false;
  const [isBasketPrepared, setIsBasketPrepared] = useState(false);
  const [basketShouldBeUpdated, setBasketShouldBeUpdated] = useState(true);
  const [basketItemsAmount, setBasketItemsAmount] = useState(basketItems?.length);
  const currentAbTestType = useSelector(getAbTestType);

  function checkoutInitializeEffect() {
    setTimeout(() => {
      someExtensionsStillFetching && setIsTimeout(true);
    }, CHECKOUT_VIEW_PREPARATION_TIMEOUT);

    frameMessage({name: 'scrollTop', offset: 0});

    if (fetchForUpsell && numberOfBasketItems) {
      const activitiesVariantUniquesIds = getActivitiesVariantUniquesIds(basketItems);

      dispatch(fetchUpsellOffer(activitiesVariantUniquesIds));
    }

    pubsub.trigger(EVENT_SHOW_BUYING_PREVIEW,
      {
        facilityId,
        facilityName,
        industry: company.industry,
        affiliationHash,
        language: parseLanguage(locale.locale),
        isAgreeMarketingConsents: isMarketingConsentsEnabled,
        numberOfTickets: basketItems?.length,
        abTest: currentAbTestType
      }
    );

    if (numberOfBasketItems) {
      facebookPixel.postInitiateCheckout(
        facility,
        getBasketRawValue(basketItems),
        getBasketItemPrice(basketItems[0])
      );
    }
  }

  function prepareBasketWithUpdate() {
    const basketItemsPrepared = prepareBasket(activities, upsellActivities, pickedUpsellActivities, basketItems);

    const updatedBasketItems = prepareUpsellItemsSelections(basketItemsPrepared);

    dispatch(updateBasketItems(updatedBasketItems));
  }

  function prepareBasketEffect() {
    if (numberOfBasketItems && !someExtensionsStillFetching && !isBasketPrepared) {
      prepareBasketWithUpdate();
      setIsBasketPrepared(true);
      setBasketShouldBeUpdated(false);
    }

    if (someExtensionsStillFetching && !basketShouldBeUpdated) {
      setBasketShouldBeUpdated(true);
    }

    if (!someExtensionsStillFetching && basketShouldBeUpdated && isBasketPrepared) {
      setBasketShouldBeUpdated(false);
      prepareBasketWithUpdate();
    }
  }

  React.useEffect(() => {
    if (basketItemsAmount !== basketItems?.length) {
      prepareBasketWithUpdate();
      setBasketItemsAmount(basketItems?.length);
    }
  }, [basketItems]);

  React.useEffect(checkoutInitializeEffect, []);
  React.useEffect(prepareBasketEffect, [someExtensionsStillFetching]);

  React.useEffect(() => {
    if (!numberOfBasketItems) {

      if (latestOrder) {
        return navigate(SUMMARY_PATH);
      } else {
        return navigate(HOME_PATH);
      }
    }
  }, []);

  if (isBasketPrepared) {
    return (
      <div className="dl-layout__content">
        <Checkout activities={activities} />
      </div>
    );
  }

  if (someExtensionsStillFetching) {
    return (
      <div style={{minHeight: 100}}>
        <Loader type={LOADER_TYPE.DEFAULT} withNavigation />
      </div>
    );
  }

  return null;
};

export default CheckoutView;
