import React from 'react';
import classNames from 'classnames';
import './icon.scss';
import {IIconProps} from './types';

const Icon = ({type, size, className, onClick, iconButton}: IIconProps) => (
  <div
    className={classNames(
      className,
      'dl-icon',
      {
        'dl-icon--icon-button': iconButton,
        [`dl-icon--${type}`]: type,
        [`dl-icon--${size}`]: size
      }
    )}
    onClick={onClick}
  />
);

export default Icon;
