import React from 'react';
import {string} from 'yup';
import locale from '@Utils/locale';
import {NIP_MASK, NIP_PATTERN} from '../patterns';
import FormInput from '@Components/formComponents/FormInput';

export const FIELD_NAME = 'invoiceCompanyNip';

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .length(10, locale.translate('invalidNIPLength'))
  .matches(NIP_PATTERN, {message: locale.translate('InvalidNipFormat')});

interface Props {
  name?: string,
  saveInStorage?: boolean,
  label?: string
}

const CompanyNipField = ({name = FIELD_NAME, saveInStorage, label = locale.translate('companyNIP')}: Props) => (
  <FormInput
    componentProps={{
      label,
      sx: {width: 1},
      inputProps: {inputMode: 'numeric'}
    }}
    name={name}
    mask={NIP_MASK}
    saveInStorage={saveInStorage}
  />
);

export default CompanyNipField;
