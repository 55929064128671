import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';

const CheckoutContent = ({children, style}) => (
  <div className="dl-checkout__content" style={style}>
    {children}
  </div>
);

CheckoutContent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

export default CheckoutContent;
