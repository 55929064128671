import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.scss';

export const BUTTON_TYPE = {
  SINGLE_CHARACTER: 'single-character',
  SINGLE_CHARACTER_SMALL: 'single-character-small',
  BLUE_INVERTED: 'blue-inverted',
  BLACK: 'black',
  BLACK_INVERTED: 'black-inverted'
};

export const BUTTON_SIZE = {
  WIDE: 'wide',
  SMALL: 'small'

};

const Button = ({children, type, size, onClick, disabled, ref, loading}) => (
  <div
    ref={ref}
    className={classNames(
      'dl-button',
      {
        [`dl-button--${type}`]: type,
        [`dl-button--${size}`]: size
      }
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? <div className="dl-button__spinner" /> : children}
  </div>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPE)),
  size: PropTypes.oneOf(Object.values(BUTTON_SIZE)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
