import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import {getDiscountList} from '@Utils/basket';
import {getFormattedPrice} from '@Utils/format';
import {Theme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {IDiscountMessageProps} from './types';
import useFeatureFlag, {Features} from '@Hooks/useFeatureFlag';

const indicatorStyles = (theme: Theme) => ({
  margin: theme.spacing(0, 1)
});

const getSuccessStyle = (theme: Theme) => ({
  color: theme.palette.success.main
});

const DiscountMessage = ({
  basketItems,
  withAdditions,
  code
}: IDiscountMessageProps) => {
  const {isEnabled: isTestB} = useFeatureFlag(Features.NEW_CALENDAR);
  const itemsDiscountsData = getDiscountList(basketItems);
  const itemsDiscounted = itemsDiscountsData.filter(itemDiscountData => itemDiscountData.isDiscounted);
  const someTicketIsNotDiscounted = itemsDiscountsData.some(({isDiscounted}) => !isDiscounted);
  const ticketExplanation = someTicketIsNotDiscounted ? locale.translate('notEligibleDiscountTickets') : '';
  const additionExplanation = withAdditions ? locale.translate('discountAdditionsNotIncluded') : '';
  const discountList = itemsDiscounted.map((item, id) => {
    const currencyText = isTestB ? locale.translate(`${item.currency}testB`) : locale.translate(`${item.currency}`);

    if (item.isDiscounted) {
      return (
        <Box key={`basket-item-${id}`}>
          <Box component="span" margin={indicatorStyles}>&bull;</Box>
          {
            `1 x ${item.label}:\u00A0${getFormattedPrice(item.priceDiscounted)}\u00A0${currencyText}`
          }&nbsp;<s>{getFormattedPrice(item.price)}&nbsp;{currencyText}</s>
        </Box>
      );
    }
    return null;
  });

  return (
    <Typography component="span" sx={getSuccessStyle}>
      {locale.translate('discountUsed', {code})}
      <Typography component="span" className={'dl-unordered-list__normal'} data-testid="discount-message">
        <Stack>
          {discountList}
        </Stack>
      </Typography>
      {ticketExplanation && <span>{ticketExplanation}&nbsp;</span>}
      {additionExplanation && <span>{additionExplanation}</span>}
    </Typography>
  );
};

export default DiscountMessage;
