import {shouldCreateView, shouldMerge} from './checkoutViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {itemIsHotelNarrowedType, noCreations} from './requirements';

export const createHotelNarrowedDatesViews = basketItems => {
  const creationRequirements = [itemIsHotelNarrowedType, noCreations];
  const mergingRequirements = [itemIsHotelNarrowedType];

  return basketItems.reduce((creations, basketItem) => {
    if (shouldCreateView({creations, basketItems, basketItem, requires: creationRequirements})) {
      creations.push({
        type: ECheckoutViewsTypes.HOTEL_NARROWED,
        items: [basketItem]
      });
    } else if (shouldMerge({basketItem, requires: mergingRequirements})) {
      const relevantCreationId = creations.findIndex(({type}) =>
        type === ECheckoutViewsTypes.HOTEL_NARROWED
      );

      creations[relevantCreationId].items.push(basketItem);
    }

    return creations;
  }, []);
};
