import TextField, {BaseTextFieldProps} from '@mui/material/TextField';
import React, {useEffect} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {SxProps, Theme} from '@mui/material';
import {useDispatch} from 'react-redux';
import {setStorage} from '@Features/storage/storageActions';
import {getMaskedValue} from '@Utils/inputMaskUtils';

interface IFormInputProps {
  name: string,
  componentProps: BaseTextFieldProps & {'data-testid'?: string},
  fieldStyles?: SxProps<Theme>,
  customError?: JSX.Element | string | null,
  saveInStorage?: boolean,
  mask?: (string | RegExp)[]
}

const FormInput = ({
  componentProps,
  name,
  fieldStyles,
  customError,
  saveInStorage,
  mask
}: IFormInputProps) => {
  const dispatch = useDispatch();
  const {control, resetField, watch} = useFormContext();
  const {label, variant, inputProps, disabled} = componentProps;
  const watchedValue = mask ? watch(name) : '';

  useEffect(() => {
    if (mask && watchedValue) {
      const defaultValueWithMask = getMaskedValue(watchedValue, mask);

      dispatch(setStorage(name, defaultValueWithMask, true));
      resetField(name, {defaultValue: defaultValueWithMask});
    }
  }, [mask]);

  const handleSaveToStorage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (saveInStorage) {
      const value = event.target.value;

      dispatch(setStorage(name, value, true));
    }
  };

  const prepareValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    if (mask) {
      const maskedValue = getMaskedValue(value, mask, watchedValue);

      event.target.value = maskedValue;
      return event;
    }

    return event;
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const preparedEvent = prepareValue(event);

    handleSaveToStorage(preparedEvent);

    return preparedEvent;
  };

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref, onBlur}, fieldState: {invalid, error}}) => (
        <TextField
          inputProps={inputProps}
          name={name}
          label={label}
          disabled={disabled}
          variant={variant}
          value={value}
          sx={{width: 1, ...fieldStyles}}
          onChange={event => onChange(handleOnChange(event))}
          onBlur={onBlur}
          error={invalid || !!customError}
          helperText={error?.message ?? customError ?? ''}
          inputRef={ref}
        />
      )}
    />
  );
};

export default FormInput;
