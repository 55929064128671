import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import './flex-box.scss';
import {IFlexBoxProps} from './types';
import {isObjectFilled} from '@Utils/validate';

const FlexBox = ({
  direction,
  justify,
  align,
  wrap,
  flex,
  flexGap,
  shrink,
  children,
  className,
  class: oldClass,
  style,
  onClick,
  ...rest
}: IFlexBoxProps) => {
  const flexBoxClassNames = classNames(
    oldClass || className,
    'l-flex-box',
    {
      [`l-flex-box--${direction}`]: direction,
      [`l-flex-box--${justify}`]: justify,
      [`l-flex-box--${align}`]: align,
      [`l-flex-box--${wrap}`]: wrap,
      [`l-flex-box--gap-${flexGap}`]: flexGap,
      [`l-flex-box--flex-${flex}`]: flex && flex >= 0,
      [`l-flex-box--shrink-${shrink}`]: shrink && shrink >= 0
    }
  );

  if (isObjectFilled(style)) {
    return (
      <div
        onClick={onClick}
        className={flexBoxClassNames}
        style={style}
        {...rest}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className={flexBoxClassNames}
      {...rest}
    >
      {children}
    </div>
  );
};

export default FlexBox;
