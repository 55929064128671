import React from 'react';
import {styled} from '@mui/material/styles';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';

export interface DlMuiTooltip extends TooltipProps {
    text?: string,
    isLight?: boolean,
    enable?: boolean
}

export const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontWeight: 400
  }
}));

export const LargeTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px'
  }
}));

const DlMuiTooltip = ({
  isLight = false,
  enable = true,
  enterTouchDelay = 0,
  leaveTouchDelay = 5000,
  ...props
}: DlMuiTooltip) => {
  if (!enable) {
    const {children} = props;

    return children;
  }

  if (isLight) {
    return (
      <LightTooltip
        {...props}
        enterTouchDelay={enterTouchDelay}
        leaveTouchDelay={leaveTouchDelay}
      />
    );
  }

  return (
    <LargeTooltip
      {...props}
      enterTouchDelay={enterTouchDelay}
      leaveTouchDelay={leaveTouchDelay}
    />
  );
};

export default DlMuiTooltip;
