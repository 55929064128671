import React from 'react';
import locale from '@Utils/locale';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EmailField from './field/EmailField';
import FirstNameField from './field/FirstNameField';
import LastNameField from './field/LastNameField';
import PhoneSection from '@Components/checkoutPages/agreements/PhoneSection';

interface ICheckoutGenerateUserSection {
  withContactPhoneNumber: boolean,
}

const CheckoutGenerateUserSection = ({withContactPhoneNumber}: ICheckoutGenerateUserSection) => {
  const showOptionText = !withContactPhoneNumber;

  return (
    <>
      <Typography variant="h5" sx={{fontWeight: 500, mb: 1.5}}>
        {locale.translate('customerData')}
        {showOptionText && (
          <Typography variant="body1" component="span">
            {` (${locale.translate('optionalText')})`}
          </Typography>
        )}
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={2} sx={{mb: 2}}>
        <Grid item xs={12}>
          <EmailField
            label={withContactPhoneNumber ? locale.translate('emailOptional') : undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneSection />
        </Grid>
        <Grid item xs={12} md={6}>
          <FirstNameField
            label={withContactPhoneNumber ? locale.translate('nameOptional') : undefined}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LastNameField
            label={withContactPhoneNumber ? locale.translate('surnameOptional') : undefined}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CheckoutGenerateUserSection;
