import React, {createContext, useEffect} from 'react';
import {ISummaryDataProvider} from './types';
import {useLatestOrderStorageData} from '@Utils/hooks/useLatestOrderStorageData';
import {useNavigate} from 'react-router-dom';
import {HOME_PATH} from '@Utils/routerUtils';
import {REFETCH_TIMEOUT, useFetchLatestOrder} from '../hooks/fetchHooks/useFetchLatestOrder';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import {useFetchCrossSellOffer} from '../hooks/fetchHooks/useFetchCrossSellOffer';
import {useBackToActivities} from '../hooks/useBackToActivities';
import {isBusinessOrder, isInternalOrder, isOnlineOrder} from '@Utils/typeGuards';

export const SummaryContext = createContext<ISummaryDataProvider | null>(null);

export const SummaryDataProvider = ({children}: React.PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const {latestOrderStorageData, latestOrders} = useLatestOrderStorageData();
  const widgetType = latestOrderStorageData?.widgetType;
  const isPreviewMode = !!latestOrderStorageData?.isPreviewMode;
  const {clearLatestOrder} = useBackToActivities();

  const redirectToHome = () => {
    clearLatestOrder();
    navigate(HOME_PATH);
  };

  useEffect(() => {
    if (!widgetType) {
      redirectToHome();
    }
  }, [widgetType]);

  if (!widgetType) {
    return null;
  }

  const {
    latestOrder,
    isInitialLoading,
    isPaymentComplete,
    isLatestOrderFetchError
  } = useFetchLatestOrder(
    latestOrderStorageData,
    latestOrders,
    {
      refetchInterval: REFETCH_TIMEOUT
    }
  );

  useEffect(() => {
    if (isLatestOrderFetchError) {
      redirectToHome();
    }
  }, [isLatestOrderFetchError]);

  const isWigetOnlineType = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const isWidgetBusinessType = isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType);
  const isWigetInternalType = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType);

  const {
    crossSellOffer,
    isLoading: isCrossSellOfferLoading
  } = useFetchCrossSellOffer({
    isWigetOnlineType,
    isPreviewMode,
    code: latestOrderStorageData.code,
    basket: latestOrderStorageData.basket
  });

  const documentAddress = latestOrder?.documentAddress || null;
  const isDocumentAddressAvailable = !!latestOrder?.isDocumentAddressAvailable;
  const isInitialization = !isPreviewMode ?
    isInitialLoading || isCrossSellOfferLoading :
    isCrossSellOfferLoading;

  return (
    <SummaryContext.Provider
      value={{
        ...isOnlineOrder(latestOrder) && {
          onlineOrder: latestOrder,
          onlineOrderTickets: latestOrder.tickets,
          paymentMethod: latestOrder.paymentMethod
        },
        ...isInternalOrder(latestOrder) && {
          internalOrder: latestOrder,
          internalOrderTickets: latestOrder.tickets
        },
        ...isBusinessOrder(latestOrder) && {
          businessOrder: latestOrder,
          businessOrderTickets: latestOrder.tickets
        },
        isInitialLoading: isInitialization,
        latestOrderStorageData,
        isPaymentComplete,
        widgetType,
        documentAddress,
        isDocumentAddressAvailable,
        isPreviewMode,
        isWigetOnlineType,
        isWidgetBusinessType,
        isWigetInternalType,
        crossSellOffer
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};
