import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {TextAvailableSeats, TextPrice, TextSoldOut, TimeText} from './TimePickerComponents';
import {timePickerItemConfig} from './types';

type Props = {
  timePickerItemConfig: timePickerItemConfig,
  isWithAllAdditionalInformation: boolean,
}

const TimePickerCellDetails = ({
  timePickerItemConfig,
  isWithAllAdditionalInformation
}: Props) => {
  const {
    textTime,
    textPrice,
    textSeats,
    showPrice,
    showAvailableSeats,
    isDisabled,
    hasNoSeats,
    isSoldOut,
    isBargainPrice
  } = timePickerItemConfig;

  return (
    <Box>
      <TimeText
        isDisabled={isDisabled}
        isPrices={showPrice}
      >
        {textTime}
      </TimeText>
      {
        showPrice && (
          <TextPrice isBargainPrice={isBargainPrice} isDisabled={isDisabled}>
            {textPrice}
          </TextPrice>
        )
      }
      {
        showAvailableSeats && (
          <TextAvailableSeats isDisabled={isDisabled}>
            {textSeats}
          </TextAvailableSeats>
        )
      }
      { isSoldOut && (
        <TextSoldOut isWithAllAdditionalInformation={isWithAllAdditionalInformation}>
          {locale.translate('isSoldOut')}
        </TextSoldOut>
      )}
      { hasNoSeats && (
        <TextSoldOut isWithAllAdditionalInformation={isWithAllAdditionalInformation}>
          {locale.translate('hasNoSeatsTest')}
        </TextSoldOut>
      )}
    </Box>
  );
};

export default TimePickerCellDetails;
