import gtmPurchase from './purchase';
import gtmAddOrRemove from './cartActions';

export const GTM_MESSAGE_NAME = 'gtmAnalytics';
export enum EGtmEvents {
  GTM_PURCHASE = 'purchase',
  GTM_ADD_TO_CART = 'addToCart',
  GTM_REMOVE_FROM_CART = 'removeFromCart'
}

export default {
  addPurchase: gtmPurchase.addPurchase,
  addToCart: gtmAddOrRemove.addToCart,
  removeFromCart: gtmAddOrRemove.removeFromCart
};
