export const COLOR = {
  WHITE: 'white',
  BLACK: 'black',
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  MAGENTA: 'magenta',
  PRIMARY: 'primary',
  PRIMARY_REVERSE: 'primary-reverse',
  PRIMARY_ADD: 'primary-add',
  SECONDARY: 'secondary',
  SECONDARY_REVERSE: 'secondary-reverse',
  BLUE_GREY: 'blue-grey',
  BLUE: 'blue',
  TOPAZ: 'topaz',
  BLANK: 'blank'
};

export const TEXT_COLOR_TYPE = {
  LIGHT: 'LIGHT',
  DARK: 'DARK'
};

export const WIDGET_BUTTON_COLORS = {
  BLUE_PRIMARY: '#0e68af',
  BLUE_LIGHT: '#a4bde6'
};

/** [New] Droplabs Materials */

export const DL_COLOR_TYPE = {
  PRIMARY: 'primary',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  GREYSCALE: 'grey-scale'
};
