import {ITicketOnline} from '@Consts/apiGlobals';
import React from 'react';
import {TPreviewTicket} from './types';
import WalletPass from './WalletPass';
import locale from '@Utils/locale';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import {isTicketOnline} from '@Utils/typeGuards';

type TTicketBottomContentProps = {
  ticket: TPreviewTicket | ITicketOnline,
  isPreviewMode: boolean,
  onAppleWalletClick: () => void
}

const TicketBottomContent = ({ticket, isPreviewMode, onAppleWalletClick}: TTicketBottomContentProps) => (
  <FollowCursorTooltip
    title={locale.translate('actionDisabledInPreviewMode')}
    enable={isPreviewMode}
  >
    <WalletPass
      url={isTicketOnline(ticket) ? ticket.walletPassUrl : undefined}
      onClick={onAppleWalletClick}
    />
  </FollowCursorTooltip>
);

export default TicketBottomContent;
