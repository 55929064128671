class RequestValidationError extends Error {
  constructor(message, errors) {
    super(message);
    this.errors = errors;
  }

  getValidationErrors() {
    return this.errors;
  }
}

export default RequestValidationError;
