import React, {forwardRef} from 'react';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import locale from '@Utils/locale';

type Props = {
    isOpen?: boolean,
    toggle?: VoidFunction
}

export const TooltipBase = forwardRef(
  function TooltipBase({isOpen, toggle}: Props, ref) {
    const theme = useTheme();

    return (
      <Tooltip
        ref={ref}
        open={isOpen}
        onClick={toggle}
        title={
          <Typography variant="caption">
            {locale.translate('emailNotGetThroughTooltip')}
          </Typography>
        }
        enterTouchDelay={50}
        placement="top"
        arrow
      >
        <InfoOutlinedIcon sx={{ml: 0.5, width: 20, height: 20, color: theme.palette.text.secondary}} />
      </Tooltip>
    );
  }
);
