import FormPrefixInput from '@Components/formComponents/FormPrefixInput';
import locale from '@Utils/locale';
import React from 'react';
import {object} from 'yup';

export const FIELD_NAME = 'phonePrefix';
export const schema = () => object()
  .required(locale.translate('requiredField'));

interface IPhonePrefixField {
    name?: string,
}

const PhonePrefixField = ({name = FIELD_NAME}: IPhonePrefixField) => (
  <FormPrefixInput name={name} componentProps={{label: locale.translate('prefix')}} />
);

export default PhonePrefixField;
