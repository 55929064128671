import {IStore} from 'src/js/store/types';

export const getUpsellActivities = (state: IStore) => {
  if (!state.upsell.upsellActivities.length) {
    return null;
  }

  return state.upsell.upsellActivities;
};

export const getPickedUpsellActivities = (state: IStore) => {
  if (!state.upsell.pickedUpsellActivities.length) {
    return null;
  }

  return state.upsell.pickedUpsellActivities;
};

export const getUpsellStatus = (state: IStore) => {
  if (!state.upsell.status) {
    return null;
  }

  return state.upsell.status;
};

