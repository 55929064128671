import {EPaymentMethod} from '@Components/checkout/types';
import {EWidgetType} from '@Consts/globals';
import {getTimezoneDate, getFormattedDate} from './dayjs/dayjsUtils';
import {isWidgetInternal, isWidgetInternalPreview, isWidgetOnlineClient} from './widgetType';
import {IFacility} from '@Consts/apiGlobals';
import {EVENT_BUY_CLICK_ON_RETRY_PAYMENT} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';

export const getBlikCodeMutable = (blikCode?: string, paymentMethod?: EPaymentMethod) => {
  if (paymentMethod === EPaymentMethod.BLIK && blikCode) {
    return blikCode.replace(' ', '');
  }
};

export const getPaymentMethodMutable = (
  widgetType: EWidgetType,
  basketValue: number,
  paymentMethod?: string,
  isPaymentOnline?: boolean
) => {
  if (isWidgetOnlineClient(widgetType) && paymentMethod) {
    return paymentMethod;
  }

  const isWidgetInternalType = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType);

  if (isWidgetInternalType && paymentMethod !== EPaymentMethod.OTHER && basketValue && !isPaymentOnline) {
    return paymentMethod;
  }
};

export const getPaymentCompleteUntil = (
  timezone: string,
  isPaymentOnline?: boolean,
  paymentCompleteUntil?: string
) => {
  if (isPaymentOnline && paymentCompleteUntil) {
    return getFormattedDate(getTimezoneDate(paymentCompleteUntil, timezone));
  }
};

export const sendRetryPaymentEvent = (
  facility: IFacility,
  eventProperties: {
    orderId: string | undefined;
    affiliationHash: string | null;
    isFirstLoading: boolean
  }
) => {
  const {id: facilityId, name: facilityName, company} = facility;
  const {industry} = {...company};
  const {orderId, affiliationHash, isFirstLoading} = eventProperties;

  pubsub.trigger(EVENT_BUY_CLICK_ON_RETRY_PAYMENT, {
    facilityId,
    facilityName,
    industry,
    affiliationHash,
    isFirstLoading,
    orderId,
    language: parseLanguage(locale.locale)
  });
};
