import {SET_ADDITIONS_STATUS, CLEAR_ADDITIONS_STATUS} from './additionsActions';

const initialState = {};

const additionsReducer = (state = initialState, action = {type: null}) => {
  switch (action.type) {
    case SET_ADDITIONS_STATUS:
      return {
        ...state,
        [action.payload.id]: action.payload.status
      };

    case CLEAR_ADDITIONS_STATUS:
      return {};

    default:
      return state;
  }
};

export default additionsReducer;
