/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Eladó kizárva!',
  'buyOnline': 'Vásároljon online!',
  'buyVoucher': 'Vásároljon utalványt',
  'buyTicket': 'Vegyen jegyet!',
  'bookingOnline': 'Foglaljon online',
  'buySkipass': 'Vásároljon síbérletet',
  'buyCourse': 'Vásároljon tanfolyamot',
  'localTours': 'Helyi kirándulások',
  'shopOnline': 'Online bolt',
  'orderOnline': 'Online rendelés',
  'planVisit': 'Tervezzen látogatást',
  'buyOnlineCheaper': 'Vásároljon online olcsóbban!'
};
