import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import React from 'react';
import TabPanel from '../common/TabPanel';
import locale from '@Utils/locale';
import PaymentMethodField from './field/PaymentMethodField';
import PaymentDeadlingDateField from './field/PaymentDeadlingDateField';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import Placeholder from '@Components/common/placeholder/Placeholder';
import {replaceByElement} from '@Utils/text';

const PaymentSection = () => {
  const facility = useSelector(getFacility);
  const {company} = {...facility};
  const {isInternalChannelWithOnlinePaymentEnabled} = {...company};
  const [tab, setTab] = React.useState(0);
  const {setValue, clearErrors} = useFormContext();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    clearErrors();
    setTab(newValue);
    if (newValue === 0) {
      setValue('isPaymentOnline', false);
    } else {
      setValue('isPaymentOnline', true);
    }
  };

  return (
    <Box sx={{width: '100%'}}>
      <Tabs value={tab} onChange={handleChange} aria-label="Payment tabs" sx={{pb: 1.5}}>
        <Tab label={locale.translate('paymentOutside')} />
        <Tab label={locale.translate('paymentOnline')} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <FormControl sx={{mt: 2}}>
          <Typography>{locale.translate('paymentFormOutside')}</Typography>
          <PaymentMethodField />
        </FormControl>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {isInternalChannelWithOnlinePaymentEnabled ? (
          <>
            <Typography variant="h6" sx={{my: 2}}>{locale.translate('setPaymentDate')}</Typography>
            <PaymentDeadlingDateField />
            <Alert severity="warning" sx={{my: 0.5}}>{locale.translate('setPaymentDateDesc')}</Alert>
          </>
        ) : (
          <Placeholder
            imgSrc="/img/placeholders/withoutPermission.svg"
            headerText={locale.translate('thisFeatureIsNotEnabled')}
            descriptionText={
              replaceByElement(
                locale.translate('thisFeatureIsNotEnabledSolution'),
                <MuiLink
                  href={`mailto:${locale.translate('droplabsHelpMail')}`}
                  key="droplabsHelpMail"
                >
                  {locale.translate('droplabsHelpMail')}
                </MuiLink>
              )}
          />
        )}

      </TabPanel>
    </Box>
  );
};

export default PaymentSection;
