import {IBasketItem, IQuestionAnswer} from '@Consts/types';

const getAnswersToCopy = (basketItemsGroup: IBasketItem[]) =>
  basketItemsGroup.reduce((activityScopeAnswers: IQuestionAnswer[], basketItem) => {
    const basketItemWithAnswersToCopy = basketItem.selections?.answers.filter(
      answer => answer.isActivityAnswerScope
    );

    if (basketItemWithAnswersToCopy) {
      return [...activityScopeAnswers, ...basketItemWithAnswersToCopy];
    }

    return activityScopeAnswers;
  }, []);

const getUniqueAnswers = (allAnswers: IQuestionAnswer[], basketItemQuestionsIds: number[]) => {
  const uniqueQuestionsIds = [...new Set(allAnswers.map(answer => answer.questionId))];
  const uniqueBasketItemQuestionsIds = uniqueQuestionsIds.filter(
    uniqueQuestionsId => basketItemQuestionsIds.some(
      basketItemQuestionsId => basketItemQuestionsId === uniqueQuestionsId
    )
  );

  return uniqueBasketItemQuestionsIds.map(
    questionId => allAnswers.find(answer => answer.questionId === questionId)
  ) as IQuestionAnswer[];
};

export const updateBasketItemsGroupAnswers = (basketItemsGroup: IBasketItem[]) => {
  const answersToCopy = getAnswersToCopy(basketItemsGroup);

  if (answersToCopy.length) {
    return basketItemsGroup.map(basketItem => {
      const basketItemQuestionsIds = basketItem.variant.questions.map(question => question.id);
      const allAnswers = [
        ...basketItem.selections.answers,
        ...answersToCopy
      ];

      return {
        ...basketItem,
        selections: {
          ...basketItem.selections,
          answers: getUniqueAnswers(allAnswers, basketItemQuestionsIds)
        }
      };
    });
  }

  return basketItemsGroup;
};

export const getValidDates = (basketItemsGroup: IBasketItem[], basketItem: IBasketItem) => {
  const {variantId} = basketItem;
  const basketItemVariantGroup = basketItemsGroup.filter(basketItem => basketItem.variantId === variantId);
  const basketItemWithVariantGroupDates = basketItemVariantGroup.find(
    basketItem => !!basketItem.selections?.dates?.length
  );
  const basketItemWithActivityGroupDates = basketItemsGroup.find(basketItem => !!basketItem.selections?.dates?.length);

  if (basketItemWithVariantGroupDates) {
    return basketItemWithVariantGroupDates.selections.dates;
  }

  return basketItemWithActivityGroupDates?.selections?.dates || [];
};

export const updateBasketItemsGroupDates = (basketItemsGroup: IBasketItem[]) => {
  const basketItemsUpdated: IBasketItem[] = basketItemsGroup.map(
    basketItem => ({
      ...basketItem,
      selections: {
        ...basketItem.selections,
        dates: getValidDates(basketItemsGroup, basketItem)
      }
    })
  );

  return basketItemsUpdated;
};

export const prepareUpsellItemsSelections = (basketItems: IBasketItem[]) => {
  const basketItemsVariants = [...new Set(basketItems.map(basketItem => basketItem.activityId))];
  let baksetUpdated: IBasketItem[] = [];

  basketItemsVariants.forEach(activityId => {
    const basketItemsGroup = basketItems.filter(basketItem => basketItem.activityId === activityId);

    if (basketItemsGroup[0].isUpsell) {
      const basketItemsWithNewDates = updateBasketItemsGroupDates(basketItemsGroup);
      const basketItemsWithNewAnswers = updateBasketItemsGroupAnswers(basketItemsWithNewDates);

      baksetUpdated = [
        ...baksetUpdated,
        ...basketItemsWithNewAnswers
      ];
    } else {
      baksetUpdated = [
        ...baksetUpdated,
        ...basketItemsGroup
      ];
    }
  });

  return baksetUpdated;
};
