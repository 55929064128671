const getData = ($scriptNode: Element | null, name: string, alternative = '') => {
  const attribute = $scriptNode?.getAttribute(name);

  if (attribute) {
    return attribute;
  }

  return alternative;
};

export default getData;
