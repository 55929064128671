import Button from '@mui/material/Button';
import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {useBackToActivities} from '../../hooks/useBackToActivities';

type TNewOrderButtonProps = {
  isWigetInternalType: boolean
}
const NewOrderButton = ({isWigetInternalType}: TNewOrderButtonProps) => {
  const {handleClickBackToActivities} = useBackToActivities();

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', my: 4}}>
      <Button
        onClick={handleClickBackToActivities}
        sx={{width: {xs: 1, sm: 'auto'}}}
        size="large"
        color={isWigetInternalType ? 'inherit' : 'success'}
        variant={isWigetInternalType ? 'outlined' : 'contained'}
      >
        {locale.translate('newOrder')}
      </Button>
    </Box>
  );
};

export default NewOrderButton;
