import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {applyBasketItemsDiscounts} from '@Features/basket/basketActions';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {apiPost} from '@Utils/api/api';
import locale from '@Utils/locale';

export const SET_DISCOUNT_STATUS = 'SET_DISCOUNT_STATUS';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const RESET_DISCOUNT = 'RESET_DISCOUNT';

export const setDiscountStatus = status => ({
  type: SET_DISCOUNT_STATUS,
  payload: {status}
});

export const setDiscount = (code, discount) => ({
  type: SET_DISCOUNT,
  payload: {code, discount}
});

export const resetDiscount = () => ({
  type: RESET_DISCOUNT
});

export const calculateDiscount = (code, items) => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());
  const {facilityId, affiliationHash} = configuration;
  const url = `user-api/facilities/${facilityId}/discounts/${code}/calculate`;

  dispatch(setDiscountStatus(IN_PROGRESS));

  try {
    const discounts = await apiPost(url, {facilityId, body: {items, affiliationHash}});

    dispatch(setDiscount(code, discounts));
    dispatch(applyBasketItemsDiscounts(discounts));
    dispatch(setDiscountStatus(SUCCESS));

    return discounts;
  } catch (error) {
    dispatch(setDiscountStatus(FAILURE));
    throw new Error(locale.translate('discountCodeNotFound', {code: code.toUpperCase()}));
  }
};

