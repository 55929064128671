import {
  SET_ACTIVITIES,
  SET_ACTIVITIES_STATUS,
  SET_ACTIVITIES_ADDITIONS,
  SET_ACTIVITIES_QUESTIONS,
  SET_ACTIVITIES_LOCALIZATION
} from './activitiesActions';

const initialState = {
  activities: null,
  status: null
};

const activitiesReducer = (state = initialState, action = {type: null}) => {
  switch (action.type) {
    case SET_ACTIVITIES_LOCALIZATION:
      return {
        ...state,
        locale: action.payload.locale
      };

    case SET_ACTIVITIES_STATUS:
      return {
        ...state,
        status: action.payload.status
      };

    case SET_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload.activities
      };
    }

    case SET_ACTIVITIES_ADDITIONS: {
      const activityId = action.payload.activityId;
      const additions = action.payload.additions;
      const activities = state.activities;

      return {
        ...state,
        activities: activities.map(activity => (
          activity.id !== activityId ? activity : {...activity, additions}
        ))
      };
    }

    case SET_ACTIVITIES_QUESTIONS: {
      const activityId = action.payload.activityId;
      const questions = action.payload.questions;
      const activities = state.activities;

      return {
        ...state,
        activities: activities.map(activity => (
          activity.id !== activityId ? activity : {...activity, questions}
        ))
      };
    }

    default:
      return state;
  }
};

export default activitiesReducer;
