import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PhonePrefixField from '@Components/checkout/field/PhonePrefixField';
import PhoneNumberField from '@Components/checkout/field/PhoneNumberField';
import DlMuiTooltip from '@Components/common/DlMuiTooltip';

interface IPhoneSection{
  customHeader?: boolean,
  withOnlyForRealisationHint?: boolean
}

const PhoneSection = ({
  customHeader,
  withOnlyForRealisationHint
}: IPhoneSection) => (
  <>
    {
      customHeader ? (
        <Box sx={{mb: 2}}>
          {customHeader}
        </Box>
      ) : withOnlyForRealisationHint && (
        <Box sx={{mb: 1}}>
          <Typography variant="h5" sx={{fontWeight: 500}}>
            {locale.translate('contactPhoneNumber')}
          </Typography>
          <Stack sx={{spacing: 1, flexDirection: 'row', alignItems: 'center', mb: 1}}>
            <Typography variant="body1" color="text.secondary">
              {locale.translate('numberOnlyForRealisation')}
            </Typography>
            <DlMuiTooltip
              title={locale.translate('contactNumberToClient')}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              arrow
            >
              <IconButton aria-label="info">
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </DlMuiTooltip>
          </Stack>
        </Box>
      )

    }
    <Stack direction="row" spacing={2}>
      <PhonePrefixField />
      <PhoneNumberField />
    </Stack>
  </>
);

export default PhoneSection;
