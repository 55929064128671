import {AgreementsOrderResponse} from '@Components/checkoutPages/agreements/types';
import {EOrderState, ILatestOrder} from '@Consts/apiGlobals';
import {EWidgetType, TLatestOrderStorageData} from '@Consts/globals';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview, isWidgetOnlineClient, isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import {IConfigurationStore} from 'src/js/store/types';

export const COMPLETED = 'completed';

export const getOrdersUrl = ({
  widgetType,
  facilityId,
  onlineGroupId,
  groupId,
  businessGroupId,
  internalBuyerFacilityId
}: {
  widgetType: EWidgetType,
  facilityId: number,
  onlineGroupId?: number,
  groupId?: number,
  businessGroupId?: number,
  internalBuyerFacilityId?: number
}) => {
  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/orders`;
  }

  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/orders/preview`;
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/orders`;
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/orders`;
  }

  const groupRoute = onlineGroupId ? onlineGroupId : groupId ? groupId : 1;

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${groupRoute}/orders/preview`;
  }

  return `user-api/facilities/${facilityId}/online_groups/${groupRoute}/orders`;
};

export const getLatesOrderUrl = (
  {widgetType, facilityId}: Pick<IConfigurationStore, 'widgetType' | 'facilityId'>,
  code?: string,
  orderId?: string
) => {
  if (isWidgetBusiness(widgetType) && orderId) {
    return `company-api/business/facilities/${facilityId}/new_orders/${orderId}`;
  }

  if (isWidgetInternal(widgetType) && orderId) {
    return `company-api/internal/facilities/${facilityId}/new_orders/${orderId}`;
  }

  if (code) {
    return `user-api/facilities/${facilityId}/orders/tickets/${code}`;
  }

  return null;
};

export const getOrderUrl = (
  {widgetType, facilityId}: IConfigurationStore,
  code: string,
  orderId: string
) => {
  if (isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/orders/${orderId}`;
  }

  if (isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/orders/${orderId}`;
  }

  return `user-api/facilities/${facilityId}/online/orders/tickets/${code}`;
};

export const findLatestOrder = (
  latestOrders: ILatestOrder[] | null,
  onlineGroupId: number | null,
  widgetType?: EWidgetType | null,
  facilityId?: number | null
): ILatestOrder | undefined => {
  if (facilityId) {
    return latestOrders?.find(
      i => i?.onlineGroupId === onlineGroupId && i?.widgetType === widgetType && i.facilityId === facilityId
    );
  }

  return latestOrders?.find(
    i => i?.onlineGroupId === onlineGroupId && i?.widgetType === widgetType
  );
};

export const filterLatestOrders = (
  latestOrders: ILatestOrder[] | null,
  onlineGroupId: number | null,
  widgetType?: EWidgetType | null
) =>
  latestOrders?.filter(i => i?.onlineGroupId !== onlineGroupId || i.widgetType !== widgetType);

export const updateLatestOrders = (
  latestOrders: ILatestOrder[] | null,
  onlineGroupId: number | null,
  widgetType: EWidgetType | null,
  newValue: ILatestOrder,
  isStoredLatestOrder = true
) => {
  if (latestOrders?.length) {
    return isStoredLatestOrder ?
      latestOrders.map(i => (i?.onlineGroupId === onlineGroupId && i?.widgetType === widgetType ? newValue : i)) :
      [...latestOrders, {...newValue}];
  }

  return [{...newValue}];
};

export const updateLatestOrdersStorageData = (
  latestOrders: TLatestOrderStorageData[] | null,
  onlineGroupId: number | null,
  widgetType: EWidgetType | null,
  newValue: TLatestOrderStorageData,
  isStoredLatestOrder = true
) => {
  if (latestOrders?.length) {
    return isStoredLatestOrder ?
      latestOrders.map(i => (i?.onlineGroupId === onlineGroupId && i?.widgetType === widgetType ? newValue : i)) :
      [...latestOrders, newValue];
  }

  return [newValue];
};

export const shouldOpenPayment = (
  order: AgreementsOrderResponse,
  widgetType: EWidgetType,
  state: EOrderState,
  isVivaWalletPayment: boolean,
  paymentViewUrl?: string,
  blikCode?: string
) => {
  const {price} = order;
  const widgetIsOnlineClient = isWidgetOnlineClient(widgetType);
  const widgetIsBusinessBuy = isWidgetBusiness(widgetType);

  return (widgetIsOnlineClient || widgetIsBusinessBuy) &&
    price > 0 &&
    state !== EOrderState.SKIPPED &&
    !paymentViewUrl &&
    !isVivaWalletPayment &&
    !blikCode;
};
