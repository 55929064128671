export enum ICON_SIZE {
    SIZE60 = 'size60',
    SIZE70 = 'size70'
}

export enum ICON_TYPE {
  CASH = 'cash',
  INVOICE = 'invoice',
  PRINTER = 'printer',
  RECEPTION = 'reception',
  PRICE = 'price',
  HANDSHAKE = 'handshake',
  PDF = 'pdf',
}

export interface IIconProps {
    type?: ICON_TYPE,
    size?: ICON_SIZE,
    className?: string,
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,
    iconButton?: boolean
}
