import {isActivitySinglePickDate} from '@Utils/activity/activity';
import {getBasketItemNumberOfAddmissionDates} from '@Utils/basket';
import {shouldCreateView, shouldMerge} from './checkoutViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {itemHasDates, noViewForSameDatesNumber} from './requirements';

export const createAdmissionDatesViews = basketItems => {
  const creationRequirements = [itemHasDates, noViewForSameDatesNumber];
  const mergingRequirements = [itemHasDates];

  return basketItems.reduce((creations, basketItem) => {
    if (itemHasDates({basketItem})) {
      const {
        numberOfAdmissionDatesMin,
        numberOfAdmissionDatesMax
      } = getBasketItemNumberOfAddmissionDates(basketItem);

      if (shouldCreateView({creations, basketItems, basketItem, requires: creationRequirements})) {
        const passType = basketItem.activity.passType;
        const type = isActivitySinglePickDate(passType) ? ECheckoutViewsTypes.DATES : ECheckoutViewsTypes.TIMES;

        creations.push({
          type,
          numberOfAdmissionDatesMin,
          numberOfAdmissionDatesMax,
          items: [basketItem]
        });
      } else if (shouldMerge({basketItem, requires: mergingRequirements})) {
        const relevantCreationId = creations.findIndex(creation =>
          creation.numberOfAdmissionDatesMin === numberOfAdmissionDatesMin &&
        creation.numberOfAdmissionDatesMax === numberOfAdmissionDatesMax
        );

        creations[relevantCreationId].items.push(basketItem);
      }
    }

    return creations;
  }, []);

};
