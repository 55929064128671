import React from 'react';
import {IFacility} from '@Consts/apiGlobals';
import SelectAllConsents from './SelectAllConsents';
import TermsOfService from './TermsOfService';
import Box from '@mui/material/Box';

export interface IAgreementsSectionProps {
  facility: IFacility
}

const AgreementsSection = ({
  facility
}: IAgreementsSectionProps) => {
  const {isMarketingConsentsEnabled} = facility;

  if (isMarketingConsentsEnabled) {
    return (
      <Box sx={{mb: 3, mt: 3}}>
        <SelectAllConsents
          facility={facility}
        />
      </Box>
    );
  }

  return (
    <Box sx={{mb: 3, mt: 3}}>
      <TermsOfService facility={facility} />
    </Box>
  );
};

export default AgreementsSection;
