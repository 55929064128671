import {IBasketItem} from '@Consts/types';
import {getBasketValue} from './basket';
import {invoiceFormFieldsNames} from '@Consts/storageConsts';
import {removeStorage} from './storage';
import {InvoiceAddressCountry} from '@Consts/invoiceCountries';
import {EInvoiceFormType} from '@Components/invoice/types';
import {FieldValues, UseFormSetValue} from 'react-hook-form';

export const SIMPLIFIED_COMPANY_INVOICE_BASKET_PRICE = 450;

export interface IIsCompanySimplifiedInvoiceFormType {
  basketItems: IBasketItem[],
  isOnlinePaymentReceiptEnabled: boolean,
  invoiceAddressCountryCode?: InvoiceAddressCountry
}

export interface IIsInternalInvoiceFormSimplified {
  basketItems: IBasketItem[],
  isOnlinePaymentReceiptEnabled: boolean,
  isInternalPaymentReceiptEnabled: boolean,
  isPaymentOnline: boolean,
  setValue: UseFormSetValue<FieldValues>,
  invoiceFormType: EInvoiceFormType
}

export const isCompanySimplifiedInvoiceFormType = ({
  basketItems,
  isOnlinePaymentReceiptEnabled,
  invoiceAddressCountryCode
}: IIsCompanySimplifiedInvoiceFormType) => {
  const basketPriceValue = getBasketValue(basketItems, true);
  const isPolishCountryCode = invoiceAddressCountryCode === InvoiceAddressCountry.PL;

  return isOnlinePaymentReceiptEnabled && isPolishCountryCode &&
    basketPriceValue <= SIMPLIFIED_COMPANY_INVOICE_BASKET_PRICE;
};

export const isInternalInvoiceFormSimplified = ({
  basketItems,
  isOnlinePaymentReceiptEnabled,
  isInternalPaymentReceiptEnabled,
  isPaymentOnline,
  setValue,
  invoiceFormType
}: IIsInternalInvoiceFormSimplified) => {
  const basketPriceValue = getBasketValue(basketItems, true);
  const isPriceForSimplifiedForm = basketPriceValue <= SIMPLIFIED_COMPANY_INVOICE_BASKET_PRICE;
  const formTypeIsSimplified = invoiceFormType === EInvoiceFormType.COMPANY_SIMPLIFIED;
  const isReceiptEnabled = isPaymentOnline ? isOnlinePaymentReceiptEnabled : isInternalPaymentReceiptEnabled;

  // check if form should be simplified
  if (isReceiptEnabled && isPriceForSimplifiedForm) {
    return true;
  }
  // if it's currently selected for simplified form change to normal company form
  if (formTypeIsSimplified) {
    setValue('invoiceFormType', EInvoiceFormType.COMPANY);
  }
  return false;
};

export const clearOldInvoiceStorageData = () => {
  invoiceFormFieldsNames.forEach(fieldName => {
    removeStorage(fieldName);
  });
};
