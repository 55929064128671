import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

export const useUnregisterFormField = (fieldName: string) => {
  const {unregister} = useFormContext();

  const unregisterField = () => {
    unregister(fieldName);
  };

  useEffect(() => () => unregisterField(), []);
};
