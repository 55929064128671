import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

type Props = {
  isTestB: boolean;
  isSelect: boolean;
};

const TimePickerCellsWrapper = ({
  children,
  isTestB,
  isSelect
}: React.PropsWithChildren<Props>) => {
  if (isTestB) {
    if (isSelect) {
      return (
        <Box>
          {children}
        </Box>
      );
    }
    return (
      <Grid
        container
        spacing={1}
      >
        {children}
      </Grid>
    );
  }

  return <ul className="dl-time-picker">{children}</ul>;
};

export default TimePickerCellsWrapper;
