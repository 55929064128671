import {EStorageKeys} from '@Consts/storageConsts';
import {LATEST_ORDER_STORAGE_TIME} from '@Consts/timeDurations';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {
  filterLatestOrders,
  findLatestOrder
} from '@Features/order/orderUtils';
import {setStorage} from '@Features/storage/storageActions';
import {getStorageLatestOrders} from '@Features/storage/storageSelectors';
import {INTERNAL_PAYMENT_LINK_PATH, SUMMARY_PATH, WAITING_FOR_PAYMENT_PATH} from '@Utils/routerUtils';
import dayjs from 'dayjs';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export const useLatestOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {onlineGroupId, widgetType} = useSelector(getConfiguration);
  const facility = useSelector(getFacility);
  const latestOrders = useSelector(getStorageLatestOrders);
  const latestOrder = findLatestOrder(latestOrders, onlineGroupId, widgetType, facility?.id);

  useEffect(() => {
    if (facility) {
      if (latestOrder) {
        const {timestamp} = latestOrder;
        const deadline = timestamp + LATEST_ORDER_STORAGE_TIME;
        const isOutdated = dayjs().isAfter(deadline);

        if (isOutdated) {
          const filteredOrders = filterLatestOrders(
            latestOrders,
            onlineGroupId,
            widgetType
          );

          dispatch(setStorage(
            EStorageKeys.LATEST_ORDERS,
            filteredOrders,
            true
          ));
        } else {
          if (latestOrder?.isVivaWalletPayment) {
            navigate(WAITING_FOR_PAYMENT_PATH);
          } else if (latestOrder?.paymentViewUrl) {
            navigate(INTERNAL_PAYMENT_LINK_PATH);
          } else {
            navigate(SUMMARY_PATH);
          }
        }
      }
    }
  }, [facility]);
};

