import {IOnlineOrder, IOrder} from '@Consts/apiGlobals';

export const getIsOrderComplete = (order?: IOrder) => {
  if (!order) {
    return false;
  }
  const {isDocumentAddressAvailable, documentAddress} = order;

  const documentAddressIsComplete = isDocumentAddressAvailable ? !!documentAddress : true;
  const paymentStateIsComplete = order.paymentState === 'completed' || order.paymentState === 'skipped';

  return documentAddressIsComplete && paymentStateIsComplete;
};

export const getOrderPurchaseData = (order: IOnlineOrder) => ({
  orderId: order.orderId,
  email: order.orderBuyer.email,
  completedAt: order.completedAt,
  tickets: order.tickets.map(ticket => ({
    id: ticket.ticketId,
    formattedName: ticket.ticketFormattedName,
    activityName: ticket.activityName,
    price: ticket.price.amount
  })),
  tax: order.tax,
  price: order.price.amount,
  currency: order.price.currency
});
