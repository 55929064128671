import {EPaymentMethod} from '@Components/checkout/types';
import {EStorageKeys} from '@Consts/storageConsts';
import {getStoragePaymentMethod} from '@Features/storage/storageSelectors';
import {setStorage} from '@Features/storage/storageActions';
import {useDispatch, useSelector} from 'react-redux';

export const useSavedPaymentMethod = () => {
  const dispatch = useDispatch();

  const getSavedPaymentMethod = (): EPaymentMethod | null => {
    const storageValue = useSelector(getStoragePaymentMethod);

    return Object.values(EPaymentMethod).find(value => value === storageValue) ?? null;
  };

  const savePaymentMethod = (paymentMethod: EPaymentMethod | string) =>
    dispatch(setStorage(EStorageKeys.PAYMENT_METHOD, paymentMethod, true));

  return {getSavedPaymentMethod, savePaymentMethod};
};
