import React, {useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivityPreview from './ActivityPreview';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {getPreviewViews} from '@Utils/previewViews/previewViews';
import {getActivePreviewIndexes} from './utils';

const ActivityPreviewList = ({basketItems, activeView}) => {
  const previews = getPreviewViews(basketItems);
  const activitiesPreview = useRef();
  const activePreviewsIds = getActivePreviewIndexes(previews, activeView);

  const handleScrollEffect = scrollPosition => {
    if (activitiesPreview?.current && scrollPosition) {
      activitiesPreview.current.scrollLeft = scrollPosition;
    }
  };

  return (
    <div
      className={classNames(
        'l-activities-preview',
        {
          'l-activities-preview--single':
            previews.length === 1 &&
            previews[0].items[0].selections.additions?.length === 0
        }
      )}
      ref={activitiesPreview}
    >
      {
        previews.map(preview => (
          <ActivityPreview
            preview={preview}
            isSelected={activePreviewsIds.some(id => id === preview.id)}
            numberOfItems={previews.length}
            onScroll={handleScrollEffect}
            key={preview.id}
            activeView={activeView}
            firstSelectedId={activePreviewsIds[0]}
          />
        ))
      }
    </div>
  );
};

ActivityPreviewList.propTypes = {
  basketItems: PropTypes.array.isRequired,
  activeView: PropTypes.object
};

export default ActivityPreviewList;
