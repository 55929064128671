import {IConfigurationStore, IStore} from 'src/js/store/types';

export const getConfiguration = (state: IStore) => state.configuration;

export const getFacilityId = (state: IStore): IConfigurationStore['facilityId'] =>
  getConfiguration(state).facilityId;

export const getAuthToken = (state: IStore): IConfigurationStore['authToken'] =>
  getConfiguration(state).authToken;

export const getWidgetType = (state: IStore): IConfigurationStore['widgetType'] =>
  getConfiguration(state).widgetType;

export const getScriptInitialized = (state: IStore): IConfigurationStore['scriptInitialized'] =>
  getConfiguration(state).scriptInitialized;

export const getParentUrl = (state: IStore): IConfigurationStore['parentUrl'] =>
  getConfiguration(state).parentUrl;

export const getIndicatedActivityId = (state: IStore): IConfigurationStore['indicatedActivityId'] =>
  getConfiguration(state).indicatedActivityId;

export const getOnlineGroupId = (state: IStore): IConfigurationStore['onlineGroupId'] =>
  getConfiguration(state).onlineGroupId;

export const getAffiliationHash = (state: IStore): IConfigurationStore['affiliationHash'] =>
  getConfiguration(state).affiliationHash;

export const getHideLanguagePicker = (state: IStore): IConfigurationStore['hideLanguagePicker'] =>
  getConfiguration(state).hideLanguagePicker;

export const getIsModal = (state: IStore): IConfigurationStore['isModal'] =>
  getConfiguration(state).isModal;

export const getLockBasket = (state: IStore): IConfigurationStore['lockBasket'] =>
  getConfiguration(state).lockBasket;

export const getParentReferrer = (state: IStore): IConfigurationStore['parentReferrer'] =>
  getConfiguration(state).parentReferrer;
