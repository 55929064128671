import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface PlaceholderProps {
  imgSrc?: string,
  headerText?: string,
  descriptionText: string
  children?: string | JSX.Element,
  descMaxWidth?: string
}

const Placeholder = ({
  imgSrc,
  headerText,
  descriptionText,
  descMaxWidth = '620px',
  children
}: PlaceholderProps) => (
  <Stack sx={{alignItems: 'center', pb: 4}}>
    {
      imgSrc && (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            m: '24px 0 32px'
          }}
          component="img"
          alt="person"
          src={imgSrc}
        />
      )
    }
    <Stack sx={{alignItems: 'center'}}>
      {
        headerText && (
          <Typography
            variant="h6"
            component="h3"
            sx={{
              textAlign: 'center',
              p: '4px 0'
            }}
          >
            {headerText}
          </Typography>
        )
      }
      {
        descriptionText && (
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              textAlign: 'center',
              maxWidth: descMaxWidth
            }}
          >
            {descriptionText}
            {children}
          </Typography>
        )
      }
    </Stack>
  </Stack>
);

export default Placeholder;
