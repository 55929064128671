import locale from '@Utils/locale';
import {
  createDate,
  isToday,
  isTomorrow,
  getFormattedDate,
  DateType
} from '@Utils/dates/timezoneDates';

const getDayOfWeek = (isToday: boolean, isTomorrow: boolean, date: DateType) => {
  if (isToday) {
    return locale.translate('today');
  }

  if (isTomorrow) {
    return locale.translate('tomorrow');
  }

  return `${getFormattedDate(date, 'dddd')}, ${getFormattedDate(date, 'DD.MM')}`;
};

export const getActivityNearestDate = (
  firstAdmissionDate: string,
  isActivityWithDateTime: boolean
) => {
  const comparedDate = createDate(firstAdmissionDate);
  const hour = isActivityWithDateTime ? getFormattedDate(comparedDate, 'HH:mm') : null;
  const day = getDayOfWeek(isToday(comparedDate), isTomorrow(comparedDate), comparedDate);

  if (hour) {
    return locale.translate('nearestDateWithHour', {day, hour});
  }

  return day;
};

export const formatRealizationDate = (date: string, withHour: boolean) => {
  const day = getFormattedDate(date, 'DD.MM.YYYY');

  if (withHour) {
    const hour = getFormattedDate(date, 'HH:mm');

    return locale.translate('nearestDateWithHour', {day, hour});
  }

  return day;
};
