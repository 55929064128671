import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import dayjs, {Dayjs} from 'dayjs';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {IConfigurationStore} from 'src/js/store/types';
import {ApiAdmissionDateVariant, ApiDayOffers, DayOffersProcessed} from './types';
import {getTimezoneDate, isSameDate} from '@Utils/dayjs/dayjsUtils';
import {getCurrency} from '@Components/checkoutPages/admissionRealization/admissionRealizationUtils';
import {IBasketItem} from '@Consts/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';

const updateBargainPrices = (dayOffers: DayOffersProcessed[]) => {
  if (!dayOffers?.length) {
    return [];
  }

  const dayOffersByPrices =
  [...dayOffers].sort((a, b) => a.totalPrice - b.totalPrice);

  const minPrice = dayOffersByPrices[0].totalPrice;
  const maxPrice = dayOffersByPrices[dayOffersByPrices.length - 1].totalPrice;

  const isPriceChangePerMonth = minPrice - maxPrice < 0;

  return dayOffers.map(dayOffer => ({
    ...dayOffer,
    isBargainPrice: isPriceChangePerMonth ? dayOffer.totalPrice === minPrice : false
  }));
};

const getBasketItemsPriceVariants = (
  variants: ApiAdmissionDateVariant[],
  itemsData: {variantId: number, numberOfSeats: number | null}[]
) => itemsData.map(
  ({variantId: basketItemVariantId}) => variants.find(variant => variant.variantId === basketItemVariantId)
).filter(variant => !!variant);

const getDayOfferTotalPrice = (
  basketItemsVariantsPrices: ApiAdmissionDateVariant[],
  itemsData: {variantId: number, numberOfSeats: number | null}[]
) =>
  basketItemsVariantsPrices.reduce((minTotalPrice, variant) => {
    const variantNumbetOfSeats = itemsData.find(({variantId}) => variantId === variant.variantId)?.numberOfSeats || 1;

    const isGroupVariant = variant.variantType === 2;
    const variantCostPrice = isGroupVariant ? variant.costMin.price * variantNumbetOfSeats : variant.costMin.price;

    return minTotalPrice += variantCostPrice;
  }, 0);

export const getIsCommonVariantsPrice = (basketItemsVariantsPrices: ApiAdmissionDateVariant[]) =>
  basketItemsVariantsPrices.every(
    variant => variant.costMax.price === variant.costMin.price
  );

export const getProcessedDayOffers = (
  dates: ApiDayOffers[],
  timezone: string,
  items: IBasketItem[],
  selectedDayOffer: DayOffersProcessed | null
): DayOffersProcessed[] => {
  if (!dates.length) {
    return [];
  }
  const itemsData = items.map(({variantId, selections}) => ({variantId, numberOfSeats: selections.numberOfSeats}));
  const dayOffersProcessed: DayOffersProcessed[] = dates.map(date => {
    const dateWithTimezone = getTimezoneDate(date.date, timezone, true);
    const {variants, isAvailable} = date;
    const basketItemsVariantsPrices = getBasketItemsPriceVariants(
      variants, itemsData
    ) as ApiAdmissionDateVariant[];

    return {
      id: dateWithTimezone.valueOf(),
      date: dateWithTimezone,
      originalDate: date.date,
      isAvailable,
      variants,
      isCommonVariantsPrice: getIsCommonVariantsPrice(basketItemsVariantsPrices),
      currency: getCurrency(dates),
      totalPrice: getDayOfferTotalPrice(basketItemsVariantsPrices, itemsData),
      isSelected: selectedDayOffer ? isSameDate(selectedDayOffer.date, dateWithTimezone) : false
    };
  }).sort((firstDate, secondDate) => firstDate.id - secondDate.id);

  return updateBargainPrices(dayOffersProcessed);
};

export const getDatesWithTimeEndpoint = ({
  facilityId,
  widgetType,
  onlineGroupId,
  groupId,
  internalBuyerFacilityId,
  businessGroupId,
  activityId
}: IConfigurationStore & {activityId: number}) => {
  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/activities/${activityId}/admission_date_day_offers/preview`; // eslint-disable-line max-len
  }

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/activities/${activityId}/admission_date_day_offers`; // eslint-disable-line max-len
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/activities/${activityId}/admission_date_day_offers`; // eslint-disable-line max-len
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/activities/${activityId}/admission_date_day_offers`;// eslint-disable-line max-len
  }

  const groupRoute = onlineGroupId ? onlineGroupId : groupId ? groupId : 1;

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/admission_date_day_offers/preview`; // eslint-disable-line max-len
  }

  return `user-api/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/admission_date_day_offers`; // eslint-disable-line max-len
};

export const getDatesEndpoint = ({
  facilityId,
  widgetType,
  onlineGroupId,
  groupId,
  internalBuyerFacilityId,
  businessGroupId,
  activityId
}: IConfigurationStore & {activityId: number}) => {
  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/activities/${activityId}/admission_dates/preview`; // eslint-disable-line max-len
  }

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/activities/${activityId}/admission_dates`; // eslint-disable-line max-len
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/activities/${activityId}/admission_dates`; // eslint-disable-line max-len
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/activities/${activityId}/admission_dates`;// eslint-disable-line max-len
  }

  const groupRoute = onlineGroupId ? onlineGroupId : groupId ? groupId : 1;

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/admission_dates/preview`; // eslint-disable-line max-len
  }

  return `user-api/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/admission_dates`; // eslint-disable-line max-len
};

const getRangeDates = (
  calendarYear: number, calendarMonth: number, numberOfAdmissionDatesMax: number, calendarDate?: number
) => {
  const monthRange = calendarDate ? 'day' : 'month';
  const date = calendarDate ?? 15;

  const start = dayjs(new Date(calendarYear, calendarMonth, date))
    .startOf(monthRange)
    .format(DASH_DAY_FORMAT);
  const end = dayjs(new Date(calendarYear, calendarMonth, date))
    .endOf(monthRange)
    .add(numberOfAdmissionDatesMax - 1, 'day')
    .format(DASH_DAY_FORMAT);

  return {start, end};
};

export const getDateRangeParams = (
  calendarYear: number, calendarMonth: number, numberOfAdmissionDatesMax: number, calendarDate?: number
) => {
  const {start, end} = getRangeDates(calendarYear, calendarMonth, numberOfAdmissionDatesMax, calendarDate);

  return [encodeURIComponent(start), encodeURIComponent(end), start, end];
};

const everyVariantsWithSamePrice = (comparedVariant: ApiAdmissionDateVariant, dayOffers: DayOffersProcessed[]) => {
  let isCommonPrice = true;

  dayOffers.forEach(dayOffer =>
    dayOffer.variants.forEach(variant => {
      const isSameVariant = comparedVariant.variantId === variant.variantId;

      if (isSameVariant) {
        const minCostIsSame = variant.costMin.price === comparedVariant.costMin.price;
        const maxCostIsSame = variant.costMax.price === comparedVariant.costMax.price;

        if (!minCostIsSame || !maxCostIsSame) {
          isCommonPrice = false;
        }
      }
    })
  );

  return isCommonPrice;
};

const isPriceCommonPerDay = (dayOffers: DayOffersProcessed[]) => {
  const firstDayOfferWithValidVariants = dayOffers.find(dayOffer => dayOffer.variants.length);

  if (!firstDayOfferWithValidVariants) {
    return true;
  }

  const dayOfferVariants = firstDayOfferWithValidVariants?.variants;

  // Get first variants and compare by price with same day offers variant per month
  return dayOfferVariants.every(variant => everyVariantsWithSamePrice(variant, dayOffers));
};

const isPriceCommonPerVariants = (dayOffers: DayOffersProcessed[]) =>
  dayOffers.every(dayOffer => dayOffer.isCommonVariantsPrice);

export const getIsCommonPrice = (dayOffers: DayOffersProcessed[] | null) => {
  if (dayOffers) {
    return isPriceCommonPerVariants(dayOffers) && isPriceCommonPerDay(dayOffers);
  }
};

export const getDateData = (
  calendarDate: Dayjs | null,
  dayOffers: DayOffersProcessed[] | null,
  datesGroupedByDay: IDatesGroupedByDay[] | null,
  timezone: string
) => {
  if (!calendarDate) {
    return;
  }

  if (dayOffers) {
    return dayOffers?.find(({date}) => isSameDate(date, calendarDate, 'D'));
  }

  return datesGroupedByDay?.find(({date}) =>
    isSameDate(getTimezoneDate(date, timezone, true), calendarDate, 'D')
  );
};
