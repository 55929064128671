import React, {SyntheticEvent} from 'react';
import {InvoiceTogglerProps} from './types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import locale from '@Utils/locale';
import {useDispatch, useSelector} from 'react-redux';
import {isWidgetInternal, isWidgetInternalPreview} from '@Utils/widgetType';
import {getWidgetType} from '@Features/configuration/configurationSelectors';
import {setStorage} from '@Features/storage/storageActions';
import {useFormContext} from 'react-hook-form';
import {getFacility} from '@Features/facility/facilitySelectors';
import pubsub from '@Utils/pubsub';
import {EVENT_INVOICE_FORM_DISABLED, EVENT_INVOICE_FORM_ENABLED} from '@Utils/events';

const InvoiceToggler = ({sendInvoice, saveInStorage, styles}: InvoiceTogglerProps) => {
  const dispatch = useDispatch();
  const widgetType = useSelector(getWidgetType);
  const {setValue} = useFormContext();
  const togglerLabel = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType) ?
    locale.translate('issueAnInvoice') : locale.translate('iWantInvoice');
  const facility = useSelector(getFacility)!;
  const {id: facilityId, name: facilityName} = facility;

  const handleSendEvent = (checked: boolean) => {
    const eventName = checked ? EVENT_INVOICE_FORM_ENABLED : EVENT_INVOICE_FORM_DISABLED;

    pubsub.trigger(eventName, {facilityId, facilityName});
  };

  function handleFormToggle(event: SyntheticEvent<Element, Event>, checked: boolean) {
    setValue('sendInvoice', checked);

    handleSendEvent(checked);

    if (saveInStorage) {
      dispatch(setStorage('sendInvoice', checked, true));
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={styles}
    >
      <FormControlLabel
        onChange={handleFormToggle}
        control={<Switch checked={sendInvoice} />}
        label={togglerLabel}
      />
    </Stack>
  );
};

export default InvoiceToggler;
