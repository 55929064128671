import './rafPolyfil';
import './includesPolyfil';
import 'whatwg-fetch';
import './polyfil';
import '../scss/main.scss';
import React from 'react';
import Promise from 'promise-polyfill';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createAppStore} from './store/store';
import LanguageProvider from './utils/LanguageProvider';
import App from './App';
import {initialize as initializeAmplitude} from '@Utils/events';
import {initSentry} from '@Utils/sentry';

initSentry();

if (!window.Promise) {
  window.Promise = Promise;
}

const $root = document.getElementById('dl-root');

if ($root) {
  initializeAmplitude();
  const store = createAppStore();

  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </Provider>, $root);
}

