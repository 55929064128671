import {IStore} from 'src/js/store/types';

export const getShippingMethods = (state: IStore) => {
  if (!state.shippingMethods.items.length) {
    return null;
  }

  return state.shippingMethods.items;
};

export const getShippingMethodsStatus = (state: IStore) => {
  if (!state.shippingMethods.status) {
    return null;
  }

  return state.shippingMethods.status;
};
