import FormInput from '@Components/formComponents/FormInput';
import locale from '@Utils/locale';
import {BaseTextFieldProps} from '@mui/material/TextField';
import {SxProps, Theme} from '@mui/material/styles';
import React from 'react';
import {string} from 'yup';

export const FIELD_NAME = 'email';
export const schema = () => string().email(locale.translate('invalidEmail')).nullable().default('');

interface IEmailField {
  name?: string,
  label?: string,
  fieldStyles?: SxProps<Theme>,
  componentProps?: BaseTextFieldProps
}

const EmailField = ({
  name = FIELD_NAME,
  label = locale.translate('email'),
  fieldStyles,
  componentProps
}: IEmailField) => (
  <FormInput
    name={name}
    componentProps={{label, inputProps: {type: 'email'}, ...componentProps}}
    fieldStyles={fieldStyles}
  />
);

export default EmailField;
