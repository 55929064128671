import {InvoiceAddressCountry} from '@Consts/invoiceCountries';
import locale from '@Utils/locale';

export const getCountries = () =>
  Object.values(InvoiceAddressCountry)
    .map(country => ({code: country, label: locale.translate(country)}));

export const getSpecificCountry = (country: string) => {
  const addressCountry = Object.values(InvoiceAddressCountry)
    .find(item => item.toLowerCase() === country);

  if (!addressCountry) {
    return {
      code: InvoiceAddressCountry.PL,
      label: locale.translate(InvoiceAddressCountry.PL)
    };
  }

  return {
    code: addressCountry,
    label: locale.translate(addressCountry)
  };
};
