import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getParsedAdmissionDuration, isActivitySinglePickDate} from '@Utils/activity/activity';
import locale from '@Utils/locale';
import ActivityContent, {SPACING_TYPE} from '@Components/activities/activity/ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const ActivitySingle = ({basketItem}) => {
  const {activity, selections} = basketItem;
  const {passType, admissionPolicy} = activity;
  const {numberOfDays} = selections;
  const {durationInMinutes} = admissionPolicy;
  const parsedDuration = getParsedAdmissionDuration(durationInMinutes);

  function getMultipleDurationInfo() {
    if (numberOfDays && isActivitySinglePickDate(passType)) {

      const durationInfo = parsedDuration?.length ? parsedDuration.join(' ') : locale.translate('durationUnlimited');

      if (numberOfDays === 1) {

        return parsedDuration?.length ? `${durationInfo}` : `${locale.translate('durationUnlimitedSentence')}`;
      }

      return `${numberOfDays} dni x ${durationInfo}`;
    }

    return parsedDuration?.length ? parsedDuration.join(' ') : locale.translate('durationUnlimitedSentence');
  }

  if (durationInMinutes) {
    return (
      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <AccessTimeOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        <Text color={ETextColor.LIGHT}>{getMultipleDurationInfo()}</Text>
      </ActivityContent>
    );
  }

  return null;
};

export default ActivitySingle;
