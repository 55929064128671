import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import DlTag from '@Components/common/dlTag/DlTag';
import {ETagState} from '@Components/common/dlTag/types';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Text from '@Components/common/text/Text';
import {ETextDisplay, ETextSize} from '@Components/common/text/types';
import {getFacility} from '@Features/facility/facilitySelectors';
import locale from '@Utils/locale';
import {COLOR_DARK, COLOR_LIGHT, TEXT_COLOR_DARK} from '@Utils/styles';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import './filter-tags.scss';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_ACTIVITIES_FILTER_TAG} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {EAlignItems, EFlexGap, EFlexWrap} from '@Components/common/flexBox/types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const FilterTags = ({facilityTags, activeTag, setActiveTag}) => {
  const facility = useSelector(getFacility);
  const {facilityId, affiliationHash} = useSelector(getConfiguration);
  const {name: facilityName, company, widgetCustomization} = facility;
  const {industry} = company;
  const {primaryButton} = widgetCustomization;
  const {backgroundColor, textColor} = primaryButton;

  const handleEvent = tag => {
    pubsub.trigger(EVENT_VIEW_ACTIVITIES_FILTER_TAG, {
      facilityId,
      facilityName,
      language: parseLanguage(locale.locale),
      industry,
      affiliationHash,
      tag: tag ? tag : 'none'
    });
  };

  const handleClickTag = tag => {
    if (tag === activeTag) {
      handleEvent(null);
      return setActiveTag(null);
    }

    handleEvent(tag);
    return setActiveTag(tag);
  };

  return (
    <div className="dl-filter-tags">
      <FlexBox wrap={EFlexWrap.WRAP} flexGap={EFlexGap.GAP_4}>
        {
          !!facilityTags?.length && facilityTags.map(({name: tagName}, id) => {
            const isActive = tagName === activeTag;

            return (
              <DlTag
                size={ETextSize.SMALL}
                key={`dlTag-${id}`}
                onClick={() => handleClickTag(tagName)}
                customColors={{
                  textColor: textColor === TEXT_COLOR_DARK ? COLOR_DARK : COLOR_LIGHT,
                  backgroundColor
                }}
                state={isActive ? ETagState.ACTIVED : undefined}
                withIconRight={isActive}
              >
                <FlexBox wrap={EFlexWrap.NOWRAP} flexGap={EFlexGap.GAP_4} align={EAlignItems.CENTER}>
                  <Text size={ETextSize.SMALL} display={ETextDisplay.INLINE} >
                    {tagName}
                  </Text>
                  {
                    isActive && <CloseOutlinedIcon fontSize="small" />
                  }
                </FlexBox>
              </DlTag>
            );
          })
        }
      </FlexBox>
    </div>
  );
};

FilterTags.propTypes = {
  facilityTags: PropTypes.arrayOf(
    PropTypes.shape({name: PropTypes.string})
  ).isRequired,
  activeTag: PropTypes.string,
  setActiveTag: PropTypes.func.isRequired
};

export default FilterTags;
