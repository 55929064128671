import directFrameMessage, {DirectFrameMessageParams} from '@Utils/directFrameMessage';
import {useCallback, useMemo} from 'react';

// There are more actions triggered that are outside of Redux Context.
// Search for 'directFrameMessage' in the codebase to see all the actions.
type FrameMessageAction = DirectFrameMessageParams;

const useFrameMessage = () => {
  const url = window.location.href.split('#')[0];
  const isModal = useMemo(() => url?.includes('mode=modal') ?? false, [url]);

  /**
   * Posts a message to the parent window.
   * @param {FrameMessageAction} payload - The data to be sent to the parent window.
   * @returns {void}
   */
  const messageToParent = useCallback((payload: FrameMessageAction) => {
    directFrameMessage({isModal, url, ...payload});
  }, [isModal, url]);

  return messageToParent;
};

export default useFrameMessage;