import React from 'react';
import {EInvoiceFormType} from '../types';
import locale from '@Utils/locale';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {Controller, useFormContext} from 'react-hook-form';

export const FIELD_NAME = 'invoiceFormType';

type Props = {
  isInvoiceFormSimplified: boolean;
}

const InvoiceFormTypeTabs = ({isInvoiceFormSimplified}: Props) => {
  const {control, watch} = useFormContext();

  const isPaymentOnline = watch('isPaymentOnline');

  return (
    <Controller
      control={control}
      name="invoiceFormType"
      render={({field: {value, onChange}}) => (
        <Tabs
          value={value}
          onChange={(_e, newValue) => onChange(newValue)}
          aria-label="Invoice type tabs"
          variant="fullWidth"
          sx={{mb: 3, mt: isPaymentOnline ? 0 : 3}}
        >
          {isInvoiceFormSimplified && (
            <Tab value={EInvoiceFormType.COMPANY_SIMPLIFIED} label={locale.translate('simplifiedInvoice')} />
          )}
          <Tab value={EInvoiceFormType.COMPANY} label={locale.translate('fullInvoice')} />
          <Tab value={EInvoiceFormType.PRIVATE_PERSON} label={locale.translate('privateInvoice')} />
        </Tabs>
      )}
    />
  );
};

export default InvoiceFormTypeTabs;
