import {AUTOPAY, PRZELEWY24, IMOJE, VIVAWALLET, GOPAY, DEV} from '@Consts/paymentProviders';
import {getApiDates} from './basket';
import {ECheckoutViewsTypes} from './checkoutViews/checkoutViewsConsts';
import locale from './locale';

export const MAX_LENGTH = 255;
export const MIN_LENGTH = 3;

/**
 * Returns linked checkout views
 * @param {CheckoutView[]} views - checkout views
 * @return {CheckoutView[]} linked checkout views
 */
export const getLinkedCheckoutViews = views => {
  if (views.length) {
    return views
      .map((view, index) => {
        const id = index + 1;

        if (index === 0 && views.length > 1) {
          return {
            ...view,
            id,
            previousViewId: null,
            nextViewId: id + 1
          };
        }

        if (index === views.length - 1) {
          return {
            ...view,
            id,
            previousViewId: id - 1 ? id - 1 : null,
            nextViewId: null
          };
        }

        return {
          ...view,
          id,
          previousViewId: id - 1,
          nextViewId: id + 1
        };
      });
  }

  return null;
};

/**
 * Returns checkout view of given id
 * @param {CheckoutView[]} views - array of views
 * @param {number} viewId - id of an view
 * @return {CheckoutView[]} checkout view
 */
export const getCheckoutView = (views, viewId) =>
  views?.find(view => view?.id === viewId) || null;

/**
 * Returns label for checkout header based on coresponding view
 * @param {CheckoutView} view - checkout view
 * @return {string} header label
 */
export const getCheckoutPageListItemContent = ({type, items, questionId}) => {
  if (type === ECheckoutViewsTypes.UPSELL) {
    return locale.translate('thisMayInterestYou');
  }

  if (type === ECheckoutViewsTypes.DATES || type === ECheckoutViewsTypes.TIMES) {
    const {activity, variant} = items[0];
    const {name: activityName} = activity;
    const {name: variantName} = variant;
    const name = variantName && activityName ? activityName + ', ' + variantName : activityName;

    return locale.translate('activityAdmissionDateLabel', {name});
  }

  if (type === ECheckoutViewsTypes.HOTEL_NARROWED) {
    return locale.translate('chooseHotelNight');
  }

  if (type === ECheckoutViewsTypes.PARTICIPANTS) {
    return locale.translate('enterNumberOfParticipants');
  }

  if (type === ECheckoutViewsTypes.PARTICIPANTS_GROUP) {
    const {activity, variant} = items[0];
    const {name: activityName} = activity;
    const {name: variantName} = variant;
    const name = variantName && activityName ? activityName + ', ' + variantName : activityName;

    return locale.translate('numberOfParticipantsLabel', {name});
  }

  if (type === ECheckoutViewsTypes.QUESTIONS) {
    const {variant} = items[0];
    const {questions} = variant;
    const question = questions.find(question => question.id === questionId);

    if (question) {
      return question.text;
    }

    return null;
  }

  if (type === ECheckoutViewsTypes.ADDITIONS) {
    const {activity} = items[0];
    const {name: activityName} = activity;

    return locale.translate('additionsHeader', {activityName});
  }

  if (type === ECheckoutViewsTypes.SHIPPING_METHODS) {
    return locale.translate('chooseShippingMethod');
  }

  return null;
};

/**
 * Returns description for checkout view about assinged items
 * @param {BasketItem[]} basketItems - basket items array
 * @return {string} description
 */
export const getCheckoutItemsDescription = basketItems => {
  const firstItem = basketItems[0];
  const description = basketItems
    .reduce((accumulator, item) => {
      if (!accumulator.filter(accumulatedItem => accumulatedItem.variantId === item.variantId).length) {
        return accumulator.concat(item);
      }

      return accumulator;
    }, [])
    .map(item => {
      const numberOfItems = basketItems.filter(viewItem => viewItem.variantId === item.variantId).length;
      const variantName = item.variant.name;
      const variantOptionalInfo = variantName ? `${variantName} ` : '';

      return `${variantOptionalInfo}x ${numberOfItems}`;
    })
    .join(', ');

  return `${firstItem.activity.name} ${description}`;
};

/**
 * Returns payment provider image info
 * @param {string} paymentProvider - payment provider name
 * @return {object} object which contains src, alt and className fields
 */
export const getPaymentProviderImageInfo = paymentProvider => {
  if (paymentProvider === AUTOPAY) {
    return {src: 'img/autopay.png', alt: 'Autopay', className: 'dl-dotpay__image--autopay'};
  }

  if (paymentProvider === PRZELEWY24) {
    return {src: 'img/przelewy24.png', alt: 'Przelewy24', className: 'dl-dotpay__image--przelewy24'};
  }

  if (paymentProvider === IMOJE) {
    return {src: 'img/imoje_logo.png', alt: 'imoje', className: 'dl-dotpay__image--imoje'};
  }

  if (paymentProvider === VIVAWALLET) {
    return {src: 'img/vivawallet_logo.png', alt: 'Viva Wallet', className: 'dl-dotpay__image--vivawallet'};
  }

  if (paymentProvider === GOPAY) {
    return {src: 'img/gopay_logo.png', alt: 'Go Pay', className: 'dl-dotpay__image--gopay'};
  }

  if (paymentProvider === DEV) {
    return {src: 'img/dev_logo.png', alt: 'Go Pay', className: 'dl-dotpay__image--dev'};
  }

  return {};
};

/**
 * Returns discount items array
 * @param {BasketItem[]} basketItems - basket items
 * @return {DiscountItem[]} items prepared for calculating discount
 */
export const getDiscountItems = basketItems =>
  basketItems.map(item => {
    const {activityId, variantId, selections} = item;
    const {dates, time, numberOfParticipants, numberOfSeats} = selections;
    const numberOfDates = dates?.length;
    const apiDates = numberOfDates >= 1 || !!time ? getApiDates(item) : null;

    return {
      activityId,
      variantId,
      ...apiDates,
      numberOfParticipants: numberOfParticipants || numberOfSeats
    };
  });

