import {IShippingMethod, TFacilityId, TOnlineGroupId} from '@Consts/apiGlobals';
import {EStatus} from '@Consts/status';
import {EWidgetType} from '@Consts/globals';

export enum EShippingMethodActionsTypes {
  SET_SHIPPING_METHODS = 'SET_SHIPPING_METHODS',
  SET_SHIPPING_METHODS_STATUS = 'SET_SHIPPING_METHODS_STATUS',
  CLEAR_SHIPPING_METHODS = 'CLEAR_SHIPPING_METHODS',
}

export type TShippingMethodsActions = {
  type: EShippingMethodActionsTypes.SET_SHIPPING_METHODS,
  payload: IShippingMethod[]
} | {
  type: EShippingMethodActionsTypes.SET_SHIPPING_METHODS_STATUS,
  payload: EStatus
} | {
  type: EShippingMethodActionsTypes.CLEAR_SHIPPING_METHODS,
}

export interface IConfiguration {
  facilityId: TFacilityId,
  onlineGroupId: TOnlineGroupId,
  widgetType: EWidgetType,
  internalBuyerFacilityId: TFacilityId,
  businessGroupId: TFacilityId
}
