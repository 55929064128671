/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Sprzedaż wyłączona!',
  'buyOnline': 'Kup online!',
  'buyVoucher': 'Kup voucher',
  'buyTicket': 'Kup bilet!',
  'bookingOnline': 'Rezerwuj online',
  'buySkipass': 'Kup skipass',
  'buyCourse': 'Kup kurs',
  'localTours': 'Wycieczki lokalne',
  'shopOnline': 'Sklep online',
  'orderOnline': 'Zamów online',
  'planVisit': 'Zaplanuj wizytę',
  'buyOnlineCheaper': 'Kup online taniej!'
};
