import {ELocale} from '@Consts/globals';
import locale from '../locale';
import {ApiBubbleConfig} from '../types';
import getData from './getData';

const API_URL = __API_ADDRESS__;

type BubbleConfig = Omit<ApiBubbleConfig, 'bottomMargin' | 'sideMargin' | 'type' | 'locale' | 'url'> & {
  bottomMargin: string,
  sideMargin: string,
  text: string,
  className: string,
  uuid: string | null,
  configuration: 'uuid' | 'params' | 'config',
  url: string,
  salesDisabled: boolean
}

export const TEXT_TYPES = {
  DEFAULT: 'DEFAULT',
  BUY_ONLINE: 'BUY_ONLINE',
  TICKET: 'TICKET',
  VOUCHER: 'VOUCHER',
  BOOKING_ONLINE: 'BOOKING_ONLINE',
  LOCAL_TOURS: 'LOCAL_TOURS',
  BUY_SKIPASS: 'BUY_SKIPASS',
  BUY_COURSE: 'BUY_COURSE',
  SHOP_ONLINE: 'SHOP_ONLINE',
  ORDER_ONLINE: 'ORDER_ONLINE',
  PLAN_VISIT: 'PLAN_VISIT',
  BUY_ONLINE_CHEAPER: 'BUY_ONLINE_CHEAPER'
} as const;

export const BUBBLE_TYPES = [
  {textType: TEXT_TYPES.DEFAULT, className: 'buy-online', text: 'buyOnline'},
  {textType: TEXT_TYPES.BUY_ONLINE, className: 'buy-online', text: 'buyOnline'},
  {textType: TEXT_TYPES.TICKET, className: 'buy-ticket', text: 'buyTicket'},
  {textType: TEXT_TYPES.VOUCHER, className: 'buy-voucher', text: 'buyVoucher'},
  {textType: TEXT_TYPES.BOOKING_ONLINE, className: 'booking-online', text: 'bookingOnline'},
  {textType: TEXT_TYPES.LOCAL_TOURS, className: 'local-tours', text: 'localTours'},
  {textType: TEXT_TYPES.BUY_SKIPASS, className: 'buy-skipass', text: 'buySkipass'},
  {textType: TEXT_TYPES.BUY_COURSE, className: 'buy-course', text: 'buyCourse'},
  {textType: TEXT_TYPES.SHOP_ONLINE, className: 'shop-online', text: 'shopOnline'},
  {textType: TEXT_TYPES.ORDER_ONLINE, className: 'order-online', text: 'orderOnline'},
  {textType: TEXT_TYPES.PLAN_VISIT, className: 'plan-visit', text: 'planVisit'},
  {textType: TEXT_TYPES.BUY_ONLINE_CHEAPER, className: 'buy-online-cheaper', text: 'buyOnlineCheaper'}
];

const cleanupElements = (elements: HTMLCollectionOf<Element>) => {
  Array.from(elements).forEach(element => element.remove());
};

const cleanupHardcodedBubbles = () => {
  cleanupElements(document.getElementsByClassName('dl-bubble'));
  cleanupElements(document.getElementsByClassName('dl-bubble-tab-mobile'));
};

class SalesDisabledError extends Error {
  // added constructor for problems with test in TS -->
  // https://stackoverflow.com/questions/68899615/how-to-expect-a-custom-error-to-be-thrown-with-jest
  constructor() {
    super();
    Object.setPrototypeOf(this, SalesDisabledError.prototype);
  }
}
class NoConfigError extends Error {}

// facilityId should be a number

const getApiBubbleConfig = async (facilityId: string, uuid: string | null, dataHref: string | null) => {
  const url = new URL(`${API_URL}/user-api/bubble_configs`);

  if (uuid) {
    url.searchParams.append('uuid', uuid);
  } else if (dataHref) {
    url.searchParams.append('facilityId', facilityId);
    url.searchParams.append('url', dataHref);
  } else {
    return;
  }

  try {
    const response = await fetch(url.toString());
    const data: ApiBubbleConfig = await response.json();

    if (data?.type === 'onlineSaleDisabled') {
      throw new SalesDisabledError();
    }
    return data;
  } catch (error) {
    if (error instanceof SalesDisabledError) {
      throw error;
    }
    throw new NoConfigError();
  }
};

const getTranslation = (translate: ReturnType<typeof locale>, salesDisabled: boolean, textType: string) => {
  if (salesDisabled) {
    return {
      className: 'booking-online',
      text: translate('bubbleDisabled')
    };
  }

  const {text, className} =
    BUBBLE_TYPES.find(typeObject => typeObject.textType === textType) || BUBBLE_TYPES[0];

  return {
    className,
    text: translate(text)
  };
};

const getConfig = async (node: Element, isPreview: boolean): Promise<BubbleConfig> => {
  const facilityId = getData(node, 'data-facility-id');
  const dataTextType = getData(node, 'data-text-type');
  const dataHref = getData(node, 'data-href').trim();
  const uuid = getData(node, 'data-uuid');
  let apiConfig = null;
  let salesDisabled = false;
  let configuration: 'uuid' | 'params' | 'config';

  if (!isPreview) {
    try {
      apiConfig = await getApiBubbleConfig(facilityId, uuid, dataHref);
    } catch (error) {
      if (error instanceof SalesDisabledError) {
        salesDisabled = true;
        cleanupHardcodedBubbles();
      }
    }
  }

  if (apiConfig?.facilityId) {
    configuration = uuid ? 'uuid' : 'params';
  } else {
    configuration = 'config';
  }

  const defaultLanguage = apiConfig?.locale ?? getData(node, 'data-default-language') as ELocale;
  const translate = locale(defaultLanguage);
  const {text, className} = getTranslation(translate, salesDisabled, apiConfig?.type ?? dataTextType);
  const isLeft = !!getData(node, 'data-left');
  const sideMargin = isLeft ? getData(node, 'data-left') : getData(node, 'data-right', '24px');
  const configHref = salesDisabled ? window.location.href : dataHref;

  return {
    facilityId: apiConfig?.facilityId ?? Number(facilityId),
    shape: apiConfig?.shape ?? getData(node, 'data-shape', 'BUBBLE') as 'BUBBLE' | 'TAB',
    backgroundColor: apiConfig?.backgroundColor ?? getData(node, 'data-background-color', '#0E68AF'),
    textColor: apiConfig?.textColor ?? getData(node, 'data-text-color-type', 'LIGHT').toUpperCase() as 'LIGHT' | 'DARK',
    position: apiConfig?.position ?? (isLeft ? 'LEFT' : 'RIGHT'),
    bottomMargin: apiConfig?.hasOwnProperty('bottomMargin') ?
    `${apiConfig.bottomMargin}px` : getData(node, 'data-bottom', '24px'),
    sideMargin: apiConfig?.hasOwnProperty('sideMargin') ? `${apiConfig.sideMargin}px` : sideMargin,
    url: apiConfig?.url || configHref,
    onlineGroupId: apiConfig?.onlineGroupId,
    widgetScriptId: apiConfig?.widgetScriptId,
    text,
    className,
    uuid,
    configuration,
    salesDisabled
  };
};

export default getConfig;
