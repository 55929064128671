import React from 'react';
import {areActivityDatesInvalid, hasActivityValidPrice, isActivitySingleGroupEvent} from '@Utils/activity/activity';
import {useDispatch, useSelector} from 'react-redux';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getActivityBasketItems, getVariantBasketItems} from '@Utils/basket';
import {getVariantById} from '@Utils/activity/variant';
import {getFacility} from '@Features/facility/facilitySelectors';
import {isVariantGroup} from '@Utils/variantType';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import ActivityAddButton from './buttons/ActivityAddButton';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';
import {getPickerDisableReason, getCounterMaxValue} from './utils';
import {IBasketItem} from '@Consts/types';
import Box from '@mui/material/Box';
import CounterPicker from './counterPicker/CounterPicker';
import {modifyBasket} from '@Features/basket/basketActions';

import {useVariantPickerAnalytics} from '@Hooks/useVariantPickerAnalytics';

type Props = {
  activity: IActivityProcessed;
  variantId: number;
  orderNumber: number;
  showErrorAbsolute?: boolean;
};

const ActivityVariantPicker = ({activity, variantId, orderNumber, showErrorAbsolute}: Props) => {
  const dispatch = useDispatch();
  const isCheckout = !!useMatch(CHECKOUT_PATH);
  const {id: activityId, passType, variants, admissionPolicy, salePolicy} = activity;
  const {isSoldOut} = salePolicy;
  const variant: IActivityVariantProcessed = getVariantById(variants, variantId);
  const {
    handleAmplitudeEvent,
    handleGTMEvent,
    handleFBPixelEvent
  } = useVariantPickerAnalytics(activity, variant, orderNumber);
  const facility = useSelector(getFacility)!;
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const variantBasketItems = getVariantBasketItems(activityBasketItems, activityId, variantId);
  const numberOfItems = getNumberOfItems();
  const {company} = facility;
  const {maximumTicketsInOrder} = company;
  const showAddButton = variants.length === 1;
  const isGroupVariantType = isVariantGroup(variant?.type) || isActivitySingleGroupEvent(activity.passType);
  const datesAreInvalid = areActivityDatesInvalid(activity);
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const disabled = isSoldOut || datesAreInvalid || !activityHaveValidPrices;
  const counterMaxValue = getCounterMaxValue({
    variant,
    activity,
    maximumTicketsInOrder,
    numberOfBasketItems: basketItems.length,
    numberOfVariantItems: variantBasketItems.length,
    isGroupVariantType
  });
  const disableReason = getPickerDisableReason({
    activity,
    basketItems,
    maximumTicketsInOrder,
    variantId
  });
  const selectedNumberOfParticipants = variantBasketItems[0]?.selections?.numberOfParticipants;
  const selectedNumberOfSeats = variantBasketItems[0]?.selections?.numberOfSeats;
  const modifyBasketActionData = {activity, variantId, orderNumber, isCheckout, blockEvent: true};

  function getNumberOfItems() {
    if (variantBasketItems.length > 0) {
      if (isVariantGroup(variant?.type)) {
        return variantBasketItems[0].selections.numberOfSeats;
      }

      if (isActivitySingleGroupEvent(passType)) {
        return variantBasketItems[0].selections.numberOfParticipants;
      }
    }

    return variantBasketItems.length;
  }

  const addBasketItems = async (amount: number) => {
    for (let i = 0; i < amount; i++) {
      dispatch(modifyBasket(modifyBasketActionData, 'add'));
    }

    handleGTMEvent(amount, 'add');
  };

  const removeBasketItems = (amount: number) => {
    for (let i = 0; i < amount; i++) {
      dispatch(modifyBasket(modifyBasketActionData, 'remove'));
    }

    handleGTMEvent(amount, 'remove');
  };

  function handleBasketItemsChange(value: number) {
    const currentPickerElements = selectedNumberOfParticipants ?? selectedNumberOfSeats ?? numberOfItems;
    const shouldAdd = value > currentPickerElements;
    const shouldRemove = value < currentPickerElements;

    if (shouldAdd) {
      if (numberOfItems === 0) {
        handleFBPixelEvent();
      }
      addBasketItems(value - currentPickerElements);
    } else if (shouldRemove) {
      removeBasketItems(currentPickerElements - value);
    }
  }

  const handleAddButtonClick = () => {
    dispatch(modifyBasket(modifyBasketActionData, 'add'));
    handleAmplitudeEvent(1);
    handleGTMEvent(1, 'add');
    handleFBPixelEvent();
  };

  if (showAddButton && !numberOfItems) {
    return (
      <Box sx={{width: {xs: isCheckout ? '158px' : '100%', sm: '158px'}}}>
        <ActivityAddButton
          onClick={handleAddButtonClick}
          disableReason={disableReason}
        />
      </Box>
    );
  }

  const getPickerGroupValue = () => {
    if (isVariantGroup(variant?.type)) {
      return selectedNumberOfSeats ?? 0;
    }

    if (isActivitySingleGroupEvent(passType)) {
      return selectedNumberOfParticipants ?? 0;
    }

    return numberOfItems;
  };

  const handleCounterOnChange = (value: number) => {
    handleBasketItemsChange(value);

    // Number of tickets is calculated based on future basket items creations prediction
    // Alternative way is to keep in state how many items should be add/remove and call event when action is done
    // Both methods don't guarantee that number of tickets will be correct
    const predictedBasketItemsAmount = basketItems.length - variantBasketItems.length + value;

    if (predictedBasketItemsAmount) {
      handleAmplitudeEvent(predictedBasketItemsAmount);
    }
  };

  return (
    <CounterPicker
      value={getPickerGroupValue()}
      disableReason={disableReason}
      onChange={handleCounterOnChange}
      maxValue={counterMaxValue}
      minValue={variant.minNumberOfSeats ?? admissionPolicy?.minNumberOfParticipants ?? 0}
      isGroupVariantType={isGroupVariantType}
      basketItemExists={!!variantBasketItems.length}
      showErrorAbsolute={showErrorAbsolute}
      variantId={variantId}
      disabled={disabled}
    />
  );
};

export default ActivityVariantPicker;
