import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getPaymentProviderImageInfo} from '@Utils/checkout';

const CheckoutPaymentProvider = ({paymentProvider}) => {
  const {src, alt, className} = getPaymentProviderImageInfo(paymentProvider);

  return (
    <div>
      <img className={classNames('dl-dotpay__image', className)} src={src} alt={alt} />
    </div>
  );
};

CheckoutPaymentProvider.protoTypes = {
  paymentProvider: PropTypes.string
};

export default CheckoutPaymentProvider;
