import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import locale from '@Utils/locale';
import ActivityContent from '../ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const ActivityAdmissionSingle = ({admissionPolicy, hideIcon, parsedDuration}) => {
  const {durationInMinutes} = admissionPolicy;
  const durationInfo = parsedDuration?.length ?
    parsedDuration.join(' ') : locale.translate('durationUnlimitedSentence');

  if (durationInMinutes) {
    return (
      <ActivityContent>
        {
          !hideIcon &&
          <AccessTimeOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        <Text color={ETextColor.LIGHT}>{durationInfo}</Text>
      </ActivityContent>
    );
  }

  return null;
};

ActivityAdmissionSingle.propTypes = {
  admissionPolicy: PropTypes.object.isRequired,
  parsedDuration: PropTypes.array,
  hideIcon: PropTypes.bool
};

export default ActivityAdmissionSingle;
