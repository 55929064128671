import {useEffect, useState} from 'react';
import {useSummaryData} from './useSummaryData';
import {useForm} from 'react-hook-form';
import {getValidationSchema} from '@Components/summary/sendOrderForm/validationSchema';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSendOrder} from './fetchHooks/useSendOrder';
import {TFormData} from '@Components/summary/sendOrderForm/types';

export const useSendOrderForm = () => {
  const {
    businessOrder,
    internalOrder,
    orderId
  } = useSummaryData();
  const [orderEmail, setOrderEmail] = useState('');
  const emailDefaultValue = internalOrder?.orderBuyer?.email || businessOrder?.orderBuyer?.email;
  const defaultValues: TFormData = {
    email: emailDefaultValue ?? '',
    termsOfService: false
  };

  const formProperties = useForm<TFormData>({
    defaultValues,
    resolver: yupResolver<TFormData>(getValidationSchema())
  });
  const {setValue} = formProperties;

  const {handleSendOrder, isLoading, isSuccess, isError, reset} = useSendOrder({
    orderId
  });

  useEffect(() => {
    if (isSuccess) {
      setValue('email', '');
      setValue('termsOfService', false);
    }
  }, [isSuccess]);

  const handleFormSubmit = (data: TFormData) => {
    handleSendOrder(data);
    setOrderEmail(data.email);
  };

  const handleCloseAlert = () => {
    reset();
  };

  return {
    formProperties,
    handleFormSubmit,
    isLoading,
    isError,
    isSuccess,
    handleCloseAlert,
    orderEmail
  };
};
