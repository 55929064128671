import {BaseTextFieldProps} from '@mui/material/TextField';
import React, {useContext} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import {LanguageContext} from '@Utils/LanguageProvider';
import locale from '@Utils/locale';
import {Dayjs} from 'dayjs';
import {Theme, useMediaQuery} from '@mui/material';
import {getTimezoneDate} from '@Utils/dayjs/dayjsUtils';

interface IFormDateTimePicker {
  name: string,
  componentProps?: BaseTextFieldProps,
  maxDate?: Dayjs
}

const FormDateTimePicker = ({
  name,
  componentProps,
  maxDate
}: IFormDateTimePicker) => {
  const facility = useSelector(getFacility);

  if (!facility) {
    return null;
  }
  const {control} = useFormContext();
  const datefnsToDayjs = (datefnsDate: Dayjs | null) => getTimezoneDate(datefnsDate, facility.timezone);
  const {language} = useContext(LanguageContext);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref}, fieldState: {invalid, error}}) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
          localeText={{
            cancelButtonLabel: locale.translate('cancel'),
            dateTimePickerToolbarTitle: locale.translate('setDateAndTime')
          }}
        >
          <DateTimePicker
            label={componentProps?.label}
            ampm={false}
            maxDate={maxDate}
            sx={{mb: 2, width: 1}}
            disablePast
            value={value}
            onChange={(newVal: Dayjs | null) => onChange(datefnsToDayjs(newVal))}
            format="DD.MM.YYYY HH:mm"
            views={['year', 'month', 'day', 'hours', 'minutes']}
            viewRenderers={isDesktop ? {
              minutes: null,
              hours: null
            } : {}}
            slotProps={{
              textField:
              {
                name,
                variant: componentProps?.variant,
                error: invalid,
                helperText: error?.message ?? '',
                inputProps: {'data-testid': 'DatePickerInput'}
              }
            }}
            inputRef={ref}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDateTimePicker;
