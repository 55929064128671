import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import FlexBox from '../flexBox/FlexBox';
import './counter.scss';
import {EJustifyContent} from '../flexBox/types';

const Counter = ({
  name,
  disabled,
  min,
  max,
  value,
  onFocus,
  onChange,
  onBlur,
  invalid,
  className
}) => {
  function handleInputChange({target}) {
    const value = parseInt(target.value, 10);
    const validValue = Number.isNaN(value) ? null : value;

    if (validValue === null) {
      target.value = null;
    }

    target.value = validValue;
  }

  function handleInputBlur({target}) {
    const value = parseInt(target.value, 10);
    const validValue = Number.isNaN(value) ? null : value;

    if (validValue === null) {
      target.value = null;

      return onBlur(null);
    }

    return onBlur(validValue);
  }

  function handleChange(value) {
    const parsedValue = parseInt(value, 10);

    if (!disabled) {
      onChange(parsedValue);
    }
  }

  function handleDecrement() {
    const currentValue = parseInt(value, 10);
    const validValue = Number.isNaN(currentValue) ? 0 : currentValue;
    const nextValue = validValue - 1;

    handleChange(nextValue);
  }

  function handleIncrement() {
    const currentValue = parseInt(value, 10);
    const validValue = Number.isNaN(currentValue) ? 0 : currentValue;
    const nextValue = validValue + 1;

    handleChange(nextValue);
  }

  return (
    <FlexBox justify={EJustifyContent.CENTER} className={classNames('dl-counter', className)}>
      <div
        className="dl-button dl-button--black"
        onClick={handleDecrement}
        disabled={value <= min || !value}
      >
        <div className="dl-text--emphasized">
          -
        </div>
      </div>

      <input
        id={name}
        name={name}
        type="number"
        disabled={disabled}
        onFocus={onFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        value={value || ''}
        className={classNames({
          'dl-dotpay__input--invalid': invalid
        })}
      />

      <div
        className="dl-button dl-button--black"
        onClick={handleIncrement}
        disabled={value >= max}
      >
        <div className="dl-text--emphasized">
          +
        </div>
      </div>
    </FlexBox>
  );
};

export default Counter;
