import locale from '@Utils/locale';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview, isWidgetOnlineClientPreview
} from '@Utils/widgetType';

export const getGroupParam = (onlineGroupId, groupId) => {
  if (onlineGroupId) {
    return `onlineGroupId=${onlineGroupId}`;
  }

  if (groupId) {
    return `onlineGroupId=${groupId}`;
  }

  return 'onlineGroupId=1';
};

export const getFacilityEndpoint = ({facilityId, widgetType, onlineGroupId, groupId}) => {
  const params = [`locale=${locale.language}`];

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}?${params.join('&')}`;
  }

  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/preview?${params.join('&')}`;
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}?${params.join('&')}`;
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/preview?${params.join('&')}`;
  }

  params.push(getGroupParam(onlineGroupId, groupId));

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/preview?${params.join('&')}`;
  }

  return `user-api/facilities/${facilityId}?${params.join('&')}`;
};
