import {ThunkAction} from 'redux-thunk';
import {AnyAction} from 'redux';
import {apiGet} from '@Utils/api/api';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {
  getDateRangeParams,
  getDatesEndpoint,
  getDatesWithTimeEndpoint
} from './activityAdmissionDatesUtils';
import pubsub from '@Utils/pubsub';
import {EVENT_ACTIVITY_ADMISSION_DATES_ERROR} from '@Utils/events';
import {getProcessedDates} from '@Features/activities/activitiesUtils';
import {
  AdmissionDateExtended,
  ApiDayOffers,
  EAdmissionDatesActionsTypes,
  FetchDayOffersProps,
  IAdmissionDateProcessed,
  IFetchAdmissionDatesAndFilterAvailableProps,
  IFetchAdmissionDatesProps
} from './types';
import {IStore} from 'src/js/store/types';
import {IAdmissionDate} from '@Consts/apiGlobals';
import {filterAvailableDates, getActivityAdmissionDatesApiParams} from './apiUtils';
import locale from '@Utils/locale';
import {
  getCurrency,
  getDatesExtended
} from '@Components/checkoutPages/admissionRealization/admissionRealizationUtils';
import {isVariantPriceCommon} from '@Utils/activity/variant';
import {getFacility} from '@Features/facility/facilitySelectors';
import {EStatus} from '@Consts/status';

export const SET_ACTIVITY_ADMISSION_DATES = 'SET_ACTIVITY_ADMISSION_DATES';
export const SET_SELECTED_ACTIVITY_ADMISSION_DATES_MONTH = 'SET_SELECTED_ACTIVITY_ADMISSION_DATES_MONTH';

export const setActivityAdmissionDates = (dates: AdmissionDateExtended[] | null) => ({
  type: EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES,
  payload: {dates}
});

export const setActivityAdmissionDatesStatus = (datesStatus: EStatus | null) => ({
  type: EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES_STATUS,
  payload: {datesStatus}
});

export const setDayOffers = (dayOffers: ApiDayOffers[] | null) => ({
  type: EAdmissionDatesActionsTypes.SET_DAY_OFFER,
  payload: {dayOffers}
});

export const datesCleanup = (): ThunkAction<void, IStore, unknown, AnyAction> => async dispatch => {
  dispatch(setActivityAdmissionDates(null));
  dispatch(setDayOffers(null));
  dispatch(setActivityAdmissionDatesStatus(null));
};

export const fetchAdmissionDatesAndFilterAvailable = async ({
  url, configuration
}: IFetchAdmissionDatesAndFilterAvailableProps): Promise<Function | IAdmissionDate[]> => {
  const activityDates: IAdmissionDate[] = await apiGet(url, configuration);

  return filterAvailableDates(activityDates);
};

export const fetchAdmissionDates = ({
  activityId,
  calendarMonth,
  calendarYear,
  calendarDate,
  numberOfAdmissionDatesMax,
  activityVariantsIds,
  activeViewItems,
  itemsVariants,
  currentAbTestType
}: IFetchAdmissionDatesProps): ThunkAction<void, IStore, unknown, AnyAction> => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());
  const facility = getFacility(getState())!;
  const {affiliationHash} = configuration;
  const {id, name} = facility;
  const [sinceParam, untilParam, start, end] = getDateRangeParams(
    calendarYear, calendarMonth, numberOfAdmissionDatesMax, calendarDate
  );

  const url = getDatesEndpoint({...configuration, activityId});
  const urlParams = getActivityAdmissionDatesApiParams({
    affiliationHash,
    locale: locale.language,
    sinceDate: sinceParam,
    uniltDate: untilParam,
    activityVariantsIds
  });

  const fetchingStart = new Date();

  dispatch(setActivityAdmissionDatesStatus(EStatus.IN_PROGRESS));
  dispatch(setActivityAdmissionDates(null));

  try {
    const activityAdmissionDates = await fetchAdmissionDatesAndFilterAvailable({
      url: `${url}?${urlParams}`, configuration
    });

    const parsedAdmissionDates: IAdmissionDateProcessed[] = getProcessedDates(
      activityAdmissionDates
    );

    const currency = getCurrency(parsedAdmissionDates);
    const isCommonPrice = activeViewItems.every(({variant}) => isVariantPriceCommon(variant));
    const datesExtended = getDatesExtended(
      parsedAdmissionDates, activeViewItems, itemsVariants, isCommonPrice, currency
    );

    dispatch(setActivityAdmissionDates(datesExtended));
    dispatch(setActivityAdmissionDatesStatus(EStatus.SUCCESS));
  } catch (error) {
    dispatch(setActivityAdmissionDates(null));
    dispatch(setActivityAdmissionDatesStatus(EStatus.FAILURE));

    const fetchingEnd = new Date();
    const fetchingTime = fetchingEnd.valueOf() - fetchingStart.valueOf();

    pubsub.trigger(EVENT_ACTIVITY_ADMISSION_DATES_ERROR, {
      id,
      name,
      activityId,
      error,
      since: start,
      until: end,
      fetchingTime,
      abTest: currentAbTestType
    });

    throw error;
  }
};

export const fetchDayOffers = ({
  activityId,
  calendarMonth,
  calendarYear,
  numberOfAdmissionDatesMax,
  activityVariantsIds,
  currentAbTestType
}: FetchDayOffersProps): ThunkAction<void, IStore, unknown, AnyAction> => async (dispatch, getState) => {
  const configuration = getConfiguration(getState());
  const facility = getFacility(getState())!;
  const {affiliationHash} = configuration;
  const {id, name} = facility;
  const [sinceParam, untilParam, start, end] = getDateRangeParams(
    calendarYear, calendarMonth, numberOfAdmissionDatesMax
  );

  const url = getDatesWithTimeEndpoint({...configuration, activityId});

  const urlParams = getActivityAdmissionDatesApiParams({
    affiliationHash,
    locale: locale.language,
    sinceDate: sinceParam,
    uniltDate: untilParam,
    activityVariantsIds
  });

  const fetchingStart = new Date();

  dispatch(setDayOffers(null));

  try {
    const dayOffers: ApiDayOffers[] = await apiGet(`${url}?${urlParams}`, configuration);
    const dayOffersAvailable = dayOffers.filter(dayOffer => dayOffer.isAvailable);

    dispatch(setDayOffers(dayOffersAvailable));
  } catch (error) {
    dispatch(setDayOffers(null));

    const fetchingEnd = new Date();
    const fetchingTime = fetchingEnd.valueOf() - fetchingStart.valueOf();

    pubsub.trigger(EVENT_ACTIVITY_ADMISSION_DATES_ERROR, {
      id,
      name,
      activityId,
      error,
      since: start,
      until: end,
      fetchingTime,
      abTest: currentAbTestType
    });

    throw error;
  }
};
