import React from 'react';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {TooltipBase} from './TooltipBase';
import {TooltipMobile} from './TooltipMobile';

export const EmailNotGetThroughTooltip = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? <TooltipMobile /> : <TooltipBase />;
};

