import {IAdmissionDateStore} from 'src/js/store/types';
import {EAdmissionDatesActionsTypes, TAdmissionDatesActions} from './types';

export const initialState: IAdmissionDateStore = {
  dates: null,
  dayOffers: null,
  datesStatus: null
};

const admissionDatesReducer = (state = initialState, action: TAdmissionDatesActions) => {
  switch (action.type) {
    case EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES: {
      return {
        ...state,
        dates: action.payload.dates
      };
    }

    case EAdmissionDatesActionsTypes.SET_ACTIVITY_ADMISSION_DATES_STATUS: {
      return {
        ...state,
        datesStatus: action.payload.datesStatus
      };
    }

    case EAdmissionDatesActionsTypes.SET_DAY_OFFER: {
      return {
        ...state,
        dayOffers: action.payload.dayOffers
      };
    }

    default:
      return state;
  }
};

export default admissionDatesReducer;
