import React from 'react';
import locale from '@Utils/locale';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const SUCCESS_DURATION = 5 * 1000;
const ERROR_DURATION = 30 * 1000;

type ISnackBarInfo = {
  isSnackbarOpen: boolean,
  closeSnackBar: () => void,
  isReinitializeOrderError: boolean
}

const SnackBarInfo = ({isSnackbarOpen, closeSnackBar, isReinitializeOrderError}: ISnackBarInfo) => (
  <Snackbar
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    open={isSnackbarOpen}
    autoHideDuration={isReinitializeOrderError ? ERROR_DURATION : SUCCESS_DURATION}
    onClose={closeSnackBar}
  >
    <Alert severity={isReinitializeOrderError ? 'error' : 'success'}>
      {
        isReinitializeOrderError ?
          locale.translate('paymentRepeatError') : locale.translate('paymentRepeatSuccess')
      }
    </Alert>
  </Snackbar>
);

export default SnackBarInfo;
