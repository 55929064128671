import React from 'react';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import PaymentMethodPicker from './formFields/PaymentMethodPicker';

const PaymentMethod = () => (
  <Box sx={{mb: 3}}>
    <Typography variant="h5" sx={{fontWeight: 500, py: 2}}>{locale.translate('paymentMethod')}</Typography>
    <PaymentMethodPicker />
  </Box>
);

export default PaymentMethod;
