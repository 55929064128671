import Polyglot from 'node-polyglot';
import TEXT_PL from '../locale/pl';
import TEXT_ENG from '../locale/eng';
import TEXT_SK from '../locale/sk';
import TEXT_CS from '../locale/cs';
import TEXT_DE from '../locale/de';
import TEXT_IT from '../locale/it';
import TEXT_UK from '../locale/uk';
import TEXT_ES from '../locale/es';
import TEXT_HU from '../locale/hu';
import {getQueryStringParameters, getParam} from './query';
import {ELocale} from '@Consts/globals';

class Locale {
  _locale: ELocale;
  _translate: Function;
  _polyglot: Polyglot;
  _phrases?: object;

  constructor() {
    this._locale = this.formatLocale(this.getInitLocale());
    this.fetchPhrases();
    const phrases = this.phrases;

    this._polyglot = new Polyglot({phrases});
    this._translate = this._polyglot.t.bind(this);
  }

  get locale() {
    return this._locale;
  }

  get phrases() {
    return this._phrases;
  }

  get polyglot() {
    return this._polyglot;
  }

  get translate() {
    return this._translate;
  }

  get language(): ELocale {
    const locale = this.locale.toLowerCase();

    if (locale.includes(ELocale.PL)) {
      return ELocale.PL;
    }

    if (locale.includes(ELocale.SK)) {
      return ELocale.SK;
    }

    if (locale.includes(ELocale.CS)) {
      return ELocale.CS;
    }

    if (locale.includes(ELocale.DE)) {
      return ELocale.DE;
    }

    if (locale.includes(ELocale.IT)) {
      return ELocale.IT;
    }

    if (locale.includes(ELocale.UK)) {
      return ELocale.UK;
    }

    if (locale.includes(ELocale.ES)) {
      return ELocale.ES;
    }

    if (locale.includes(ELocale.HU)) {
      return ELocale.HU;
    }

    return ELocale.EN;
  }

  getInitLocale() {
    const parameters = getQueryStringParameters();
    const defaultLanguage = getParam('defaultLanguage', parameters);

    if (defaultLanguage && this.isValidLocale(defaultLanguage)) {
      return defaultLanguage;
    }

    return window.navigator.language || '';
  }

  formatLocale(locale: string) {
    if (locale.includes(ELocale.PL)) {
      return ELocale.PL;
    }

    if (locale.includes(ELocale.SK)) {
      return ELocale.SK;
    }

    if (locale.includes(ELocale.CS)) {
      return ELocale.CS;
    }

    if (locale.includes(ELocale.DE)) {
      return ELocale.DE;
    }

    if (locale.includes(ELocale.IT)) {
      return ELocale.IT;
    }

    if (locale.includes(ELocale.UK)) {
      return ELocale.UK;
    }

    if (locale.includes(ELocale.ES)) {
      return ELocale.ES;
    }

    if (locale.includes(ELocale.HU)) {
      return ELocale.HU;
    }

    return ELocale.EN;
  }

  fetchPhrases() {
    switch (this.language) {
      case ELocale.PL:
        this._phrases = TEXT_PL;
        break;
      case ELocale.ES:
        this._phrases = TEXT_ES;
        break;
      case ELocale.IT:
        this._phrases = TEXT_IT;
        break;
      case ELocale.CS:
        this._phrases = TEXT_CS;
        break;
      case ELocale.DE:
        this._phrases = TEXT_DE;
        break;
      case ELocale.SK:
        this._phrases = TEXT_SK;
        break;
      case ELocale.UK:
        this._phrases = TEXT_UK;
        break;
      case ELocale.HU:
        this._phrases = TEXT_HU;
        break;
      default:
        this._phrases = TEXT_ENG;
    }
  }

  reinitialize(locale: ELocale) {
    this._locale = locale;
    this.fetchPhrases();
    this.polyglot.replace(this.phrases);
  }

  isValidLocale(locale: string) {
    return Boolean(Object.values(ELocale).find(language => language === locale));
  }
}

const locale = new Locale();

export default locale;
