import {SET_FACILITY_DATA, SET_FACILITY_STATUS} from './facilityActions';

const initialState = {
  facility: null,
  status: null
};

const facilityReducer = (state = initialState, action = {type: null}) => {
  switch (action.type) {
    case SET_FACILITY_STATUS:
      return {
        ...state,
        status: action.payload.status
      };

    case SET_FACILITY_DATA:
      return {
        ...state,
        facility: action.payload.facility
      };

    default:
      return state;
  }
};

export default facilityReducer;
