import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '@Components/common/flexBox/FlexBox';
import SkeletonBox from '@Components/common/skeletonBox/SkeletonBox';
import './navigationSkeleton.scss';
import {EAlignItems, EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const NavigationSkeleton = () => (
  <div className="dl-navigation-skeleton">
    <FlexBox
      direction={EFlexDirection.ROW}
      justify={EJustifyContent.SPACE_AROUND}
      align={EAlignItems.STRETCH}
      className="dl-navigation-skeleton__top-bar"
    >
      <SkeletonBox height="20px" />
      <div className="dl-navigation-skeleton__divider" />
      <SkeletonBox width="70px" height="20px" />
    </FlexBox>
  </div>
);

export default NavigationSkeleton;
