import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';

export const SPACING_TYPE = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

const ActivityContent = ({children, spacing, pullToCorner, className}) => {
  const isUpsell = useMatch(CHECKOUT_PATH);
  const activityClass = isUpsell ? 'dl-activity-upsell' : 'dl-activity';

  return (

    <div
      className={classNames(
        className,
        `${activityClass}__content`,
        {
          [`${activityClass}__content--spaced-${spacing}`]: spacing,
          [`${activityClass}__content--pull-to-corner`]: pullToCorner
        }
      )}
    >
      {children}
    </div>
  );
};

ActivityContent.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf(Object.values(SPACING_TYPE)),
  pullToCorner: PropTypes.bool,
  className: PropTypes.string
};

export default ActivityContent;
