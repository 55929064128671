import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivitiesList from '@Components/activities/ActivitiesList';
import {getActivitiesByPriority} from '@Components/activities/activitiesListUtils';
import {getUpsellActivities} from '@Features/upsell/upsellSelectors';
import locale from '@Utils/locale';
import {useSelector} from 'react-redux';
import './upsell.scss';
import pubsub from '@Utils/pubsub';
import {EVENT_BUY_UPSELL_COMPLETED, EVENT_VIEW_UPSELL_OFFER} from '@Utils/events';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {parseLanguage} from '@Utils/eventsUtils';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EFlexDirection} from '@Components/common/flexBox/types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {getBasketItems, getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {ActiveView} from '@Components/checkout/types';
import {useNextStepValidationScroll} from '@Utils/hooks/useNextStepValidationScroll';

type Props = {
  onChangeView(number: number): void;
  activeView: ActiveView,
  requestedCheckoutPageId: number | null;
};

const Upsell = ({onChangeView, activeView, requestedCheckoutPageId}: Props) => {
  const upsellActivities = useSelector(getUpsellActivities);
  const activitiesByPriority = getActivitiesByPriority(upsellActivities);
  const {facilityId, affiliationHash, onlineGroupId} = useSelector(getConfiguration);
  const facility = useSelector(getFacility)!;
  const basketItems = useSelector(getBasketItems);
  const {name: facilityName, company} = facility;
  const {id} = activeView;
  const {industry} = company;
  const isNextStepBlocked = useSelector(getIsNextStepBlocked);
  const {triggerScrollToError} = useNextStepValidationScroll();

  function handleNextClick() {
    if (isNextStepBlocked) {
      return triggerScrollToError();
    }

    pubsub.trigger(EVENT_BUY_UPSELL_COMPLETED, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      numberOfTickets: basketItems.length,
      language: parseLanguage(locale.locale),
      onlineGroupId
    });
    return onChangeView(id + 1);
  }

  React.useEffect(() => {
    if (requestedCheckoutPageId) {
      onChangeView(requestedCheckoutPageId);
    }
  }, [requestedCheckoutPageId]);

  React.useEffect(() => {
    if (upsellActivities?.length) {
      pubsub.trigger(EVENT_VIEW_UPSELL_OFFER, {
        facilityId,
        facilityName,
        numberOfActivities: upsellActivities.length,
        language: parseLanguage(locale.locale),
        numberOfTickets: basketItems.length,
        industry,
        affiliationHash,
        onlineGroupId
      });
    }
  }, []);

  if (!upsellActivities?.length) {
    return null;
  }

  return (
    <FlexBox direction={EFlexDirection.COLUMN} className="dl-dotpay dl-dotpay__checkout-page">
      <Typography variant="h5" sx={{fontWeight: 500, mb: 2}}>{
        locale.translate('thisMayInterestYou')}
      </Typography>
      <ActivitiesList
        indicatedActivityListIndex={0}
        activities={activitiesByPriority}
        numberOfActivities={upsellActivities?.length ?? 0}
      />
      <Button
        onClick={handleNextClick}
        variant="contained"
        size="large"
        color="success"
        fullWidth
        sx={{mt: 2}}
        endIcon={<ChevronRightIcon />}
      >
        {locale.translate('next')}
      </Button>
    </FlexBox>

  );
};

export default Upsell;

