import dayjs from 'dayjs';
import {getStartOfYesterday, addToDate, isSameDate, isAfter} from './dates/timezoneDates';
import {IAdmissionPolicyProcessed} from '@Consts/globals';

export const getNarrowedDateRangeBasedOnAdmission = (
  {endInDays, startDatetime, endDatetime}: IAdmissionPolicyProcessed
) => {
  const startOfYesterday = getStartOfYesterday();

  if (endInDays) {
    return {minDate: startOfYesterday, maxDate: addToDate(startOfYesterday, endInDays, 'day')};
  }

  if (endDatetime) {
    const maxDate = endDatetime;

    if (startDatetime) {
      if (
        isSameDate(startDatetime, startOfYesterday) ||
        isAfter(startDatetime, startOfYesterday)
      ) {
        return {minDate: startDatetime, maxDate};
      }

      return {minDate: startOfYesterday, maxDate};
    }

    return {minDate: startOfYesterday, maxDate};
  }

  return {minDate: startOfYesterday, maxDate: addToDate(startOfYesterday, 1, 'month')};
};

export const isNarrowedDateRangeValid = (validSince: Date, validUntil: Date) =>
  dayjs(validUntil).isAfter(dayjs(validSince), 'day');
