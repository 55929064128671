import {EWidgetType} from '@Consts/globals';

export const isWidgetOnlineClient = (widgetType: EWidgetType) => widgetType === EWidgetType.ONLINE_CLIENT;

export const isWidgetOnlineClientPreview = (widgetType: EWidgetType) =>
  widgetType === EWidgetType.ONLINE_CLIENT_PREVIEW;

export const isWidgetBusiness = (widgetType: EWidgetType) => widgetType === EWidgetType.BUSINESS_CLIENT;

export const isWidgetBusinessPreview = (widgetType: EWidgetType) => widgetType === EWidgetType.BUSINESS_CLIENT_PREVIEW;

export const isWidgetInternal = (widgetType: EWidgetType) => widgetType === EWidgetType.INTERNAL_CLIENT;

export const isWidgetInternalPreview = (widgetType: EWidgetType) => widgetType === EWidgetType.INTERNAL_CLIENT_PREVIEW;

export const isWidgetPreview = (widgetType: EWidgetType) =>
  isWidgetOnlineClientPreview(widgetType) ||
  isWidgetBusinessPreview(widgetType) ||
  isWidgetInternalPreview(widgetType);

export const isWidgetClientUser = (widgetType: EWidgetType) =>
  widgetType === EWidgetType.ONLINE_CLIENT_PREVIEW ||
  widgetType === EWidgetType.BUSINESS_CLIENT ||
  widgetType === EWidgetType.BUSINESS_CLIENT_PREVIEW ||
  widgetType === EWidgetType.INTERNAL_CLIENT ||
  widgetType === EWidgetType.INTERNAL_CLIENT_PREVIEW;
