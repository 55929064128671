import {IBasketItem} from '@Consts/types';
import {DayOffersProcessed, IAdmissionDateVariant} from '@Features/activityAdmissionDates/types';
import {DOT_DAY_FORMAT_REVERSED} from '@Utils/dayjs/const';
import locale from '@Utils/locale';
import {isVariantGroup} from '@Utils/variantType';
import {Dayjs} from 'dayjs';
import {IDatesGroupedByDay} from './types';

interface ITakenResource {
  amount: number,
  isExceeded: boolean
}

interface ITakenResources {
  [key: string]: ITakenResource
}

const getTakenResources = (dateVariants: IAdmissionDateVariant[], basketItems: IBasketItem[]) =>
  basketItems.reduce((takenResources: ITakenResources, basketItem) => {
    const dateVariant = dateVariants.find(dateVariant => dateVariant.variantId === basketItem.variantId);
    const dateResourceId = dateVariant?.resource?.resourceId;

    if (!dateVariant || !dateResourceId) {
      return takenResources;
    }

    const currentlyTakenResource = takenResources[dateResourceId as keyof ITakenResources]?.amount ?? 0;
    const isGroupType = isVariantGroup(dateVariant.variantType);
    const selectedNumberOfSeats = basketItem.selections.numberOfSeats ?? 1;
    const amountToTake = isGroupType ? selectedNumberOfSeats : 1;
    const takenAmount = currentlyTakenResource + amountToTake;
    const limit = dateVariant.resource?.amount ?? 0;

    return {
      ...takenResources,
      [dateResourceId]: {
        amount: takenAmount,
        isExceeded: takenAmount > limit
      }
    };
  }, {});

const areTakenResorcesExceeded = (takenResources: ITakenResources) => {
  const resourcesKeys = Object.keys(takenResources);

  if (!resourcesKeys.length) {
    return false;
  }

  return resourcesKeys.some(resourceKey => takenResources[resourceKey].isExceeded);
};

export const isDateOutOfLimit = (
  dateVariants: IAdmissionDateVariant[],
  takenSeats: number,
  numberOfSeats: number,
  basketItems: IBasketItem[]
) => {
  const numberOfSeatsExceeded = takenSeats > numberOfSeats;
  const takenResources = getTakenResources(dateVariants, basketItems);
  const takenResorcesExceeded = areTakenResorcesExceeded(takenResources);

  return numberOfSeatsExceeded || takenResorcesExceeded;
};

export const dateVariantsHaveResources = (dateVariants: IAdmissionDateVariant[]) =>
  dateVariants.some(dateVariant => !!dateVariant.resource);

export const validateDayOfferSelection = (
  selectedDate: Dayjs,
  isTestB: boolean,
  selectedDayOffer?: DayOffersProcessed
) => {
  const date = selectedDate.format(DOT_DAY_FORMAT_REVERSED);
  const notAvaliableError = isTestB ?
    locale.translate('selectedDateIsNotAvailable', {date}) : locale.translate('selectedDayIsNotAvailable', {date});

  if (!selectedDayOffer) {
    return {validationError: notAvaliableError};
  }

  if (!selectedDayOffer.isAvailable) {
    return {validationError: notAvaliableError};
  }

  return {
    validationError: null
  };
};

export const getNumberOfAvailableDates = (
  admissionDates: IDatesGroupedByDay[] | null,
  dayOffers: DayOffersProcessed[] | null
) => {
  const numberOfAvailableDays = admissionDates ? admissionDates.filter(item => {
    const {metaData} = item;
    const {isOutOfLimit = true, variantsCostsAreValid} = metaData;
    const isDisabled = isOutOfLimit || !variantsCostsAreValid;

    return !isDisabled;
  }).length : 0;

  const numberOfAvailableDayOffers = dayOffers ? dayOffers.filter(item => item.isAvailable).length : 0;

  return numberOfAvailableDays || numberOfAvailableDayOffers;
};

export const getOriginalDateMonthDate = (originalDate: string) =>
  parseInt(originalDate.split('-')[2]);
