import {TLatestOrderStorageData} from '@Consts/globals';
import {EStorageKeys} from '@Consts/storageConsts';
import {updateLatestOrdersStorageData} from '@Features/order/orderUtils';
import {setStorage, setStorageItemToState} from '@Features/storage/storageActions';
import {Action} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {IStore} from 'src/js/store/types';

export const onLatestOrderComplete = (
  latestOrderStorageData: TLatestOrderStorageData,
  latestOrders: TLatestOrderStorageData[] | null
) => async (dispatch: ThunkDispatch<IStore, void, Action>) => {
  const {
    onlineGroupId,
    widgetType,
    isEventsSent
  } = latestOrderStorageData;

  if (isEventsSent) {
    return null;
  }

  const updatedOrders = updateLatestOrdersStorageData(
    latestOrders,
    onlineGroupId,
    widgetType,
    {...latestOrderStorageData, isEventsSent: true},
    Boolean(latestOrderStorageData)
  );

  dispatch(setStorage(EStorageKeys.LATEST_ORDERS, updatedOrders, false));

  dispatch(setStorageItemToState(EStorageKeys.LATEST_ORDERS, updatedOrders));
};
