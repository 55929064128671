import {
  areActivityDatesInvalid,
  isActivityLimitReached
} from '@Utils/activity/activity';
import {getVariantById} from '@Utils/activity/variant';
import {getActivityBasketItems} from '@Utils/basket';
import locale from '@Utils/locale';
import {IBasketItem} from '@Consts/types';
import {IActivityProcessed, IActivityVariantProcessed} from '@Consts/globals';

export type PickerDisableReasonProps = {
  activity: IActivityProcessed;
  basketItems: IBasketItem[];
  maximumTicketsInOrder: number;
  variantId: number;
};

export const getPickerDisableReason = ({
  activity,
  basketItems,
  maximumTicketsInOrder,
  variantId
}: PickerDisableReasonProps) => {
  const datesAreInvalid = areActivityDatesInvalid(activity);
  const {id: activityId, variants} = activity;
  const variant = getVariantById(variants, variantId);
  const activityBasketItems = getActivityBasketItems(basketItems, activityId);
  const numberOfBasketItems = basketItems.length;

  if (datesAreInvalid) {
    return locale.translate('noValidDatesRange');
  }

  if (numberOfBasketItems >= maximumTicketsInOrder) {
    return locale.translate('chooseLimitReached');
  }

  if (variant) {
    const selectedActivityBasketItemAmount = activityBasketItems
      .reduce(amount => amount + 1, 0);

    if (isActivityLimitReached(activity, selectedActivityBasketItemAmount)) {
      return locale.translate('canNotBuyMoreTickets');
    }
  }

  return null;
};

export type VariantGroupMinLimit = {
  variant: IActivityVariantProcessed;
  activity: IActivityProcessed;
};

export const getVariantGroupLimit = ({
  variant,
  activity
}: VariantGroupMinLimit) => {
  if (!variant) {
    return 0;
  }

  const maxNumberOfSeats = variant.maxNumberOfSeats;
  const maxNumberOfParticipants = activity.admissionPolicy.maxNumberOfParticipants;

  return maxNumberOfSeats || maxNumberOfParticipants || 0;
};

export type VariantMinLimitProps = {
  variant: IActivityVariantProcessed;
  activity: IActivityProcessed;
  maximumTicketsInOrder: number;
  numberOfBasketItems: number;
  numberOfVariantItems: number;
};

export const getVariantMinLimit = ({
  variant,
  activity,
  maximumTicketsInOrder,
  numberOfBasketItems,
  numberOfVariantItems
}: VariantMinLimitProps) => {
  if (!variant) {
    return 0;
  }

  const {limit, usesCount} = activity?.salePolicy;
  const orderLimit = maximumTicketsInOrder - numberOfBasketItems + numberOfVariantItems;
  const usesCountLimit = limit ? limit - usesCount : null;
  const existsLimits = [
    orderLimit, usesCountLimit
  ].filter(v => Number.isInteger(v)) as number[];

  return existsLimits.length ? Math.min(...existsLimits) : 0;
};

export const getCounterMaxValue = ({
  variant,
  activity,
  maximumTicketsInOrder,
  numberOfBasketItems,
  numberOfVariantItems,
  isGroupVariantType
}: VariantMinLimitProps & {isGroupVariantType: boolean}) => {
  if (isGroupVariantType) {
    return getVariantGroupLimit({
      variant,
      activity
    });
  }

  return getVariantMinLimit({
    variant,
    activity,
    maximumTicketsInOrder,
    numberOfBasketItems,
    numberOfVariantItems
  });

};
