import React, {useRef} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TimePickerSelectItem from './TimePickerSelectItem';
import MenuItem from '@mui/material/MenuItem';
import locale from '@Utils/locale';
import {Option} from './types';
import {getTimePickerItemDetails} from './utils';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {FormHelperText} from '@mui/material';

type Props = {
    options: Option[];
    value: Option;
    onChange: (arg0?: Option) => void;
    canShowItemsLeft: boolean;
    showPrices: boolean;
    isWithAllAdditionalInformation: boolean;
    isSelectErrorComponent: boolean;
    handleRemoveSelectError: () => void
}

const TimePickerSelect = ({
  options,
  value,
  onChange,
  canShowItemsLeft,
  showPrices,
  isWithAllAdditionalInformation,
  isSelectErrorComponent,
  handleRemoveSelectError
}: Props) => {
  const timezone = useSelector(getFacilityTimezone)!;
  const menuRef = useRef<HTMLDivElement | null>(null);
  const onFocus = () => {
    const focusElement = menuRef.current?.children[2]?.children[0]?.firstChild as HTMLElement;

    focusElement?.focus();
  };

  return (
    <FormControl
      sx={{
        width: 1,
        maxWidth: {xs: 'none', md: isWithAllAdditionalInformation ? 302 : 227}
      }}
      error={isSelectErrorComponent}
    >
      <InputLabel id="time-picker-select-label">{locale.translate('hourFull')}</InputLabel>
      <Select
        labelId="time-picker-select-label"
        id="time-picker-select"
        value={value ? value.id : ''}
        label={locale.translate('hourFull')}
        onChange={e => {
          handleRemoveSelectError();
          onChange(options.find(option => option.id === e.target.value));
        }}
        inputProps={{sx: {height: 57}}}
        MenuProps={{
          MenuListProps: {
            sx: {p: 0, maxHeight: 270},
            autoFocusItem: false
          },
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          marginThreshold: 0,
          autoFocus: false,
          disableAutoFocus: true,
          TransitionProps: {
            onEntered: onFocus
          },
          ref: menuRef
        }}
      >
        {options.map(option => {
          const timePickerItemConfig = getTimePickerItemDetails(option, canShowItemsLeft, showPrices, timezone);
          const {isDisabled} = timePickerItemConfig;

          return (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{
                py: 1.5,
                px: 0,
                pl: 2,
                cursor: isDisabled ? 'default' : 'pointer',
                '&:hover': {
                  backgroundColor: isDisabled ? 'transparent' : 'rgba(0, 0, 0, 0.04)'
                }
              }}
              divider
              disableRipple={isDisabled}
              disableTouchRipple={isDisabled}
            >
              <TimePickerSelectItem
                key={option.id}
                timePickerItemConfig={timePickerItemConfig}
              />
            </MenuItem>
          );
        })}
      </Select>
      {isSelectErrorComponent && (
        <FormHelperText>{locale.translate('pleaseSelectTime')}</FormHelperText>
      )}
    </FormControl>
  );
};

export default TimePickerSelect;
