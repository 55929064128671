import React from 'react';
import locale from '@Utils/locale';
import {SxProps, Theme} from '@mui/material';
import FormInput from '@Components/formComponents/FormInput';
import {string} from 'yup';
import {MAX_LENGTH, MIN_LENGTH} from '@Utils/checkout';

export const FIELD_NAME = 'invoiceNote';

export const schema = () => string()
  .matches(/^.{3,}$/, {message: locale.translate('minLength', {length: MIN_LENGTH}), excludeEmptyString: true})
  .max(255, locale.translate('maxLength', {length: MAX_LENGTH}));

interface Props {
  name?: string,
  label?: string,
  fieldStyles?: SxProps<Theme>,
  saveInStorage: boolean
}

const InvoiceNoteField = ({
  name = FIELD_NAME,
  label = locale.translate('invoiceOptionalNote'),
  fieldStyles,
  saveInStorage
}: Props) => (
  <FormInput
    name={name}
    componentProps={{label}}
    fieldStyles={fieldStyles}
    saveInStorage={saveInStorage}
  />
);

export default InvoiceNoteField;
