import {useDispatch, useSelector} from 'react-redux';
import {getIsStorageInitializated, getStorageLanguagePicked} from '@Features/storage/storageSelectors';
import React, {
  createContext,
  useEffect,
  useState
} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import {ELocale} from '@Consts/globals';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getParam, getQueryStringParameters} from './query';
import {getMatchedBrowserLanguage, validLanguageWithFallback} from './LanguageProviderUtils';
import {getScriptInitialized} from '@Features/configuration/configurationSelectors';
import pubsub from './pubsub';
import {EVENT_SELECTS_LANGUAGE} from './events';
import {parseLanguage} from './eventsUtils';
import {setStorage} from '@Features/storage/storageActions';
import {refetchQuestions} from '@Features/activityQuestions/questionsActions';

export const LanguageContext = createContext();

const LanguageProvider = ({children}) => {
  const dispatch = useDispatch();
  const facility = useSelector(getFacility);
  const {id: facilityId, name: facilityName, company} = {...facility};
  const languageFromCookie = useSelector(getStorageLanguagePicked);
  const [language, setLanguage] = useState(null);
  const [isFallbackLanguage, setIsFallbackLanguage] = useState(false);
  const parameters = getQueryStringParameters();
  const defaultLanguage = getParam('defaultLanguage', parameters);
  const isScriptInitialized = useSelector(getScriptInitialized);
  const isStorageInitializated = useSelector(getIsStorageInitializated);

  const handleFallbackStatus = isFallback => {
    setIsFallbackLanguage(isFallback);
  };

  const handleLanguageChange = (languagePicked, isCheckout) => {
    if (languagePicked !== language) {
      dispatch(setStorage('languagePicked', languagePicked, true));
      setLanguage(languagePicked);

      if (isCheckout) {
        dispatch(refetchQuestions(languagePicked));
      }

      pubsub.trigger(EVENT_SELECTS_LANGUAGE, {
        facilityId,
        facilityName,
        originalLanguage: parseLanguage(locale.locale),
        language: parseLanguage(languagePicked)
      });

      locale.reinitialize(languagePicked);
    }
  };

  const handleInit = initLanguage => {
    setLanguage(initLanguage);
    locale.reinitialize(initLanguage);
  };

  const validateLanguage = facility => {
    const validatedLanguage = validLanguageWithFallback(language, facility);

    if (validatedLanguage !== language) {
      handleFallbackStatus(true);
      handleInit(validatedLanguage);
    }
  };

  useEffect(() => {
    const browserLanguageMatched = getMatchedBrowserLanguage();

    if (!isScriptInitialized || !isStorageInitializated) {
      return;
    }

    if (languageFromCookie) {
      return handleInit(languageFromCookie);
    }

    if (defaultLanguage) {
      return handleInit(defaultLanguage);
    }

    if (browserLanguageMatched) {
      return handleInit(browserLanguageMatched);
    }

    if (company?.locale) {
      return handleInit(company.locale);
    }

    return handleInit(ELocale.EN);
  }, [company, isScriptInitialized, isStorageInitializated]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        isFallbackLanguage,
        handleFallbackStatus,
        setLanguage: handleLanguageChange,
        validateLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
