import {ICrossSellOffer} from '@Consts/apiGlobals';
import {IBasketItem} from '@Consts/types';
import {apiGet} from '@Utils/api/api';
import {getWidgetConfiguration} from '@Utils/configurationUtils';
import {getCrossSellUrl} from '@Utils/crossSellOffer';
import {useQuery} from '@tanstack/react-query';
import {useSelector} from 'react-redux';

type IuseFetchCrossSellOffer = {
  isWigetOnlineType: boolean,
  isPreviewMode: boolean,
  code?: string,
  basket?: IBasketItem[]
}

export const useFetchCrossSellOffer = ({
  isWigetOnlineType,
  isPreviewMode,
  code,
  basket
}: IuseFetchCrossSellOffer) => {
  const widgetConfiguration = useSelector(getWidgetConfiguration);
  const canFetchCrossSell = (isPreviewMode ? !!basket : !!code) && isWigetOnlineType;
  const url = canFetchCrossSell ? getCrossSellUrl(widgetConfiguration, code, basket) : null;

  const {data, isLoading} = useQuery<ICrossSellOffer>({
    queryKey: [url],
    queryFn: () => apiGet(url, widgetConfiguration),
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: canFetchCrossSell
  });

  return {
    crossSellOffer: data,
    isLoading: canFetchCrossSell ? isLoading : false
  };
};
