import {SxProps, Theme} from '@mui/material/styles';

export type InvoiceTogglerProps = {
  sendInvoice: boolean,
  saveInStorage?: boolean,
  styles?: SxProps<Theme>
}

export enum EInvoiceFormType {
  COMPANY = 'company',
  COMPANY_SIMPLIFIED = 'company_simplified',
  PRIVATE_PERSON = 'privatePerson',
}

export interface IAgreementsInvoiceSection {
  isUsersInvoiceNoteEnabled: boolean,
  isOnlinePaymentReceiptEnabled: boolean
}
