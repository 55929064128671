import {IBasketItem} from '@Consts/types';
import {TPreviewTicket} from './types';
import {ITicketOnline} from '@Consts/apiGlobals';

export const getPreviewTickets = (basket: IBasketItem[]): TPreviewTicket[] =>
  basket.map((basket, index) => {
    const activityName = basket.activity.name;
    const variantName = basket.variant.name;

    const formattedName = variantName ? `activityName, ${variantName}` : activityName;

    return {
      ticketId: index,
      ticketFormattedName: formattedName,
      ticketFormattedId: 'DROP/TID/0000000000'
    };
  });

export const getValidWalletPassUrls = (
  onlineOrderTickets: ITicketOnline[] | undefined | null,
  isPreviewMode: boolean
) => {
  if (isPreviewMode) {
    return [];
  }

  const walletPassUrls = onlineOrderTickets?.map(onlineOrderTicket => onlineOrderTicket.walletPassUrl);

  return walletPassUrls?.filter(url => !!url) ?? [];
};
