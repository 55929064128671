import React from 'react';
import locale from '@Utils/locale';
import FormInput from '@Components/formComponents/FormInput';
import {string} from 'yup';
import {MAX_LENGTH, MIN_LENGTH} from '@Utils/checkout';

export const FIELD_NAME = 'invoiceAddressCity';

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .min(3, locale.translate('minLength', {length: MIN_LENGTH}))
  .max(255, locale.translate('maxLength', {length: MAX_LENGTH}));

interface Props {
  name?: string,
  label?: string,
  saveInStorage?: boolean
}

const InvoiceCityField = ({
  name = FIELD_NAME,
  label = locale.translate('city'),
  saveInStorage
}: Props) => (
  <FormInput
    name={name}
    componentProps={{label, sx: {width: 1}}}
    saveInStorage={saveInStorage}
  />
);

export default InvoiceCityField;
