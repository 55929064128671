import React from 'react';
import MaskedInput from 'react-text-mask';

export type Mask = string | RegExp;

type MaskedInputCustomProps = {
  mask: Mask[],
  inputRef: (ref: HTMLElement | null) => void;
}

const TextMaskInput = React.forwardRef<HTMLInputElement, MaskedInputCustomProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function TextMaskCustom(props, _ref) {
    const {mask, inputRef, ...other} = props;

    return (
      <MaskedInput
        {...other}
        ref={input => {
          inputRef(input ? input.inputElement : null);
        }}
        mask={mask}
        placeholderChar={'\u2000'}
        showMask={false}
        guide={false}
      />
    );
  }
);

export default TextMaskInput;
