import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Price, {PRICE_POSITION} from '../price/Price';
import {getFacility} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const ActivityPreviewBottom = ({activityName, children}) => {
  const {logotypeAddress} = useSelector(getFacility);

  return (
    <>
      <FlexBox
        direction={EFlexDirection.COLUMN}
        justify={EJustifyContent.CENTER}
        className="c-activity-preview__logo-box"
      >
        {
          logotypeAddress &&
          <img className="c-activity-preview__logo" src={logotypeAddress} alt={activityName} />
        }
      </FlexBox>

      <FlexBox justify={EJustifyContent.FLEX_START}>
        <Price position={PRICE_POSITION.BOTTOM}>
          {children}
        </Price>
      </FlexBox>
    </>
  );
};

export default ActivityPreviewBottom;
