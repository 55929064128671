import {TInternalCheckoutFormValues} from '@Components/checkout/types';
import {TAgreementsFormValues} from '@Components/checkoutPages/agreements/types';
import {CompanyInvoiceData} from '@Consts/apiGlobals';
import {FIELD_NAME as INVOICE_COMPANY_NAME_FIELD_NAME} from '@Components/invoice/formFields/CompanyNameField';
import {FIELD_NAME as INVOICE_CITY_FIELD_NAME} from '@Components/invoice/formFields/InvoiceCityField';
import {FIELD_NAME as INVOICE_STREET_FIELD_NAME} from '@Components/invoice/formFields/InvoiceStreetField';
import {FIELD_NAME as INVOICE_POSTAL_CODE_FIELD_NAME} from '@Components/invoice/formFields/InvoicePostalCodeField';

export const isInvoiceDataComplete = (formValues: TAgreementsFormValues | TInternalCheckoutFormValues) => {
  const {
    invoiceAddressCity,
    invoiceAddressPostalCode,
    invoiceCompanyName,
    invoiceAddressStreet
  } = formValues;

  return !!invoiceCompanyName && !!invoiceAddressStreet && !!invoiceAddressPostalCode && !!invoiceAddressCity;
};

export const fillMissedData = (
  formValues: TAgreementsFormValues | TInternalCheckoutFormValues,
  invoiceData: CompanyInvoiceData,
  handleSetValue: (key: string, value: string) => void
) => {
  const {address, companyName, companyNip} = invoiceData;
  const {city, postalCode, street} = address;
  const {invoiceCompanyNip} = formValues;
  const isFetchedNipMatchesCurrentNip = companyNip === invoiceCompanyNip;

  if (isFetchedNipMatchesCurrentNip) {
    const {
      invoiceCompanyName,
      invoiceAddressPostalCode,
      invoiceAddressStreet,
      invoiceAddressCity
    } = formValues;

    if (!invoiceCompanyName) {
      handleSetValue(INVOICE_COMPANY_NAME_FIELD_NAME, companyName);
    }
    if (!invoiceAddressPostalCode) {
      handleSetValue(INVOICE_POSTAL_CODE_FIELD_NAME, postalCode);
    }
    if (!invoiceAddressStreet) {
      handleSetValue(INVOICE_STREET_FIELD_NAME, street);
    }
    if (!invoiceAddressCity) {
      handleSetValue(INVOICE_CITY_FIELD_NAME, city);
    }
  }
};
