import {IAdmissionPolicyProcessed} from '@Consts/globals';
import {getFormattedDate, getHours, getMinutes} from '@Utils/dates/timezoneDates';
import {getNumberOfDaysFormatted} from '@Utils/format';
import locale from '@Utils/locale';

export const getAdmissionDatesDescription = (
  {startDatetime, endDatetime, endInDays}: IAdmissionPolicyProcessed
) => {
  if (endInDays) {
    return locale.translate('validInDays', {number: getNumberOfDaysFormatted(endInDays)});
  }

  if (endInDays === 0) {
    return locale.translate('today');
  }

  if (endDatetime) {
    const endHours = getHours(endDatetime);
    const endMinutes = getMinutes(endDatetime);

    const showEndTime = endHours + endMinutes && endHours + endMinutes < 23 + 59;
    const formattedEndDatetime = getFormattedDate(
      endDatetime,
      showEndTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
    );

    if (startDatetime) {
      const startHours = getHours(startDatetime);
      const startMinutes = getMinutes(startDatetime);
      const showStartTime = startHours + startMinutes > 0;
      const formattedStartDatetime = getFormattedDate(
        startDatetime,
        showStartTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
      );

      if (formattedStartDatetime === formattedEndDatetime) {
        return locale.translate('onDate', {date: formattedEndDatetime});
      }

      return locale.translate('betweenDates', {since: formattedStartDatetime, until: formattedEndDatetime});
    }

    return locale.translate('validBeforeDate', {date: formattedEndDatetime});
  }

  return null;
};
