import React, {useEffect, useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import './basket.scss';
import {getBasketItems, getIsNextStepBlocked} from '@Features/basket/basketSelectors';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import {useSelector} from 'react-redux';
import {getLockBasket} from '@Features/configuration/configurationSelectors';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import {useNavigate} from 'react-router-dom';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import useFrameMessage from '@Hooks/useFrameMessage';
import {useNextStepValidationScroll} from '@Utils/hooks/useNextStepValidationScroll';

const HEIGHT = 72;
const HALF_HEIGHT = HEIGHT / 2;
const FOOTER_HEIGHT = 80;

const Basket = ({isModal}) => {
  const navigate = useNavigate();
  const frameMessage = useFrameMessage();
  const basketRef = useRef(null);
  const basketItems = useSelector(getBasketItems);
  const lockBasket = useSelector(getLockBasket);
  const numberOfItems = basketItems.length;
  const isNextStepBlocked = useSelector(getIsNextStepBlocked);
  const {triggerScrollToError} = useNextStepValidationScroll();

  function setAbsolutePosition() {
    const activitiesElement = document.querySelector('#dl-activities-list');

    if (activitiesElement) {
      const containerBottom = activitiesElement.getBoundingClientRect().bottom;
      const top = containerBottom;

      basketRef.current.style.top = `${top}px`;
    }
  }

  function handleBasketScroll({iframeTop, scrollTop, viewportHeight}) {
    const baseTop = viewportHeight - iframeTop - HALF_HEIGHT + scrollTop;
    const top = baseTop - FOOTER_HEIGHT;
    const topToBottom = scrollTop + viewportHeight + HEIGHT;

    if (lockBasket) {
      return setAbsolutePosition();
    }

    const relativeTop = document.body.offsetHeight + iframeTop;

    if (relativeTop > topToBottom) {
      basketRef.current.style.top = `${top}px`;
    } else {
      setAbsolutePosition();
    }
  }

  function handleMessage(event) {
    if (event.data.name === 'scroll') {
      const {iframeTop, scrollTop, viewportHeight} = event.data;

      return handleBasketScroll({iframeTop, scrollTop, viewportHeight});
    }
  }

  function basketInitializeEffect() {
    if (!isModal) {
      window.addEventListener('message', handleMessage);
      frameMessage({name: 'triggerScroll'});
    }

    return () => {
      if (isModal) {
        return;
      }

      window.removeEventListener('message', handleMessage);
    };
  }

  useEffect(basketInitializeEffect, []);

  function handleClick() {
    if (isNextStepBlocked) {
      return triggerScrollToError();
    }

    navigate(CHECKOUT_PATH);
  }

  return (
    <Box
      sx={{
        height: 56,
        position: isModal ? 'sticky' : 'static',
        bottom: '24px',
        zIndex: 2,
        pointerEvents: 'none'
      }}
    >
      <Fab
        variant="extended"
        color="success"
        ref={basketRef}
        sx={{
          position: isModal ? 'relative' : 'fixed',
          transition: '0.6s',
          px: 3.5,
          height: 56,
          left: '50%',
          transform: 'translate(-50%)',
          borderRadius: '64px',
          minWidth: 240,
          animation: 'slide-bottom 0.4s forwards',
          pointerEvents: 'all'
        }}
        onClick={handleClick}
      >
        <Box component="span" data-testid="basket">
          {locale.translate('goToTheNextStep')}
        </Box>
        <Box sx={{pl: 1.5}}>
          <Badge badgeContent={numberOfItems} color="error">
            <ConfirmationNumberOutlinedIcon color="inherit" />
          </Badge>
        </Box>
      </Fab>
    </Box>
  );
};

export default Basket;
