import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import PropTypes from 'prop-types';
import './error.scss';
import Text from '@Components/common/text/Text';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {ETextAlign, ETextColor, ETextTransform, ETextWeight} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const PageError = ({message, clearError, clearable}) => {
  let copy = locale.translate('close');

  if (clearable) {
    copy = locale.translate('tryAgain');
  }

  return (
    <FlexBox
      direction={EFlexDirection.COLUMN}
      justify={EJustifyContent.CENTER}
      align={EAlignItems.CENTER}
      className="dl-error dl-p-8"
    >
      <Text
        color={ETextColor.WHITE}
        weight={ETextWeight.BOLD}
        align={ETextAlign.CENTER}
      >
        {message}
      </Text>

      {
        clearError && (
          <Text
            color={ETextColor.WHITE}
            onClick={clearError}
            transform={ETextTransform.UNDERLINE}
            className="dl-cursor-pointer"
          >
            {copy}
          </Text>
        )
      }
    </FlexBox>
  );
};

PageError.propTypes = {
  message: PropTypes.string,
  clearError: PropTypes.func,
  clearable: PropTypes.bool
};

export default PageError;
