
import {apiGet} from '@Utils/api/api';
import {FAILURE, IN_PROGRESS, SUCCESS} from '@Consts/status';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {getAdditionsEndpoint} from './additionsUtils';
import {setActivityAdditions} from '@Features/activities/activitiesActions';
import pubsub from '@Utils/pubsub';
import {EVENT_ADDITIONS_ERROR} from '@Utils/events';
import {setPickedUpsellActivityAdditions, setUpsellActivityAdditions} from '@Features/upsell/upsellActions';

export const SET_ADDITIONS_STATUS = 'SET_ADDITIONS_STATUS';
export const CLEAR_ADDITIONS_STATUS = 'CLEAR_ADDITIONS_STATUS';

export const setAdditionsStatus = (status, id) => ({
  type: SET_ADDITIONS_STATUS,
  payload: {status, id}
});

export const clearAdditionsStatus = () => ({
  type: CLEAR_ADDITIONS_STATUS
});

export const fetchAdditions = (activityId, facility) => async (dispatch, getState) => {
  const {id, name} = facility;

  const configuration = getConfiguration(getState());
  const fetchingStart = new Date();

  dispatch(setAdditionsStatus(IN_PROGRESS, activityId));

  try {
    let additions = await apiGet(getAdditionsEndpoint(configuration, activityId), configuration);

    additions = additions.sort((firstAddition, secondAddition) => firstAddition.id - secondAddition.id);
    dispatch(setActivityAdditions(activityId, additions));
    dispatch(setUpsellActivityAdditions(activityId, additions));
    dispatch(setPickedUpsellActivityAdditions(activityId, additions));
    dispatch(setAdditionsStatus(SUCCESS, activityId));

    return additions;
  } catch (error) {
    dispatch(setAdditionsStatus(FAILURE, activityId));

    const fetchingEnd = new Date();
    const fetchingTime = fetchingEnd - fetchingStart;

    pubsub.trigger(EVENT_ADDITIONS_ERROR, {
      id,
      name,
      activityId,
      error,
      fetchingTime
    });

    throw error;
  }
};
