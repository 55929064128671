export const ALPHANUMERIC_POSTAL_CODE_MASK = [
  /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/,
  /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/, /[a-zA-Z0-9- ]/
];

export const NUMERIC_NIP_MASK = [
  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/,
  /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
];

export const POSTAL_CODE_MASK = [
  /\d/, /\d/, '-', /\d/, /\d/, /\d/
];

export const BLIK_CODE_MASK = [
  /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/
];

export const NIP_MASK = [
  /\d/, /\d/, /\d/, /\d/, /\d/,
  /\d/, /\d/, /\d/, /\d/, /\d/
];

export const NUMERIC_PATTERN = /^[0-9]*$/;

export const ALPHANUMERIC_PATTERN_WITH_DASH_SPACE = /^[a-zA-Z0-9- ]*$/;

export const POSTAL_CODE_PATTERN = /\d{2}-\d{3}/;

export const NIP_PATTERN = /^([1-9](([0-9][1-9])|([1-9][0-9]))[0-9][0-9][0-9][0-9][0-9][0-9][0-9])$/;

export const BLIK_CODE_PATTERN = /\d{3} \d{3}/;
