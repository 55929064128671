import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import NavigationSkeleton from '@Components/navigation/navigationSkeleton/NavigationSkeleton';
import ActivitiesListingSkeleton from '@Components/activities/activitiesListSkeleton/ActivitiesListSkeleton';

const EntrySkeleton = () => (
  <>
    <NavigationSkeleton />
    <ActivitiesListingSkeleton />
  </>
);

export default EntrySkeleton;
