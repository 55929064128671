import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars

import classNames from 'classnames';

export const PRICE_POSITION = {
  BOTTOM: 'bottom'
};

const Price = ({children, position}) => (
  <div
    className={classNames(
      'dl-price',
      {
        [`dl-price--${position}`]: position
      }
    )}
  >
    {children}
  </div>
);

export default Price;
