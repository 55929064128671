/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Sale disabled!',
  'buyOnline': 'Buy online!',
  'buyVoucher': 'Buy voucher',
  'buyTicket': 'Buy ticket!',
  'bookingOnline': 'Book online',
  'buySkipass': 'Buy skipass',
  'buyCourse': 'Buy course',
  'localTours': 'Local tours',
  'shopOnline': 'Online shop',
  'orderOnline': 'Order online',
  'planVisit': 'Plan your visit',
  'buyOnlineCheaper': 'Buy online and save!'
};
