import {EInvoiceFormType} from '@Components/invoice/types';
import {IOnlineOrderInvoice, IOrderInvoice} from './types';
import {InvoiceAddressCountry} from '@Consts/invoiceCountries';

export const getInternalInvoiceDataMutable = ({
  sendInvoice,
  invoiceAddressStreet,
  invoiceAddressCity,
  invoiceAddressPostalCode,
  invoiceAddressCountry,
  invoiceFirstName,
  invoiceLastName,
  invoiceCompanyNip,
  invoiceCompanyName,
  invoiceFormType,
  isInvoiceRecipient,
  invoiceRecipientName,
  invoiceRecipientAddressStreet,
  invoiceRecipientAddressCity,
  invoiceRecipientAddressPostalCode,
  invoiceRecipientAddressCountry
}: IOrderInvoice) => {
  if (!sendInvoice) {
    return null;
  }

  if (invoiceFormType === EInvoiceFormType.COMPANY_SIMPLIFIED) {
    return {
      invoiceData: {
        companyNip: invoiceCompanyNip,
        country: InvoiceAddressCountry.PL.toLowerCase()
      }
    };
  }

  if (invoiceFormType === EInvoiceFormType.COMPANY) {
    return {
      invoiceData: {
        ...invoiceCompanyNip && {companyNip: invoiceCompanyNip},
        ...invoiceCompanyName && {companyName: invoiceCompanyName},
        address: {
          street: invoiceAddressStreet,
          city: invoiceAddressCity,
          postalCode: invoiceAddressPostalCode
        },
        country: invoiceAddressCountry.code.toLowerCase(),
        ...isInvoiceRecipient && {
          recipientName: invoiceRecipientName,
          recipientCountry: invoiceRecipientAddressCountry.code.toLowerCase(),
          recipientAddress: {
            street: invoiceRecipientAddressStreet,
            city: invoiceRecipientAddressCity,
            postalCode: invoiceRecipientAddressPostalCode
          }
        }
      }
    };
  }

  if (invoiceFormType === EInvoiceFormType.PRIVATE_PERSON) {
    return {
      invoiceData: {
        ...invoiceFirstName && {firstName: invoiceFirstName},
        ...invoiceLastName && {lastName: invoiceLastName},
        address: {
          street: invoiceAddressStreet,
          city: invoiceAddressCity,
          postalCode: invoiceAddressPostalCode
        },
        country: invoiceAddressCountry.code.toLowerCase()
      }
    };
  }

  return null;
};

export const getOnlineInvoiceDataMutable = ({
  sendInvoice,
  invoiceAddressStreet,
  invoiceAddressCity,
  invoiceAddressPostalCode,
  invoiceAddressCountry,
  invoiceFirstName,
  invoiceLastName,
  invoiceCompanyNip,
  invoiceCompanyName,
  invoiceNote,
  invoiceFormType,
  isCompanySimplifiedInvoiceFormType,
  isInvoiceRecipient,
  invoiceRecipientName,
  invoiceRecipientAddressStreet,
  invoiceRecipientAddressCity,
  invoiceRecipientAddressPostalCode,
  invoiceRecipientAddressCountry
}: IOnlineOrderInvoice) => {
  if (!sendInvoice) {
    return null;
  }

  if (isCompanySimplifiedInvoiceFormType && invoiceFormType === EInvoiceFormType.COMPANY) {
    return {
      ...invoiceCompanyNip && {invoiceCompanyNip},
      ...invoiceNote && {invoiceNote},
      invoiceCountry: invoiceAddressCountry.code.toLowerCase()
    };
  }

  if (invoiceFormType === EInvoiceFormType.COMPANY) {
    return {
      invoiceAddress: {
        street: invoiceAddressStreet,
        city: invoiceAddressCity,
        postalCode: invoiceAddressPostalCode
      },
      invoiceCountry: invoiceAddressCountry.code.toLowerCase(),
      ...invoiceCompanyName && {invoiceCompanyName},
      ...invoiceCompanyNip && {invoiceCompanyNip},
      ...invoiceNote && {invoiceNote},
      ...isInvoiceRecipient && {
        invoiceRecipientName,
        invoiceRecipientCountry: invoiceRecipientAddressCountry.code.toLowerCase(),
        invoiceRecipientAddress: {
          street: invoiceRecipientAddressStreet,
          city: invoiceRecipientAddressCity,
          postalCode: invoiceRecipientAddressPostalCode
        }
      }
    };
  }

  if (invoiceFormType === EInvoiceFormType.PRIVATE_PERSON) {
    return {
      invoiceAddress: {
        street: invoiceAddressStreet,
        city: invoiceAddressCity,
        postalCode: invoiceAddressPostalCode
      },
      invoiceCountry: invoiceAddressCountry.code.toLowerCase(),
      ...invoiceFirstName && {invoiceFirstName},
      ...invoiceLastName && {invoiceLastName},
      ...invoiceNote && {invoiceNote}
    };
  }

  return null;
};
