import {getFacility} from '@Features/facility/facilitySelectors';
import useFrameMessage from '@Hooks/useFrameMessage';
import {
  EVENT_BUY_CLOSING_DIALOG_APPEARS,
  EVENT_BUY_CLOSING_DIALOG_CANCELED,
  EVENT_BUY_CLOSING_DIALOG_CONFIRMED
} from '@Utils/events';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

interface ExitConfirmationProps {
  isOpen: boolean,
  onCancel: () => void
}

const ExitConfirmation = ({isOpen, onCancel}: ExitConfirmationProps) => {
  const frameMessage = useFrameMessage();
  const facility = useSelector(getFacility);
  const {id: facilityId, name: facilityName} = {...facility};

  const sendAmplitudeEvent = (event: string, onEventSend?: () => void) => {
    pubsub.trigger(event, {
      facilityId,
      facilityName,
      url: window.location.href,
      callback: onEventSend
    });
  };

  const onEventSend = () => {
    onCancel();
    frameMessage({name: 'widgetCloseRequest'});
  };

  const closeWidget = () => {
    sendAmplitudeEvent(EVENT_BUY_CLOSING_DIALOG_CONFIRMED, onEventSend);
  };

  const handleContinueShopping = () => {
    sendAmplitudeEvent(EVENT_BUY_CLOSING_DIALOG_CANCELED);
    onCancel();
  };

  useEffect(() => {
    if (isOpen) {
      sendAmplitudeEvent(EVENT_BUY_CLOSING_DIALOG_APPEARS);
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="confirm-exit-dialog-title"
      aria-describedby="confirm-exit-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="confirm-exit-dialog-title">
        {locale.translate('areYouLeaving')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="inherit" id="confirm-exit-dialog-description">
          {locale.translate('exitConfirmationComment')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={closeWidget}>{locale.translate('abandonPurchase')}</Button>
        <Button color="inherit" onClick={handleContinueShopping} autoFocus>
          {locale.translate('continueShopping')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExitConfirmation;
