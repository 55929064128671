import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormContext} from 'react-hook-form';
import {IBasketItem} from '@Consts/types';
import {ICheckoutInvoiceSection} from './types';
import {getBasketItems} from '@Features/basket/basketSelectors';
import InvoiceToggler from '@Components/invoice/InvoiceToggler';
import InvoiceFormTypeTabs, {
  FIELD_NAME as FORM_TYPE_FIELD_NAME
} from '@Components/invoice/formFields/InvoiceFormTypeTabs';
import {isInternalInvoiceFormSimplified} from '@Utils/invoice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InvoiceFirstNameField from '@Components/invoice/formFields/InvoiceFirstNameField';
import InvoiceLastNameField from '@Components/invoice/formFields/InvoiceLastNameField';
import InvoiceNipSection, {FIELD_NAME as COMPANY_INVOICE_NIP_FIELD_NAME} from '@Components/invoice/InvoiceNipSection';
import CompanyNameField from '@Components/invoice/formFields/CompanyNameField';
import InvoicePostalCodeSection from '@Components/invoice/InvoicePostalCodeSection';
import InvoiceCityField from '@Components/invoice/formFields/InvoiceCityField';
import InvoiceStreetField from '@Components/invoice/formFields/InvoiceStreetField';
import {EInvoiceFormType} from '@Components/invoice/types';
import Alert from '@mui/material/Alert';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import locale from '@Utils/locale';
import RecipientToogler from '@Components/invoice/RecipientToggler';
import InvoiceCountryField, {
  FIELD_NAME as COMPANY_INVOICE_COUNTRY_FIELD_NAME
} from '@Components/invoice/formFields/InvoiceCountryField';
import CompanyNipField from '@Components/invoice/formFields/CompanyNipField';
import {fillMissedData, isInvoiceDataComplete} from '@Utils/formUtils';
import {useFetchInvoiceData} from '@Utils/hooks/useFetchInvoiceData';
import {setStorage} from '@Features/storage/storageActions';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';

const CheckoutInvoiceSection = ({
  isOnlinePaymentReceiptEnabled,
  isInternalPaymentReceiptEnabled
}: ICheckoutInvoiceSection) => {
  const dispatch = useDispatch();
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const [isInvoiceFormSimplified, setIsInvoiceFormSimplified] = useState(false);
  const {watch, setValue, getValues} = useFormContext();
  const [
    invoiceFormType,
    invoiceCompanyNip,
    invoiceAddressCountry,
    sendInvoice,
    isPaymentOnline,
    isInvoiceRecipient
  ] = watch([
    FORM_TYPE_FIELD_NAME,
    COMPANY_INVOICE_NIP_FIELD_NAME,
    COMPANY_INVOICE_COUNTRY_FIELD_NAME,
    'sendInvoice',
    'isPaymentOnline',
    'isInvoiceRecipient'
  ]) as [EInvoiceFormType, string, CountryType, boolean, boolean, boolean];

  const isPrivatePersonFormType = invoiceFormType === EInvoiceFormType.PRIVATE_PERSON;
  const isCompanyFormType = invoiceFormType === EInvoiceFormType.COMPANY;
  const isCompanySimplifiedFormType = invoiceFormType === EInvoiceFormType.COMPANY_SIMPLIFIED;
  const isPolishCountryCode = invoiceAddressCountry.code === InvoiceAddressCountry.PL;
  const canFetchInvoiceData = !isCompanySimplifiedFormType &&
    isPolishCountryCode &&
    !isInvoiceDataComplete(getValues());
  const {invoiceData, isError} = useFetchInvoiceData(
    invoiceCompanyNip, invoiceAddressCountry.code, canFetchInvoiceData
  );

  useEffect(() => {
    const isInvoiceFormSimplifiedEnabled = isInternalInvoiceFormSimplified({
      basketItems,
      isOnlinePaymentReceiptEnabled,
      isInternalPaymentReceiptEnabled,
      isPaymentOnline,
      setValue,
      invoiceFormType
    });

    setIsInvoiceFormSimplified(isInvoiceFormSimplifiedEnabled);
  }, [isPaymentOnline]);

  const handleSetValue = (name: string, value: string) => {
    dispatch(setStorage(name, value, true));
    setValue(name, value, {shouldValidate: true});
  };

  useEffect(() => {
    if (invoiceData && !isError && !isCompanySimplifiedFormType) {
      const formValues = getValues();

      fillMissedData(formValues, invoiceData, handleSetValue);
    }
  }, [invoiceData]);

  return (
    <>
      <InvoiceToggler sendInvoice={sendInvoice} styles={{mb: 2}} />
      {
        sendInvoice &&
          <Box sx={{mb: 3}} data-testid="agreements-invoice">

            {!isPaymentOnline && (
              <Alert
                severity="warning"
                icon={<WarningAmberOutlined sx={{fontSize: 22, mr: 0.5}} />}
              >
                {locale.translate('requiredEmailAlert')}
              </Alert>
            )}

            <InvoiceFormTypeTabs isInvoiceFormSimplified={isInvoiceFormSimplified} />

            {
              (isPrivatePersonFormType || isCompanyFormType) &&
                (
                  <Grid container rowSpacing={3} columnSpacing={2}>
                    <Grid item xs={12}>
                      <InvoiceCountryField />
                    </Grid>
                    {
                      isPrivatePersonFormType ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <InvoiceFirstNameField />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <InvoiceLastNameField />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={6}>
                            <InvoiceNipSection />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CompanyNameField />
                          </Grid>
                        </>
                      )
                    }
                    <Grid item xs={12}>
                      <InvoiceStreetField />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InvoicePostalCodeSection />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InvoiceCityField />
                    </Grid>
                    {
                      isCompanyFormType && (
                        <>
                          <RecipientToogler />
                          {
                            isInvoiceRecipient && (
                              <>
                                <Grid item xs={12}>
                                  <InvoiceCountryField name="invoiceRecipientAddressCountry" />
                                </Grid>
                                <Grid item xs={12}>
                                  <CompanyNameField
                                    name="invoiceRecipientName"
                                    label={locale.translate('recipientName')}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <InvoiceStreetField name="invoiceRecipientAddressStreet" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <InvoicePostalCodeSection
                                    fieldName="invoiceRecipientAddressPostalCode"
                                    countryFieldName="invoiceRecipientAddressCountry"
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <InvoiceCityField name="invoiceRecipientAddressCity" />
                                </Grid>
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </Grid>
                )
            }

            {
              isCompanySimplifiedFormType && (
                <>
                  <Alert
                    severity="info"
                    icon={<InfoOutlined sx={{fontSize: 22, mr: 0.5}} />}
                  >
                    {locale.translate('simplifiedInvoiceInformation')}
                  </Alert>
                  <Box mt={3}>
                    <CompanyNipField label={locale.translate('polishNip')} />
                  </Box>
                </>
              )
            }
          </Box>
      }
    </>
  );
};

export default CheckoutInvoiceSection;
