import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import locale from '@Utils/locale';
import pubsub from '@Utils/pubsub';
import {EVENT_VIEW_ACTIVITIES_DETAILS, EVENT_VIEW_ACTIVITIES_DETAILS_HIDE} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {useSelector} from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import {useHideDescription} from '@Utils/hooks/useHideDescription';

type ActivityDescriptionProps = {
  description: string;
  disabled: boolean;
  activityName: string;
  activityId: number;
  activityWrapperRef: Element | null;
  indicated?: boolean,
  numberOfActivities: number
};

const ActivityDescription = (
  {description, disabled, activityName, activityId, activityWrapperRef, indicated, numberOfActivities
  }: ActivityDescriptionProps) => {
  const [isExpanded, setExpand] = useState(indicated || numberOfActivities === 1);
  const [isMoreLines, setIsMoreLines] = useState(false);
  const facility = useSelector(getFacility);
  const {id, name, company} = {...facility};
  const affiliationHash = useSelector(getAffiliationHash);
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const EXPANDABLE_TEXT_LENGTH = 109;
  const {scrollToParent} = useHideDescription(activityWrapperRef);

  function handleExpand() {
    if (!isExpanded) {
      pubsub.trigger(
        EVENT_VIEW_ACTIVITIES_DETAILS,
        {
          facilityId: id,
          facilityName: name,
          industry: company?.industry,
          affiliationHash,
          activityName,
          activityId
        }
      );
    } else {
      const textHeight = textRef?.current?.clientHeight ?? 0;

      scrollToParent(textHeight);
      pubsub.trigger(
        EVENT_VIEW_ACTIVITIES_DETAILS_HIDE,
        {
          facilityId: id,
          facilityName: name,
          industry: company?.industry,
          affiliationHash,
          activityName,
          activityId
        }
      );
    }

    return setExpand(prev => !prev);
  }

  function createMarkup() {
    return {__html: description};
  }

  useEffect(() => {
    if (textRef.current?.innerText && textRef.current?.innerText?.length > EXPANDABLE_TEXT_LENGTH) {
      setIsMoreLines(true);
    }
  }, [textRef]);

  return (
    <Stack
      direction={isExpanded ? 'column' : 'row'}
      spacing={1}
      sx={{px: 2, pb: isExpanded ? 1 : 0}}
    >

      <Typography
        ref={textRef}
        dangerouslySetInnerHTML={createMarkup()}
        sx={{
          pt: 1.5,
          pb: isExpanded ? 0 : 1.5,
          alignSelf: 'flex-start',
          wordBreak: 'break-word',
          whiteSpace: isExpanded || !isMoreLines ? 'pre-wrap' : 'nowrap',
          ...!isExpanded && {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}}
      />
      {isMoreLines && (
        <Box
          sx={{
            alignSelf: isExpanded ? 'flex-end' : isMoreLines ? 'flex-start' : 'center'
          }}
        >
          <Button
            variant="text"
            endIcon={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={handleExpand}
            sx={{
              width: 95,
              minWidth: 95,
              color: 'action.active',
              opacity: disabled ? 0.8 : 1,
              mt: isExpanded ? 0 : isMoreLines ? '6px' : 0
            }}
          >
            {isExpanded ? locale.translate('hide') : locale.translate('expand')}
          </Button>
        </Box>
      )}

    </Stack>
  );
};

export default ActivityDescription;
