import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import locale from '@Utils/locale';
import Typography from '@mui/material/Typography';
import {usePaymentUrl} from '@Hooks/fetchHooks/usePaymentUrl';
import {useSummaryData} from '@Hooks/useSummaryData';
import TextLink from '@Components/common/TextLink';
import Divider from '@mui/material/Divider';
import GoToActivities from '@Components/navigation/GoToActivities';
import useFrameMessage from '@Hooks/useFrameMessage';
import {getFacility} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {sendRetryPaymentEvent} from '@Utils/orderUtils';

const BlikPaymentSummary = () => {
  const {latestOrderStorageData, onlineOrder} = useSummaryData();
  const {code, isEventsSent, orderId} = latestOrderStorageData;
  const {handleFetchNewPaymentUrl, payUrl, isSuccess} = usePaymentUrl(code);
  const email = onlineOrder?.orderBuyer?.email;
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);

  const frameMessage = useFrameMessage();

  useEffect(() => {
    if (payUrl && isSuccess) {
      frameMessage({
        name: 'payment',
        href: payUrl
      });
    }
  }, [isSuccess]);

  const payAgain = () => {
    if (facility) {
      sendRetryPaymentEvent(facility, {
        orderId,
        affiliationHash,
        isFirstLoading: !isEventsSent
      });
    }

    handleFetchNewPaymentUrl();
  };

  return (
    <Box sx={{p: {xs: 1.25, md: 3}, bgcolor: 'background.main'}} >
      <GoToActivities translationKey="back" />
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: '126px',
          mx: 'auto',
          maxWidth: {xs: 1, md: 475},
          textAlign: 'center'
        }}
      >
        <CircularProgress size={32} sx={{mt: 1.5, mb: 4}} />
        <Typography variant="h4" sx={{mb: 4, fontWeight: 500}}>
          {locale.translate('checkTheBankingApp')}
        </Typography>

        <Box sx={{maxWidth: 350}}>
          <Typography>
            {locale.translate('confirmPaymentWithPin') }
          </Typography>

          <Divider variant="middle" flexItem sx={{my: 3}} />

          <Typography
            sx={{
              mb: 2,
              color: 'text.secondary'
            }}
          >
            {locale.translate('paymentProcessInterrupted')}{' '}
            <TextLink onClick={payAgain}>{locale.translate('clickHere')}</TextLink>
          </Typography>
        </Box>

        {
          email && (
            <Typography data-testid="order-buyer-email" sx={{color: 'text.secondary', px: 6}}>
              {locale.translate('ticketsLoadedAndSentToEmail')}{' '}
              <Typography component="span" sx={{fontWeight: 700}}>{email}</Typography>
            </Typography>
          )
        }
      </Stack>
    </Box>
  );
};

export default BlikPaymentSummary;
