import Polyglot from 'node-polyglot';
import TEXT_PL from './locale/pl';
import TEXT_ENG from './locale/eng';
import TEXT_SK from './locale/sk';
import TEXT_CS from './locale/cs';
import TEXT_DE from './locale/de';
import TEXT_IT from './locale/it';
import TEXT_UK from './locale/uk';
import TEXT_ES from './locale/es';
import TEXT_HU from './locale/hu';
import {ELocale} from '@Consts/globals';

const windowLanguage = window.navigator.language.split('-')[0] as ELocale;

const getPhrases = (language: ELocale) => {
  const phrases: Record<ELocale, object> = {
    pl: TEXT_PL,
    en: TEXT_ENG,
    cs: TEXT_CS,
    de: TEXT_DE,
    es: TEXT_ES,
    it: TEXT_IT,
    sk: TEXT_SK,
    uk: TEXT_UK,
    hu: TEXT_HU
  };

  return phrases[language] ?? phrases.en;
};

const locale = (language: ELocale) => {
  const mutableLanguage = language || windowLanguage;
  const phrases = getPhrases(mutableLanguage);
  const polyglot = new Polyglot({phrases, locale: mutableLanguage});
  const translate = polyglot.t.bind(polyglot);

  return translate;
};

export default locale;
