import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import classNames from 'classnames';
import locale from '../../utils/locale';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

const CalendarPanel = ({selectedYear, selectedMonth, onPrevMonthClick, onNextMonthClick, isLower, isTestB}) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();

  // Do not disable when it is first date of month. We accept yesterday.
  const prevButtonDisabled = selectedYear <= currentYear && selectedMonth <= currentMonth && currentDate !== 1;
  const prevMonthClick = !prevButtonDisabled && {onClick: onPrevMonthClick};

  if (isTestB) {
    return (
      <Stack direction="row" sx={{justifyContent: 'space-between', alignItems: 'center', mb: 2, px: 1}}>
        <Typography sx={{fontSize: '18px', fontWeight: 500, color: 'text.primary'}}>
          {locale.translate(MONTHS[selectedMonth])} {selectedYear}
        </Typography>
        <Stack direction="row" spacing={2}>
          <IconButton {...prevMonthClick} disabled={prevButtonDisabled} sx={{p: 1.5}}>
            <ArrowBackIosNewOutlinedIcon sx={{fontSize: '20px'}} />
          </IconButton>
          <IconButton data-testid="next-month" onClick={onNextMonthClick} sx={{p: 1.5}}>
            <ArrowForwardIosOutlinedIcon sx={{fontSize: '20px'}} />
          </IconButton>
        </Stack>
      </Stack>
    );
  }

  return (
    <div
      className={classNames(
        'dl-calendar__panel',
        {
          'dl-calendar__panel--lower': isLower
        }
      )}
    >
      <div
        data-testid="prev-month"
        className={classNames(
          'dl-calendar__panel-icon',
          {
            'dl-calendar__panel-icon--disabled': prevButtonDisabled
          }
        )}
        {...prevMonthClick}
      >
        <KeyboardArrowLeftOutlinedIcon />
      </div>
      <div className="dl-calendar__panel-month" data-testid="calendar-month">
        {locale.translate(MONTHS[selectedMonth])} {selectedYear}
      </div>
      <div data-testid="next-month" className="dl-calendar__panel-icon" onClick={onNextMonthClick}>
        <KeyboardArrowRightOutlinedIcon />
      </div>
    </div>
  );
};

export default CalendarPanel;
