/* eslint-disable max-len */
export default {
  'bubbleDisabled': '¡Venta deshabilitada!',
  'buyOnline': '¡Compra en línea!',
  'buyVoucher': 'Compra un vale',
  'buyTicket': '¡Compra un boleto!',
  'bookingOnline': 'Reserva en línea',
  'buySkipass': 'Compra un skipass',
  'buyCourse': 'Compra un curso',
  'localTours': 'Tours locales',
  'shopOnline': 'Comprar en línea',
  'orderOnline': 'Ordenar en línea',
  'planVisit': 'Planifica tu visita',
  'buyOnlineCheaper': '¡Compra online más barato!'
};
