export const getUniquesTags = facilityTags => {
  let uniquesTags = [];

  if (!facilityTags) {
    return uniquesTags;
  }

  facilityTags.forEach(fTag => {
    const {name: tagName} = fTag;
    const isTagInUniques = uniquesTags?.length ?
      uniquesTags.some(({name: uTagName}) => uTagName === tagName) :
      false;

    if (!isTagInUniques) {
      uniquesTags = [...uniquesTags, fTag];
    }
  });

  return uniquesTags;
};

export const getAllFacilitiesTags = activities => {
  let allFacilitiesTags = [];

  activities.forEach(activity => {
    if (activity?.facilityTags?.length) {
      allFacilitiesTags = [
        ...allFacilitiesTags,
        ...activity?.facilityTags
      ];
    }
  });

  return allFacilitiesTags;
};

export const getActivitiesTags = activities => {
  const allFacilitiesTags = getAllFacilitiesTags(activities);
  const uniquesTags = getUniquesTags(allFacilitiesTags);

  if (uniquesTags.length === 1 && activities.length === allFacilitiesTags.length) {
    return null;
  }

  if (uniquesTags.length) {
    return uniquesTags;
  }

  return null;
};

export const getFilteredActivitiesByTag = (activities, activeTag) => {
  if (!activeTag) {
    return activities;
  }

  if (!activities?.length) {
    return activities;
  }

  return activities.filter(activity =>
    activity?.facilityTags?.some(({name}) => name === activeTag)
  );
};

export const getActivitiesByPriority = activities =>
  activities?.sort((firstActivity, secondActivity) => secondActivity.priority - firstActivity.priority) || [];
