import {
  getActivitiesUniquesIds,
  getBasketItemsByActivityId,
  getVariantBasketItems
} from '@Utils/basket';

export const getBasketState = state => state.basket || null;

export const getBasketItems = state => {
  const basketState = getBasketState(state);
  const basketItems = basketState?.items;

  if (basketItems?.length) {
    const activitiesUniquesIds = getActivitiesUniquesIds(basketItems);

    return activitiesUniquesIds.reduce((sortedBasketItems, activityId) => {
      const activityBasketItems = getBasketItemsByActivityId(basketItems, activityId);

      return sortedBasketItems.concat(activityBasketItems);
    }, []);
  }

  return [];
};

export const getBasketActivityItems = (state, activityId) => {
  const basketItems = getBasketItems(state);

  return basketItems.filter(item => item.activityId === activityId);
};

export const getBasketVariantItems = (state, activityId, variantId) => {
  const basketItems = getBasketItems(state);

  return getVariantBasketItems(basketItems, activityId, variantId);
};

export const getBasketItemByUid = (state, uid) => {
  const basketState = getBasketState(state);
  const basketItems = basketState?.items;

  return basketItems.find(basketItem => basketItem.uid === uid);
};

export const getBasketItemsActivitiesIds = state => {
  const basketState = getBasketState(state);
  const basketItems = basketState?.items;
  const basketItemsActivitiesIds = basketItems.map(basketItem => basketItem.activityId);

  return new Set(basketItemsActivitiesIds);
};

export const getIsNextStepBlocked = state => {
  const basketState = getBasketState(state);

  return basketState?.blockNextStepWith?.length > 0;
};

