import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {green, grey} from '@mui/material/colors';
import {
  CalendarCellButtonProps,
  DefaultTextDayProps,
  TextAvailableSeatsProps,
  TextDayProps,
  TextFromProps,
  TextPriceProps,
  TextWithoutDetailsProps
} from './types';

export const CalendarCellButton = styled(Button, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'isDisabled' &&
    prop !== 'isSelectedStart' &&
    prop !== 'isSelectedMiddle' &&
    prop !== 'isSelectedEnd' &&
    prop !== 'isFirstDayInMonth' &&
    prop !== 'isLastDayInMonth'
})<CalendarCellButtonProps>(({
  theme, isSelected, isDisabled, isSelectedStart, isSelectedMiddle, isSelectedEnd, isFirstDayInMonth, isLastDayInMonth
}) => {
  const isStartOrEndCalendarCell = isLastDayInMonth || isFirstDayInMonth;
  const gradientSide = isFirstDayInMonth ? 'to left' : 'to right';

  const middleCellBackground = isStartOrEndCalendarCell ? `
  linear-gradient(${gradientSide}, ${grey[100]} 60%, #fff) padding-box` : grey[100];

  const middleCellBorderImage = isStartOrEndCalendarCell ?
  `linear-gradient(${gradientSide}, ${grey[600]} 10%, #fff) 1` : 'none';

  const startCellBackground = isStartOrEndCalendarCell && !isFirstDayInMonth ? `
  linear-gradient(${gradientSide}, ${grey[200]} 60%, #fff) padding-box,
  linear-gradient(${gradientSide}, ${grey[600]} 10%, #fff) border-box` : grey[200];

  const endCellBackground = (isSelectedStart && isFirstDayInMonth) || (isSelectedEnd && isFirstDayInMonth) ? `
  linear-gradient(${gradientSide}, ${grey[200]} 60%, #fff) padding-box,
  linear-gradient(${gradientSide}, ${grey[600]} 10%, #fff) border-box` : grey[200];

  const backgroundHover = () => {
    if ((isSelectedStart || isSelectedEnd) && (isFirstDayInMonth || isLastDayInMonth)) {
      return `
      linear-gradient(180deg, ${grey[100]} 0%, #eeeeee 50%) padding-box,
      linear-gradient(${gradientSide}, ${grey[600]} 10%, #fff) border-box`;
    }

    if (isDisabled) {
      return `linear-gradient(180deg, #ffffff 0%, ${grey[50]} 50%)`;
    }

    return `linear-gradient(180deg, ${grey[100]} 0%, #eeeeee 50%)`;
  };

  return {
    width: '100%',
    height: '100%',
    minWidth: 'auto',
    background: 'linear-gradient(180deg, #ffffff 0%, #fafafa 50%)',
    textTransform: 'none',
    border: '1px solid #fff',
    padding: 0,
    alignItems: 'flex-start',
    transition: '0s',
    backgroundColor: isDisabled ? theme.palette.action.disabledBackground : 'auto',
    color: isDisabled ? theme.palette.action.disabled : 'auto',
    cursor: isDisabled ? 'auto' : 'cursor',
    '&:hover': {
      background: backgroundHover()
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.primary.main}`
    },
    ...isSelected && {
      background: grey[200],
      border: `1px solid ${grey[600]}`,
      transition: '0s'
    },
    ...isSelectedStart && {
      background: startCellBackground,
      borderColor: isStartOrEndCalendarCell && !isFirstDayInMonth ? 'transparent' : grey[600],
      borderRight: 'none',
      paddingRight: '1px',
      borderTopRightRadius: 0,
      borderEndEndRadius: 0
    },
    ...isSelectedMiddle && {
      background: middleCellBackground,
      borderImage: middleCellBorderImage,
      borderRight: 'none',
      borderLeft: 'none',
      paddingLeft: '1px',
      paddingRight: '1px',
      borderRadius: 0
    },
    ...isSelectedEnd && {
      background: endCellBackground,
      borderColor: isStartOrEndCalendarCell && !isLastDayInMonth ? 'transparent' : grey[600],
      borderLeft: 'none',
      paddingLeft: '1px',
      borderTopLeftRadius: 0,
      borderEndStartRadius: 0
    }
  };
});

export const TextPrice = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isBargainPrice'
})<TextPriceProps>(({theme, isBargainPrice}) => ({
  color: isBargainPrice ? green[800] : grey[800],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  lineHeight: '14px',
  fontWeight: 500,
  marginBottom: '4px',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '12px'
  }
}));

export const TextAvailableSeats = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isDisabled'
})<TextAvailableSeatsProps>(({theme, isDisabled}) => ({
  fontSize: '10px',
  color: isDisabled ? 'inherit' : grey[600],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  letterSpacing: -0.4,
  lineHeight: '12px',
  marginBottom: '8px',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '11px',
    fontSize: '9px'
  }
}));

export const TextDay = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' &&
    prop !== 'isToday' &&
    prop !== 'isPriceNotFixed'
})<TextDayProps>(({theme, isDisabled, isToday, isPriceNotFixed}) => ({
  fontSize: '16px',
  fontWeight: isToday ? 700 : 400,
  color: isDisabled ? 'inherit' : grey[900],
  marginTop: '8px',
  marginBottom: isPriceNotFixed ? '2px' : '14px',
  lineHeight: '20px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: isPriceNotFixed ? '2px' : '10px'
  }
}));

export const DefaultTextDay = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' &&
    prop !== 'isToday'
})<DefaultTextDayProps>(
  ({theme, isDisabled, isToday}) => ({
    color: isDisabled ? 'inherit' : theme.palette.grey[900],
    fontWeight: isToday ? 700 : 400,
    lineHeight: '20px',
    marginTop: '22px',
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '19.5px'
    }
  })
);

export const TextFrom = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'isBargainPrice'
})<TextFromProps>(
  ({theme, isSelected, isBargainPrice}) => ({
    fontSize: '9px',
    lineHeight: '12px',
    fontWeight: isSelected || isBargainPrice ? 500 : 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '7px',
      lineHeight: '8px'
    }
  })
);

export const TextWithoutDetails = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isWithAllAdditionalInformation'
})<TextWithoutDetailsProps>(
  ({theme, isWithAllAdditionalInformation}) => ({
    fontSize: '10px',
    color: 'inherit',
    marginTop: isWithAllAdditionalInformation ? '24px' : 0,
    lineHeight: '12px',
    [theme.breakpoints.down('sm')]: {
      marginTop: isWithAllAdditionalInformation ? '16px' : 0
    }
  })
);

export const TextSoldOutOrNoSeats = styled(Typography)(
  ({theme}) => ({
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: -0.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px',
      lineHeight: '9px'
    }
  })
);
