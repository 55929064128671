import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import locale from '@Utils/locale';
import ActivityContent from '../ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

const ActivityAdmissionMultiple = ({admissionPolicy, hideIcon, parsedDuration}) => {
  const {durationInMinutes, useLimit, useLimitOncePerDay} = admissionPolicy;
  const durationInfo = parsedDuration?.length ?
    parsedDuration.join(' ') : locale.translate('durationUnlimited');
  const useLimitText = useLimitOncePerDay ?
    locale.translate('oncePerDay') : useLimit ?
      `${locale.translate('useNumber')}: ${useLimit}` :
      locale.translate('withoutLimit');

  return (
    <ActivityContent>
      {
        !hideIcon &&
        <LayersOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
      }

      <Text color={ETextColor.LIGHT}>
        {useLimitText}
        {durationInMinutes ? ` x ${durationInfo}` : ''}
      </Text>
    </ActivityContent>
  );
};

ActivityAdmissionMultiple.propTypes = {
  admissionPolicy: PropTypes.object.isRequired,
  parsedDuration: PropTypes.array,
  hideIcon: PropTypes.bool
};

export default ActivityAdmissionMultiple;
