import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timeZonePlugin from 'dayjs/plugin/timezone';
import {getNumberWithLeadingZero} from './format';
import locale from './locale';
import {
  getFirstOfMonth,
  getTimezoneDate
} from './dayjs/dayjsUtils';

dayjs.extend(utc).extend(timeZonePlugin);

export const LAST_MONTH_INDEX = 11;
const LAST_DAY_OF_WEEK_INDEX = 6;

/**
 * Return end of day for given date
 * @param {Date} date - date object
 * @return {Date} end of day
 */
export const getEndOfDate = date => {
  const newDate = new Date(date);

  newDate.setHours(23);
  newDate.setMinutes(59);
  newDate.setSeconds(59);
  newDate.setMilliseconds(0);

  return newDate;
};

/**
 * Return calculated date with number of days
 * @param {Date} date - date object
 * @param {number} days - number of days to add
 * @param {boolean} addition - is operation addition
 * @return {Date} shifted date
 */
const calculateDate = (date, days, addition) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(year, month, addition ? day + days : day - days);
};

/**
 * Return date with subtracted number of days
 * @param {Date} date - date object
 * @param {number} days - number of days to add
 * @return {Date} shifted date
 */
export const subtractDaysFromDate = (date, days) => calculateDate(date, days, false);

/**
 * Returns number of days in month
 * @param {number} year - year
 * @param {number} month - month number (0 corresponds to January)
 * @return {number} number of days in month
 */
export const getNumberOfDaysInMonth = (year, month) => {
  if (month === LAST_MONTH_INDEX) {
    return new Date(year + 1, 0, 0).getDate();
  }

  return new Date(year, month + 1, 0).getDate();
};

/**
 * Returns number of first day in month
 * @param {number} year - year
 * @param {number} month - month number (0 corresponds to January)
 * @return {number} number of first day in month
 */
export const getNumberOfFirstDayInMonth = (year, month) => {
  const dayNumber = new Date(year, month, 1).getDay() - 1;

  return dayNumber < 0 ? LAST_DAY_OF_WEEK_INDEX : dayNumber;
};

export const startFromMonday = day => {
  const daysTranslated = [6, 0, 1, 2, 3, 4, 5];

  return daysTranslated[day];
};

export const trimLastWeek = weeks => {
  const lastWeek = weeks[5];
  const isLastWeekEmpty = lastWeek.every(day => !day);

  if (isLastWeekEmpty) {
    return weeks.slice(0, 5);
  }

  return weeks;
};

/**
 * Creates dates grouped by weeks for calendar month
 * @param {number} year - year
 * @param {number} month - month
 * @param {string} timezone - facility timezone
 * @return {Array.<Array.<Date>>} array of weeks, each array contains array of days
 */
export const getMonthDates = (year, month, timezone) => {
  const weeks = [
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null]
  ];

  const firstOfMonth = getFirstOfMonth(getTimezoneDate(new Date(year, month, 1), timezone, true));
  const daysInMonth = firstOfMonth.daysInMonth();

  let currentDay = 1;
  let currentWeek = 0;

  while (currentDay <= daysInMonth) {
    const currentDate = getTimezoneDate(new Date(year, month, currentDay), timezone, true);
    const weekDay = startFromMonday(currentDate.day());

    weeks[currentWeek][weekDay] = currentDate;

    if (weekDay === 6) {
      currentWeek++;
    }
    currentDay++;
  }

  return trimLastWeek(weeks);
};

export const getTime = date => {
  const hours = getNumberWithLeadingZero(date.getHours());
  const minutes = getNumberWithLeadingZero(date.getMinutes());

  return `${hours}:${minutes}`;
};

export const getFormattedDate = (dt, includeHours) => {
  const dayOfWeek = dt.getDate();
  const dayOfMonth = dt.getMonth() + 1;
  const fullYear = dt.getFullYear();
  const day = getNumberWithLeadingZero(dayOfWeek);
  const month = getNumberWithLeadingZero(dayOfMonth);
  const date = `${day}.${month}.${fullYear}`;
  const hourAbbr = locale.translate('hour');

  if (includeHours) {
    return `${date} ${hourAbbr} ${getTime(dt)}`;
  }

  return date;
};

export const getDateShort = date => {
  if (date) {
    const day = date.getDate();
    const formattedDay = getNumberWithLeadingZero(day);
    const month = date.getMonth() + 1;
    const formattedMonth = getNumberWithLeadingZero(month);

    return `${formattedDay}.${formattedMonth}`;
  }

  return null;
};

export const isSameMonth = (calendarMonth, calendarYear, selectedDate) => {
  const selectedDateMonth = dayjs(selectedDate?.date).get('month');
  const selectedDateYear = dayjs(selectedDate?.date).get('year');

  return selectedDateMonth === calendarMonth && selectedDateYear === calendarYear;
};
