import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import React, {useState} from 'react';
import locale from '@Utils/locale';
import ClearOrChangeActivitiesConfirmation from '@Components/confirmations/ClearOrChangeActivitiesConfirmation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ClearActivities = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Box sx={wrapperStyles}>
        <Chip
          icon={<DeleteOutlineIcon />}
          label={locale.translate('removeSelectedActivities')}
          onClick={() => setIsDialogOpen(true)}
          sx={{
            backgroundColor: '#fff',
            fontSize: '13px',
            borderRadius: '8px',
            border: '1px solid #e0e0e0',

            '&:hover, &:focus': {
              backgroundColor: '#f5f5f5'
            }
          }}
        />
      </Box>
      <ClearOrChangeActivitiesConfirmation
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        source="button"
        clearActivities
      />
    </>
  );
};

const wrapperStyles = {
  p: {xs: '0 10px 12px 10px', md: '0 24px 12px 24px'},
  display: 'flex',
  justifyContent: {xs: 'flex-end', md: 'flex-start'}
};

export default ClearActivities;
