import React, {
  useState,
  useEffect
} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getBasketItems} from '@Features/basket/basketSelectors';
import CustomerSection from './customerSection/CustomerSection';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {isWidgetBusiness, isWidgetBusinessPreview} from '@Utils/widgetType';
import {getFacility} from '@Features/facility/facilitySelectors';

import BusinessSalePaymentInfo from '@Components/termsOfService/BusinessSalePaymentInfo';
import Discounts from '@Components/discounts/Discounts';
import pubsub from '@Utils/pubsub';
import {EVENT_SUBMITTED_FORM} from '@Utils/events';
import {getDiscountCode} from '@Features/discount/discountSelectors';
import {getOrderMutable} from '@Utils/order';
import {useDispatch, useSelector} from 'react-redux';
import locale from '@Utils/locale';
import './agreements.scss';
import {parseLanguage} from '@Utils/eventsUtils';
import {isCompanySimplifiedInvoiceFormType} from '@Utils/invoice';
import {EInvoiceFormType} from '@Components/invoice/types';
import AgreementsSection from './agreementsSection/AgreementsSection';
import Divider from '@mui/material/Divider';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormProvider, useForm} from 'react-hook-form';
import {IAreementsFormProps, TAgreementsFormValues} from './types';
import {IBasketItem} from '@Consts/types';
import {getAgreementsValidationSchema} from './validationSchema';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PhoneSection from './PhoneSection';
import RequestValidationError from '@Utils/api/errors/requestValidationErrror';
import {Alert, Typography, useTheme} from '@mui/material';
import AgreementsInvoiceSection from '@Components/checkoutPages/agreements/invoiceSection/AgreementsInvoiceSection';
import {handleApiErrors} from '@Utils/api/errors/apiError';
import {useAgreementsFormDefaultValues} from '../../../hooks/useAgreementsFormDefaultValues';
import {mutateOrderStorage} from '@Features/order/orderFormActions';
import {useScroll} from '@Utils/hooks/useScroll';
import PaymentMethodPicker from '@Components/checkoutPages/agreements/paymentMethod/PaymentMethod';
import {useIsPaymentMethodsEnabled} from '@Hooks/useIsPaymentMethodsEnabled';
import {resetDiscount} from '@Features/discount/discountActions';
import {EPaymentMethod} from '@Components/checkout/types';
import {getCheckoutButtonLabel} from '@Utils/checkoutUtils';
import useFeatureFlag, {Features} from '@Hooks/useFeatureFlag';

const AgreementsForm = ({onSubmit, submitDisabled, wrapperRef}: IAreementsFormProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [defaultError, setDefaultError] = useState<null | string>(null);
  const facility = useSelector(getFacility);
  const {isEnabled: isTestB} = useFeatureFlag(Features.NEW_CALENDAR);

  if (!facility) {
    return null;
  }
  const config = useSelector(getConfiguration);
  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const discountCode = useSelector(getDiscountCode);
  const paymentMethodsEnabled = useIsPaymentMethodsEnabled();
  const {widgetType, affiliationHash, shippingMethod} = config;
  const {
    id: facilityId,
    name: facilityName,
    company,
    timezone,
    salePolicy
  } = facility;
  const {
    paymentType,
    name: companyName,
    isUsersInvoiceFormEnabled,
    isUsersInvoiceNoteEnabled,
    isOnlinePaymentReceiptEnabled,
    industry,
    paymentProvider
  } = company;
  const {termsOfServiceURL, termsOfSaleURL} = salePolicy;
  const isMultipleTerms = !!termsOfServiceURL && !!termsOfSaleURL;
  const withContactPhoneNumber = basketItems.some(basketItem => basketItem.activity.salePolicy.buyerPhoneRequired);
  const withDiscounts = facility.salePolicy?.isDiscountsFormEnabled;
  const widgetIsBusiness = isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType);
  const validationSchema = getAgreementsValidationSchema(isMultipleTerms);
  const defaultValues = useAgreementsFormDefaultValues({
    facility,
    widgetIsBusiness,
    withContactPhoneNumber,
    paymentMethodsEnabled
  });

  const isSimplifiedInvoiceForm = isCompanySimplifiedInvoiceFormType({
    basketItems,
    isOnlinePaymentReceiptEnabled,
    invoiceAddressCountryCode: defaultValues.invoiceAddressCountry?.code
  });

  const formProperties = useForm<TAgreementsFormValues>({
    defaultValues,
    resolver: yupResolver<TAgreementsFormValues>(validationSchema),
    context: {
      widgetIsBusiness,
      withContactPhoneNumber,
      isUsersInvoiceNoteEnabled,
      isSimplifiedInvoiceForm,
      paymentMethodsEnabled
    }
  });
  const {handleSubmit, formState: {errors: formErrors}, setError, watch} = formProperties;
  const {scrollToElement} = useScroll();

  const paymentMethod = watch('paymentMethod') as EPaymentMethod | undefined;

  function scrollEffect(element?: Element) {
    if (element) {
      scrollToElement(element);
    }
  }

  useEffect(() => {
    setTimeout(() => scrollEffect(wrapperRef?.current), 550);
  }, [wrapperRef]);

  useEffect(() => {
    if (Object.values(formErrors)?.length) {
      const firstErrorName = Object.keys(formErrors)[0] as keyof TAgreementsFormValues;

      const $firstErrorElement = wrapperRef?.current?.querySelector(`[name="${firstErrorName}"]`);

      if ($firstErrorElement) {
        scrollEffect($firstErrorElement);
      }
    }

  }, [formErrors]);

  async function handleFormSubmit(values: TAgreementsFormValues) {
    const orderMutable = getOrderMutable({
      ...config,
      ...values,
      phonePrefix: values.phonePrefix?.value,
      withContactPhoneNumber,
      withDiscounts,
      discountCode,
      basketItems,
      affiliationHash,
      timezone,
      isCompanySimplifiedInvoiceFormType: isCompanySimplifiedInvoiceFormType({
        basketItems,
        isOnlinePaymentReceiptEnabled,
        invoiceAddressCountryCode: values.invoiceAddressCountry?.code
      }) && values?.invoiceFormType === EInvoiceFormType.COMPANY,
      paymentProvider
    });
    const {items, ...orderDetails} = orderMutable;

    pubsub.trigger(EVENT_SUBMITTED_FORM, {
      facilityId,
      facilityName,
      numberOfTickets: items.length,
      industry,
      affiliationHash: config.affiliationHash,
      orderDetails: JSON.stringify(orderDetails),
      paymentItems: JSON.stringify(orderMutable.items),
      language: parseLanguage(locale.locale)
    });

    try {
      await onSubmit(orderMutable);

      // mutate storage and clear discount code after submit succeed
      if (discountCode) {
        dispatch(resetDiscount());
      }
      dispatch(mutateOrderStorage(values, withContactPhoneNumber));
    } catch (error) {
      if (error instanceof RequestValidationError) {
        handleApiErrors(error.errors, setError);
      } else {
        setDefaultError(locale.translate('orderCreationError'));
      }
    }
  }

  return (
    <FormProvider {...formProperties}>
      <Box component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        {
          !widgetIsBusiness && (
            <CustomerSection />
          )
        }

        {
          withContactPhoneNumber && (
            <Box sx={{my: 3}}>
              <PhoneSection
                withOnlyForRealisationHint
              />
            </Box>
          )
        }

        {
          isUsersInvoiceFormEnabled && (
            <AgreementsInvoiceSection
              isUsersInvoiceNoteEnabled={isUsersInvoiceNoteEnabled}
              isOnlinePaymentReceiptEnabled={isOnlinePaymentReceiptEnabled}
            />
          )
        }

        <Divider />

        {
          !(isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) && (
            <>
              {
                withDiscounts && <Discounts />
              }
            </>
          )
        }

        {
          paymentMethodsEnabled && (
            <>
              <PaymentMethodPicker />
              <Divider />
            </>
          )
        }

        {
          !(isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) && (
            <AgreementsSection facility={facility} />
          )
        }

        {
          widgetIsBusiness && (
            <BusinessSalePaymentInfo
              paymentType={paymentType}
              facilityName={facilityName}
              companyName={companyName}
            />
          )
        }

        <Button
          sx={{width: 1, p: theme.spacing(1, 3)}}
          type="submit"
          disabled={submitDisabled}
          variant="contained"
          color="success"
          data-testid="buttonFormSubmit"
        >
          {getCheckoutButtonLabel(isTestB, basketItems, widgetType, paymentType, shippingMethod, paymentMethod)}
        </Button>
        {
          defaultError && (
            <Alert sx={{mt: 3}} severity="error">
              <Typography>{locale.translate('orderCreationError')}</Typography>
            </Alert>
          )
        }
      </Box>
    </FormProvider>
  );
};

export default AgreementsForm;
