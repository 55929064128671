import {getFormattedPrice} from '@Utils/format';
import locale from '@Utils/locale';

export const getPriceFontSize = (isMobile: boolean, totalPrice: number, currency: string) => {
  const priceLength = `${getFormattedPrice(totalPrice)} ${locale.translate(`${currency}testB`)}`.length;

  if (isMobile) {
    if (priceLength < 10) {return '10.5px';}
    if (priceLength >= 10) {return '9.5px';}
    if (priceLength > 12) {return '8.5px';}
    if (priceLength > 13) {return '7.5px';}
    if (priceLength > 15) {return '6.5px';}
  }
  if (priceLength < 12) {return '12px';}
  if (priceLength === 12) {return '11px';}
  if (priceLength === 13) {return '10px';}
  if (priceLength > 13) {return '9px';}
  if (priceLength > 15) {return '8px';}

  return '12px';
};
