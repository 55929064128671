import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useDispatch} from 'react-redux';
import locale from '@Utils/locale';
import {findSelectedAdditionQuantity, isAdditionsLimitReached, makeAdditonsSelections} from './additionsUtils';
import Button, {BUTTON_SIZE, BUTTON_TYPE} from '@Components/common/button/Button';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Tooltip, {TOOLTIP_POSITION} from '@Components/common/tooltip/Tooltip';
import {EAlignItems, EFlexWrap, EJustifyContent} from '@Components/common/flexBox/types';

const AdditionsCounter = ({basketItem, addition, numberOfSeats}) => {
  const {id: additionId, maxNumberPerTicket, isPricePerParticipant} = addition;
  const {selections} = basketItem;
  const {additions} = selections;
  const additionQuantity = findSelectedAdditionQuantity(additions, additionId);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(additionQuantity);
  const disableReason = getPickerDisableReason();

  function getPickerDisableReason() {
    if (isPricePerParticipant && isAdditionsLimitReached(quantity, numberOfSeats, maxNumberPerTicket)) {
      return locale.translate('additionsLimitReached');
    }

    if (quantity >= maxNumberPerTicket) {
      return locale.translate('additionsLimitReached');
    }

    return false;
  }

  function handleAddToBasket() {
    if (!disableReason) {
      setQuantity(quantity + 1);
    }
  }

  function handleRemoveFromBasket() {
    setQuantity(quantity - 1);
  }

  React.useEffect(() => {
    dispatch(updateBasketItemsSelections(
      [basketItem],
      makeAdditonsSelections(additions, additionId, quantity)
    ));
  }, [quantity]);

  return (
    <div className="dl-relative">
      {
        quantity ? (
          <FlexBox
            justify={EJustifyContent.SPACE_BETWEEN}
            align={EAlignItems.CENTER}
            wrap={EFlexWrap.NOWRAP}
          >
            <Button type={BUTTON_TYPE.SINGLE_CHARACTER_SMALL} onClick={handleRemoveFromBasket}>
              −
            </Button>

            <div className="dl-py-0 dl-px-3">{quantity}</div>

            <Tooltip
              text={disableReason}
              position={TOOLTIP_POSITION.RIGHT}
            >
              <Button
                type={BUTTON_TYPE.SINGLE_CHARACTER_SMALL}
                onClick={handleAddToBasket}
                disabled={Boolean(disableReason)}
              >
                +
              </Button>
            </Tooltip>
          </FlexBox>
        ) : (
          <Tooltip text={disableReason}>
            <Button
              size={BUTTON_SIZE.SMALL}
              onClick={handleAddToBasket}
              disabled={disableReason}
              type={BUTTON_TYPE.BLUE_INVERTED}
            >
              {locale.translate('add')}
            </Button>
          </Tooltip>
        )
      }
    </div>
  );
};

export default AdditionsCounter;
