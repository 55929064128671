import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneNumberPrefixes from './PhoneNumberPrefixes';
import Header from '@Components/common/header/Header';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {ETextColor, ETextSize} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection} from '@Components/common/flexBox/types';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PhoneNumberSection = ({
  form,
  resetError,
  setFieldValue,
  customHeader,
  withOnlyForRealisationHint
}) => {
  const [showText, setTextVisibility] = useState(false);
  const {values, errors} = form;
  const {phoneNumber, phonePrefix} = values;
  const value = phoneNumber || '';

  function handleInputFocus({target}) {
    const {name} = target;

    return resetError(name);
  }

  function handleChange({target}) {
    const {name, value} = target;

    return setFieldValue(name, value);
  }

  function handleIconToggle() {
    return setTextVisibility(!showText);
  }

  function handlePhoneNumberChange({target}) {
    const {value} = target;

    const newValue = value
      .split(' ')
      .map(chunk => chunk.replace(/\D/g, ''))
      .join(' ');

    return setFieldValue('phoneNumber', newValue);
  }

  return (
    <div className="dl-mb-3">
      {
        customHeader ? (
          <div className="dl-mb-1">
            <Header>
              {
                customHeader
              }
            </Header>
          </div>
        ) : (
          <div className="">
            <Header>
              { locale.translate('contactPhoneNumber')}
            </Header>
            {
              withOnlyForRealisationHint &&
              <FlexBox align={EAlignItems.CENTER} className="dl-dotpay__contact-phone-info dl-mb-1">
                <Text size={ETextSize.SMALL} color={ETextColor.LIGHT}>
                  {locale.translate('numberOnlyForRealisation')}
                </Text>

                <IconButton aria-label="info" onClick={handleIconToggle}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </FlexBox>
            }
            {
              showText && (
                <Text size={ETextSize.SMALL} color={ETextColor.LIGHT}>
                  {locale.translate('contactNumberToClient')}
                </Text>
              )
            }
          </div>
        )
      }

      <FlexBox
        direction={EFlexDirection.ROW}
        align={EAlignItems.CENTER}
        className="dl-dotpay__content--with-prefixed-input"
      >
        <PhoneNumberPrefixes
          value={phonePrefix}
          setFieldValue={setFieldValue}
          onChange={handleChange}
        />

        <input
          data-testid="phoneNumber"
          name="phoneNumber"
          className={classNames(
            'dl-dotpay__input',
            {
              'dl-dotpay__input--invalid': errors.phoneNumber
            })
          }
          type="text"
          maxLength="15"
          onFocus={handleInputFocus}
          value={value}
          onChange={handlePhoneNumberChange}
        />
      </FlexBox>

      {
        errors.phoneNumber &&
          <Text color={ETextColor.ERROR}>{errors.phoneNumber}</Text>
      }
    </div>
  );
};

PhoneNumberSection.propTypes = {
  form: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired
};

export default PhoneNumberSection;
