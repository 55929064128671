import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, {TextFieldProps} from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import {CountryType} from '@Consts/invoiceCountries';
import {useDispatch} from 'react-redux';
import {setStorage} from '@Features/storage/storageActions';

interface IFormSelectProps {
  name: string;
  textFieldProps?: Partial<Omit<TextFieldProps, 'label' | 'error' | 'helperText'>>;
  label: string;
  items: CountryType[];
  saveInStorage?: boolean
}

const FormCountryInput = ({
  textFieldProps,
  name,
  label,
  items,
  saveInStorage
}: IFormSelectProps) => {
  const {control} = useFormContext();
  const isOptionEqualToValue = (option: CountryType, value: CountryType) => option.code === value.code;
  const dispatch = useDispatch();

  const handleSaveToStorage = (selectedCountry: CountryType) => {
    if (saveInStorage) {
      dispatch(setStorage(name, selectedCountry, true));
    }
  };

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref, onBlur, ...field}, fieldState: {invalid, error}}) => (
        <Autocomplete
          ListboxProps={{style: {maxHeight: 300}}}
          options={items.sort((a, b) => a.label.localeCompare(b.label))}
          autoHighlight
          disableClearable
          value={value}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={option => option.label}
          onChange={(_, option) => {handleSaveToStorage(option); onChange(option);}}
          renderOption={(props, option) => (
            <Box
              {...props}
              component="li"
              sx={{'& > img': {mr: 2, flexShrink: 0}}}
            >
              <img
                loading="lazy"
                width="24"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt={option.label}
              />
              {option.label}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...field}
              {...textFieldProps}
              {...params}
              label={label}
              inputProps={{
                ...textFieldProps?.inputProps,
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
              InputProps={{
                ...textFieldProps?.InputProps,
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        border: '1px solid',
                        borderColor: 'grey.200',
                        borderRadius: '4px',
                        overflow: 'hidden'
                      }}
                    >
                      <img
                        loading="lazy"
                        width="24"
                        srcSet={`https://flagcdn.com/w40/${value.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${value.code.toLowerCase()}.png`}
                        alt={value.label}
                      />
                    </Box>
                  </InputAdornment>
                )
              }}
              error={invalid}
              helperText={error?.message ?? ''}
            />
          )
          }
        />
      )}
    />
  );
};

export default FormCountryInput;
