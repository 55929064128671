import React from 'react';
import locale from '@Utils/locale';
import {string} from 'yup';
import {POSTAL_CODE_MASK, POSTAL_CODE_PATTERN} from '../patterns';
import FormInput from '@Components/formComponents/FormInput';

export const FIELD_NAME = 'invoiceAddressPostalCode';

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .length(6, locale.translate('invalidPostalCode'))
  .matches(POSTAL_CODE_PATTERN, {message: locale.translate('invalidPostalCode')});

interface Props {
  name?: string,
  saveInStorage?: boolean
}

const InvoicePostalCodeField = ({name = FIELD_NAME, saveInStorage}: Props) => (
  <FormInput
    name={name}
    saveInStorage={saveInStorage}
    componentProps={{
      label: locale.translate('postalCode'),
      sx: {width: 1},
      inputMode: 'numeric'
    }}
    mask={POSTAL_CODE_MASK}
  />
);

export default InvoicePostalCodeField;
