import {IShippimngMethodsStore} from 'src/js/store/types';
import {EShippingMethodActionsTypes, TShippingMethodsActions} from './types';

const initialState: IShippimngMethodsStore = {items: [], status: undefined};

const shippingMehtodsReducer = (state = initialState, action: TShippingMethodsActions) => {
  switch (action.type) {
    case EShippingMethodActionsTypes.SET_SHIPPING_METHODS:
      return {...state, items: action.payload};
    case EShippingMethodActionsTypes.SET_SHIPPING_METHODS_STATUS:
      return {...state, status: action.payload};
    case EShippingMethodActionsTypes.CLEAR_SHIPPING_METHODS:
      return {items: [], status: undefined};
    default:
      return state;
  }
};

export default shippingMehtodsReducer;
