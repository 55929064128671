import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {isShippingMethodOptional, isShippingMethodRequired} from './requirements';

export const createShippingMethodsViews = (basketItems, shippingMethodsView, numberOfShippingMethods) =>
  basketItems.reduce((shippingMethodsView, basketItem) => {
    if (isShippingMethodRequired(basketItem) || (isShippingMethodOptional(basketItem) && numberOfShippingMethods)) {
      if (Object.keys(shippingMethodsView).length > 0) {
        shippingMethodsView.items.push(basketItem);
      } else {
        shippingMethodsView = {
          type: ECheckoutViewsTypes.SHIPPING_METHODS,
          items: [basketItem]
        };
      }
    }
    return shippingMethodsView;
  }, shippingMethodsView);

