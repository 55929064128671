import {
  BUSINESS,
  BUSINESS_FREE,
  BUSINESS_PREVIEW,
  CLIENT,
  CLIENT_FREE,
  CLIENT_PREVIEW,
  INTERNAL_SALE,
  INTERNAL_SALE_PREVIEW,
  SKIPPED_PAYMENT
} from '@Consts/order';
import {getQuestionById} from './activity/activity';
import {getApiDates, getBasketValue} from './basket';
import {getPhoneNumberFormatted} from './format';
import locale from './locale';
import {QUESTION_TYPE} from './question';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from './widgetType';
import {getInternalInvoiceDataMutable, getOnlineInvoiceDataMutable} from './order/orderInvoice';
import {getBlikCodeMutable, getPaymentCompleteUntil, getPaymentMethodMutable} from './orderUtils';
import {getBasketItemPrice} from './basketItemPrice';
import {getFormattedDateWithTime} from './dates/timezoneDates';

export const isOrderInternal = orderType =>
  orderType === INTERNAL_SALE ||
  orderType === INTERNAL_SALE_PREVIEW;

export const isOrderPreview = orderType =>
  orderType === CLIENT_PREVIEW ||
  orderType === BUSINESS_PREVIEW ||
  orderType === INTERNAL_SALE_PREVIEW;

export const getDiscountCodeMutable = ({widgetType, withDiscounts, discountCode}) => {
  if (isWidgetOnlineClient(widgetType) && withDiscounts && discountCode) {
    return discountCode;
  }

  return null;
};

export const getContinueUrlMutable = (parentUrl, paymentProvider) => {
  if (parentUrl) {
    const url = parentUrl.split('?')[0];

    if (paymentProvider === 'dev') {
      return url;
    }

    if (!url.includes('localhost:8080')) {
      return url;
    }

    return null;
  }

  return null;
};

export const getPhoneNumberMutable = ({phoneNumber, phonePrefix}) => {
  if (phoneNumber && phonePrefix) {
    return getPhoneNumberFormatted(phoneNumber, phonePrefix);
  }

  return null;
};

export const getContactDataMutable = config => {
  const {
    email,
    firstName,
    lastName,
    widgetType,
    withContactPhoneNumber
  } = config;
  const formattedPhone = getPhoneNumberMutable(config);

  if (isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) {
    if (formattedPhone) {
      return {...(formattedPhone && withContactPhoneNumber) && {phoneNumber: formattedPhone}};
    }

    return null;
  }

  return {
    ...email && {email},
    ...firstName && {firstName},
    ...lastName && {lastName},
    ...formattedPhone && {phoneNumber: formattedPhone}
  };
};

export const getAnswersMutable = (questions, answers) =>
  answers.map(itemAnswer => {
    const {questionId, answer} = itemAnswer;
    const question = getQuestionById(questions, questionId);

    if (!answer) {
      return null;
    }

    if (question?.type === QUESTION_TYPE.LICENSE_PLATE) {
      return {
        questionId,
        answer: answer.split(' ').join('')
      };
    }

    if (question?.type === QUESTION_TYPE.ADDRESS) {
      const street = answer?.street || '';
      const city = answer?.city || '';
      const postalCode = answer?.postalCode || '';

      if (!street && !city && !postalCode) {
        return null;
      }

      return {
        questionId,
        answer: `${street}\n${city} ${postalCode}`
      };
    }

    return {questionId, answer};
  });

export const getItemDatesMutable = basketItem => {
  const {selections, activity} = basketItem;
  const {admissionPolicy} = activity;
  const {validSinceTime, validUntilTime} = admissionPolicy;
  const {validSince, validUntil} = selections;

  if (validSince && validUntil) {
    return {
      validSince: getFormattedDateWithTime(validSince, validSinceTime),
      validUntil: getFormattedDateWithTime(validUntil, validUntilTime)
    };
  }

  return getApiDates(basketItem);
};

export const getItemMutable = (basketItem, isOnlineClient) => {
  const {
    activityId,
    variantId,
    activity,
    selections,
    isUpsell
  } = basketItem;
  const {
    numberOfSeats,
    numberOfParticipants,
    answers,
    additions
  } = selections;
  const {questions} = activity;
  const answersMutable = getAnswersMutable(questions, answers).filter(answer => answer);
  const datesMutable = getItemDatesMutable(basketItem);

  return {
    activityId,
    variantId,
    ...isOnlineClient && {isUpselled: !!isUpsell},
    ...numberOfParticipants && {numberOfParticipants},
    ...numberOfSeats && {numberOfParticipants: numberOfSeats},
    ...additions?.length && {additions},
    ...answersMutable?.length && {answers: answersMutable},
    ...datesMutable && {...datesMutable}
  };
};

export const getShippingMethodMutable = shippingMethod => {
  if (!shippingMethod || !Object.keys(shippingMethod)?.length) {
    return null;
  }

  const {id, parcelLockerCode} = shippingMethod;
  const shippingAddress =
  shippingMethod?.parcelLockerAddress ? 'parcelLockerAddress' :
    shippingMethod?.courierShippingAddress ? 'courierShippingAddress' :
      null;

  if (shippingAddress) {
    const {phoneNumber, phonePrefix, city, postalCode, street, firstName, lastName} = shippingMethod?.[shippingAddress];
    const phoneNumberFormatted = getPhoneNumberFormatted(phoneNumber, phonePrefix);

    if (parcelLockerCode) {
      return {
        id,
        parcelLockerCode,
        [shippingAddress]: {
          street,
          city,
          postalCode,
          phoneNumber: phoneNumberFormatted
        }
      };
    }

    return {
      id,
      [shippingAddress]: {
        firstName,
        lastName,
        street,
        city,
        postalCode,
        phoneNumber: phoneNumberFormatted
      }
    };
  }

  return {
    id
  };
};

/**
 * Returns order data
 * @param {OrderData} data - order data
 * @returns {OrderMutable} prepared order
 */
export const getOrderMutable = data => {
  const {
    parentUrl,
    basketItems,
    timezone,
    affiliationHash,
    widgetType,
    shippingMethod,
    isAgreeMarketingConsents,
    paymentProvider,
    termsOfService,
    blikCode,
    paymentMethod,
    isPaymentOnline,
    paymentCompleteUntil
  } = data;

  const onlineClient = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const internalClient = isWidgetInternal(widgetType) || isWidgetInternalPreview(widgetType);
  const {currency} = getBasketItemPrice(basketItems[0]);
  const basketValue = getBasketValue(basketItems, true);
  const shippingPrice = shippingMethod?.price?.amount || 0;
  const totalPrice = parseFloat((basketValue + shippingPrice).toFixed(2));
  const continueUrl = getContinueUrlMutable(parentUrl, paymentProvider);
  const discountCodeMutable = getDiscountCodeMutable(data);
  const shippingMethodMutable = getShippingMethodMutable(shippingMethod);
  const onlineClientInvoiceData = onlineClient ? getOnlineInvoiceDataMutable(data) : null;
  const internalClientInvoiceData = internalClient ? getInternalInvoiceDataMutable(data) : null;
  const blikCodeMutable = getBlikCodeMutable(blikCode, paymentMethod);
  const paymentMethodMutable = getPaymentMethodMutable(widgetType, basketValue, paymentMethod, isPaymentOnline);
  const paymentCompleteUntilFromatted = getPaymentCompleteUntil(timezone, isPaymentOnline, paymentCompleteUntil);

  return {
    ...blikCodeMutable && {blikCode: blikCodeMutable},
    ...paymentMethodMutable && {paymentMethod: paymentMethodMutable},
    ...shippingMethodMutable && {shippingMethod: shippingMethodMutable},
    ...continueUrl && {continueUrl},
    ...discountCodeMutable && {discountCode: discountCodeMutable},
    ...onlineClientInvoiceData && {...onlineClientInvoiceData},
    ...internalClientInvoiceData && {...internalClientInvoiceData},
    ...getContactDataMutable(data),
    ...paymentCompleteUntilFromatted && {paymentCompleteUntil: paymentCompleteUntilFromatted},
    ...affiliationHash && {affiliationHash},
    items: basketItems.map(basketItem => getItemMutable(basketItem, onlineClient)),
    locale: locale.language,
    ...(isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType)) && {acceptTerms: termsOfService},
    totalPrice: {
      amount: totalPrice,
      currency
    },
    ...isAgreeMarketingConsents !== undefined && {isAgreeMarketingConsents}
  };
};

export const getOrderType = (order, widgetType) => {
  if (order) {
    const {state, price} = order;

    if (isWidgetOnlineClient(widgetType)) {
      if (price <= 0) {
        return CLIENT_FREE;
      }

      return CLIENT;
    }

    if (isWidgetBusiness(widgetType)) {
      if (state === 'skipped') {
        return SKIPPED_PAYMENT;
      }

      if (price <= 0) {
        return BUSINESS_FREE;
      }

      return BUSINESS;
    }

    if (isWidgetInternal(widgetType)) {
      return INTERNAL_SALE;
    }
  }

  if (isWidgetInternalPreview(widgetType)) {
    return INTERNAL_SALE_PREVIEW;
  }

  if (isWidgetOnlineClientPreview(widgetType)) {
    return CLIENT_PREVIEW;
  }

  if (isWidgetBusinessPreview(widgetType)) {
    return BUSINESS_PREVIEW;
  }

  return null;
};
