import React from 'react';
import HighlightedSection from './HighlightedSection';
import locale from '@Utils/locale';
import Typography from '@mui/material/Typography';
import BlikField from './formFields/BlikField';
import Box from '@mui/material/Box';

const BlikSection = () => (
  <>
    <Box sx={{mb: 2}}>
      <HighlightedSection>
        <Typography variant="body2">
          {locale.translate('typeGeneratedBlikCode')}
        </Typography>
      </HighlightedSection>
    </Box>
    <BlikField />
  </>
);

export default BlikSection;
