import React from 'react';
import {useFormContext} from 'react-hook-form';
import {CountryType, InvoiceAddressCountry} from '@Consts/invoiceCountries';
import CompanyVatIdField from './formFields/CompanyVatIdField';
import CompanyNipField from './formFields/CompanyNipField';

export const FIELD_NAME = 'invoiceCompanyNip';

const InvoiceNipSection = ({fieldName = FIELD_NAME, saveInStorage = false}) => {
  const {watch} = useFormContext();
  const country: CountryType = watch('invoiceAddressCountry');

  return country.code !== InvoiceAddressCountry.PL ?
    <CompanyVatIdField name={fieldName} saveInStorage={saveInStorage} /> :
    <CompanyNipField name={fieldName} saveInStorage={saveInStorage} />;
};

export default InvoiceNipSection;
