import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import QuestionText from './QuestionText';
import QuestionChoices from './QuestionChoices';
import QuestionInput from './QuestionInput';
import QuestionLicensePlate from './QuestionLicensePlate';
import QuestionAddress from './QuestionAddress';
import locale from '@Utils/locale';
import {
  isQuestionInput,
  isQuestionLicensePlate,
  isQuestionSingleChoice,
  isQuestionText,
  isQuestionAddress
} from '@Utils/question';
import {getTextWithExtractedLinks} from '@Utils/text';
import classNames from 'classnames';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';

const QuestionFormContent = ({
  question,
  value,
  description,
  invalid,
  setInvalid,
  setAnswer,
  onClickNext,
  viewId,
  fullCheckoutProgress,
  onPageClose
}) => {
  const {
    text,
    comment,
    choices,
    isRequired,
    type
  } = question;

  return (
    <div className="dl-dotpay__content">
      <div className="dl-dotpay__content dl-dotpay__content--with-icon">
        <div className="dl-text--overflow-wrap-anywhere js-selected-entry-description dl-text--light">
          {locale.translate('forChosen')} {description}
        </div>
        <div
          className={classNames(
            {
              'dl-dotpay--clickable dl-icon dl-icon--margin-correction dl-icon--arrow-up': fullCheckoutProgress
            })
          }
          onClick={onPageClose}
        />
      </div>

      <div>
        <div className="dl-dotpay__content">
          <div className="dl-header">
            {text}
          </div>

          {
            !isRequired && (
              <div className="dl-text--small dl-text--emphasized">
                ({locale.translate('optionally')})
              </div>
            )
          }
        </div>

        {
          comment && (
            <div className="dl-text--overflow-wrap-anywhere">
              {getTextWithExtractedLinks(comment)}
            </div>
          )
        }
      </div>

      <div className="dl-dotpay__content" key={viewId}>
        {
          isQuestionSingleChoice(type) && (
            <QuestionChoices
              choices={choices}
              value={value}
              isRequired={isRequired}
              invalid={invalid}
              setAnswer={setAnswer}
              setInvalid={setInvalid}
            />
          )
        }

        {
          isQuestionInput(type) && (
            <QuestionInput
              choices={choices}
              value={value}
              isRequired={isRequired}
              invalid={invalid}
              setAnswer={setAnswer}
              setInvalid={setInvalid}
            />
          )
        }

        {
          isQuestionLicensePlate(type) && (
            <QuestionLicensePlate
              choices={choices}
              value={value}
              isRequired={isRequired}
              invalid={invalid}
              setAnswer={setAnswer}
              setInvalid={setInvalid}
            />
          )
        }

        {
          (isQuestionText(type) || !type) && (
            <QuestionText
              value={value}
              isRequired={isRequired}
              invalid={invalid}
              setAnswer={setAnswer}
              setInvalid={setInvalid}
            />
          )
        }

        {
          isQuestionAddress(type) && (
            <QuestionAddress
              value={value}
              isRequired={isRequired}
              invalid={invalid}
              setAnswer={setAnswer}
              setInvalid={setInvalid}
            />
          )
        }
      </div>

      <div className="dl-dotpay__content">
        <Button
          onClick={onClickNext}
          variant="contained"
          size="large"
          color="success"
          fullWidth
          endIcon={<ChevronRightIcon />}
        >
          {locale.translate('next')}
        </Button>
      </div>
    </div>
  );
};

export default QuestionFormContent;
