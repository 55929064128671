import {useMediaQuery, useTheme} from '@mui/material';
import {useScroll} from './useScroll';

export const useHideDescription = (element: Element | null) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {scrollToElementCenter} = useScroll();

  const scrollToParent = (describeTextHeight: number, mobileThreshold = 200, desktopThreshold = 400) => {
    if (describeTextHeight > desktopThreshold && !isMobile) {
      scrollToElementCenter(element);
    } else if (describeTextHeight > mobileThreshold && isMobile) {
      scrollToElementCenter(element);
    }
  };

  return {scrollToParent};
};
