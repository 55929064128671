import {getAdmissionDates, getDayOffers} from '@Features/activityAdmissionDates/activityAdmissionDatesSelectors';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCurrency,
  getDatesGroupedByDay
} from '@Components/checkoutPages/admissionRealization/admissionRealizationUtils';
import {isVariantPriceCommon} from '@Utils/activity/variant';
import {IBasketItem} from '@Consts/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {ECheckoutViewsTypes} from '@Utils/checkoutViews/checkoutViewsConsts';
import {useEffect, useState} from 'react';
import {DayOffersProcessed, IFetchAdmissionDatesProps} from '@Features/activityAdmissionDates/types';
import {getProcessedDayOffers} from '@Features/activityAdmissionDates/activityAdmissionDatesUtils';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {datesCleanup, fetchAdmissionDates} from '@Features/activityAdmissionDates/activityAdmissionDatesActions';
import {getNumberOfAvailableDates} from '@Components/checkoutPages/admissionRealization/admissionDatesUtils';

export const useAdmissionDates = (
  items: IBasketItem[],
  selectedDates: IDatesGroupedByDay[] | null,
  canShowItemsLeft: boolean,
  type: ECheckoutViewsTypes,
  selectedDayOffer: DayOffersProcessed | null
) => {
  const dispatch = useDispatch();
  const datesExtended = useSelector(getAdmissionDates);
  const dayOffers = useSelector(getDayOffers);
  const timezone = useSelector(getFacilityTimezone);
  const [admissionDates, setAdmissionDates] = useState<IDatesGroupedByDay[] | null>(null);
  const [dayOfferState, setDayOfferState] = useState<DayOffersProcessed[] | null>(null);
  const isCommonPrice = items.every(({variant}) => isVariantPriceCommon(variant));
  const showAvailableSeatsForDay = type === ECheckoutViewsTypes.DATES && canShowItemsLeft;

  useEffect(() => {
    if (datesExtended) {
      const datesGroupedByDay = getDatesGroupedByDay(
        datesExtended,
        selectedDates,
        isCommonPrice,
        getCurrency(datesExtended),
        canShowItemsLeft,
        type,
        timezone
      );

      setAdmissionDates(datesGroupedByDay);
    } else {
      setAdmissionDates(null);
    }

  }, [datesExtended, selectedDates]);

  useEffect(() => {
    if (dayOffers && timezone) {
      const dayOffersProcessed = getProcessedDayOffers(dayOffers, timezone, items, selectedDayOffer);

      setDayOfferState(dayOffersProcessed);
    } else {
      setDayOfferState(null);
    }
  }, [dayOffers, selectedDayOffer]);

  useEffect(() => () => {
    dispatch(datesCleanup());
  }, []);

  const fetchDayOfferDateTimes = async (
    fetchProps: IFetchAdmissionDatesProps
  ) => {
    await dispatch(fetchAdmissionDates(fetchProps));
  };

  const onFetchAdmissionDates = () => {
    setAdmissionDates(null);
    setDayOfferState(null);
  };

  return {
    dates: admissionDates,
    numberOfAvailableDays: getNumberOfAvailableDates(admissionDates, dayOfferState),
    onFetchAdmissionDates,
    dayOffers: dayOfferState,
    fetchDayOfferDateTimes,
    showCalendarPrices: !isCommonPrice,
    showAvailableSeatsForDay
  };
};
