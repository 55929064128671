import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import locale from '@Utils/locale';
import Ticket from './Ticket';
import {useSummaryData} from '../../../hooks/useSummaryData';
import TicketBottomContent from './TicketBottomContent';

type TTicketsListProps = {
  onAppleWalletClick: () => void
}

const TicketsList = ({onAppleWalletClick}: TTicketsListProps) => {
  const {onlineOrderTickets, previewTickets, isPreviewMode} = useSummaryData();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const tickets = onlineOrderTickets || previewTickets;

  if (!tickets) {
    return null;
  }

  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      sx={{width: 1}}
      TransitionProps={{
        timeout: 0,
        unmountOnExit: true
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        {
          expanded ?
            <Typography>{locale.translate('hideTickets')}</Typography> :
            <Typography>{locale.translate('showTickets')}</Typography>
        }
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          {
            tickets?.map(ticket => (
              <Box
                key={ticket.ticketId}
              >
                <Ticket
                  topContent={
                    <>
                      <Typography sx={{fontWeight: 600, mb: 1.5}}>
                        {ticket.ticketFormattedName}
                      </Typography>
                      <Typography variant="caption">
                        {ticket.ticketFormattedId}
                      </Typography>
                    </>
                  }
                  bottomContent={
                    <TicketBottomContent
                      ticket={ticket}
                      isPreviewMode={isPreviewMode}
                      onAppleWalletClick={onAppleWalletClick}
                    />
                  }
                  topContentStyles={{p: 2}}
                  bottomContentStyles={{justifyContent: 'end', p: 2}}
                />
              </Box>
            ))
          }
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default TicketsList;
