import React from 'react';
import Paper from '@mui/material/Paper';

const InternalOrderCard = ({children}: React.PropsWithChildren<{}>) => (
  <Paper
    elevation={0}
    sx={{p: 3}}
  >
    {children}
  </Paper>
);

export default InternalOrderCard;
