/* eslint-disable prefer-rest-params */
/* eslint-disable babel/no-invalid-this */

export const isArray = value => Array.isArray(value);

export const isObject = value => value && typeof value === 'object' && value.constructor === Object;

export const createRange = (rangeMin, rangeMax) => {
  const min = Math.abs(rangeMin);
  const max = Math.abs(rangeMax);

  if (min > max) {
    return [];
  }

  return Array(max - min + 1).fill().map((number, index) => min + index);
};

export const constructObjectBasedOnPath = (path, value) => {
  const object = {};
  const subPath = [...path];
  const segment = subPath.shift();
  const nextSegment = subPath[0];

  if (nextSegment) {
    const parsedNextSegment = parseInt(nextSegment, 10);

    if (isNaN(parsedNextSegment)) {
      object[segment] = constructObjectBasedOnPath(subPath, value);
    } else {
      subPath.shift();
      object[segment] = [];
      object[segment][parsedNextSegment] = constructObjectBasedOnPath(subPath, value);
    }
  } else {
    object[segment] = value;
  }

  return object;
};

export const deepMerge = (baseObject, nextObject) =>
  Object.keys(nextObject).reduce((mergedObject, key) => {
    const currentObject = mergedObject[key];

    if (currentObject) {
      if (isObject(currentObject)) {
        if (isObject(nextObject[key])) {
          mergedObject[key] = {...currentObject, ...deepMerge(currentObject, nextObject[key])};
        } else {
          mergedObject[key] = currentObject;
        }
      } else if (isArray(currentObject)) {
        mergedObject[key] = [...currentObject, ...nextObject[key]];
      } else {
        mergedObject[key] = nextObject[key];
      }
    } else {
      mergedObject[key] = nextObject[key];
    }

    return mergedObject;
  }, {...baseObject});

export const findByOnlineGroupId = (arrayOfObj, onlineGroupId) =>
  arrayOfObj?.find(value => value?.onlineGroupId === onlineGroupId);

export const copyToClipBoard = text => navigator.clipboard.writeText(text);

export function debounce(func, delay) {
  let timeoutId;

  return function() {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(function() {
      func.apply(context, args);
    }, delay);
  };
}
