import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '../common/flexBox/FlexBox';
import ActivityContent, {SPACING_TYPE} from '../activities/activity/ActivityContent';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Text from '../common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import {EFlexDirection} from '@Components/common/flexBox/types';

const ActivityPreviewAdditionsTop = ({additions, numberOfSeats}) => (
  <FlexBox direction={EFlexDirection.COLUMN}>
    {
      additions.map(({quantity, name, isPricePerParticipant}, index) => {
        const numberOfSeatsLabel = isPricePerParticipant ? `${numberOfSeats} os. x` : null;

        return (
          <ActivityContent spacing={SPACING_TYPE.TOP} key={index}>
            <AddOutlinedIcon sx={{color: 'background.text'}} />
            <Text color={ETextColor.LIGHT} wrap>
              {quantity}&nbsp;x&nbsp;{numberOfSeatsLabel}{numberOfSeatsLabel && <span>&nbsp;</span>}{name}
            </Text>
          </ActivityContent>
        );
      })
    }
  </FlexBox>
);

export default ActivityPreviewAdditionsTop;
