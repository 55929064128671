import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import Button from '@mui/material/Button';
import {useBackToActivities} from '../../hooks/useBackToActivities';

const OrderMore = () => {
  const {handleClickBackToActivities} = useBackToActivities();

  return (
    <Stack spacing={6} sx={{alignItems: 'center', mt: 6, mb: 4, px: {xs: 2, md: 3}}}>
      <Typography variant="h5" sx={{fontWeight: 500}}>
        {locale.translate('orderSomethingElse')}
      </Typography>
      <Button
        onClick={handleClickBackToActivities}
        sx={{width: {xs: 1, sm: 'auto'}}}
        size="large"
        color="inherit"
        variant="outlined"
      >
        {locale.translate('buyAnotherActivities')}
      </Button>
    </Stack>
  );
};

export default OrderMore;
