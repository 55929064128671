import locale from '@Utils/locale';
import {
  ERROR_TYPE_MISSING_FIELD,
  ERROR_TYPE_BLANK_FIELD,
  ERROR_TYPE_INVALID_TYPE,
  ERROR_TYPE_INVALID_VALUE,
  ERROR_TYPE_TOO_SHORT,
  ERROR_TYPE_TOO_LONG,
  ERROR_TYPE_INVALID_EMAIL,
  ERROR_TYPE_INVALID_EMAIL_FORMAT,
  ERROR_TYPE_INVALID_PHONE_FORMAT,
  ERROR_TYPE_TOO_HIGH_TOTAL_PRICE,
  ERROR_TYPE_INVALID_NIP_CHECKSUM
} from '@Consts/apiError';
import {constructObjectBasedOnPath, deepMerge} from '@Utils/utils';
import {EInvoiceFormType} from '@Components/invoice/types';
import {FIELD_NAME as COMPANY_NIP_FIELD_NAME} from '@Components/invoice/formFields/CompanyNipField';

export const getErrorMessage = errorType => {
  switch (errorType) {
    case ERROR_TYPE_MISSING_FIELD:
      return locale.translate('missingFieldError');
    case ERROR_TYPE_BLANK_FIELD:
      return locale.translate('requiredField');
    case ERROR_TYPE_INVALID_TYPE:
      return locale.translate('invalidTypeError');
    case ERROR_TYPE_INVALID_VALUE:
      return locale.translate('invalidTypeError');
    case ERROR_TYPE_TOO_SHORT:
      return locale.translate('toShortError');
    case ERROR_TYPE_TOO_LONG:
      return locale.translate('toLongError');
    case ERROR_TYPE_INVALID_EMAIL:
      return locale.translate('invalidEmail');
    case ERROR_TYPE_INVALID_EMAIL_FORMAT:
      return locale.translate('invalidEmail');
    case ERROR_TYPE_INVALID_PHONE_FORMAT:
      return locale.translate('invalidPhone');
    case ERROR_TYPE_TOO_HIGH_TOTAL_PRICE:
      return locale.translate('priceTooHigh');
    case ERROR_TYPE_INVALID_NIP_CHECKSUM:
      return locale.translate('invalidNip');
    default:
      return `${locale.translate('unknownError')} (${errorType})`;
  }
};

export const aggregateValidationErrors = errors =>
  errors.reduce((aggregated, error) => {
    const path = error.path;
    const message = getErrorMessage(error.error);
    const errorObject = constructObjectBasedOnPath(path, message);

    return deepMerge(aggregated, errorObject);
  }, {});

export const handleApiErrors = (apiErrors, errorSetFunction, invoiceFormType) => {
  Object.entries(apiErrors).forEach(([name, message]) => {
    if (invoiceFormType === EInvoiceFormType.COMPANY_SIMPLIFIED && name === COMPANY_NIP_FIELD_NAME) {
      return errorSetFunction(COMPANY_NIP_FIELD_NAME, {type: 'manual', message: locale.translate('isNotPolishNip')});
    }
    errorSetFunction(name, {type: 'manual', message});
  });
};
