import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import './text.scss';
import {ETagType, ITextProps} from './types';

const Text = ({
  children,
  size,
  color,
  transform,
  modifier,
  onClick,
  className,
  weight,
  align,
  display,
  wrap,
  decoration,
  leading,
  tagType: Wrapper = ETagType.SPAN,
  testId
}: ITextProps) => {
  const textClassName = classNames(
    className,
    'dl-text',
    `dl-text--${Wrapper}`,
    {
      [`dl-text--${size}`]: size,
      [`dl-text--${color}`]: color,
      [`dl-text--${transform}`]: transform,
      [`dl-text--${modifier}`]: modifier,
      [`dl-text--${display}`]: display,
      [`dl-text--${weight}`]: weight,
      [`dl-text--${align}`]: align,
      [`dl-text--${leading}`]: leading,
      [`dl-text--${decoration}`]: decoration,
      'dl-text--overflow-wrap-anywhere': wrap
    }
  );

  return (
    <Wrapper className={textClassName} onClick={onClick} data-testid={testId}>
      {children}
    </Wrapper>
  );
};

export default Text;
