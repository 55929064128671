import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {ETagSize, ETagState, ETagType, ITagProps} from './types';
import classNames from 'classnames';
import './dlTag.scss';
import {isObjectFilled} from '@Utils/validate';
import FlexBox from '../flexBox/FlexBox';
import {EAlignItems} from '../flexBox/types';

const DlTag = ({
  children,
  size,
  color,
  type,
  state,
  className,
  customColors,
  withIconRight,
  withIconLeft,
  onClick
}: ITagProps) => {
  const {textColor, backgroundColor} = {...customColors};
  const style = isObjectFilled(customColors) ? {
    color: textColor,
    background: backgroundColor
  } : null;

  const tagClassName = classNames(
    className,
    'dl-tag',
    {
      'dl-tag__icon-right': withIconRight,
      'dl-tag__icon-left': withIconLeft,
      'dl-tag__clickable': typeof onClick === 'function',
      [`dl-tag--${size}`]: size,
      [`dl-tag__${type}--${state}`]: type && state,
      [`dl-tag__${type}--${state}--${color}`]: type && state && color
    }
  );

  if (
    state === ETagState.ACTIVED &&
    style &&
    style?.color &&
    style?.background
  ) {
    return (
      <FlexBox
        dl-test="FlexBox-with-style"
        align={EAlignItems.CENTER}
        className={tagClassName}
        onClick={onClick}
        style={{
          color: style?.color,
          background: style?.background
        }}
      >
        {children}
      </FlexBox>
    );
  }

  return (
    <FlexBox
      dl-test="FlexBox-without-style"
      align={EAlignItems.CENTER}
      className={tagClassName}
      onClick={onClick}
    >
      {children}
    </FlexBox>
  );
};

const defaultProps = {
  state: ETagState.ENABLED,
  size: ETagSize.MEDIUM,
  type: ETagType.CONTAINED
};

DlTag.defaultProps = defaultProps;

export default DlTag;
