import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {useDispatch} from 'react-redux';
import locale from '@Utils/locale';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Counter from '@Components/common/counter/Counter';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import {isActivitySinglePickDate, isActivitySinglePickDatetime} from '@Utils/activity/activity';
import {COLON_HOUR_FORMAT, DOT_DAY_FORMAT_REVERSED} from '@Utils/dayjs/const';
import dayjs from 'dayjs';
import {EAlignItems, EFlexWrap, EJustifyContent} from '@Components/common/flexBox/types';

const ParticipantsCounter = ({basketItem, sumOfAddedSeats, handleIsCounterError}) => {
  const dispatch = useDispatch();
  const {
    activity: {passType},
    variant: {maxNumberOfSeats, minNumberOfSeats},
    selections: {numberOfSeats, time, dates}
  } = basketItem;
  const [error, setError] = React.useState(null);
  const isSinglePickDate = isActivitySinglePickDate(passType);
  const isSinglePickDateTime = isActivitySinglePickDatetime(passType);
  const avaliableSeatsFromDates = dates && dates.length > 0 ? dates[0].metaData.availableSeats : null;

  const maxSeats = () => {
    if (
      isSinglePickDate &&
      avaliableSeatsFromDates &&
      avaliableSeatsFromDates <= sumOfAddedSeats
    ) {
      return numberOfSeats;
    }

    if (
      isSinglePickDateTime &&
      time?.numberOfSeats <= sumOfAddedSeats
    ) {
      return numberOfSeats;
    }

    return maxNumberOfSeats;
  };

  React.useEffect(() => {
    handleIsCounterError(error !== null);
  }, [error]);

  function handleChange(value) {
    setError(null);

    dispatch(updateBasketItemsSelections(
      [basketItem],
      {
        numberOfSeats: value,
        additions: []
      }
    ));
  }

  function handleFocus() {
    return setError(null);
  }

  function handleBlur(value) {
    handleChange(value);

    if (value === null) {
      return setError(locale.translate('requiredField'));
    }

    if (value < minNumberOfSeats) {
      return setError(locale.translate('minNumberOfParticipants', {number: minNumberOfSeats}));
    }

    if (
      isSinglePickDate &&
      avaliableSeatsFromDates &&
      (avaliableSeatsFromDates < (sumOfAddedSeats - (numberOfSeats - value)))
    ) {
      return setError(
        locale.translate('hasNoSeatsForGivenDate', {date: dayjs(dates[0].date).format(DOT_DAY_FORMAT_REVERSED)})
      );
    }

    if (isSinglePickDateTime && (time.numberOfSeats < (sumOfAddedSeats - (numberOfSeats - value)))) {
      return setError(locale.translate('isOutOfLimit', {time: dayjs(time.date).format(COLON_HOUR_FORMAT)}));
    }

    if (value > maxNumberOfSeats) {
      return setError(locale.translate('maxNumberOfParticipants', {number: maxNumberOfSeats}));
    }
  }

  return (
    <div>
      <FlexBox
        justify={EJustifyContent.FLEX_END}
        align={EAlignItems.CENTER}
        wrap={EFlexWrap.NOWRAP}
      >
        <Counter
          name="numberOfParticipants"
          min={minNumberOfSeats}
          max={maxSeats()}
          value={numberOfSeats}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={error}
        />
      </FlexBox>
      {
        error &&
          <Text color={ETextColor.ERROR}>{error}</Text>
      }
    </div>
  );
};

export default ParticipantsCounter;
