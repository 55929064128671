import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '@mui/material/Button';
import pubsub from '@Utils/pubsub';
import {EVENT_CLICK_ON_ADD_TO_APPLE_WALLET, EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import WalletPass from './WalletPass';
import TicketsList from './TicketsList';
import {useSummaryData} from '../../../hooks/useSummaryData';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import {getValidWalletPassUrls} from './utils';

const TicketsOnlineContent = () => {
  const {
    widgetType,
    onlineOrderTickets,
    latestOrderStorageData,
    isPreviewMode,
    documentAddress,
    previewTickets,
    isWigetOnlineType
  } = useSummaryData();

  if (!onlineOrderTickets && !previewTickets) {
    return null;
  }

  const walletPassUrls = getValidWalletPassUrls(onlineOrderTickets, isPreviewMode);

  const handleDownloadTickets = () => {
    if (documentAddress) {
      pubsub.trigger(EVENT_CLICK_ON_DOWNLOAD_TICKET_BUTTON, {
        language: parseLanguage(locale.locale),
        isFirstLoading: !latestOrderStorageData.isEventsSent
      });
      window.open(documentAddress);
      window.focus();
    }
  };

  function getWidgetType() {
    const typeSplitted = widgetType && widgetType.split('_');

    return typeSplitted && typeSplitted[0].toLowerCase();
  }

  function handleOnAppleWalletClick() {
    pubsub.trigger(EVENT_CLICK_ON_ADD_TO_APPLE_WALLET, {
      source: 'widget',
      saleType: getWidgetType(),
      language: parseLanguage(locale.locale),
      isFirstLoading: !latestOrderStorageData.isEventsSent
    });
  }

  return (
    <Stack sx={{flexDirection: 'column', alignItems: 'center'}}>
      <Typography sx={{my: 2, fontWeight: 500}} variant="h5">
        {locale.translate('everythingDone')}
      </Typography>
      <Typography sx={{mb: 4, maxWidth: 360, textAlign: 'center', color: 'text.secondary'}} >
        {locale.translate('ticketsReadyToDownload')}
      </Typography>
      <FollowCursorTooltip
        title={locale.translate('actionDisabledInPreviewMode')}
        enable={isPreviewMode}
        styles={{width: {xs: 1, sm: 'auto'}}}
      >
        <Button
          color="success"
          variant={isWigetOnlineType ? 'contained' : 'outlined'}
          onClick={handleDownloadTickets}
          sx={{width: 1, mb: 2}}
          size="large"
          endIcon={<FileDownloadOutlinedIcon />}
          disabled={isPreviewMode}
        >
          {locale.translate('download')}
        </Button>
      </FollowCursorTooltip>
      {
        (walletPassUrls?.length || previewTickets?.length) &&
        <Typography sx={{fontSize: 12, mb: 2, color: 'text.secondary', textTransform: 'capitalize'}}>
          {locale.translate('or')}
        </Typography>
      }
      {
        walletPassUrls?.length === 1 && walletPassUrls[0] &&
        <Box sx={{mb: 2}}>
          <WalletPass url={walletPassUrls[0]} onClick={handleOnAppleWalletClick} />
        </Box>
      }

      {
        previewTickets?.length === 1 &&
          <Box sx={{mb: 2}}>
            <FollowCursorTooltip
              title={locale.translate('actionDisabledInPreviewMode')}
              enable={isPreviewMode}
            >
              <WalletPass />
            </FollowCursorTooltip>
          </Box>
      }

      {
        (walletPassUrls && walletPassUrls?.length > 1 ||
        previewTickets && previewTickets?.length > 1) && (
          <Box sx={{mb: 2, width: {xs: '100%', md: 420}}}>
            <TicketsList
              onAppleWalletClick={handleOnAppleWalletClick}
            />
          </Box>
        )
      }
    </Stack>
  );
};

export default TicketsOnlineContent;
