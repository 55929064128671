import React from 'react'; /* eslint-disable no-unused-vars */

export enum EFlexWrap {
  WRAP = 'wrap',
  NOWRAP = 'nowrap'
}

export enum EJustifyContent {
  SPACE_BETWEEN = 'justify-between',
  SPACE_AROUND = 'justify-space-around',
  FLEX_START = 'justify-flex-start',
  FLEX_END = 'justify-flex-end',
  CENTER = 'justify-center'
}

export enum EFlexDirection {
  ROW = 'row',
  COLUMN = 'column'
}

export enum EAlignItems {
  FLEX_START = 'align-flex-start',
  FLEX_END = 'align-flex-end',
  CENTER = 'align-center',
  BASELINE = 'align-baseline',
  STRETCH = 'align-stretch'
}

export enum EFlexGap {
  GAP_4 = '4'
}

export type ECustomColors = {
  textColor: string,
  backgroundColor: string
}

export interface IFlexBoxProps {
  children: string | JSX.Element | React.ReactNode,
  direction?: EFlexDirection
  justify?: EJustifyContent
  align?: EAlignItems
  wrap?: EFlexWrap
  flex?: number
  flexGap?: EFlexGap
  shrink?: number
  className?: string,
  class?: string,
  style?: {[key: string]: string | number},
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}
