import React from 'react';
import FormCheckbox from '@Components/formComponents/FormCheckbox';
import {bool} from 'yup';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {SxProps, Theme} from '@mui/material/styles';
import {CheckboxProps} from '@mui/material/Checkbox';

export const FIELD_NAME = 'termsOfService';

export const schema = (isMultipleTerms: boolean) => bool().oneOf(
  [true],
  isMultipleTerms ? locale.translate('regulationsAcceptRequired') : locale.translate('regulationAcceptRequired')
);

interface ITermsOfServiceFieldProps {
  label: JSX.Element,
  fieldStyles?: SxProps<Theme>,
  componentProps?: CheckboxProps
}

const TermsOfServiceField = ({
  label,
  fieldStyles,
  componentProps
}: ITermsOfServiceFieldProps) => (
  <Box sx={{mb: 1, ...fieldStyles}} >
    <FormCheckbox name={FIELD_NAME} label={label} componentProps={componentProps} />
  </Box>
);

export default TermsOfServiceField;
