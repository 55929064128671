import {IFacility} from '@Consts/apiGlobals';
import {ELocale} from '@Consts/globals';

export const getMatchedBrowserLanguage = () => {
  const browserLanguage = window.navigator.language;
  const applicationLanguages = Object.values(ELocale);

  return applicationLanguages.find(appLang => browserLanguage?.includes(appLang)) || ELocale.EN;
};

export const validLanguageWithFallback = (selectedLanguage: ELocale, facility: IFacility) => {
  const additionalLangs = facility?.company?.additionalLangs;
  const companyLanguage = facility?.company?.locale;
  const availableLanguages = [companyLanguage, ...additionalLangs];

  const isSelectedLanguageValid = availableLanguages.some(availableLanguage => availableLanguage === selectedLanguage);

  if (isSelectedLanguageValid) {
    return selectedLanguage;
  }

  return companyLanguage;
};
