import {shouldCreateView, shouldMerge} from './checkoutViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {hasAdditions, noCreations} from './requirements';

export const createAdditionsViews = basketItems => {
  const creationRequirements = [hasAdditions, noCreations];
  const mergingRequirements = [hasAdditions];

  return basketItems.reduce((creations, basketItem) => {
    if (shouldCreateView({creations, basketItems, basketItem, requires: creationRequirements})) {
      creations.push({
        type: ECheckoutViewsTypes.ADDITIONS,
        items: [basketItem]
      });
    } else if (shouldMerge({basketItem, requires: mergingRequirements})) {
      const relevantCreationId = creations.findIndex(({type}) => type === ECheckoutViewsTypes.ADDITIONS);

      creations[relevantCreationId].items.push(basketItem);
    }

    return creations;
  }, []);
};
