import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import Stack from '@mui/material/Stack';
import {useMediaQuery} from '@mui/material';

const CalendarRow = ({
  children,
  header,
  className,
  showPrices,
  showAvailableSeatsForDay,
  isTestB
}) => {
  if (isTestB) {
    let rowHeight = 'auto';

    if (!header) {
      const isMobile = useMediaQuery(theme =>
        theme.breakpoints.down('sm')
      );
      const isWithAllAdditionalInformation = showAvailableSeatsForDay && showPrices;

      if (isWithAllAdditionalInformation) {
        rowHeight = isMobile ? 75 : 82;
      } else if (showPrices) {
        rowHeight = isMobile ? 64 : 70;
      } else {
        rowHeight = isMobile ? 59 : 64;
      }
    }

    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          height: rowHeight,
          mb: header ? 1 : 0
        }}
      >
        {children}
      </Stack>
    );
  }

  return (
    <div
      className={classNames(
        className,
        'dl-calendar__row',
        {
          'dl-calendar__row--header': header
        })
      }
    >
      {children}
    </div>
  );
};

export default CalendarRow;
