import {getAffiliationHashByOnlineGroupId} from '@Utils/configurationUtils';

export const SET_SCRIPT_DATA = 'SET_SCRIPT_DATA';
export const UPDATE_ORDER_SHIPPING_METHOD = 'UPDATE_ORDER_SHIPPING_METHOD';
export const RESET_ORDER_SHIPPING_METHOD = 'RESET_ORDER_SHIPPING_METHOD';

export const setScriptData = data => ({
  type: SET_SCRIPT_DATA,
  payload: {data}
});

export const prepareScriptData = ({
  eventData,
  urlAffiliationHash,
  indicatedActivityId,
  onlineGroupId
}) => dispatch => {
  const {authToken, parentUrl, storage, parentReferrer} = eventData;
  // Modal mode is retrieved from the URL query string, this is the fastest way to get it
  const isModal = window.location.search.includes('mode=modal');

  const affiliationHash = getAffiliationHashByOnlineGroupId(
    urlAffiliationHash,
    storage,
    onlineGroupId
  );

  dispatch(setScriptData({
    authToken,
    parentUrl,
    isModal,
    affiliationHash,
    indicatedActivityId,
    parentReferrer
  }));
};

export const updateOrderShippingMethod = data => ({
  type: UPDATE_ORDER_SHIPPING_METHOD,
  payload: {data}
});

export const resetShippingMethod = () => ({
  type: RESET_ORDER_SHIPPING_METHOD
});
