import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '@Components/common/flexBox/FlexBox';
import Text from '@Components/common/text/Text';
import {ETextWeight} from '@Components/common/text/types';
import ParticipantsCounter from './ParticipantsCounter';
import {isObject} from '@Utils/utils';
import {EAlignItems, EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const ActivityParticipants = ({basketItem, variantViewId, sumOfAddedSeats, handleIsCounterError}) => {
  const {variant} = basketItem;
  const {name: variantName} = variant;
  const showVariant = isObject(variant) && Object.keys(variant).length > 0;

  return showVariant ? (
    <ul className="dl-mb-3 dl-activity-additions" key={variantViewId}>
      <FlexBox justify={EJustifyContent.SPACE_BETWEEN} className={'dl-variant-addition--header'}>
        <Text className="dl-addition-name" weight={ETextWeight.BOLD} wrap>
          {variantName}
        </Text>
      </FlexBox>
      <li key={`dl-variant-section-${variantViewId}`}>
        <FlexBox
          direction={EFlexDirection.COLUMN}
          key={`dl-variant-${variantViewId}`}
          className="dl-variant-addition"
        >
          <FlexBox
            align={EAlignItems.CENTER}
            justify={EJustifyContent.FLEX_END}
          >
            <ParticipantsCounter
              key={`dl-variant-${variantViewId}`}
              name="quantity"
              basketItem={basketItem}
              sumOfAddedSeats={sumOfAddedSeats}
              handleIsCounterError={handleIsCounterError}
            />
          </FlexBox>
        </FlexBox>
      </li>
    </ul>
  ) : null;
};

export default ActivityParticipants;
