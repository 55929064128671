import React from 'react';
import Stack from '@mui/material/Stack';
import locale from '@Utils/locale';
import FormCheckbox from '@Components/formComponents/FormCheckbox';

const FIELD_NAME = 'isInvoiceRecipient';

const RecipientToogler = ({
  name = FIELD_NAME,
  label = locale.translate('anotherRecipient'),
  saveInStorage = false
}) => (
  <Stack
    sx={{
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      pt: 3, pl: 3
    }}
  >
    <FormCheckbox name={name} label={label} saveInStorage={saveInStorage} />
  </Stack>
);

export default RecipientToogler;
