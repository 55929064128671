import React from 'react';
import TermsOfServiceField from '../../../formFields/TermsOfServiceField';
import TermsOfServiceLabel from '@Components/termsOfService/TermsOfServiceLabel';
import {IFacility} from '@Consts/apiGlobals';

interface ITermsOfService {
  facility: IFacility
}

const TermsOfService = ({
  facility
}: ITermsOfService) => (
  <TermsOfServiceField
    label={<TermsOfServiceLabel facility={facility} />}
  />
);

export default TermsOfService;
