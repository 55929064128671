import React, {useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import CalendarPanel from './CalendarPanel';
import {getMonthDates, LAST_MONTH_INDEX} from '@Utils/date';
import CalendarHeader from './CalendarHeader';
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import Text from '@Components/common/text/Text';
import {DL_COLOR_TYPE} from '@Consts/color';
import locale from '@Utils/locale';
import './calendar.scss';
import {ETagType} from '@Components/common/text/types';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {useSelector} from 'react-redux';
import {getFacilityTimezone} from '@Features/facility/facilitySelectors';
import {getMonth,
  getYear,
  createDate,
  getFormattedDate,
  isSameDate,
  isDateInRange
} from '@Utils/dates/timezoneDates';

const DlCalendar = ({value, minDate, maxDate, onChange}) => {
  const timezone = useSelector(getFacilityTimezone);
  const year = getYear(value ?? minDate);
  const month = getMonth(value ?? minDate);
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedMonth, setSelectedMonth] = useState(month);
  const datesGroupedByWeeks = getMonthDates(selectedYear, selectedMonth, timezone);
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(null);

  function onChangeDate(date) {
    setError(null);
    const dateTz = createDate(date);
    const isAvaliable = isDateInRange(dateTz, minDate, maxDate);

    if (!isAvaliable) {
      return setError(locale.translate('selectedDayIsNotAvailable', {date}));
    }

    onChange(dateTz);

    setSelectedDate({
      date,
      selectionRangeStart: true,
      selectionRangeEnd: true
    });
  }

  function handleSelectPrevMonth() {
    if (selectedMonth === 0) {
      setSelectedYear(selectedYear - 1);
      return setSelectedMonth(LAST_MONTH_INDEX);
    }

    return setSelectedMonth(selectedMonth - 1);
  }

  function handleSelectNextMonth() {
    if (selectedMonth === LAST_MONTH_INDEX) {
      setSelectedYear(selectedYear + 1);
      return setSelectedMonth(0);
    }

    return setSelectedMonth(selectedMonth + 1);
  }

  return (
    <div className={'dl-calendar dl-calendar--without-mt'}>
      <CalendarPanel
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        onPrevMonthClick={handleSelectPrevMonth}
        onNextMonthClick={handleSelectNextMonth}
        isLower
      />

      <div className="dl-calendar__days">
        <CalendarHeader isLower />
        {
          datesGroupedByWeeks.map((week, weekIndex) => (
            <CalendarRow key={`week-${weekIndex}`}>
              {
                week.map((weekDate, dayIndex) => {
                  const key = `day-${dayIndex}`;
                  const isAvaliable = weekDate ? isDateInRange(weekDate, minDate, maxDate) : false;
                  const isSelected = selectedDate?.date ? isSameDate(weekDate, selectedDate.date) : false;
                  const dateData = {
                    ...selectedDate,
                    isSelected,
                    isAvaliable,
                    isHotelNarrowDate: true,
                    date: weekDate ? getFormattedDate(weekDate, DASH_DAY_FORMAT) : null
                  };

                  return (
                    <CalendarCell
                      calendarDay={weekDate ?? null}
                      cellData={dateData}
                      onClick={onChangeDate}
                      key={key}
                    />
                  );
                })
              }
            </CalendarRow>
          ))
        }
      </div>

      {
        error &&
          <Text
            className="dl-calendar__error"
            tagType={ETagType.DIV}
            color={DL_COLOR_TYPE.ERROR}
            dl-test="error-text"
          >
            {error}
          </Text>
      }
    </div>
  );
};

DlCalendar.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default DlCalendar;
