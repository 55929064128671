import {Button} from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import locale from '@Utils/locale';
import {useBackToActivities} from '../../hooks/useBackToActivities';

type Props = {
  translationKey?: string
}

const GoToActivities = ({translationKey = 'buyAnotherActivities'}: Props) => {
  const {handleClickBackToActivities} = useBackToActivities();

  return (
    <Button
      size="small"
      variant="text"
      startIcon={<ArrowBackIcon />}
      onClick={handleClickBackToActivities}
      sx={{color: 'text.primary'}}
      dl-test="back-btn"
    >
      {locale.translate(translationKey)}
    </Button>
  );
};

export default GoToActivities;
