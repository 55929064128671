import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import locale from '@Utils/locale';
import {green, grey} from '@mui/material/colors';
import {timePickerItemConfig} from './types';

type Props = {
  timePickerItemConfig: timePickerItemConfig;

}

const TimePickerSelectItem = ({
  timePickerItemConfig
}: Props) => {
  const {
    textTime,
    textPrice,
    textSeats,
    showPrice,
    showAvailableSeats,
    textColor,
    isDisabled,
    hasNoSeats,
    isSoldOut,
    isBargainPrice
  } = timePickerItemConfig;

  return (
    <Stack direction="row" sx={{alignItems: 'center', justifyContent: 'space-between', width: 1}}>
      <Stack direction="row">
        <AccessTimeIcon color={isDisabled ? 'disabled' : 'action'} sx={{mr: 1, opacity: '54%'}} />
        <Typography color={textColor}>
          {textTime}
        </Typography>
      </Stack>
      <Stack direction="row">
        {isSoldOut && (
          <Typography color={textColor} sx={detailsTextStyle}>
            {locale.translate('isSoldOut')}
          </Typography>
        )
        }
        {
          hasNoSeats && (
            <Typography color={textColor} sx={detailsTextStyle}>
              {locale.translate('hasNoSeatsTest')}
            </Typography>
          )
        }
        {
          showPrice && (
            <Typography
              sx={{
                ...detailsTextStyle,
                color: isBargainPrice ? green[800] : isDisabled ? 'text.disabled' : grey[700],
                fontWeight: 500
              }}
            >
              {textPrice}
            </Typography>
          )
        }
        {
          showAvailableSeats && (
            <Typography color={isDisabled ? 'text.disabled' : grey[600]} sx={detailsTextStyle}>
              {textSeats}
            </Typography>
          )
        }
      </Stack>
    </Stack>
  );
};

const detailsTextStyle = {fontSize: '12px', px: 1};

export default TimePickerSelectItem;
