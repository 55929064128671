import React from 'react';
import locale from '@Utils/locale';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

type TWalletPassProps = {
  onClick?: () => void,
  url?: string | null
}

const WalletPass = ({url, onClick}: TWalletPassProps) => (
  <Link
    component={url ? Link : 'span'}
    data-testid="apple-wallet"
    href={url ? url : undefined}
    {...!onClick && {onClick}}
  >
    <Box
      sx={{width: 140, opacity: url ? 1 : 0.5}}
      component="img"
      src={`img/walletPass/${locale.locale}.svg`}
      alt={`language ${locale.locale}`}
    />
  </Link>
);

export default WalletPass;
