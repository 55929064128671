import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import CalendarRow from './CalendarRow';
import CalendarCell from './CalendarCell';
import locale from '../../utils/locale';

export const DAY_NAMES = [
  'mondayShort',
  'tuesdayShort',
  'wednesdayShort',
  'thursdayShort',
  'fridayShort',
  'saturdayShort',
  'sundayShort'
];

// IMPORTANT TEST AB
// If test B will be corrent than change DAY_NAMES_TWO_PART to DAY_NAMES and add changes in translates
// IMPORTANT TEST AB

export const DAY_NAMES_TWO_PART = [
  'mondayTwoPart',
  'tuesdayTwoPart',
  'wednesdayTwoPart',
  'thursdayTwoPart',
  'fridayTwoPart',
  'saturdayTwoPart',
  'sundayTwoPart'
];

const CalendarHeader = ({isLower, isTestB}) => {

  if (isTestB) {
    return (
      <CalendarRow header isTestB={isTestB}>
        {
          DAY_NAMES_TWO_PART.map(dayName => (
            <CalendarCell
              key={dayName}
              isLower={isLower}
              isHeader
              isTestB={isTestB}
            >
              {locale.translate(dayName)}
            </CalendarCell>
          ))
        }
      </CalendarRow>
    );
  }

  return (
    <CalendarRow header>
      {
        DAY_NAMES.map(dayName => (
          <CalendarCell
            key={dayName}
            isLower={isLower}
            isHeader
          >
            {locale.translate(dayName)}
          </CalendarCell>
        ))
      }
    </CalendarRow>
  );
};

export default CalendarHeader;
