import {EPaymentMethod} from '@Components/checkout/types';
import FormRadioGroup, {IRadioInput} from '@Components/formComponents/FormRadioGroup';
import React from 'react';
import {string} from 'yup';
import SecureFormDescription from '../SecureFormDescription';
import BlikSection from '../BlikSection';
import LabelIcon from '../LabelIcon';
import locale from '@Utils/locale';
import {useUnregisterFormField} from '@Hooks/useHideFormField';

export const GROUP_FIELD_NAME = 'paymentMethod';

export const schema = () => string().oneOf(
  [EPaymentMethod.SECURE_FORM, EPaymentMethod.BLIK]).required();

  interface Props {
    groupName?: string,
}

const PaymentMethodPicker = ({groupName = GROUP_FIELD_NAME}: Props) => {
  useUnregisterFormField(groupName);

  const radioInputs: IRadioInput[] = [
    {
      value: EPaymentMethod.SECURE_FORM,
      label: locale.translate('paymentOnline'),
      children: <SecureFormDescription />,
      labelAdornment: <LabelIcon src="img/icons/Autopay-logo.svg" />
    },
    {
      value: EPaymentMethod.BLIK,
      label: locale.translate('blik'),
      children: <BlikSection />,
      labelAdornment: <LabelIcon src="img/icons/Blik-logo.svg" />
    }
  ];

  return (
    <FormRadioGroup
      groupName={groupName}
      radioInputs={radioInputs}
      spacing={2}
    />
  );
};

export default PaymentMethodPicker;
