import {ECurrency} from '@Consts/apiGlobals';
import {IBasketItem} from '@Consts/types';
import {getBasketItems} from '@Features/basket/basketSelectors';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getBasketValue} from '@Utils/basket';
import {useSelector} from 'react-redux';
import {getWidgetType} from '@Features/configuration/configurationSelectors';
import {isWidgetOnlineClient, isWidgetOnlineClientPreview} from '@Utils/widgetType';
import {getBasketItemPrice} from '@Utils/basketItemPrice';

export const useIsPaymentMethodsEnabled = () => {
  const facility = useSelector(getFacility);

  if (!facility) {
    return false;
  }

  const basketItems: IBasketItem[] = useSelector(getBasketItems);
  const widgetType = useSelector(getWidgetType);
  const isWidgetOnlineType = isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType);
  const basketPriceValue = getBasketValue(basketItems, true);
  const currency = getBasketItemPrice(basketItems[0], false)?.currency;

  const isValidCurrency = currency === ECurrency.PLN;
  const isBlumedia = facility.company.paymentProvider === 'bluemedia';
  const isDev = facility.company.paymentProvider === 'dev';
  const isValidPaymentProvider = isBlumedia || isDev;

  return isWidgetOnlineType && isValidPaymentProvider && basketPriceValue > 0 && isValidCurrency;
};
