import React from 'react';
import Paper from '@mui/material/Paper';
import {Theme} from '@mui/material/styles';

const HighlightedSection = ({children}: React.PropsWithChildren<{}>) => (
  <Paper
    elevation={0}
    sx={{
      bgcolor: (theme: Theme) => theme.palette.grey[100],
      px: 2,
      py: 1.75,
      mt: 1
    }}
  >
    {children}
  </Paper>
);

export default HighlightedSection;
