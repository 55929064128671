import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {useLatestOrder} from '@Utils/hooks/useLatestOrder';

const EntryHandler = () => {
  useLatestOrder();

  return null;
};

export default EntryHandler;
