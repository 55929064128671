import {Stack} from '@mui/material';
import React from 'react';
import OrderTicketsUseSection from './OrderTicketsUseSection';
import TicketsSection from './TicketsSection';
import InternalSendOrderSection from './InternalSendOrderSection';

const InternalOrderSummary = () => (
  <Stack spacing={{xs: 2, sm: 3}}>
    <OrderTicketsUseSection />
    <TicketsSection />
    <InternalSendOrderSection />
  </Stack>
);

export default InternalOrderSummary;
