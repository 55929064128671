import React from 'react';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';

const TicketsSectionContent = () => (
  <>
    <Typography sx={{mb: 1, fontWeight: 500}} variant="h5">
      {locale.translate('pdfDocumentIsReady')}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        mb: 3,
        maxWidth: {xs: 1, sm: 250, md: 300, lg: 400},
        color: 'text.secondary'
      }}
    >
      {locale.translate('clickForPdfDocument')}
    </Typography>
  </>
);

export default TicketsSectionContent;
