import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';

type Props = {
  position?: 'spaced-bottom',
  className?: string
}

const Header = ({children, position, className}: React.PropsWithChildren<Props>) => (
  <div
    className={classNames(
      'dl-header',
      {
        [`dl-header--${position}`]: position
      },
      className
    )}
  >
    {children}
  </div>
);

export default Header;
