import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';

export const CHECKOUT_TYPE = {
  NARROW: 'narrow',
  WIDE: 'wide',
  SUMMARY: 'summary',
  UPSELL: 'upsell'
};

const CheckoutWrapper = ({type, children, ...rest}) => {
  const checkoutClassName = classNames(
    'dl-checkout__wrapper',
    {
      [`dl-checkout__wrapper--${type}`]: type
    }
  );

  return (
    <div className={checkoutClassName} {...rest}>
      {children}
    </div>
  );
};

export default CheckoutWrapper;
