import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Ticket from '../ticket/Ticket';
import ActivityPreviewAdditionsTop from './ActivityPreviewAdditionsTop';
import ActivityPreviewBottom from './ActivityPreviewBottom';
import PriceCount from '../price/PriceCount';
import PriceDeal from '../price/PriceDeal';
import Text from '@Components/common/text/Text';
import {getAdditionById} from '@Utils/activity/activity';
import {getFormattedPrice} from '@Utils/format';
import {debounce} from '@Utils/utils';
import locale from '@Utils/locale';
import {ETextColor, ETextSize} from '@Components/common/text/types';
import {isPriceValid} from '@Utils/validate';

const ActivityPreviewAdditions = ({
  id,
  previewId,
  additions,
  additionsCost,
  activity,
  className,
  numberOfSeats,
  disabled,
  additionsRef,
  additionsCurrency
}) => {
  function updateHeight() {
    const $previewActivity = document.querySelector(`#activity-preview-${previewId}`);
    const $previewAdditions = document.querySelector(`#activity-preview-additions-${previewId}`);

    if ($previewActivity && $previewAdditions) {
      const activityHeight = $previewActivity.getBoundingClientRect().height;
      const previewHeight = $previewAdditions.getBoundingClientRect().height;
      const widgetWidth = document.body.clientWidth;

      if (widgetWidth < 787) {
        if (previewHeight > activityHeight) {
          $previewActivity.style.height = `${previewHeight}px`;
        } else {
          $previewAdditions.style.height = `${activityHeight}px`;
        }
      } else {
        $previewActivity.style.height = null;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(updateHeight, 50));

    updateHeight();

    return () => {
      const $previewActivity = document.querySelector(`#activity-preview-${previewId}`);

      if ($previewActivity) {
        $previewActivity.style.height = null;
      }
    };
  }, []);

  const {name, additions: activityAdditions} = activity;
  const mappedAdditions = additions.reduce((allAdditions, addition) => {
    const activityAddition = getAdditionById(activityAdditions, addition.additionId);

    if (activityAddition) {
      allAdditions.push({...activityAddition, quantity: addition.quantity});
    }

    return allAdditions;
  }, []);

  const PreviewBottom = (
    <ActivityPreviewBottom activityName={name}>
      {
        isPriceValid(additionsCost) ? (
          <>
            <PriceCount>
              <Text color={ETextColor.LIGHT}>+</Text>
            </PriceCount>
            <PriceDeal>
              <Text size={ETextSize.LARGE}>
                {getFormattedPrice(additionsCost)} {locale.translate(`${additionsCurrency}`)}
              </Text>
            </PriceDeal>
          </>
        ) : null
      }
    </ActivityPreviewBottom>
  );

  return (
    <Ticket
      ref={additionsRef}
      id={id}
      topContent={(
        <ActivityPreviewAdditionsTop
          activity={activity}
          additions={mappedAdditions}
          numberOfSeats={numberOfSeats}
          isActive={!disabled}
        />
      )}
      bottomContent={PreviewBottom}
      className={className}
    />
  );
};

export default ActivityPreviewAdditions;
