import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import locale from '@Utils/locale';
import {ANSWER_MAX_LENGTH} from '@Utils/checkoutViews/checkoutViewsConsts';

const QuestionText = ({
  value,
  invalid,
  isRequired,
  setAnswer,
  setInvalid
}) => {
  function handleChange({target}) {
    return setAnswer(target.value);
  }

  function handleFocus() {
    return setInvalid(false);
  }

  return (
    <>
      <textarea
        className={classNames(
          'dl-dotpay__textarea',
          {
            'dl-dotpay__input--invalid': invalid
          }
        )}
        value={value || ''}
        onChange={handleChange}
        onFocus={handleFocus}
        required={isRequired}
        maxLength={ANSWER_MAX_LENGTH}
      />

      {
        invalid && (
          <div className="dl-text--error">
            {locale.translate('answerRequired')}
          </div>
        )
      }
    </>
  );
};

export default QuestionText;
