import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '../../utils/locale';
import Text from '../common/text/Text';
import ActivityContent, {SPACING_TYPE} from '../activities/activity/ActivityContent';
import {ETextColor} from '@Components/common/text/types';
import {isVariantGroup} from '@Utils/variantType';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {getAdmissionDatesDescription} from '@Utils/activity/admissionPolicy';

const ActivityEnd = ({basketItem}) => {
  const {
    activity: {admissionPolicy},
    selections: {numberOfSeats},
    variant
  } = basketItem;
  const {endDescription} = admissionPolicy;
  const suitableDate = getAdmissionDatesDescription(admissionPolicy);

  return (
    <>
      <ActivityContent spacing={SPACING_TYPE.TOP}>
        <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />

        <Text color={ETextColor.LIGHT}>
          {endDescription ?? `${locale.translate('useDatetime')} ${suitableDate}`}
        </Text>
      </ActivityContent>

      {isVariantGroup(variant?.type) && (
        <ActivityContent spacing={SPACING_TYPE.TOP}>
          <PeopleAltOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
          <Text color={ETextColor.LIGHT}>
            {locale.translate('numberOfPeopleInGroup')}: {numberOfSeats}
          </Text>
        </ActivityContent>
      )}
    </>
  );
};

export default ActivityEnd;
