import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {Theme} from '@mui/material';
import {IUnorderedListProps} from './types';

const indicatorStyles = (theme: Theme) => ({
  margin: theme.spacing(0, 1)
});

const UnorderedList = ({
  header,
  listElements
}: IUnorderedListProps) => (
  <>
    <Typography variant="inherit">{header}</Typography>
    <Stack>
      {
        listElements.map((listElement, key) => (
          <Typography variant="inherit" key={key}>
            <Box component="span" margin={indicatorStyles}>&bull;</Box>{listElement}
          </Typography>
        )
        )
      }
    </Stack>
  </>
);

export default UnorderedList;
