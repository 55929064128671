import {IStore} from '../../store/types';

const getFacilityState = (state: IStore) => state?.facility || null;

export const getFacilityStatus = (state: IStore) => {
  const facilityState = getFacilityState(state);

  return facilityState?.status || null;
};

export const getFacility = (state: IStore) => {
  const facilityState = getFacilityState(state);

  return facilityState?.facility;
};

export const getFacilityTimezone = (state: IStore) => {
  const facilityState = getFacility(state);

  return facilityState?.timezone || null;
};
