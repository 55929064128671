import React from 'react';
import EmailField from '@Components/checkout/field/EmailField';
import TermsOfServiceField from '@Components/formFields/TermsOfServiceField';
import {FormProvider} from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import TextLink from '@Components/common/TextLink';
import {BUSINESS_CLIENT_TERMS_OF_SERVICE_URL} from '@Consts/urls';
import {useSummaryData} from '../../../hooks/useSummaryData';
import Alert from '@mui/material/Alert';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import CarouselItem from '@Components/carousel/CarouselItem';
import {useSendOrderForm} from '../../../hooks/useSendOrderForm';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const formContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 450
};

const SendOrderForm = () => {
  const {
    isPreviewMode
  } = useSummaryData();
  const {
    formProperties,
    handleFormSubmit,
    isLoading,
    isError,
    isSuccess,
    handleCloseAlert,
    orderEmail
  } = useSendOrderForm();
  const {handleSubmit} = formProperties;

  return (
    <CarouselItem
      styles={{
        border: '1px solid',
        borderColor: 'primary.main'
      }}
    >
      <FormProvider {...formProperties}>
        <Box component="form" sx={formContentStyles} noValidate onSubmit={handleSubmit(handleFormSubmit)}>
          <Typography sx={{mb: 2, fontWeight: 500}} variant="h5">
            {locale.translate('sendOrder')}
          </Typography>
          <Typography sx={{mb: 4, maxWidth: 360, textAlign: 'center', color: 'text.secondary'}} >
            {locale.translate('sendOrderDescription')}
          </Typography>
          <EmailField fieldStyles={{mb: 2}} componentProps={{disabled: isLoading}} />
          <TermsOfServiceField
            fieldStyles={{mb: 4}}
            componentProps={{disabled: isLoading}}
            label={
              <Box component="span" data-testid="terms-of-service">
                {locale.translate('forSendingAcceptTos')}{' '}
                <Box component="span">
                  <TextLink href={BUSINESS_CLIENT_TERMS_OF_SERVICE_URL}>
                    {locale.translate('agreementFormWithClause')}
                  </TextLink>
                </Box>
              </Box>
            }
          />
          <FollowCursorTooltip
            title={locale.translate('actionDisabledInPreviewMode')}
            enable={isPreviewMode}
            styles={{width: {xs: 1, sm: 'auto'}}}
          >
            <Button
              variant={!isPreviewMode ? 'outlined' : 'contained'}
              disabled={isPreviewMode || isLoading}
              color="inherit"
              type="submit"
              sx={{width: 1}}
              size="large"
              endIcon={<SendOutlinedIcon />}
              {...isLoading && {
                disabled: true,
                endIcon: <CircularProgress size={16} color="inherit" />
              }}
            >
              {locale.translate('send')}
            </Button>
          </FollowCursorTooltip>
          {
            isError &&
            <Alert color="error" sx={{mt: 4}} onClose={handleCloseAlert}>
              <Typography variant="body2" >
                {locale.translate('unsuccessfullyOrderedSending', {email: orderEmail})}
              </Typography>
            </Alert>
          }
          {
            isSuccess &&
            <Alert sx={{mt: 4}} onClose={handleCloseAlert}>
              <Typography variant="body2" >
                {locale.translate('orderSendedOnEmail')}{' '}
                <Typography component="span" sx={{fontWeight: 700}}>
                  {orderEmail}
                </Typography>
              </Typography>
            </Alert>
          }
        </Box>
      </FormProvider>
    </CarouselItem>
  );
};

export default SendOrderForm;
