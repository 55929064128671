import {EShippingMethodsTypes} from '@Consts/apiGlobals';
import locale from '@Utils/locale';

const MIN_LENGTH = 3;
const MIN_LENGTH_PHONE_NUMBER = 8;
const MAX_LENGTH_PHONE_NUMBER = 18;
const MIN_LENGTH_POSTAL_CODE = 6;
const MAX_LENGTH = 255;

export const isMinLengthValid = (formField, length) => formField.length >= length;

export const isMaxLengthValid = (formField, length) => formField.length <= length;

export const isLengthValid = (formField, length) => formField.length === length;

export const validForm = (selectedShippingMethodId, selectedMethod, parcelLockerPoint, form, setErrors) => {
  let isValid = true;
  let validationErrors = {...form.errors};
  const formErrors = Object.values(form.errors).some(error => error);
  const {values} = form;

  if (selectedShippingMethodId === null) {
    validationErrors = {...validationErrors, 'shippingMethodSelect': locale.translate('shippingMethodRequired')};
    setErrors(validationErrors);
    return false;
  }

  if (selectedMethod?.type === EShippingMethodsTypes.COURIER) {
    if (!isMinLengthValid(values.firstName, MIN_LENGTH)) {
      validationErrors = {...validationErrors, 'firstName': locale.translate('minLength', {length: MIN_LENGTH})};
      isValid = false;
    }

    if (!isMaxLengthValid(values.firstName, MAX_LENGTH)) {
      validationErrors = {...validationErrors, 'firstName': locale.translate('maxLength', {length: MAX_LENGTH})};
      isValid = false;
    }

    if (!values.firstName) {
      validationErrors = {...validationErrors, 'firstName': locale.translate('requiredField')};
      isValid = false;
    }

    if (!isMinLengthValid(values.lastName, MIN_LENGTH)) {
      validationErrors = {...validationErrors, 'lastName': locale.translate('minLength', {length: MIN_LENGTH})};
      isValid = false;
    }

    if (!isMaxLengthValid(values.lastName, MAX_LENGTH)) {
      validationErrors = {...validationErrors, 'lastName': locale.translate('maxLength', {length: MAX_LENGTH})};
      isValid = false;
    }

    if (!values.lastName) {
      validationErrors = {...validationErrors, 'lastName': locale.translate('requiredField')};
      isValid = false;
    }

    if (!isMinLengthValid(values.city, MIN_LENGTH)) {
      validationErrors = {...validationErrors, 'city': locale.translate('minLength', {length: MIN_LENGTH})};
      isValid = false;
    }

    if (!isMaxLengthValid(values.city, MAX_LENGTH)) {
      validationErrors = {...validationErrors, 'city': locale.translate('maxLength', {length: MAX_LENGTH})};
      isValid = false;
    }

    if (!values.city) {
      validationErrors = {...validationErrors, 'city': locale.translate('requiredField')};
      isValid = false;
    }

    if (!isMinLengthValid(values.street, MIN_LENGTH)) {
      validationErrors = {...validationErrors, 'street': locale.translate('minLength', {length: MIN_LENGTH})};
      isValid = false;
    }

    if (!isMaxLengthValid(values.street, MAX_LENGTH)) {
      validationErrors = {...validationErrors, 'street': locale.translate('maxLength', {length: MAX_LENGTH})};
      isValid = false;
    }

    if (!values.street) {
      validationErrors = {...validationErrors, 'street': locale.translate('requiredField')};
      isValid = false;
    }

    if (!isLengthValid(values.postalCode, MIN_LENGTH_POSTAL_CODE)) {
      validationErrors = {...validationErrors, 'postalCode': locale.translate('validPostalCodeLength')};
      isValid = false;
    }

    if (!values.postalCode) {
      validationErrors = {...validationErrors, 'postalCode': locale.translate('requiredField')};
      isValid = false;
    }

  }

  if (
    selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER ||
    selectedMethod?.type === EShippingMethodsTypes.COURIER
  ) {
    const phoneNumberJoined = values.phoneNumber.split(' ').join('');

    if (!isMinLengthValid(phoneNumberJoined, MIN_LENGTH_PHONE_NUMBER)) {
      validationErrors = {
        ...validationErrors, 'phoneNumber': locale.translate('minLength', {length: MIN_LENGTH_PHONE_NUMBER})
      };
      isValid = false;
    }

    if (!isMaxLengthValid(phoneNumberJoined, MAX_LENGTH_PHONE_NUMBER)) {
      validationErrors = {
        ...validationErrors, 'phoneNumber': locale.translate('maxLength', {length: MAX_LENGTH_PHONE_NUMBER})
      };
      isValid = false;
    }

    if (!values?.phoneNumber) {
      validationErrors = {...validationErrors, 'phoneNumber': locale.translate('requiredField')};
      isValid = false;
    }
  }

  if (selectedMethod?.type === EShippingMethodsTypes.PARCEL_LOCKER) {
    if (!parcelLockerPoint || !Object.keys(parcelLockerPoint).length && !form.errors?.parcelLocker) {
      validationErrors = {...validationErrors, 'parcelLocker': locale.translate('selectParcelLocker')};
      isValid = false;
    }
  }

  setErrors(validationErrors);

  if (formErrors) {
    return false;
  }

  return isValid;
};

export const isParcelLockerDataValid = point => {
  const expectedKeys = ['city', 'post_code', 'street', 'building_number'];

  if (!point?.name) {
    return false;
  }

  if (!point?.address_details) {
    return false;
  }

  return expectedKeys.reduce((isValid, key) => {
    if (isValid) {
      isValid = key in point?.address_details;
    }

    return isValid;
  }, true);
};
