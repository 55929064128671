import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import {green, grey} from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import {
  DefaultTimeTextProps,
  TextAvailableSeatsProps,
  TextPriceProps,
  TextSoldOutProps,
  TimePickerButtonProps,
  TimeTextProps
} from './types';

export const TimePickerButton = styled(Button, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'isDisabled'
})<TimePickerButtonProps>(({
  theme, isSelected, isDisabled
}) => ({
  width: '100%',
  height: '100%',
  minWidth: 'auto',
  backgroundColor: grey[50],
  textTransform: 'none',
  border: '1px solid #fff',
  padding: 0,
  alignItems: 'flex-start',
  transition: '0s',
  color: isDisabled ? theme.palette.action.disabled : 'auto',
  cursor: isDisabled ? 'auto' : 'cursor',
  '&:hover': {
    background: isDisabled ? grey[50] : `linear-gradient(180deg, ${grey[100]} 0%, ${grey[200]} 50%)`
  },
  '&:focus-visible': {
    border: `1px solid ${theme.palette.primary.main}`
  },
  ...isSelected && {
    background: grey[200],
    border: `1px solid ${grey[600]}`,
    transition: '0s'
  }
}));

export const DefaultTimeText = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled'
})<DefaultTimeTextProps>(
  ({theme, isDisabled}) => ({
    color: isDisabled ? 'inherit' : theme.palette.grey[900],
    lineHeight: '20px',
    marginTop: '16px',
    fontSize: '16px'
  })
);

export const TextSoldOut = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isWithAllAdditionalInformation'
})<TextSoldOutProps>(
  ({isWithAllAdditionalInformation}) => ({
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: -0.4,
    marginTop: isWithAllAdditionalInformation ? '12px' : '0px'
  })
);

export const TimeText = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' && prop !== 'isPrices'
})<TimeTextProps>(({isDisabled, isPrices}) => ({
  fontSize: '16px',
  fontWeight: 400,
  color: isDisabled ? 'inherit' : grey[900],
  marginTop: '10px',
  marginBottom: isPrices ? '6px' : '8px',
  lineHeight: '20px'
}));

export const TextPrice = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isBargainPrice' && prop !== 'isDisabled'
})<TextPriceProps>(({isBargainPrice, isDisabled}) => ({
  color: isBargainPrice ? green[800] : isDisabled ? 'inherit' : grey[800],
  fontSize: '12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  lineHeight: '14px',
  fontWeight: 500,
  marginBottom: '2px'
}));

export const TextAvailableSeats = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isDisabled'
})<TextAvailableSeatsProps>(({isDisabled}) => ({
  fontSize: '10px',
  color: isDisabled ? 'inherit' : grey[600],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  letterSpacing: -0.4,
  lineHeight: '12px',
  marginBottom: '8px'
}));
