import {EPaymentMethod} from '@Components/checkout/types';
import {IAdmissionDateVariant} from '@Features/activityAdmissionDates/types';
import {ELocale, EWidgetType} from './globals';
import {IBasketItem, IPickedAddition, IQuestionAnswer} from './types';

/** Facility */
export type TFacilityId = number;
export type TOnlineGroupId = number;

export enum ECurrency {
  PLN = 'PLN',
  EUR = 'EUR'
}

export interface IFacilitySalePolicy {
  termsOfServiceURL: string | null
  privacyPolicyURL: string | null
  isDiscountsFormEnabled: boolean,
  termsOfSaleURL?: string | null
}

export interface IAddress {
  formattedAddressLine1: string,
  formattedAddressLine2: string
}
// bluemedia is autopay equivalent
export type PaymentProvider = 'bluemedia' | 'gopay' | 'vivawallet' | 'imoje' | 'przelewy24' | 'dev';

export type PaymentType = 'transaction' | 'delayed' | 'for_used' | 'on_site';

export interface ICompany {
  name: string,
  address: IAddress,
  nip: string,
  industry: string,
  paymentProvider: PaymentProvider,
  onlineSaleTermsOfServiceUrl: string,
  isUsersInvoiceFormEnabled: boolean,
  isUsersInvoiceNoteEnabled: boolean,
  locale: ELocale,
  buyingDisclaimer: string | null,
  isInternalPaymentReceiptEnabled: boolean,
  isOnlinePaymentReceiptEnabled: boolean,
  isInternalChannelWithOnlinePaymentEnabled: boolean,
  maximumTicketsInOrder: number,
  country: string,
  paymentType?: PaymentType,
  additionalLangs: ELocale[]
}

export enum ETextColor {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export interface IButtonCustomization {
  backgroundColor: string
  textColor: ETextColor
}

export interface IGradientCustomization {
  leftColor: string,
  rightColor: string
}

export interface IWidgetCustomization {
  primaryButton: IButtonCustomization,
  secondaryButton: IButtonCustomization | null,
  gradient: IGradientCustomization
}

export interface IFacility {
  id: number,
  name: string,
  email: string,
  timezone: string,
  logotypeAddress: string,
  salePolicy: IFacilitySalePolicy,
  company: ICompany,
  isFacebookPixelEnabled: boolean
  isGoogleTagManagerEnabled: boolean,
  widgetCustomization: IWidgetCustomization,
  facilityTags: string[],
  isMarketingConsentsEnabled: boolean,
  marketingConsentsTitle: string | null,
  marketingConsentsDescription: string | null
}

/** Price model */
export interface IPrice {
  amount: number,
  currency: ECurrency
}

/** Shipping methods */
export enum EShippingMethodsTypes {
  PERSONAL_COLLECTION = 'PERSONAL_COLLECTION',
  PARCEL_LOCKER = 'PARCEL_LOCKER',
  COURIER = 'COURIER',
  DIGITAL = 'DIGITAL'
}

export enum EShippingMethodsRequirement {
  REQUIRED = 'required',
  OPTIONAL = 'optional'
}

export interface IShippingMethod {
  id: number
  name: string
  price: IPrice
  type: EShippingMethodsTypes
}

export interface IBookingInformation {
  isRequired: boolean | null
  description: string | null
  phone: string | null
  website: string | null
  email: string | null
}

export interface ICost {
  price: number,
  currency: ECurrency,
  catalogPrice: number | null,
  catalogPriceDescription: string | null
}

export type TActivityVariantType = 1 | 2;

export interface IActivityVariant {
  id: number
  name: string | null
  cost: ICost | null
  datesCostMin: ICost | null
  datesCostMax: ICost | null
  numberOfSeats: number
  numberOfAdmissionDatesMin: number | null
  numberOfAdmissionDatesMax: number | null
  isPricePerAdmissionDate: boolean
  maxNumberOfSeats: number | null
  minNumberOfSeats: number | null
  priority: number
  type: TActivityVariantType,
  requiredActivityVariants: unknown[]
  description: string | null
}

export interface IActivitySalePolicy {
  usesCount: number;
  limit: number | null;
  isEndingSoon: boolean;
  showEndDate: string | null;
  canShowItemsLeft: boolean;
  buyerPhoneRequired: boolean;
  isSoldOut: boolean;
}

export enum EPassType {
  SINGLE = 'SINGLE',
  SINGLE_PICK_DATE = 'SINGLE_PICK_DATE',
  SINGLE_PICK_DATETIME = 'SINGLE_PICK_DATETIME',
  SINGLE_GROUP_EVENT = 'SINGLE_GROUP_EVENT',
  SINGLE_HOTEL_NARROWED = 'SINGLE_HOTEL_NARROWED',
  MULTIPLE = 'MULTIPLE',
  MULTIPLE_ACTIVATION = 'MULTIPLE_ACTIVATION',
  MULTIPLE_HOTEL_NARROWED = 'MULTIPLE_HOTEL_NARROWED',
}

export enum EPassLayout {
TICKET = 'ticket',
VOUCHER = 'voucher',
VOUCHER_CUSTOM = 'voucher-custom',
VOUCHER_CHRISTMAS = 'voucher-christmas',
VOUCHER_VALENTINES_DAY = 'voucher-valentines-day',
VOUCHER_TULIPS = 'voucher-tulips',
VOUCHER_GIFT = 'voucher-gift',
VOUCHER_MODERN = 'voucher-modern',
VOUCHER_MASSAGE = 'voucher-massage',
VOUCHER_TRIP = 'voucher-trip',
VOUCHER_GO_KARTS = 'voucher-go-karts',
VOUCHER_CAR = 'voucher-car',
VOUCHER_WATER_PARK = 'voucher-water-park',
VOUCHER_KIDS = 'voucher-kids',
VOUCHER_FITNESS = 'voucher-fitness'
}

export interface IAdmissionPolicy {
  startDatetime: string | null,
  endDatetime: string | null,
  firstAdmissionDate: string | null,
  endInDays: number | null,
  durationInMinutes: number | null,
  endDescription: string | null,
  useLimit: number | null,
  useLimitOncePerDay: boolean,
  activeInDays: number | null,
  activeDescription: number | null,
  validSinceTime: string | null,
  validUntilTime: string | null,
  minNumberOfParticipants: number | null,
  maxNumberOfParticipants: number | null,
  canSkipTheLine: boolean,
  isOneAdmissionDateAvailable: boolean
}

export interface IFacilityTag {
  name: string
}

export interface ISaleInformations {
  text: string
  type: string
}

export type Activity = {
  id: number
  name: string
  bookingInformation: IBookingInformation
  description: string | null
  addressDescription: string | null
  variants: IActivityVariant[]
  variantsDescription: string | null
  salePolicy: IActivitySalePolicy
  coverPhoto: string | null
  priority: number
  passType: EPassType
  passLayout: EPassLayout
  admissionPolicy: IAdmissionPolicy
  shippingMethodRequirement: EShippingMethodsRequirement | null
  facilityTags: IFacilityTag[] | []
  saleInformations: ISaleInformations[]
}

export interface IAddition {
  id: number
  name: string
  price: IPrice
  isPricePerParticipant: boolean
  maxNumberPerTicket: number
  variantIds: number[]
}

export enum EQuestionType {
  TEXT = 'text',
  INPUT = 'input',
  SINGLE_CHOICE = 'single_choice',
  VEHICLE_PLATE = 'vehicle_plate',
  ADDRESS = 'address'
}

export enum EAnswerScope {
  TICKET = 'ticket',
  ACTIVITY = 'activity',
  ORDER = 'order'
}

export interface IQuestionChoice {
  name: string
}

export interface IQuestion {
  id: number
  text: string
  comment: string | null
  type: EQuestionType
  choices: IQuestionChoice[]
  isRequired: boolean
  answerScope: EAnswerScope
  variantIds: number[]
}

export interface ITicket {
  id: string,
  formattedId: string,
  formattedName: string,
  activityName: string,
  code: string,
  price: number,
  currency: ECurrency,
  walletPassUrl: string | null,
  facilityLogotypeUrl: string | null,
  locale: ELocale
}

export enum EOrderState {
    PENDING = 'pending',
    COMPLETED = 'completed',
    REJECTED = 'rejected',
    DELAYED = 'delayed',
    FOR_USED = 'for_used',
    ON_SITE = 'on_site',
    INTERNAL = 'internal',
    SKIPPED = 'skipped'
  }

export type TOrderType = 'CLIENT' |
  'CLIENT_FREE' |
  'CLIENT_PREVIEW' |
  'BUSINESS' |
  'BUSINESS_FREE' |
  'BUSINESS_PREVIEW' |
  'INTERNAL_SALE' |
  'INTERNAL_SALE_PREVIEW' |
  'SKIPPED_PAYMENT'

export interface ILatestOrder {
  paymentViewUrl?: string | null;
  orderId: string,
  onlineGroupId: number | null,
  widgetType: EWidgetType,
  timestamp: number,
  isEventsSent?: boolean,
  code: string,
  orderType: TOrderType,
  basket?: IBasketItem[],
  facilityId: number,
  email?: string,
  isVivaWalletPayment?: boolean
}

export interface IOrderSavedData {
  phoneNumber: string,
  firstName: string,
  lastName: string
}

export type TVariantCost = {
  price: number,
  currency: ECurrency,
  catalogPrice: number | null;
  catalogPriceDescription: string | null
}

export type TCost = {
  price: number,
  currency: ECurrency,
  catalogPrice: number | null;
}

export interface IAdmissionDate {
  date: string,
  description: string | null,
  numberOfSeats: number,
  resourceExists: boolean,
  isAvailable: boolean,
  variants: IAdmissionDateVariant[]
}

export type TPaymentState = 'pending' | 'completed' | 'rejected' | 'skipped'

export type TOrderBuyer = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string | null,
  hash: string | null
}

export interface ITicketCommon {
  ticketId: string,
  ticketFormattedId: `DROP/TID/${string}`,
  price: IPrice,
  ticketFormattedName: string,
  activityName: string,
  ticketCode: string,
}

export interface ITicketOnline extends ITicketCommon {
  walletPassUrl: string | null
}

export interface IOrderCommonData {
  orderId: string,
  orderFormattedId: `DROP/PID/${string}`,
  price: IPrice,
  tax: number,
  paymentState: TPaymentState,
  isDocumentAddressAvailable: boolean,
  documentAddress: string | null,
  orderBuyer: TOrderBuyer | null,
  completedAt: string,
  tickets: ITicketCommon[]
}

export interface IOnlineOrder extends IOrderCommonData {
    payUrl: string | null,
    tickets: ITicketOnline[],
    orderBuyer: TOrderBuyer,
    paymentMethod: EPaymentMethod.BLIK | null
}

export interface IInternalOrder extends IOrderCommonData {
  paymentViewUrl: string | null,
}

export interface IBusinessOrder extends IOrderCommonData {
  payUrl: string | null,
  businessBuyerCompanyId: number,
  businessBuyerCompanyUserId: number
}

export type IOrder = IOnlineOrder | IBusinessOrder | IInternalOrder;

export interface ICrossSellOffer {
  bannerUrl: string,
  redirectUrl: string,
  description: string,
  placeName: string
}

interface ITicketInternal {
  activitySnapshot: [],
  code: string,
  formattedId: string,
  activityName: string,
  id: string,
  isActive: boolean,
  useLimit: number,
  usesCount: number,
  walletPassUrl: string | null,
  pickedAdditions: IPickedAddition[],
  questionAnswers: IQuestionAnswer[]
}

export interface IOrderData {
  created: string,
  currency: ECurrency,
  documentAddress: string | null,
  id: number,
  paymentViewUrl: string | null,
  price: number,
  state: EOrderState,
  tickets: ITicketInternal[]
}

export type CompanyInvoiceData = {
  companyNip: string,
  companyName: string,
  address: {
    city: string,
    street: string,
    postalCode: string
  }
}
