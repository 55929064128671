import {QUESTION_TYPE} from './question';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
const PHONE_REGEX = /^[0-9+]{8,15}$/;
const POSTAL_CODE_REGEX = /^(\d{2}-\d{3})$/;
const PLATE_REGEXP = /^[A-Za-zÄäÖöÜü0-9 ]*$/;

export const isString = value => typeof value === 'string';

export const isLicensePlateValid = licensePlate => PLATE_REGEXP.test(licensePlate);

export const isObjectFilled = object => {
  if (object && typeof object === 'object') {
    return Object.keys(object)?.length > 0;
  }

  return false;
};

export const isEmailValid = email => EMAIL_REGEX.test(email);

export const isPhoneNumberValid = (phonePrefix, phoneNumber) => {
  if (phoneNumber && phonePrefix) {
    const escapedPhoneNumber = phonePrefix + phoneNumber.split(' ').join('');

    return PHONE_REGEX.test(escapedPhoneNumber);
  }

  return false;
};

export const isPostalCodeInputValid = postalCode => POSTAL_CODE_REGEX.test(postalCode);

export const isAnswerValid = (answer, question) => {
  if (!question.isRequired) {
    return true;
  }

  const answerOnlyToTrim =
    question.type === QUESTION_TYPE.INPUT ||
    question.type === QUESTION_TYPE.TEXT ||
    question.type === QUESTION_TYPE.LICENSE_PLATE ||
    question.type === QUESTION_TYPE.SINGLE_CHOICE ||
    question.type === QUESTION_TYPE.PICKUP;

  if (question.type === QUESTION_TYPE.ADDRESS) {
    if (!answer || typeof answer !== 'object') {
      return false;
    }

    const {street, city, postalCode} = {...answer};

    if (!street || !city || !postalCode || !isPostalCodeInputValid(postalCode)) {
      return false;
    }
  }

  if (answerOnlyToTrim) {
    if (!answer) {
      return false;
    }
  }

  return true;
};

export const isPriceValid = itemPrice =>
  !isNaN(itemPrice) && itemPrice >= 0 && itemPrice !== null;

export const validateVariantsCosts = (variantsLists, variants) => {
  if (!variantsLists?.length && !variants?.length) {
    return false;
  }

  return variantsLists.every(variantId =>
    variants.find(variantCost => variantCost?.variantId === variantId)
  );
};
