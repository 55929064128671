import FormInput from '@Components/formComponents/FormInput';
import {MAX_LENGTH, MIN_LENGTH} from '@Utils/checkout';
import locale from '@Utils/locale';
import React from 'react';
import {string} from 'yup';

export const FIELD_NAME = 'lastName';
export const schema = () => string()
  .matches(/^.{3,}$/, {message: locale.translate('minLength', {length: MIN_LENGTH}), excludeEmptyString: true})
  .max(255, locale.translate('maxLength', {length: MAX_LENGTH}));

  interface ILastNameField {
    name?: string,
    label?: string
}

const LastNameField = ({name = FIELD_NAME, label = locale.translate('surname')}: ILastNameField) => (
  <FormInput name={name} componentProps={{label}} />
);

export default LastNameField;
