import Box from '@mui/material/Box';
import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import 'src/scss/_ticket.scss';

const TicketSeparator = () => (
  <Box className="c-ticket__separator">
    <Box
      className="c-ticket__separator-corner c-ticket__separator-corner--sharp c-ticket__separator-corner--left"
    />
    <Box className="c-ticket__separator-center">
      <Box className="c-ticket__separator-divider" />
      <Box className="c-ticket__separator-divider" />
    </Box>
    <Box
      className="c-ticket__separator-corner c-ticket__separator-corner--sharp c-ticket__separator-corner--right"
    />
  </Box>
);

export default TicketSeparator;
