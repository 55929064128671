/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Розпродаж без урахування!',
  'buyOnline': 'Купи онлайн!',
  'buyVoucher': 'Купи ваучер',
  'buyTicket': 'Купи квиток!',
  'bookingOnline': 'Замовляй онлайн',
  'buySkipass': 'Купи скіпас',
  'buyCourse': 'Купи курс',
  'localTours': 'Місцеві екскурсії',
  'shopOnline': 'Інтернет-магазин',
  'orderOnline': 'Замовляйте онлайн',
  'planVisit': 'Заплануйте візит',
  'buyOnlineCheaper': 'Купуйте онлайн дешевше!'
};
