import {Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import React from 'react';

type Props = {
  src: string
}

const LabelIcon = ({src}: Props) => (
  <Box
    sx={{
      maxWidth: 56,
      width: 1,
      height: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: (theme: Theme) => `1px solid ${theme.palette.action.selected}`,
      borderRadius: 1
    }}
  >
    <Box component="img" src={src} />
  </Box>
);

export default LabelIcon;
