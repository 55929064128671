import React from 'react';
import locale from '@Utils/locale';
import {Typography} from '@mui/material';

const TicketsSectionLoadingContent = () => (
  <>
    <Typography sx={{mb: 1, fontWeight: 500}} variant="h5">
      {locale.translate('generatingTickets')}
    </Typography>
    <Typography
      sx={{
        mb: 3,
        color: 'text.secondary'
      }}
    >
      {locale.translate('generatingTicketsDescription')}
    </Typography>
  </>
);

export default TicketsSectionLoadingContent;
