import {IBasketItem} from '@Consts/types';
import locale from '@Utils/locale';

export const getIsVisibleByDefault = (basketItems: IBasketItem[], indicatedActivityId?: number) => {
  if (!indicatedActivityId) {
    return false;
  }

  return basketItems.some(
    ({activityId}) => activityId !== indicatedActivityId
  );
};

const getInputElementValue = (element: Element): number => {
  if (element && element instanceof HTMLInputElement) {
    return parseInt(element.value);
  }

  return 0;
};

const getFirstElementWithValue = (Elements: NodeListOf<Element>) => {
  let firstElementWithValue: Element | null = null;

  Elements.forEach(element => {
    const elementWithValue = getInputElementValue(element) >= 1;

    if (!firstElementWithValue && elementWithValue) {
      firstElementWithValue = element;
    }
  });

  return firstElementWithValue;
};

export const getActivityToScrollInto = () => {
  const numberOfParticipantsFieldLabel = locale.translate('numberOfParticipants');
  const numberOfActivitiesFieldLabel = locale.translate('numberOfActivities');
  const numberOfParticipantsFields = document.querySelectorAll(`[aria-label="${numberOfParticipantsFieldLabel}"]`);
  const numberOfActivitiesFields = document.querySelectorAll(`[aria-label="${numberOfActivitiesFieldLabel}"]`);

  return getFirstElementWithValue(numberOfParticipantsFields) ?? getFirstElementWithValue(numberOfActivitiesFields);
};
