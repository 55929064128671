import React from 'react';
import Box from '@mui/material/Box';
import {SxProps, Theme} from '@mui/material/styles';
import TicketSeparator from './TicketSeparator';

type TTicketProps = {
  topContent: React.ReactElement,
  bottomContent: React.ReactElement,
  topContentStyles?: SxProps<Theme>
  bottomContentStyles?: SxProps<Theme>
}

const Ticket = ({
  topContent,
  bottomContent,
  topContentStyles,
  bottomContentStyles
}: TTicketProps) => (
  <Box>
    <Box className="c-ticket c-ticket--top c-ticket--sharp" sx={topContentStyles} >
      {topContent}
    </Box>
    <TicketSeparator />
    <Box className="c-ticket c-ticket--bottom c-ticket--sharp" sx={bottomContentStyles} >
      {bottomContent}
    </Box>
  </Box>
);

export default Ticket;
