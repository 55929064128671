import {shouldCreateView, shouldMerge} from './checkoutViews';
import {ECheckoutViewsTypes} from './checkoutViewsConsts';
import {hasQuestions, noCreationForQuestionId} from './requirements';

export const createQuestionsViews = basketItems => {
  const creationRequirements = [hasQuestions, noCreationForQuestionId];
  const mergingRequirements = [hasQuestions];

  return basketItems.reduce((creations, basketItem) => {
    if (hasQuestions({basketItem})) {
      basketItem.variant?.questions.forEach(question => {
        if (shouldCreateView({creations, basketItems, question, basketItem, requires: creationRequirements})) {
          creations.push({
            type: ECheckoutViewsTypes.QUESTIONS,
            questionId: question.id,
            items: [basketItem]
          });
        } else if (shouldMerge({basketItem, requires: mergingRequirements})) {
          const relevantCreationId = creations.findIndex(({questionId}) => questionId === question.id);

          creations[relevantCreationId].items.push(basketItem);
        }
      });
    }

    return creations;
  }, []);
};
