import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {EAlignItems, EFlexDirection, EFlexWrap, EJustifyContent} from '@Components/common/flexBox/types';
import FlexBox from '../common/flexBox/FlexBox';
import Header from '../common/header/Header';
import ActivityPreviewContent from './ActivityPreviewContent';

const ActivityPreviewTop = ({basketItem}) => {
  const {activity, variant} = basketItem;
  const {name, passLayout} = activity;
  const fullName = variant?.name ? `${name}, ${variant.name}` : name;

  return (
    <FlexBox wrap={EFlexWrap.NOWRAP} justify={EJustifyContent.SPACE_BETWEEN}>
      <FlexBox direction={EFlexDirection.COLUMN} flex={1}>
        <Header>{fullName}</Header>

        <div className="l-flex-box--flex-1">
          <ActivityPreviewContent basketItem={basketItem} />
        </div>
      </FlexBox>

      {
        passLayout.includes('voucher') && !passLayout.includes('voucher-custom') && (
          <FlexBox
            justify={EJustifyContent.FLEX_END}
            align={EAlignItems.FLEX_START}
            wrap={EFlexWrap.WRAP}
            shrink={1}
          >
            <FlexBox
              justify={EJustifyContent.CENTER}
              direction={EFlexDirection.COLUMN}
              className="c-activity-preview__image-box"
            >
              <img
                className="c-activity-preview__image"
                src={`../img/layout/${passLayout}-blured.png`}
              />
            </FlexBox>
          </FlexBox>
        )
      }
    </FlexBox>
  );
};

export default ActivityPreviewTop;
