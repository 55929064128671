import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {findLatestOrder} from '@Features/order/orderUtils';
import {getStorageLatestOrders} from '@Features/storage/storageSelectors';
import {isOrderPreview} from '@Utils/order';
import {useSelector} from 'react-redux';

export const useLatestOrderStorageData = () => {
  const {onlineGroupId, widgetType, facilityId} = useSelector(getConfiguration);
  const latestOrders = useSelector(getStorageLatestOrders);
  const latestOrder = findLatestOrder(latestOrders, onlineGroupId, widgetType, facilityId);

  let latestOrderStorageData = null;

  if (latestOrder) {
    latestOrderStorageData = {
      facilityId: latestOrder.facilityId,
      orderType: latestOrder.orderType,
      code: latestOrder.code,
      orderId: latestOrder.orderId,
      isEventsSent: !!latestOrder.isEventsSent,
      onlineGroupId: latestOrder.onlineGroupId,
      widgetType: latestOrder.widgetType,
      isPreviewMode: isOrderPreview(latestOrder.orderType),
      basket: latestOrder?.basket,
      email: latestOrder?.email,
      timestamp: latestOrder?.timestamp
    };
  }

  return {latestOrderStorageData: latestOrderStorageData ?? null, latestOrders};
};
