import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import FlexBox from '@Components/common/flexBox/FlexBox';
import locale from '@Utils/locale';
import Text from '@Components/common/text/Text';
import {getFormattedPrice} from '@Utils/format';
import {findAddition, haveSomeElementToRender} from './additionsUtils';
import AdditionsCounter from './AdditionsCounter';
import {ETagType, ETextDisplay, ETextSize, ETextWeight} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection, EJustifyContent} from '@Components/common/flexBox/types';

const ActivityAddition = ({
  activityName,
  addition,
  basketItems,
  additionViewId
}) => {
  const {id: additionId, name: additionName, price, isPricePerParticipant} = addition;
  const {amount, currency} = price;

  const amountFormatted =
    <Text weight={ETextWeight.BOLD}>{getFormattedPrice(amount)} {locale.translate(`${currency}`)}</Text>;
  const priceFormatted = (
    <Text display={ETextDisplay.INLINE} size={ETextSize.MEDIUM}>
      {amountFormatted}{isPricePerParticipant && <span> / {locale.translate('perPersonAbbr')}</span>}
    </Text>
  );

  const basketItemsList = basketItems.map((basketItem, index) => {
    const {activity, variantId, uid, variant, selections: {numberOfSeats: selectionNumberOfSeats}} = basketItem;
    const {additions} = activity;
    const {numberOfSeats, name: variantName} = variant;
    const formattedName = variantName && activityName ? activityName + ', ' + variantName : activityName;
    const withVariantName = basketItems.length > 1;

    if (findAddition(additions, additionId, variantId)) {
      return (
        <li className="dl-variant-additions" key={`dl-variant-additions-section-${index}`}>
          <FlexBox
            direction={EFlexDirection.COLUMN}
            key={`dl-variant-addition-${index}`}
            className="dl-variant-addition"
          >
            {
              withVariantName &&
                <Text tagType={ETagType.P} className={'dl-variant-addition--name'} wrap>{formattedName}</Text>
            }
            <FlexBox
              align={EAlignItems.CENTER}
              justify={EJustifyContent.FLEX_END}
            >
              {
                isPricePerParticipant && (
                  <span className={'dl-variant-addition--pp'}>
                    {locale.translate('perPersonNumber', {number: numberOfSeats || selectionNumberOfSeats})}
                  </span>
                )
              }
              <AdditionsCounter
                key={`dl-variant-addition-${index}`}
                name="quantity"
                basketItem={basketItem}
                uid={uid}
                variantId={variantId}
                addition={addition}
                numberOfSeats={numberOfSeats || selectionNumberOfSeats}
                className="dl-addition__counter"
              />
            </FlexBox>
          </FlexBox>
        </li>
      );
    }

    return null;
  });

  if (haveSomeElementToRender(basketItemsList)) {
    return (
      <ul className="dl-mb-3 dl-activity-additions" key={additionViewId}>
        <FlexBox justify={EJustifyContent.SPACE_BETWEEN} className={'dl-variant-addition--header'}>
          <Text className="dl-addition-name" weight={ETextWeight.BOLD} wrap>
            {additionName}
          </Text>
          <div>
            {priceFormatted}
          </div>
        </FlexBox>
        {basketItemsList}
      </ul>
    );
  }

  return null;
};

export default ActivityAddition;
