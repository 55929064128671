/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Výpredaj vylúčený!',
  'buyOnline': 'Kúp online!',
  'buyVoucher': 'Kúpte si voucher',
  'buyTicket': 'kúpiť lístok!',
  'bookingOnline': 'Rezervujte online',
  'buySkipass': 'Kúpte si skipas',
  'buyCourse': 'Kúpte si kurz',
  'localTours': 'Miestne výlety',
  'shopOnline': 'Internetový obchod',
  'orderOnline': 'Objednajte si online',
  'planVisit': 'Naplánujte si návštevu',
  'buyOnlineCheaper': 'Kúpte si online lacnejšie!'
};
