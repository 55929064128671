import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '@Components/common/text/Text';
import PropTypes from 'prop-types';
import ActivityContent from './ActivityContent';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import {ETextColor} from '@Components/common/text/types';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const ActivityBooking = ({bookingInformation}) => {
  const {isRequired, website, email, phone, description} = bookingInformation;

  function getBestContact() {
    if (website) {
      return locale.translate('website');
    }

    if (email) {
      return locale.translate('bookingEmail');
    }

    if (phone) {
      return locale.translate('phone');
    }

    return null;
  }

  let bookingText = locale.translate('bookingRequired');
  const bestContact = getBestContact();

  if (isRequired && bestContact) {
    bookingText = replaceByElement(locale.translate('bookingAfterBuy'), bestContact).join('');
  }

  if (isRequired) {
    const iconProps = {sx: {mr: 1, color: 'background.text'}, fontSize: 'small'};

    return (
      <>
        <ActivityContent>
          { website && <LanguageOutlinedIcon {...iconProps} /> }
          { email && <MailOutlineOutlinedIcon {...iconProps} /> }
          { phone && <PhoneOutlinedIcon {...iconProps} /> }
          <Text color={ETextColor.LIGHT}>{bookingText}</Text>
        </ActivityContent>

        {
          description && (
            <ActivityContent>
              <AssignmentOutlinedIcon {...iconProps} />
              <Text color={ETextColor.LIGHT} wrap>{description}</Text>
            </ActivityContent>
          )
        }
      </>
    );
  }

  return null;
};

ActivityBooking.propTypes = {
  bookingInformation: PropTypes.shape({
    description: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    isRequired: PropTypes.bool
  })
};

export default ActivityBooking;
