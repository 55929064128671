export const SHAPE_TYPES = {
  TAB: 'TAB',
  TAB_MOBILE: 'TAB-MOBILE',
  BUBBLE: 'BUBBLE'
};

const createIcon = (size: number, color: string, content: string) => {
  const SVG_NS = 'http://www.w3.org/2000/svg';
  const basketIconBubble = document.createElementNS(SVG_NS, 'svg');

  basketIconBubble.setAttribute('width', size.toString());
  basketIconBubble.setAttribute('height', size.toString());

  const path = document.createElementNS(SVG_NS, 'path');

  path.setAttributeNS(null, 'fill', color);
  path.setAttributeNS(null, 'd', content);

  basketIconBubble.appendChild(path);

  return basketIconBubble;
};

const createBasketIcon = (shape: string, color: string) => {
  if (shape === SHAPE_TYPES.TAB) {
    const content = 'M17.1875 20.3125C18.05 20.3125 18.75 21.0125 18.75 21.875C18.75 22.7375 18.05 23.4375 17.1875 23.4375C16.325 23.4375 15.625 22.7375 15.625 21.875C15.625 21.0125 16.325 20.3125 17.1875 20.3125ZM14.0625 21.875C14.0625 23.6008 15.4617 25 17.1875 25C18.9133 25 20.3125 23.6008 20.3125 21.875C20.3125 20.1492 18.9133 18.75 17.1875 18.75C15.4617 18.75 14.0625 20.1492 14.0625 21.875ZM4.6875 4.6875H23.4375L21.4398 12.6789L4.6875 14.0625V4.6875ZM6.25 17.1875C5.3875 17.1875 4.6875 16.4875 4.6875 15.625L22.6562 14.0625C22.6562 14.0625 25 4.58984 25 3.90625C25 3.47422 24.6508 3.125 24.2188 3.125H4.6875V1.5625H5.46875C5.90078 1.5625 6.25 1.21328 6.25 0.78125C6.25 0.35 5.90078 0 5.46875 0H0.78125C0.349219 0 0 0.35 0 0.78125C0 1.21328 0.349219 1.5625 0.78125 1.5625H3.125V15.625C3.125 17.3508 4.52422 18.75 6.25 18.75H24.2188C24.243 18.75 24.2188 17.9758 24.2188 17.1875H6.25ZM7.8125 20.3125C8.675 20.3125 9.375 21.0125 9.375 21.875C9.375 22.7375 8.675 23.4375 7.8125 23.4375C6.95 23.4375 6.25 22.7375 6.25 21.875C6.25 21.0125 6.95 20.3125 7.8125 20.3125ZM4.6875 21.875C4.6875 23.6008 6.08672 25 7.8125 25C9.53828 25 10.9375 23.6008 10.9375 21.875C10.9375 20.1492 9.53828 18.75 7.8125 18.75C6.08672 18.75 4.6875 20.1492 4.6875 21.875V21.875Z'; // eslint-disable-line max-len
    const basketIconBubble = createIcon(25, color, content);

    return basketIconBubble;
  }

  if (shape === SHAPE_TYPES.TAB_MOBILE) {
    const content = 'M11 13C11.552 13 12 13.448 12 14C12 14.552 11.552 15 11 15C10.448 15 10 14.552 10 14C10 13.448 10.448 13 11 13ZM9 14C9 15.1045 9.8955 16 11 16C12.1045 16 13 15.1045 13 14C13 12.8955 12.1045 12 11 12C9.8955 12 9 12.8955 9 14ZM3 3H15L13.7215 8.1145L3 9V3ZM4 11C3.448 11 3 10.552 3 10L14.5 9C14.5 9 16 2.9375 16 2.5C16 2.2235 15.7765 2 15.5 2H3V1H3.5C3.7765 1 4 0.7765 4 0.5C4 0.224 3.7765 0 3.5 0H0.5C0.2235 0 0 0.224 0 0.5C0 0.7765 0.2235 1 0.5 1H2V10C2 11.1045 2.8955 12 4 12H15.5C15.5155 12 15.5 11.5045 15.5 11H4ZM5 13C5.552 13 6 13.448 6 14C6 14.552 5.552 15 5 15C4.448 15 4 14.552 4 14C4 13.448 4.448 13 5 13ZM3 14C3 15.1045 3.8955 16 5 16C6.1045 16 7 15.1045 7 14C7 12.8955 6.1045 12 5 12C3.8955 12 3 12.8955 3 14V14Z'; // eslint-disable-line max-len
    const basketIconBubble = createIcon(16, color, content);

    return basketIconBubble;
  }

  const content = 'M11 13C11.552 13 12 13.448 12 14C12 14.552 11.552 15 11 15C10.448 15 10 14.552 10 14C10 13.448 10.448 13 11 13ZM9 14C9 15.1045 9.8955 16 11 16C12.1045 16 13 15.1045 13 14C13 12.8955 12.1045 12 11 12C9.8955 12 9 12.8955 9 14ZM3 3H15L13.7215 8.1145L3 9V3ZM4 11C3.448 11 3 10.552 3 10L14.5 9C14.5 9 16 2.9375 16 2.5C16 2.2235 15.7765 2 15.5 2H3V1H3.5C3.7765 1 4 0.7765 4 0.5C4 0.224 3.7765 0 3.5 0H0.5C0.2235 0 0 0.224 0 0.5C0 0.7765 0.2235 1 0.5 1H2V10C2 11.1045 2.8955 12 4 12H15.5C15.5155 12 15.5 11.5045 15.5 11H4ZM5 13C5.552 13 6 13.448 6 14C6 14.552 5.552 15 5 15C4.448 15 4 14.552 4 14C4 13.448 4.448 13 5 13ZM3 14C3 15.1045 3.8955 16 5 16C6.1045 16 7 15.1045 7 14C7 12.8955 6.1045 12 5 12C3.8955 12 3 12.8955 3 14V14Z'; // eslint-disable-line max-len
  const basketIconBubble = createIcon(16, color, content);

  return basketIconBubble;
};

export default createBasketIcon;
