import UnorderedList from '@Components/list/UnorderedList';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import React from 'react';

const DiscountGenericError = () => (
  <Box data-testid="discount-generic-error">
    <UnorderedList
      header={locale.translate('discountCode')}
      listElements={[
        locale.translate('isExpired'),
        locale.translate('isInvalid'),
        locale.translate('isUsed'),
        locale.translate('isAdmissionDateInvalid')
      ]}
    />
  </Box>
);

export default DiscountGenericError;
