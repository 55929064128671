import TextField, {BaseTextFieldProps} from '@mui/material/TextField';
import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {PHONE_PREFIXES} from '@Components/checkoutPages/agreements/phoneNumberSection/PhoneNumberPrefixes';
import {Autocomplete, Box} from '@mui/material';

interface IFormPrefixInput {
  name: string,
  componentProps: BaseTextFieldProps
}

export interface IPhonePrefix {
  countryCode: string | null;
  value: string | null;
  text: string;
  disabled?: boolean;
}

const FormPrefixInput = ({
  componentProps,
  name
}: IFormPrefixInput) => {
  const {control} = useFormContext();
  const {label, variant} = componentProps;
  const isOptionEqualToValue = (option: IPhonePrefix, value: IPhonePrefix) => option.value === value.value;

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref, onBlur, ...field}, fieldState: {invalid, error}}) => (
        <Autocomplete
          data-testid="autocomplete"
          sx={{width: 1, maxWidth: '160px'}}
          options={PHONE_PREFIXES}
          autoHighlight
          value={value}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={option => `${option.text}`}
          getOptionDisabled={option => option.disabled === true}
          onChange={(_, data) => onChange(data)}
          onBlur={onBlur}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.text}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              {...field}
              label={label}
              variant={variant}
              name={name}
              inputRef={ref}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
              error={invalid}
              helperText={error?.message ?? ''}
            />
          )}
        />
      )
      }
    />
  );
};

export default FormPrefixInput;
