import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import SalePolicy from '@Components/termsOfService/SalePolicy';
import {getFacility} from '@Features/facility/facilitySelectors';
import {useSelector} from 'react-redux';
import CheckoutPaymentProvider from './CheckoutPaymentProvider';

const CheckoutOnlineSaleFooter = () => {
  const facility = useSelector(getFacility);
  const {company} = facility;
  const {paymentProvider} = company;

  return (
    <div className="dl-dotpay__online-sale-footer">
      <div className="dl-mb-2" >
        <SalePolicy facility={facility} smallText />
      </div>

      {
        paymentProvider &&
          <CheckoutPaymentProvider paymentProvider={paymentProvider} />
      }
    </div>
  );
};

export default CheckoutOnlineSaleFooter;
