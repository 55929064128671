import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import ActivityContent from '../ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';

const ActivityAdmissionSkipLine = () => (
  <ActivityContent>
    <DirectionsRunOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
    <Text color={ETextColor.LIGHT}>{locale.translate('skippTheLine')}</Text>
  </ActivityContent>
);

export default ActivityAdmissionSkipLine;
