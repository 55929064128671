/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Saldi esclusi!',
  'buyOnline': 'Acquista online!',
  'buyVoucher': 'Acquista il voucher',
  'buyTicket': 'Acquista il biglietto!',
  'bookingOnline': 'Prenota online',
  'buySkipass': 'Acquista lo skipass',
  'buyCourse': 'Acquista il corso',
  'localTours': 'Visite locali',
  'shopOnline': 'Negozio online',
  'orderOnline': 'Ordina online',
  'planVisit': 'Pianifica la tua visita',
  'buyOnlineCheaper': 'Online più economico!'
};
