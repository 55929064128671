import {EPassType} from '@Consts/apiGlobals';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {isActivitySinglePickDate, isActivityWithDateTimes} from '@Utils/activity/activity';
import {getMonth, getTimezoneDate, isSameDate, getDate} from '@Utils/dayjs/dayjsUtils';
import {Dayjs} from 'dayjs';
import {IDatesGroupedByDay} from './types';
import {DOT_DAY_FORMAT_REVERSED_WITH_DAY_SHORT} from '@Utils/dayjs/const';
import {IBasketItemSelections} from '@Consts/types';
import {createCurrentDate, createDate, getFormattedDays, getFormattedDaysAndTime} from '@Utils/dates/timezoneDates';

export const getCalendarValueMonthAndYear = (date: Dayjs) => ({
  month: date.get('month'),
  year: date.get('year')
});

export const getCalendarValue = (
  timezone: string,
  firstAdmissionDate: string | null,
  selectedDate?: string
) => {
  if (selectedDate) {
    return getTimezoneDate(selectedDate, timezone);
  }

  return getTimezoneDate(firstAdmissionDate, timezone);
};

const isFirstAdmissionDateEqual = (admissionDate: string, firstAdmissionDate: string) =>
  isSameDate(getDate(firstAdmissionDate), getDate(admissionDate), 'date');

export const getShouldSelectDateGroup = (datesGroupedByDay: IDatesGroupedByDay[], firstAdmissionDate: string) => {
  const admissionDate = datesGroupedByDay[0].date;
  const isOutOfLimit = datesGroupedByDay[0].metaData.isOutOfLimit;

  return !isOutOfLimit && isFirstAdmissionDateEqual(admissionDate, firstAdmissionDate);
};

export const getShouldSelectDayOffer = (dayOffers: DayOffersProcessed[], firstAdmissionDate: string) => {
  const admissionDate = dayOffers[0].originalDate;

  return isFirstAdmissionDateEqual(admissionDate, firstAdmissionDate);
};

export const getShouldSelectDefaultDate = (
  firstAdmissionDate: string | null,
  isOneAdmissionDateAvailable: boolean,
  passType: EPassType,
  initialSelectedDate: Dayjs | null,
  datesGroupedByDay?: IDatesGroupedByDay[] | null,
  dayOffers?: DayOffersProcessed[]
) => {
  const isCommonConditionsMeet = isOneAdmissionDateAvailable && firstAdmissionDate && !initialSelectedDate;

  if (!isCommonConditionsMeet) {
    return false;
  }

  if (isActivitySinglePickDate(passType) && datesGroupedByDay?.length) {
    return getShouldSelectDateGroup(datesGroupedByDay, firstAdmissionDate);
  }

  if (isActivityWithDateTimes(passType) && dayOffers) {
    return getShouldSelectDayOffer(dayOffers, firstAdmissionDate);
  }

  return false;
};

const getDatesGroupsFromMonth = (datesGroupedByDay: IDatesGroupedByDay[], month: number) =>
  datesGroupedByDay.filter(groupedDates => getMonth(getDate(groupedDates.date)) === month);

export const updateBargainPrices = (datesGroupedByDay: IDatesGroupedByDay[]) => {
  if (!datesGroupedByDay.length) {
    return datesGroupedByDay;
  }

  const month = getMonth(getDate(datesGroupedByDay[0].date));
  const datesGroupedByDayRestrictedByMonth = getDatesGroupsFromMonth(datesGroupedByDay, month);

  const dateGroupsSortedByTotalPrice =
    [...datesGroupedByDayRestrictedByMonth].sort((a, b) => a.metaData.totalPrice - b.metaData.totalPrice);

  const groupsMinPrice = dateGroupsSortedByTotalPrice[0].metaData.totalPrice;
  const groupsMaxPrice = dateGroupsSortedByTotalPrice[dateGroupsSortedByTotalPrice.length - 1].metaData.totalPrice;

  const isPriceChangePerMonth = groupsMinPrice - groupsMaxPrice < 0;

  return datesGroupedByDay.map(dateGroup => ({
    ...dateGroup,
    metaData: {
      ...dateGroup.metaData,
      isBargainPrice: isPriceChangePerMonth ? dateGroup.metaData.totalPrice === groupsMinPrice : false
    }
  }));
};

export const getSummaryDetails = (
  isDateTimes: boolean,
  isDatesRange: boolean,
  selections: IBasketItemSelections
) => {
  const {time, dates} = selections;

  if (isDateTimes && time) {
    const date = time.date ? createDate(time.date) : createCurrentDate();
    const {catalogPriceDescription, catalogPrice} = time;

    return {
      price: time?.totalPrice,
      catalogPrice,
      catalogPriceDescription,
      currency: time?.currency,
      date: getFormattedDaysAndTime(date)
    };
  }

  if (isDatesRange && dates) {
    const firstDate = createDate(dates[0].date);
    const lastDate = createDate(dates[dates.length - 1].date);
    const isPricePerAdmissionDate = dates[0].group[0].isPricePerAdmissionDate;

    return {
      price: isPricePerAdmissionDate ? dates.reduce((sum, date) => sum + date.metaData.totalPrice, 0) :
        dates[0].metaData.totalPrice,
      catalogPrice: null,
      catalogPriceDescription: null,
      currency: dates[0].metaData.currency,
      firstDate: getFormattedDays(firstDate, DOT_DAY_FORMAT_REVERSED_WITH_DAY_SHORT),
      lastDate: getFormattedDays(lastDate, DOT_DAY_FORMAT_REVERSED_WITH_DAY_SHORT)
    };
  } else if (selections.dates) {
    const dates = selections.dates;
    const date = createDate(dates[0].date);
    const {catalogPriceDescription, catalogPrice} = dates[0].group[0];

    return {
      price: dates[0].metaData.totalPrice,
      catalogPrice,
      catalogPriceDescription,
      currency: dates[0].metaData.currency,
      date: getFormattedDays(date)
    };
  } else {
    return null;
  }
};
