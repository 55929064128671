/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Verkauf ausgeschlossen!',
  'buyOnline': 'Online kaufen!',
  'buyVoucher': 'Gutschein kaufen',
  'buyTicket': 'Ticket kaufen!',
  'bookingOnline': 'Online buchen',
  'buySkipass': 'Skipass kaufen',
  'buyCourse': 'Kurs kaufen',
  'localTours': 'Lokale Touren',
  'shopOnline': 'Online-Geschäft',
  'orderOnline': 'Online bestellen',
  'planVisit': 'Besuch planen',
  'buyOnlineCheaper': 'Online kaufen und sparen!'
};
