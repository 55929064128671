import locale from '@Utils/locale';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const TicketsLoadingContent = () => (
  <Stack sx={{flexDirection: 'column', alignItems: 'center'}}>
    <Typography sx={{mt: 2, fontWeight: 500, textAlign: 'center'}} variant="h5">
      {locale.translate('generatingTickets')}
    </Typography>
    <Typography sx={{my: 2, maxWidth: 360, textAlign: 'center', color: 'text.secondary'}} >
      {locale.translate('generatingTicketsDescription')}
    </Typography>
    <Button size="large" variant="contained" disabled sx={{width: {xs: 1, sm: 'auto'}, mb: 2}}>
      <CircularProgress color="inherit" size={16} sx={{mr: 1}} />
      {locale.translate('generating')}
    </Button>
  </Stack>
);

export default TicketsLoadingContent;
