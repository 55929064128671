import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import Text from '@Components/common/text/Text';
import locale from '@Utils/locale';
import {
  isActivitySingleGroupEvent,
  isActivitySinglePickDate,
  isActivitySinglePickDatetime,
  isActivityWithDateTimes,
  hasActivityValidPrice
} from '@Utils/activity/activity';
import ActivityAdmissionSingle from './ActivityAdmissionSingle';
import ActivityContent from '../ActivityContent';
import {getFormattedDate} from '@Utils/date';
import {ETextColor} from '@Components/common/text/types';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {formatRealizationDate} from '@Utils/activity/nearestDate';

const ActivityAdmissionFixedDate = ({activity, selections, hideIcon, parsedDuration}) => {
  const {passType, admissionPolicy} = activity;
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const {firstAdmissionDate, isOneAdmissionDateAvailable} = admissionPolicy;
  const selectedDate = selections?.realisationDate || null;
  const placeholder = '--/--/----';

  function getFormattedDateBasedOnPassType() {
    if (selectedDate) {
      if (isActivitySinglePickDate(passType) || isActivitySingleGroupEvent(passType)) {
        return getFormattedDate(selectedDate);
      }

      if (isActivitySinglePickDatetime(passType)) {
        return getFormattedDate(selectedDate, true);
      }

      return placeholder;
    }

    return placeholder;
  }

  if (selections) {
    const {realisatonDate} = selections;

    return (
      <ActivityContent>
        {
          !hideIcon && (
            <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
          )
        }

        <Text color={ETextColor.LIGHT}>
          {locale.translate('dateOfUse')}: {getFormattedDateBasedOnPassType(realisatonDate, passType)}
        </Text>
      </ActivityContent>
    );
  }

  return (
    <>
      <ActivityAdmissionSingle
        admissionPolicy={admissionPolicy}
        parsedDuration={parsedDuration}
        hideIcon={hideIcon}
      />

      <ActivityContent>
        {
          !hideIcon &&
          <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        {
          activityHaveValidPrices && isOneAdmissionDateAvailable ?
            <Text color={ETextColor.LIGHT}>
              {
                locale.translate('realiseInDay', {
                  date: formatRealizationDate(firstAdmissionDate, isActivityWithDateTimes(passType))
                })
              }
            </Text> :
            <Text color={ETextColor.LIGHT}>{locale.translate('pickFixedDateInTheNextStep')}</Text>

        }
      </ActivityContent>
    </>
  );
};

ActivityAdmissionFixedDate.propTypes = {
  activity: PropTypes.shape({
    passType: PropTypes.string.isRequired,
    admissionPolicy: PropTypes.object.isRequired
  }).isRequired,
  selections: PropTypes.object,
  hideIcon: PropTypes.bool
};

export default ActivityAdmissionFixedDate;
