import {apiGet} from '@Utils/api/api';
import {EShippingMethodActionsTypes, TShippingMethodsActions} from './types';
import {IShippingMethod} from '@Consts/apiGlobals';
import {ThunkAction} from 'redux-thunk';
import {AnyAction} from 'redux';
import {getFacilityShippingMethods} from './shippingMethodUtils';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {IStore} from 'src/js/store/types';
import {EStatus} from '@Consts/status';

export const setShippingMethods = (shippingMehtods: IShippingMethod[]): TShippingMethodsActions => ({
  type: EShippingMethodActionsTypes.SET_SHIPPING_METHODS,
  payload: shippingMehtods
});

export const setShippingMethodsStatus = (status: EStatus): TShippingMethodsActions => ({
  type: EShippingMethodActionsTypes.SET_SHIPPING_METHODS_STATUS,
  payload: status
});

export const clearShippingMethods = (): TShippingMethodsActions => ({
  type: EShippingMethodActionsTypes.CLEAR_SHIPPING_METHODS
});

export const fetchShippingMethods = (): ThunkAction<
  void,
  IStore,
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  try {
    dispatch(setShippingMethodsStatus(EStatus.IN_PROGRESS));

    const configuration = getConfiguration(getState());
    const shippingMethods = await apiGet(getFacilityShippingMethods(configuration), configuration);

    dispatch(setShippingMethods(shippingMethods));
    dispatch(setShippingMethodsStatus(EStatus.SUCCESS));
  } catch (error) {
    dispatch(setShippingMethodsStatus(EStatus.FAILURE));
    throw error;
  }
};
