import React, {SyntheticEvent} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {ReactElement} from 'react-markdown/lib/react-markdown';
import Checkbox, {CheckboxProps} from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {FormHelperText, SxProps, Theme, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import {setStorage} from '@Features/storage/storageActions';

const chekboxErrorStyles = (theme: Theme) => ({
  color: theme.palette.error.main
});

interface IFormCheckboxProps {
  name: string,
  label: JSX.Element | string,
  helperText?: string | ReactElement,
  indeterminate?: boolean,
  onChangeEffect?: (arg0: SyntheticEvent<Element, Event>, arg1: boolean) => void,
  labelStyles?: SxProps<Theme>,
  componentProps?: CheckboxProps,
  saveInStorage?: boolean
}

const FormCheckbox = ({
  name,
  label,
  helperText,
  indeterminate,
  onChangeEffect,
  labelStyles,
  componentProps,
  saveInStorage
}: IFormCheckboxProps) => {
  const {control} = useFormContext();
  const dispatch = useDispatch();

  function handleChange(checked: boolean) {
    if (saveInStorage) {
      dispatch(setStorage(name, checked, true));
    }
  }

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref}, fieldState: {invalid, error}}) => (
        <>
          <FormControlLabel
            name={name}
            label={
              <Typography component="div" sx={labelStyles}>
                {label}
              </Typography>
            }
            control={
              <Checkbox
                checked={value}
                onChange={(event, checked) => {
                  onChange(event, checked);
                  onChangeEffect?.(event, checked);
                  handleChange(checked);
                }}
                ref={ref}
                indeterminate={indeterminate}
                {...componentProps}
                {...!!invalid && {sx: chekboxErrorStyles}}
              />
            }
          />
          {
            invalid ? (
              <FormHelperText
                error={invalid}
                sx={{mb: 1}}
              >
                {error?.message}
              </FormHelperText>
            ) :
              helperText ? (
                <FormHelperText
                  component="div"
                  sx={{mb: 1, wordBreak: 'break-word'}}
                >
                  {helperText}
                </FormHelperText>
              ) : null

          }
        </>
      )}
    />
  );
};

export default FormCheckbox;
