import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import locale from '@Utils/locale';
import {EmailNotGetThroughTooltip} from './emailNotGetThroughTooltip/EmailNotGetThroughTooltip';

const EmailInfo = () => {
  const theme = useTheme();

  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Typography align="center" sx={{color: theme.palette.text.secondary}}>
        {locale.translate('emailNotGetThrough')}
      </Typography>
      <EmailNotGetThroughTooltip />
    </Box>
  );
};

export default EmailInfo;
