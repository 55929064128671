import {responseData} from '../types';

class ResponseError extends Error {
  status: number;
  data: responseData;

  constructor(message: string, status: number, data: responseData) {
    super(message);
    this.message = message;
    this.status = status;
    this.data = data;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export default ResponseError;
