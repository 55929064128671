import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetInternal,
  isWidgetInternalPreview,
  isWidgetOnlineClientPreview
} from '@Utils/widgetType';
import locale from '@Utils/locale';

export const getAdditionsEndpoint = (
  {
    facilityId,
    widgetType,
    onlineGroupId,
    groupId,
    internalBuyerFacilityId,
    businessGroupId
  },
  activityId
) => {
  const localeParam = `locale=${locale.language}`;

  if (isWidgetBusinessPreview(widgetType)) {
    return `company-api/business/facilities/${facilityId}/business_groups/${businessGroupId}/activities/${activityId}/additions/preview?${localeParam}`; // eslint-disable-line max-len
  }

  if (isWidgetBusiness(widgetType)) {
    return `company-api/business/facilities/${facilityId}/activities/${activityId}/additions?${localeParam}`;
  }

  if (isWidgetInternal(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/activities/${activityId}/additions?${localeParam}`;
  }

  if (isWidgetInternalPreview(widgetType)) {
    return `company-api/internal/facilities/${facilityId}/buyer_facilities/${internalBuyerFacilityId}/activities/${activityId}/additions?${localeParam}`;// eslint-disable-line max-len
  }

  const groupRoute = onlineGroupId ? onlineGroupId : groupId ? groupId : 1;

  if (isWidgetOnlineClientPreview(widgetType)) {
    return `company-api/online/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/additions/preview?${localeParam}`; // eslint-disable-line max-len
  }

  return `user-api/facilities/${facilityId}/online_groups/${groupRoute}/activities/${activityId}/additions?${localeParam}`; // eslint-disable-line max-len
};
