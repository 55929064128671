import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import locale from '@Utils/locale';
import Select from '@Components/common/form/Select';
import Header from '@Components/common/header/Header';
import {createRange} from '@Utils/utils';
import {getNumberOfDaysFormatted} from '@Utils/format';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {Select as SelectMUI} from '@mui/material';
import CalendarSectionHeader from '@Components/calendar/CalendarSectionHeader';

const NumberOfDaysSection = ({basketItems, variant, className, isTestB}) => {
  const dispatch = useDispatch();
  const {numberOfDays} = basketItems[0].selections;
  const {
    numberOfAdmissionDatesMin: min,
    numberOfAdmissionDatesMax: max
  } = variant;

  const options = createRange(min, max)
    .map((number, index) => ({
      value: index + min,
      text: getNumberOfDaysFormatted(index + min)
    }));

  function handleChange({target}) {
    const numberOfDays = parseInt(target.value, 10);

    return dispatch(updateBasketItemsSelections(
      basketItems, {dates: null, numberOfDays}
    ));
  }

  if (options?.length === 1) {
    return null;
  }

  return (
    <>
      {isTestB ? (
        <Box sx={{mb: 3}}>
          <CalendarSectionHeader text={locale.translate('chooseNumberOfDays')} />
          <FormControl
            sx={{
              width: 1,
              maxWidth: {md: 217}
            }}
          >
            <InputLabel id="number-of-days-label">{locale.translate('daysQuanity')}</InputLabel>
            <SelectMUI
              labelId="number-of-days-label"
              id="number-of-days-select"
              value={numberOfDays}
              label={locale.translate('daysQuanity')}
              onChange={handleChange}
              MenuProps={{
                MenuListProps: {sx: {p: 0, maxHeight: 408}},
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                marginThreshold: 0,
                autoFocus: false,
                disableAutoFocus: true
              }}
            >
              {
                options.map(({text, value}) => (
                  <MenuItem
                    value={value}
                    key={`${value}+${text}`}
                    sx={{
                      py: 1.5,
                      px: 0,
                      pl: 2
                    }}
                  >{text}
                  </MenuItem>
                ))
              }
            </SelectMUI>
          </FormControl>
        </Box>
      ) : (
        <div className={className}>
          <Header>
            {locale.translate('chooseNumberOfDays')}
          </Header>

          <div className="dl-mt-2">
            <Select
              name="numberOfDays"
              onChange={handleChange}
              options={options}
              selected={numberOfDays}
            />
          </div>
        </div>
      )}
    </>
  );
};

NumberOfDaysSection.propTypes = {
  basketItems: PropTypes.array.isRequired,
  variant: PropTypes.object.isRequired,
  className: PropTypes.string,
  isTestB: PropTypes.bool
};

export default NumberOfDaysSection;
