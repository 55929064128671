import React from 'react';
import locale from '@Utils/locale';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Theme, useMediaQuery} from '@mui/material';

interface IButtons {
  isFetchingOrderError: boolean,
  handleCancel: () => void,
  handleReinitializeOrderPayment: () => void,
  repeatBtnIsDisabled: boolean
}

const Buttons = ({
  isFetchingOrderError,
  handleCancel,
  handleReinitializeOrderPayment,
  repeatBtnIsDisabled
}: IButtons) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack
      direction={isDesktop ? 'row' : 'column-reverse'}
      spacing={isDesktop ? 1 : 2}
      sx={{width: {xs: '100%', md: 'auto'}, px: 1}}
    >
      <Button
        sx={{width: {xs: '100%', md: 'auto'}}}
        variant="text"
        color={isFetchingOrderError ? 'error' : 'primary'}
        size="large"
        onClick={() => handleCancel()}
      >
        {
          isFetchingOrderError ?
            locale.translate('newOrder') :
            locale.translate('cancel')
        }
      </Button>
      <Button
        sx={{width: {xs: '100%', md: 'auto'}}}
        variant={isFetchingOrderError ? 'contained' : 'text'}
        color={isFetchingOrderError ? 'error' : 'primary'}
        size="large"
        onClick={handleReinitializeOrderPayment}
        disabled={repeatBtnIsDisabled}
      >
        {
          isFetchingOrderError ?
            locale.translate('rePayment') :
            locale.translate('sendAgain')
        }
      </Button>
    </Stack>
  );
};

export default Buttons;
