import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import {getCheckoutPageListItemContent} from '@Utils/checkout';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const CheckoutHeader = ({checkoutView, onClick}) => {
  function handleClick() {
    return onClick(checkoutView.id);
  }

  return (
    <div
      className="dl-dotpay dl-dotpay--alternative-layout dl-dotpay--clickable"
      onClick={handleClick}
    >
      <div className="dl-extendable__content">
        <div
          className="dl-header js-header-text dl-extendable__content-text"
        >
          {getCheckoutPageListItemContent(checkoutView)}
        </div>
      </div>

      <div className="dl-extendable__more">
        <KeyboardArrowDownOutlinedIcon />
      </div>
    </div>
  );
};

export default CheckoutHeader;
