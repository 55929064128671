import {SET_STORAGE_TO_STATE, REMOVE_STORAGE_FROM_STATE, SET_STORAGE_ITEMS_TO_STATE} from './storageActions';

export const initialState = {isStorageInitializated: false};

const storageReducer = (state = initialState, action = {type: null}) => {

  switch (action.type) {
    case SET_STORAGE_TO_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case SET_STORAGE_ITEMS_TO_STATE: {
      return {
        ...state,
        ...action.payload.storageItems,
        isStorageInitializated: true
      };
    }

    case REMOVE_STORAGE_FROM_STATE: {
      return {
        ...state,
        [action.payload.key]: null
      };
    }

    default:
      return state;
  }
};

export default storageReducer;
