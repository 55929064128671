import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import Text from '@Components/common/text/Text';
import locale from '@Utils/locale';
import {ON_SITE} from '@Consts/payment';
import {BUSINESS_SALE_TERMS_OF_SERVICE_URL} from '@Consts/urls';
import {ETextSize, ETextTransform} from '@Components/common/text/types';

const BusinessSaleAgreement = ({paymentType, company}) => {
  const {name, nip, businessSaleTermsOfServiceUrl} = company;
  const companyNip = nip ? nip : '-';
  const defaultTermLink = (
    <a
      className="dl-text--emphasized"
      href={BUSINESS_SALE_TERMS_OF_SERVICE_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {locale.translate('inTermsOfService')}
    </a>
  );
  const businessSaleTermLink = (
    <a
      className="dl-text--emphasized"
      href={businessSaleTermsOfServiceUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {locale.translate('inTermsOfService')}&nbsp;
      {name}
    </a>
  );

  return (
    <div>
      <Text size={ETextSize.SMALL} transform={ETextTransform.SPACED_BOTTOM}>
        {locale.translate('acceptedTermsOfServiceIn')} {defaultTermLink} {
          businessSaleTermsOfServiceUrl ? locale.translate('and') : ''
        } {businessSaleTermsOfServiceUrl && businessSaleTermLink}
      </Text>

      {
        paymentType !== ON_SITE && (
          <Text size={ETextSize.SMALL}>
            {locale.translate('businessSellerCopy')}&nbsp;
            <Text transform={ETextTransform.EMPHASIZED}>{name}</Text>{` (NIP: ${companyNip})`}
          </Text>
        )
      }
    </div>
  );
};

BusinessSaleAgreement.propTypes = {
  company: PropTypes.object.isRequired,
  paymentType: PropTypes.string
};

export default BusinessSaleAgreement;
