import {IActivityVariantProcessed} from '@Consts/globals';
import {IBasketItem} from '@Consts/types';
import {getIsCommonVariantsPrice} from '@Features/activityAdmissionDates/activityAdmissionDatesUtils';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {
  isActivityMultipleHotelNarrowed,
  isActivitySinglePickDate,
  isActivitySinglePickDatetime,
  isActivityWithDates
} from './activity/activity';

const getDayOfferPrice = (
  dayOffer: DayOffersProcessed | null,
  variant: IActivityVariantProcessed,
  participantsAmount: number | null
) => {
  if (dayOffer && getIsCommonVariantsPrice(dayOffer?.variants)) {
    const dayOfferVariants = dayOffer.variants;

    const costMax = dayOfferVariants.find(({variantId}) => variantId === variant.id)?.costMax;

    if (costMax) {
      return {price: costMax.price * (participantsAmount || 1), currency: costMax.currency};
    } else {
      return null;
    }
  }

  return null;
};

const getBasketItemEventPrice = (basketItem: IBasketItem) => {
  const {selections, variantId: id, variant} = basketItem;
  const {numberOfParticipants, time, dayOffer} = selections;

  if (time && numberOfParticipants) {
    const {variants} = time;
    const timeCostVariant = variants.find(({variantId}) => variantId === id);
    const cost = timeCostVariant?.cost;

    if (cost) {
      return {price: cost.price * numberOfParticipants, currency: cost.currency};
    }
  }

  const dayOfferPrice = getDayOfferPrice(dayOffer, variant, numberOfParticipants);

  if (dayOfferPrice) {
    return dayOfferPrice;
  }

  return {price: null, currency: null};
};

const getBasketItemDefaultPrice = (basketItem: IBasketItem) => {
  const {variant, selections, activity} = basketItem;
  const {passType} = activity;
  const {cost} = variant;
  const {numberOfSeats} = selections;

  if (cost) {
    const {price, currency} = cost;

    if (isActivityMultipleHotelNarrowed(passType)) {
      const {numberOfHotelNights} = selections;

      if (numberOfHotelNights) {
        return {price: price * numberOfHotelNights, currency};
      }

      return {price: null, currency: null};
    }

    return {price: price * (numberOfSeats || 1), currency};
  }

  return {price: null, currency: null};
};

const getBasketItemPickDateTimePrice = (basketItem: IBasketItem) => {
  const {selections, variant} = basketItem;
  const {time, numberOfSeats, dayOffer} = selections;

  if (time) {
    const {variants} = time;

    const timeVariant = variants.find(({variantId}) => variantId === variant.id);

    if (timeVariant) {
      return {price: timeVariant.cost.price * (numberOfSeats || 1), currency: timeVariant.cost.currency};
    }
  }

  const dayOfferPrice = getDayOfferPrice(dayOffer, variant, numberOfSeats);

  if (dayOfferPrice) {
    return dayOfferPrice;
  }

  return {price: null, currency: null};
};

const getBasketItemPickDatePrice = (basketItem: IBasketItem) => {
  const {selections, variant, variantId: id} = basketItem;
  const {dates, numberOfSeats} = selections;
  const {isPricePerAdmissionDate} = variant;

  if (dates?.length) {
    const currency = dates[0].metaData.currency;

    return dates.reduce((multidayPrices, date, key) => {
      const {metaData} = date;
      const {variants} = metaData;
      const variantCost = variants.find(({variantId}) => variantId === id);
      const cost = variantCost?.cost;
      const priceAcc = isPricePerAdmissionDate ? multidayPrices.price : 0;

      if (!isPricePerAdmissionDate && key > 0) {
        return {price: multidayPrices.price, currency};
      }

      if (cost) {
        return {price: (cost?.price + priceAcc) * (numberOfSeats || 1), currency};
      }

      return multidayPrices;

    }, {price: 0, currency});
  }

  return {price: null, currency: null};
};

const getBasketItemDatesPrice = (basketItem: IBasketItem) => {
  const {activity} = basketItem;
  const {passType} = activity;

  if (isActivitySinglePickDate(passType)) {
    return getBasketItemPickDatePrice(basketItem);
  }

  if (isActivitySinglePickDatetime(passType)) {
    return getBasketItemPickDateTimePrice(basketItem);
  }

  return getBasketItemEventPrice(basketItem);
};

export const getBasketItemPrice = (item: IBasketItem, includeDiscount?: boolean) => {
  const {activity, selections} = item;
  const {discount} = selections;
  const {passType} = activity;

  if (discount && includeDiscount) {
    return {price: discount.price, currency: null};
  }

  if (isActivityWithDates(passType)) {
    return getBasketItemDatesPrice(item);
  }

  return getBasketItemDefaultPrice(item);
};

