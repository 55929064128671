import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import Icon from '@Components/common/icon/Icon';
import Text from '@Components/common/text/Text';
import {DELAYED, FOR_USED, ON_SITE, TRANSACTION} from '@Consts/payment';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import {ICON_SIZE, ICON_TYPE} from '@Components/common/icon/types';

const BusinessSalePaymentInfo = ({paymentType, facilityName, companyName}) => (
  <div className="dl-mb-2">
    {
      paymentType !== ON_SITE && (
        <div className="dl-checkout__icon-content">
          <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.PRICE} />

          <Text>{replaceByElement(locale.translate('buyerWillSettleWithYou'), facilityName)}</Text>
        </div>
      )
    }

    <div className="dl-checkout__icon-content">
      <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.PRINTER} />

      <Text>
        {
          paymentType === ON_SITE ?
            locale.translate('youCanPrintReservationConfirmation') :
            locale.translate('youCanPrintTickets')
        }
      </Text>
    </div>

    {
      paymentType === ON_SITE && (
        <div className="dl-checkout__icon-content">
          <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.RECEPTION} />

          <Text>{locale.translate('paymentWillBeChargedOnSite')}</Text>
        </div>
      )
    }

    {
      paymentType === TRANSACTION && (
        <div className="dl-checkout__icon-content">
          <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.CASH} />

          <Text>{replaceByElement(locale.translate('onlinePaymentForOrder'), companyName)}</Text>
        </div>
      )
    }

    {
      paymentType === FOR_USED && (
        <div className="dl-checkout__icon-content">
          <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.HANDSHAKE} />

          <Text>{replaceByElement(locale.translate('wholeAmountWillBeChargedForUSed'), companyName)}</Text>
        </div>
      )
    }

    {
      paymentType === DELAYED || paymentType === FOR_USED && (
        <div className="dl-checkout__icon-content">
          <Icon size={ICON_SIZE.SIZE60} type={ICON_TYPE.INVOICE} />

          <Text>{replaceByElement(locale.translate('youWillPayForOrderBasedOnInvoice'), companyName)}</Text>
        </div>
      )
    }

  </div>
);

BusinessSalePaymentInfo.propTypes = {
  facilityName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  paymentType: PropTypes.string
};

export default BusinessSalePaymentInfo;
