import {conformToMask} from 'react-text-mask';

export const getMaskedValue = (
  newValue: string,
  mask: (string | RegExp)[],
  prevValue?: string
) => {
  // If the mask contains a string element, the conformToMask
  // function prevents its removal when deleting the last character.
  // For example mask: [/\d/, '-', /\d/] have hyphen ('-') char
  // that will be automaticly added to string on removal action

  const isRemovalAction = prevValue ?
    prevValue?.length - newValue?.length === 1 :
    false;

  if (isRemovalAction) {
    return newValue;
  }

  return conformToMask(newValue, mask, {guide: false}).conformedValue;
};
