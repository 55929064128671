import {validateDayOfferSelection} from '@Components/checkoutPages/admissionRealization/admissionDatesUtils';
import {IBasketItem} from '@Consts/types';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {updateBasketItemsSelections} from '@Features/basket/basketActions';
import {getAbTestType} from '@Features/storage/storageSelectors';
import {getBookingWindow} from '@Utils/bookingWindowUtils';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import {getTimezoneDate} from '@Utils/dayjs/dayjsUtils';
import {EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE} from '@Utils/events';
import {useDispatch, useSelector} from 'react-redux';

type SetError = (error: string | null) => void;

export const useDayOffer = (
  activeViewBasketItems: IBasketItem[],
  setError: SetError,
  sendAmplitudeEvent: (key: string, props: object) => void,
  isTestB: boolean
) => {
  const dispatch = useDispatch();
  const currentAbTestType = useSelector(getAbTestType);

  const handleChangeDayOffer = (selectedDate: string, dayOffers: DayOffersProcessed[], timezone: string) => {
    const selectedDateWithTimezone = getTimezoneDate(selectedDate, timezone, true);

    // compare strings in 'YYYY-MM-DD' format
    const selectedDayOffer = dayOffers.find(({originalDate}) => originalDate === selectedDate);
    const {validationError} = validateDayOfferSelection(selectedDateWithTimezone, isTestB, selectedDayOffer);

    if (validationError) {
      dispatch(updateBasketItemsSelections(
        activeViewBasketItems, {dates: null, time: null, dayOffer: null}
      ));

      return setError(validationError);
    }

    setError(null);

    const formattedSelectedDate = selectedDateWithTimezone.format(DASH_DAY_FORMAT);

    sendAmplitudeEvent(EVENT_BUY_ADMISSION_DATES_SELECTS_AVAILABLE_DATE, {
      admissionDateDay: formattedSelectedDate,
      numberOfSelectedDates: 1,
      abTest: currentAbTestType,
      bookingWindow: getBookingWindow(formattedSelectedDate)
    });

    dispatch(updateBasketItemsSelections(
      activeViewBasketItems, {dates: null, time: null, dayOffer: selectedDayOffer}
    ));
  };

  return {
    handleChangeDayOffer
  };
};
