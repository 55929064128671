import {getUserTheme} from './theme';

export const TEXT_COLOR_DARK = 'DARK';
export const COLOR_DARK = '#2D2D2D';
export const COLOR_LIGHT = '#FFFFFF';

export const customizeButtonStyles = (classNames, options) => {
  const isInvertedClass = classNames.includes('inverted');
  const className = classNames.split(' ').join('.');
  const hoverClassName = classNames.split(' ').map(cn => `${cn}:hover`).join('.');
  const backgroundColor = options.backgroundColor ? options.backgroundColor : null;
  let textColor = null;

  if (options.textColor) {
    textColor = options.textColor === TEXT_COLOR_DARK ? COLOR_DARK : COLOR_LIGHT;
  }

  const backgroundColorStyle = backgroundColor ? `background-color: ${backgroundColor};` : '';
  const textColorStyle = textColor ? `color: ${textColor};` : '';
  const invertedBackgroundStyle = isInvertedClass ? `background-color: ${COLOR_LIGHT};` : '';
  const invertedColorStyle =
    isInvertedClass && backgroundColor ?
      options.textColor === TEXT_COLOR_DARK ?
        `color: ${COLOR_DARK};` :
        `color: ${backgroundColor};` : '';
  const invertedBorderStyle = isInvertedClass && backgroundColor ? `border-color: ${backgroundColor};` : '';

  return `
    .${className} {
      ${backgroundColorStyle}
      ${textColorStyle}
      ${invertedBackgroundStyle}
      ${invertedColorStyle}
      ${invertedBorderStyle}
    }

    .${hoverClassName} {
      ${backgroundColorStyle}
      ${textColorStyle}
      opacity: 0.9;
    }
  `;
};

export const customizeIndicatedActivityStyles = primaryButton => {
  const backgroundColor = primaryButton?.backgroundColor || null;

  const activityIndicatedStyle = backgroundColor ? `box-shadow: 2px 2px 10px 0 ${backgroundColor};` : '';

  return `
    .dl-activity--indicated {
      ${activityIndicatedStyle}
    }
  `;
};

export const customizeGradientStyles = (className, options) => {
  const {
    leftColor,
    rightColor
  } = options;

  if (!leftColor || !rightColor) {
    return '';
  }

  return `
    .${className} {
      background: -webkit-gradient(linear, left top, right top, from(${leftColor}), to(${rightColor}));
      background: -webkit-linear-gradient(left, ${leftColor}, ${rightColor});
      background: -o-linear-gradient(left, ${leftColor}, ${rightColor});
      background: linear-gradient(to right, ${leftColor}, ${rightColor});
    }
  `;
};

export const applyStyles = ({primaryButton, secondaryButton, gradient} = {}, handleThemeChange) => {
  const styles = [];

  if (primaryButton) {
    styles.push(customizeButtonStyles('dl-button', primaryButton));
    styles.push(customizeButtonStyles('dl-button--blue-inverted', primaryButton));
    styles.push(customizeIndicatedActivityStyles(primaryButton));
  }

  const secondaryBtn = secondaryButton || {backgroundColor: '#42b72b', textColor: 'LIGHT'};

  styles.push(customizeButtonStyles('dl-button dl-button--black', secondaryBtn));
  styles.push(customizeButtonStyles('dl-button dl-button--black-inverted', secondaryBtn));

  if (gradient) {
    styles.push(customizeGradientStyles('dl-checkout__gradient', gradient));
  }

  const styleClass = 'dl-style';
  const $head = document.getElementsByTagName('head')[0];
  const $previousStyle = $head.querySelector(`.${styleClass}`);

  if (!$previousStyle) {
    const $style = document.createElement('style');

    $style.type = 'text/css';
    $style.innerHTML = styles.join('\n');
    $style.classList.add(styleClass);

    $head.appendChild($style);
  }

  if (primaryButton?.backgroundColor) {
    handleThemeChange(getUserTheme({primaryButton}));
  }
};
