import './polyfil';
import './utils/amplitude';
import pubsub from './utils/pubsub';
import getConfig from './bubble/utils/getBubbleConfig';
import {
  initialize as initializeEvents,
  EVENT_BUBBLE_VIEW,
  EVENT_BUBBLE_CLICKED,
  EVENT_BUBBLE_START_LOADING
} from './utils/events';
import getIframe from './bubble/utils/getIframe';
import createBasketIcon, {SHAPE_TYPES} from './bubble/utils/createBasketIcon';
import getData from './bubble/utils/getData';
import buildStyles, {COLOR_DARK, COLOR_LIGHT, TEXT_COLOR_DARK} from './bubble/utils/buildStyles';
import {mountWidget} from './bubble/utils/mountWidget';

const $scriptNode = document.querySelector('#dl-bubble');
const $body = document.querySelector('body')!;

const isWidgetOnCurrentPage = () => Boolean(getIframe());

const initialize = async () => {
  if (isWidgetOnCurrentPage()) {
    return;
  }

  if (!$scriptNode) {
    return;
  }

  const isPreview = getData($scriptNode, 'data-is-preview') === 'true';

  const {
    facilityId,
    shape: bubbleShape,
    backgroundColor: bgColor,
    textColor: basketBubbleColorData,
    position,
    bottomMargin,
    sideMargin,
    className,
    text,
    uuid,
    configuration,
    url,
    salesDisabled: disabled,
    onlineGroupId,
    widgetScriptId
  } = await getConfig($scriptNode, isPreview);

  initializeEvents(__BUBBLE_AMPLITUDE_ID__, false);

  const href = url;

  if (!isPreview) {
    pubsub.trigger(EVENT_BUBBLE_START_LOADING, {
      facilityId,
      url: window.location.href,
      destinationUrl: href,
      uuid,
      disabled
    });
  }

  const positionProperties = {
    bottom: bottomMargin,
    left: position === 'LEFT' ? sideMargin : 'initial',
    right: position === 'RIGHT' ? sideMargin : 'initial'
  };

  const $bubble = document.createElement('a');
  const $tab = document.createElement('a');
  const $bubbleMobile = document.createElement('a');
  const $tabMobile = document.createElement('a');
  const $basket = document.createElement('div');
  const $basketMobile = document.createElement('div');
  const $bubbleText = document.createElement('span');
  const $tabText = document.createElement('span');
  const $tabMobileText = document.createElement('span');
  const $styles = document.createElement('style');

  const textIsDark = basketBubbleColorData === TEXT_COLOR_DARK;
  const basketTabColor = textIsDark ? COLOR_DARK : bgColor;
  const basketColor = textIsDark ? COLOR_DARK : COLOR_LIGHT;
  const isTab = bubbleShape.toUpperCase() === SHAPE_TYPES.TAB;

  const $basketBubbleIcon = createBasketIcon('bubble', basketColor);
  const $basketTabIcon = createBasketIcon('tab', basketTabColor);
  const $basketTabMobileIcon = createBasketIcon('tab-mobile', basketTabColor);

  if (getData($scriptNode, 'data-target-blank', 'disabled') === 'enabled') {
    $bubble.setAttribute('target', '_blank');
    $tab.setAttribute('target', '_blank');
    $bubbleMobile.setAttribute('target', '_blank');
    $tabMobile.setAttribute('target', '_blank');
  }

  if (!isTab) {
    $basket.classList.add('dl-bubble__basket');
    $basket.appendChild($basketBubbleIcon);
    $bubble.appendChild($bubbleText);
    $bubble.appendChild($basket);
    $bubble.classList.add('dl-bubble');
    $bubbleText.classList.add('dl--' + className);
    $bubbleText.setAttribute('id', 'dl-bubble-text');
    $bubbleText.innerText = text;
  }

  if (isTab) {
    $basket.classList.add('dl-bubble-tab__basket');
    $basket.appendChild($basketTabIcon);
    $tab.classList.add('dl-bubble-tab');
    $tab.appendChild($basket);
    $tabText.classList.add('dl-tab--' + className);
    $tabText.innerText = text;
    $tabText.setAttribute('id', 'dl-bubble-text__tab');
    $tab.appendChild($tabText);
  }

  $basketMobile.classList.add('dl-bubble-tab__basket-mobile');
  $basketMobile.appendChild($basketTabMobileIcon);
  $tabMobile.classList.add('dl-bubble-tab-mobile');
  $tabMobile.appendChild($basketMobile);
  $tabMobileText.classList.add('dl-tab--' + className + '-mobile');
  $tabMobileText.innerText = text;
  $tabMobileText.setAttribute('id', 'dl-bubble-text__tab-mobile');
  $tabMobile.appendChild($tabMobileText);

  $bubble.setAttribute('href', href);
  $bubbleMobile.setAttribute('href', href);
  $tab.setAttribute('href', href);
  $tabMobile.setAttribute('href', href);

  function handleClickEvent() {
    if (!isPreview) {
      pubsub.trigger(EVENT_BUBBLE_CLICKED, {
        facilityId,
        url: window.location.href,
        destinationUrl: href,
        uuid,
        configuration,
        disabled
      });
    }
  }

  $bubble.onclick = handleClickEvent;
  $bubbleMobile.onclick = handleClickEvent;
  $tab.onclick = handleClickEvent;
  $tabMobile.onclick = handleClickEvent;

  const stylesHTML = buildStyles($scriptNode, {
    bgColor,
    zIndex: getData($scriptNode, 'data-z-index', '99999'),
    textColorType: basketBubbleColorData,
    ...positionProperties
  });

  $styles.innerHTML = stylesHTML;

  $body.appendChild($styles);

  if (!isTab) {
    $body.appendChild($bubble);
  }

  if (isTab) {
    $body.appendChild($tab);
  }

  $body.appendChild($tabMobile);
  if (!isPreview) {
    pubsub.trigger(EVENT_BUBBLE_VIEW, {
      facilityId,
      url: window.location.href,
      destinationUrl: href,
      uuid,
      configuration,
      disabled
    });
  }

  if (onlineGroupId && widgetScriptId) {
    mountWidget(facilityId, onlineGroupId, widgetScriptId);
  }
};

initialize();
