
import {ICost} from '@Consts/apiGlobals';
import {EWidgetType} from '@Consts/globals';
import {
  isWidgetBusiness,
  isWidgetBusinessPreview,
  isWidgetOnlineClient,
  isWidgetOnlineClientPreview
} from './widgetType';

interface IGetCatalogPriceProps {
  cost: ICost,
  widgetType: EWidgetType,
  variantsDescription?: string | null
}

export const getCatalogPrice = ({
  cost,
  widgetType,
  variantsDescription
}: IGetCatalogPriceProps) => {
  const catalogPriceCouldBeShown = !variantsDescription && cost?.catalogPrice;

  if (!catalogPriceCouldBeShown) {
    return {catalogPrice: null, catalogPriceDescription: null};
  }

  if (isWidgetOnlineClient(widgetType) || isWidgetOnlineClientPreview(widgetType)) {
    return {
      catalogPrice: cost?.catalogPriceDescription ? cost?.catalogPrice : null,
      catalogPriceDescription: cost?.catalogPriceDescription ?? null
    };
  }

  if (isWidgetBusiness(widgetType) || isWidgetBusinessPreview(widgetType)) {
    return {
      catalogPrice: cost?.catalogPrice,
      catalogPriceDescription: cost?.catalogPriceDescription
    };
  }

  return {
    catalogPrice: null,
    catalogPriceDescription: null
  };
};
