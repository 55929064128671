import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {grey} from '@mui/material/colors';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React from 'react';
import {SvgIconTypeMap} from '@mui/material';

type Props = {
    Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    },
    text: string,
    value?: string | number,
    discount?: string | null,
    description?: string | null
}

const AdmissionSummaryDetail = (
  {children, Icon, text, value, discount, description} : React.PropsWithChildren<Props>) => {
  if (!children) {
    return (
      <Stack
        spacing={1.5}
        direction="row"
        sx={{
          backgroundColor: grey[50],
          p: 2
        }}
      >
        <Icon color="success" />
        <Typography
          variant="body2"
          sx={{
            alignContent: 'center',
            color: 'text.secondary'
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontWeight: 500,
              color: 'text.primary'
            }}
          >
            {text}
          </Typography>
          {value}&nbsp;
          {!!discount && (
            <Typography
              variant="body2"
              component="span"
              sx={theme => ({
                color: theme.palette.error.dark,
                textDecoration: 'line-through'
              })}
            >
              {discount}
            </Typography>
          )}
          <br />
          {description && (
            <Typography
              variant="body2"
              component="span"
            >
              {description}
            </Typography>
          )}
        </Typography>
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[50],
        p: 2
      }}
    >
      <Stack direction="row" spacing={1.5}>
        <Icon color="success" />
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 500,
              color: 'text.primary',
              mb: 1,
              lineHeight: '22px'
            }}
          >
            {text}
          </Typography>
          {children}
        </Box>
      </Stack>
    </Box>
  );
};

export default AdmissionSummaryDetail;
