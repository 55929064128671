import React from 'react';
import {string} from 'yup';
import locale from '@Utils/locale';
import {NUMERIC_NIP_MASK, NUMERIC_PATTERN} from '../patterns';
import FormInput from '@Components/formComponents/FormInput';

export const MIN_LENGTH = 5;
export const MAX_LENGTH = 15;

export const schema = () => string()
  .required(locale.translate('requiredField'))
  .min(MIN_LENGTH, locale.translate('minLength', {length: MIN_LENGTH}))
  .max(MAX_LENGTH, locale.translate('maxLength', {length: MAX_LENGTH}))
  .matches(NUMERIC_PATTERN, {message: locale.translate('InvalidNipFormat')});

export const FIELD_NAME = 'invoiceCompanyNip';

const CompanyVatIdField = ({name = FIELD_NAME, saveInStorage = false}) => (
  <FormInput
    componentProps={{
      label: locale.translate('companyNIP'),
      sx: {width: 1},
      inputProps: {maxLength: MAX_LENGTH}
    }}
    name={name}
    mask={NUMERIC_NIP_MASK}
    saveInStorage={saveInStorage}
  />
);

export default CompanyVatIdField;
