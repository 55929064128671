import TextField, {BaseTextFieldProps} from '@mui/material/TextField';
import React, {useEffect} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {FormHelperTextProps, SxProps, Theme} from '@mui/material';
import {useDispatch} from 'react-redux';
import {setStorage} from '@Features/storage/storageActions';
import TextMaskInput from './TextMaskInput';

interface IFormInputProps {
  name: string,
  componentProps: BaseTextFieldProps & {'data-testid'?: string},
  fieldStyles?: SxProps<Theme>,
  formHelperTextProps?: Partial<FormHelperTextProps>,
  customError?: JSX.Element | string | null,
  saveInStorage?: boolean,
  mask: (string | RegExp)[]
}

const FormMaskInput = ({
  componentProps,
  name,
  fieldStyles,
  formHelperTextProps,
  customError,
  saveInStorage,
  mask
}: IFormInputProps) => {
  const dispatch = useDispatch();
  const {control, watch} = useFormContext();
  const {label, variant, inputProps, disabled} = componentProps;
  const watchedValue = watch(name);

  useEffect(() => {
    if (watchedValue && saveInStorage) {
      dispatch(setStorage(name, watchedValue, true));
    }
  }, [watchedValue]);

  return (
    <Controller
      name={name}
      key={name}
      control={control}
      render={({field: {value, onChange, ref, onBlur}, fieldState: {invalid, error}}) => (
        <TextField
          inputProps={{...inputProps, inputRef: ref, mask}}
          name={name}
          label={label}
          disabled={disabled}
          variant={variant}
          value={value}
          sx={{
            width: 1,
            ...fieldStyles
          }}
          onChange={onChange}
          onBlur={onBlur}
          error={invalid || !!customError}
          helperText={error?.message ?? customError ?? ''}
          inputRef={ref}
          FormHelperTextProps={formHelperTextProps}
          {
            ...mask && {
              InputProps: {inputComponent: TextMaskInput as any}
            }
          }
        />
      )}
    />
  );
};

export default FormMaskInput;
