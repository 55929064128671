import {getIsModal} from '@Features/configuration/configurationSelectors';
import useFrameMessage from '@Hooks/useFrameMessage';
import {useMediaQuery, useTheme} from '@mui/material';
import {MINIMUM_SCROLL_VALUE} from '@Utils/scrollUtils';
import {useSelector} from 'react-redux';

export enum VerticalAlignment {
  TOP = 'top',
  CENTER = 'center',
}

type Props = {
  enableForModal?: boolean;
}

export const useScroll = (props?: Props) => {
  const enableForModal = props?.enableForModal;
  const theme = useTheme();
  const frameMessage = useFrameMessage();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isModal = useSelector(getIsModal);

  const scrollInModal = (
    elementRect: DOMRect,
    verticalAlignment: VerticalAlignment
  ) => {
    const elementTop = elementRect.top; // Relative to the viewport value, changed with scroll.
    const dialogFrameOffset = isMobile ? 0 : 64; // For dialog margin 32px (top and bottom)
    const navbarHeight = 46;
    const dialogSize = window.innerHeight - dialogFrameOffset + navbarHeight;
    const verticalOffset = verticalAlignment === VerticalAlignment.CENTER ? dialogSize / 2 : 0;
    const topOffset = window.scrollY + elementTop - verticalOffset; // scroll to element top + half of dialog size
    const scrollDistance = window.scrollY - topOffset;

    if (Math.abs(scrollDistance) > MINIMUM_SCROLL_VALUE) {
      window.scroll({
        top: topOffset,
        behavior: 'smooth'
      });
    }
  };

  function scrollToElement(element: Element) {
    if (!element) {
      return;
    }
    const elementRect = element.getBoundingClientRect();
    let iframeElementYCord;
    let verticalAlignment;

    if (!isMobile && elementRect.height > 1000) {
      iframeElementYCord = elementRect.top;
      verticalAlignment = VerticalAlignment.TOP;
    } else if (isMobile && elementRect.height > 650) {
      iframeElementYCord = elementRect.top;
      verticalAlignment = VerticalAlignment.TOP;
    } else {
      iframeElementYCord = elementRect.height / 2 + elementRect.top;
      verticalAlignment = VerticalAlignment.CENTER;
    }

    if (isModal && enableForModal) {
      return scrollInModal(
        elementRect,
        verticalAlignment
      );
    }

    frameMessage({
      name: 'scrollRef',
      iframeElementYCord,
      verticalAlignment
    });
  }

  function scrollToElementCenter(element: Element | null) {
    if (!element) {
      return;
    }
    const elementRect = element.getBoundingClientRect();
    const iframeElementYCord = elementRect.height / 2 + elementRect.top;
    const verticalAlignment = VerticalAlignment.CENTER;

    frameMessage({
      name: 'scrollRef',
      iframeElementYCord,
      verticalAlignment
    });
  }

  return {scrollToElement, scrollToElementCenter};
};
