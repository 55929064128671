/* eslint-disable max-len */
export default {
  'bubbleDisabled': 'Výprodej vyloučen!',
  'buyOnline': 'Koupit online!',
  'buyVoucher': 'Koupit voucher',
  'buyTicket': 'Kupte si vstupenku!',
  'bookingOnline': 'Rezervujte online',
  'buySkipass': 'Koupit skipas',
  'buyCourse': 'Koupit kurz',
  'localTours': 'Místní prohlídky',
  'shopOnline': 'Internetový obchod',
  'orderOnline': 'Objednat online',
  'planVisit': 'Naplánujte si návštěvu',
  'buyOnlineCheaper': 'Kupte online a ušetřete!'
};
