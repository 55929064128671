import React from 'react';
import {useDragScroll} from '../../hooks/useDragScroll';
import {SxProps, Theme} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

const scrollContainerStyles: SxProps<Theme> = {
  overflow: 'hidden',
  position: 'relative',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

type TCarousel = {
  children: JSX.Element,
  carouselStyles?: SxProps<Theme>
}

const Carousel = ({children, carouselStyles}: TCarousel) => {
  const {
    containerRef,
    handleMouseDown,
    handleMouseUp
  } = useDragScroll();

  return (
    <Stack
      spacing={{xs: 1.25, md: 2.5}}
      direction="row"
      sx={{
        ...scrollContainerStyles,
        ...carouselStyles
      }}
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
    >
      {children}
    </Stack>
  );
};

export default Carousel;
