import directFrameMessage from '@Utils/directFrameMessage';
import {SINGLE_GROUP_EVENT, SINGLE_PICK_DATE, SINGLE_PICK_DATETIME} from '@Consts/activityPassType';

export const FACEBOOK_VIEW_CONTENT_EVENT = 'ViewContent';
export const FACEBOOK_ADD_PAYMENT_INFO_EVENT = 'AddPaymentInfo';
export const FACEBOOK_INITIATE_CHECKOUT_EVENT = 'InitiateCheckout';
export const FACEBOOK_PURCHASE_EVENT = 'Purchase';
export const FACEBOOK_ADD_TO_CART = 'AddToCart';

const postViewContent = facility => {
  if (facility.isFacebookPixelEnabled) {
    directFrameMessage({
      name: 'facebookPixel',
      action: FACEBOOK_VIEW_CONTENT_EVENT
    }, '*');
  }
};

const postAddPaymentInfo = facility => {
  if (facility.isFacebookPixelEnabled) {
    directFrameMessage({
      name: 'facebookPixel',
      action: FACEBOOK_ADD_PAYMENT_INFO_EVENT
    }, '*');
  }
};

const postInitiateCheckout = (facility, value, currency) => {
  if (facility.isFacebookPixelEnabled) {
    directFrameMessage({
      name: 'facebookPixel',
      action: FACEBOOK_INITIATE_CHECKOUT_EVENT,
      value,
      currency
    }, '*');
  }
};

const postPurchaseInfo = (facility, value, currency) => {
  if (facility.isFacebookPixelEnabled) {
    directFrameMessage({
      name: 'facebookPixel',
      action: FACEBOOK_PURCHASE_EVENT,
      value,
      currency
    }, '*');
  }
};

const postAddToCart = (facility, cartItem) => {
  const {activity, variant} = cartItem;
  const {name, passLayout, passType} = activity;
  const price = {value: null, currency: null};

  if (passType === SINGLE_PICK_DATETIME || passType === SINGLE_PICK_DATE || passType === SINGLE_GROUP_EVENT) {
    price.value = variant.datesCostMin.price,
    price.currency = variant.datesCostMin.currency;
  } else {
    price.value = variant.cost?.price,
    price.currency = variant.cost?.currency;
  }

  if (facility.isFacebookPixelEnabled) {
    directFrameMessage({
      name: 'facebookPixel',
      action: FACEBOOK_ADD_TO_CART,
      value: {
        content_name: name,
        content_category: passLayout,
        content_ids: [`${activity.id}`],
        ...price
      }
    }, '*');
  }
};

export default {
  postViewContent: facility => postViewContent(facility),
  postAddPaymentInfo: facility => postAddPaymentInfo(facility),
  postInitiateCheckout: (facility, value, currency) => postInitiateCheckout(facility, value, currency),
  postPurchaseInfo: (facility, value, currency) => postPurchaseInfo(facility, value, currency),
  postAddToCart: (facility, cartItem) => postAddToCart(facility, cartItem)
};
