export enum Features {
  NEW_CALENDAR = 'newCalendar'
}

const useFeatureFlag = (feature: Features) => {
  if (feature === Features.NEW_CALENDAR) {
    return {isEnabled: true};
  }

  return {isEnabled: false};
};

export default useFeatureFlag;
