import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';

const RadioButton = ({id, name, label, value, checked, invalid, onChange, className}) => (
  <>
    <div
      className={classNames(
        'dl-click-input',
        'dl-click-input--radiobutton',
        {
          'dl-click-input--invalid': invalid
        },
        className
      )}
    >
      <input
        id={id}
        name={name}
        type="radio"
        checked={checked}
        value={value}
        onChange={onChange}
      />

      <label
        className={classNames(
          'dl-click-input__ghost',
          {
            'dl-click-input__ghost--checked': checked
          }
        )}
        htmlFor={id}
      />
    </div>

    <div className="dl-click-input__label">
      <label className="dl-text" htmlFor={id}>
        <p className="dl-pr-4">{label}</p>
      </label>
    </div>
  </>
);

export default RadioButton;
