import React, {useEffect} from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import locale from '@Utils/locale';
import TextLink from '@Components/common/TextLink';
import {useSummaryData} from '../../hooks/useSummaryData';
import {usePaymentUrl} from '@Hooks/fetchHooks/usePaymentUrl';
import useFrameMessage from '@Hooks/useFrameMessage';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash} from '@Features/configuration/configurationSelectors';
import {sendRetryPaymentEvent} from '@Utils/orderUtils';

const PaymentLoader = () => {
  const {onlineOrder, businessOrder, latestOrderStorageData} = useSummaryData();
  const businessOrderPayUrl = businessOrder?.payUrl;
  const email = onlineOrder?.orderBuyer?.email || businessOrder?.orderBuyer?.email;
  const {code, orderId, isEventsSent} = latestOrderStorageData;
  const {handleFetchNewPaymentUrl, payUrl, isSuccess} = usePaymentUrl(code);
  const frameMessage = useFrameMessage();
  const facility = useSelector(getFacility);
  const affiliationHash = useSelector(getAffiliationHash);

  useEffect(() => {
    if (payUrl && isSuccess) {
      frameMessage({
        name: 'payment',
        href: payUrl
      });
    }
  }, [isSuccess]);

  const payAgain = () => {
    if (facility) {
      sendRetryPaymentEvent(facility, {
        orderId,
        affiliationHash,
        isFirstLoading: !isEventsSent
      });
    }

    handleFetchNewPaymentUrl();
  };

  return (
    <Stack sx={{maxWidth: 420, alignItems: 'center'}}>
      <CircularProgress size={32} sx={{my: 1.5}} />

      <Typography variant="h4" sx={{mb: 3, fontWeight: 500, textAlign: 'center'}}>
        {locale.translate('pleaseWaitSummary')}
      </Typography>

      <Typography sx={{color: 'text.secondary', textAlign: 'center'}}>
        {locale.translate('waitingForPaymentConfirmation')}
      </Typography>

      <Typography data-testid="continue-payment" sx={{mb: 2, color: 'text.secondary', textAlign: 'center'}}>
        {locale.translate('paymentProcessInterrupted')}{' '}
        {businessOrderPayUrl ?
          <TextLink href={businessOrderPayUrl ?? ''} target="_parent">{locale.translate('clickHere')}</TextLink> :
          <TextLink onClick={payAgain}>{locale.translate('clickHere')}</TextLink>
        }
        {'.'}
      </Typography>

      {
        email && (
          <Typography data-testid="order-buyer-email" sx={{color: 'text.secondary', textAlign: 'center'}}>
            {locale.translate('ticketsLoadedAndSentToEmail')}{' '}
            <Typography component="span" sx={{fontWeight: 700}}>{email}</Typography>
          </Typography>
        )
      }
    </Stack>
  );
};

export default PaymentLoader;
