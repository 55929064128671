import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import dayjs, {Dayjs} from 'dayjs';
import locale from '@Utils/locale';
import {getFormattedPrice} from '@Utils/format';
import Typography from '@mui/material/Typography';
import {DayOffersProcessed} from '@Features/activityAdmissionDates/types';
import {IDatesGroupedByDay} from '@Components/checkoutPages/admissionRealization/types';
import {useCalendarCell} from '@Utils/hooks/useCalendarCell';
import {HotelNarrowDate} from './types';
import {DASH_DAY_FORMAT} from '@Utils/dayjs/const';
import Button from '@mui/material/Button';
import {CalendarCellButton} from './CalendarComponents';
import CalendarCellDetails from './CalendarCellDetails';
import DefaultCalendarCell from './DefaultCalendarCell';

export type CellData = IDatesGroupedByDay | DayOffersProcessed | HotelNarrowDate;

type Props = {
  calendarDay: Dayjs | null;
  cellData?: CellData;
  onClick: (date: string) => void;
  isLower?: boolean;
  isHeader?: boolean;
  showPrices?: boolean;
  showAvailableSeatsForDay?: boolean;
  lastDayInMonth?: number | null;
  isTestB: boolean;
}

const CalendarCell = ({
  calendarDay,
  cellData,
  children,
  onClick,
  isLower,
  isHeader,
  showPrices,
  showAvailableSeatsForDay,
  lastDayInMonth,
  isTestB
}: React.PropsWithChildren<Props>) => {
  if (isHeader) {
    if (isTestB) {
      return (
        <Typography
          variant="overline"
          sx={{
            width: 1,
            fontSize: '12px',
            textAlign: 'center',
            color: 'text.disabled'
          }}
        >
          {children}
        </Typography>
      );
    }
    return (
      <div
        className={classNames(
          'dl-calendar__header-cell',
          {
            'dl-calendar__header-cell--lower': isLower
          })
        }
        id="header"
      >
        {children}
      </div>
    );
  }

  if (!calendarDay) {
    return isTestB ? (
      <Button
        disabled
        // add a style to create a cell of the same size like another elements
        sx={{
          width: '100%',
          minWidth: 'auto',
          border: '1px solid #fff',
          padding: 0
        }}
      />
    ) : (
      <div className={'dl-calendar__empty-cell'} id="empty-cell" />
    );
  }

  const data = useCalendarCell({cellData, calendarDay, showPrices});

  if (isTestB) {
    const isDisabled = !!data?.isDisabled || !data;
    const showInformations = showAvailableSeatsForDay || showPrices;
    const isFirstDayInMonth = calendarDay.date() === 1;
    const isLastDayInMonth = calendarDay.date() === lastDayInMonth;

    return (
      <CalendarCellButton
        color="primary"
        disableFocusRipple
        disableRipple
        isSelected={data?.isSelected}
        isDisabled={isDisabled}
        isSelectedStart={data?.isSelectedStart}
        isSelectedMiddle={data?.isSelectedMiddle}
        isSelectedEnd={data?.isSelectedEnd}
        isFirstDayInMonth={isFirstDayInMonth}
        isLastDayInMonth={isLastDayInMonth}
        onClick={
          onClick &&
          (() =>
            onClick(data?.date || calendarDay.format(DASH_DAY_FORMAT)))
        }
      >
        {showInformations ? (
          <CalendarCellDetails
            calendarDay={calendarDay}
            isDisabled={isDisabled}
            data={data}
            showAvailableSeatsForDay={!!showAvailableSeatsForDay}
            showPrices={!!showPrices}
          />
        ) : (
          <DefaultCalendarCell
            calendarDay={calendarDay}
            isDisabled={isDisabled}
            data={data}
          />
        )}
      </CalendarCellButton>
    );
  }

  return (
    <div
      className={classNames(
        'dl-calendar__cell',
        {
          'dl-calendar__cell--selected-start': data?.isSelectedStart,
          'dl-calendar__cell--selected-middle': data?.isSelectedMiddle,
          'dl-calendar__cell--selected-end': data?.isSelectedEnd,
          'dl-calendar__cell--disabled': data?.isDisabled || !data,
          'dl-calendar__cell--selected': data?.isSelected,
          'dl-calendar__cell--current': data?.isToday
        }
      )}
      onClick={onClick && (() => onClick(data?.date || dayjs(calendarDay).format(DASH_DAY_FORMAT)))}
    >
      <span className="dl-calendar__cell-day" id="calendar-day">
        {dayjs(calendarDay).date()}
      </span>
      {
        data?.showPrices && (
          <Typography
            data-testid={`price-${dayjs(calendarDay).date()}`}
            id="price"
            sx={{
              ...priceStyles,
              ...data?.isBargainPrice && {
                color: 'success.main'
              },
              lineHeight: '12px'
            }}
          >
            {data?.isPriceNotFixed ? `${locale.translate('from')} `.toLowerCase() :
              ''}{getFormattedPrice(data?.totalPrice)}&nbsp;{locale.translate(`${data?.currency}`)}
          </Typography>
        )
      }
      {
        data?.showAvailableSeats && (
          <Typography
            id="seats"
            sx={itemsLeftStyles}
          >
            {data?.availableSeats} {locale.translate('seats')}
          </Typography>
        )
      }
    </div>
  );
};

export default CalendarCell;

const itemsLeftStyles = {
  fontSize: '0.75rem',
  color: '#778899',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  letterSpacing: '-0.015rem'
};

const priceStyles = {
  ...itemsLeftStyles,
  fontWeight: 600
};
