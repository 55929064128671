import React from 'react';
import DlMuiTooltip from './DlMuiTooltip';
import Box from '@mui/material/Box';
import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';

interface FollowCursorTooltip {
  children: React.ReactElement,
  title: string,
  enable: boolean,
  styles?: SxProps<Theme>
}

const FollowCursorTooltip = ({children, title, enable, styles}: FollowCursorTooltip) => (
  <DlMuiTooltip
    title={title}
    enable={enable}
    sx={{pt: {xs: 0, sm: 3.5}, pl: {xs: 0, sm: 1}}}
    followCursor
    arrow
  >
    <Box
      component="span"
      sx={{
        cursor: enable ? 'not-allowed' : 'auto',
        ...styles
      }}
    >
      {children}
    </Box>
  </DlMuiTooltip>
);

export default FollowCursorTooltip;
