const PROVIDER_URL = 'https://geowidget.easypack24.net';

const PARCELS_PROVIDER_CONFIG = {
  scriptUrl: `${PROVIDER_URL}/js/sdk-for-javascript.js`,
  stylesUrl: `${PROVIDER_URL}/css/easypack.css`
};

export {
  PARCELS_PROVIDER_CONFIG
};
