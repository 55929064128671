import React from 'react';
import {ITextButtonProps} from './types';
import Button from '@mui/material/Button';

const TextButton = ({
  onClick,
  styles,
  children
}: ITextButtonProps) => (
  <Button
    color="inherit"
    sx={{...styles, fontWeight: 900}}
    onClick={onClick}
    variant="text"
  >
    {children}
  </Button>
);

export default TextButton;
