import React from 'react';
import locale from '@Utils/locale';
import Box from '@mui/material/Box';
import {DefaultTimeText, TextSoldOut} from './TimePickerComponents';
import {timePickerItemConfig} from './types';

type Props = {
  timePickerItemConfig: timePickerItemConfig,
}

const DefaultTimePickerCell = ({timePickerItemConfig}: Props) => {
  const {textTime, isDisabled, hasNoSeats, isSoldOut} = timePickerItemConfig;

  return (
    <Box>
      <DefaultTimeText isDisabled={isDisabled}>
        {textTime}
      </DefaultTimeText>
      {
        isSoldOut && (
          <TextSoldOut>
            {locale.translate('isSoldOut')}
          </TextSoldOut>
        )
      }
      {
        hasNoSeats && (
          <TextSoldOut>
            {locale.translate('hasNoSeatsTest')}
          </TextSoldOut>
        )
      }
    </Box>
  );
};

export default DefaultTimePickerCell;
