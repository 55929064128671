import {IOrderData} from '@Consts/apiGlobals';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {COMPLETED} from '@Features/order/orderActions';
import {getOrderUrl} from '@Features/order/orderUtils';
import {apiGet} from '@Utils/api/api';
import ResponseError from '@Utils/api/errors/responseError';
import {useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

type TUseSendOrder = {
  code?: string
  orderId?: string
}

export const useFetchWaitingForOrderCompleted = ({
  code,
  orderId
}: TUseSendOrder) => {
  const [orderIsCompleted, setOrderIsCompleted] = useState(false);
  const [orderIsNotFound, setOrderIsNotFound] = useState(false);
  const [refetchInterval, setRefetchInterval] = useState(3000);
  const config = useSelector(getConfiguration);

  const url = orderId && code ? getOrderUrl(config, code, orderId) : null;

  const {data, isError, failureReason, isSuccess} = useQuery<IOrderData>({
    queryKey: [url],
    queryFn: () => apiGet(url, config),
    refetchOnWindowFocus: false,
    refetchInterval,
    retry: 0
  });

  useEffect(() => {
    setOrderIsCompleted(data?.state === COMPLETED);
  }, [data]);

  useEffect(() => {
    if (failureReason !== null) {
      setRefetchInterval(0);
    }
    if (failureReason instanceof ResponseError && failureReason.data.type === 'orderNotFound') {
      setOrderIsNotFound(true);
    }
  }, [failureReason]);

  return {
    orderIsCompleted,
    isError,
    orderIsNotFound,
    isSuccess
  };
};
