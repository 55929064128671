import React, {useEffect, useState} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import ActivityItemContent from './ActivityItemContent';
import ActivityVariants from './ActivityVariants';
import {getBasketActivityItems} from '@Features/basket/basketSelectors';
import {useSelector} from 'react-redux';
import {hasActivityValidPrice} from '@Utils/activity/activity';
import pubsub from '@Utils/pubsub';
import {EVENT_HIDE_VARIANTS_LIST, EVENT_SHOW_VARIANTS_LIST} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getAffiliationHash, getConfiguration} from '@Features/configuration/configurationSelectors';
import {WidgetMode} from '../../../widget/types';
import {IActivityProcessed} from '@Consts/globals';
import {useMatch} from 'react-router-dom';
import {CHECKOUT_PATH} from '@Utils/routerUtils';
import Box from '@mui/material/Box';
import ActivityVariantsListToggler from './buttons/ActivityVariantsListToggler';
import {getAbTestType} from '@Features/storage/storageSelectors';

type Props = {
  activity: IActivityProcessed;
  orderNumber: number;
  indicated?: boolean;
  numberOfActivities: number;
};

const ActivityItemWithVariants = ({activity, indicated, orderNumber, numberOfActivities}: Props) => {
  const isCheckout = !!useMatch(CHECKOUT_PATH);
  const activityBasketItems = useSelector(state => getBasketActivityItems(state, activity.id));
  const activityHaveValidPrices = hasActivityValidPrice(activity.variants);
  const facility = useSelector(getFacility)!;
  const {isModal} = useSelector(getConfiguration);
  const affiliationHash = useSelector(getAffiliationHash);
  const {id: facilityId, name, company} = facility;
  const currentAbTestType = useSelector(getAbTestType);
  const [areVariantsVisible, setVariantsVisibility] = useState(
    (Boolean(activityBasketItems.length) ||
    indicated ||
    numberOfActivities === 1) &&
    activityHaveValidPrices
  );
  const [disableEffect, setDisableEffect] = useState(false);

  const handleEvent = (isListVisible: boolean) => {
    const eventType = isListVisible ? EVENT_SHOW_VARIANTS_LIST :
      EVENT_HIDE_VARIANTS_LIST;

    pubsub.trigger(
      eventType,
      {
        facilityId,
        facilityName: name,
        industry: company?.industry,
        affiliationHash,
        activityName: activity.name,
        activityId: activity.id,
        passType: activity.passType,
        orderNumber,
        language: locale.locale,
        widgetMode: isModal ? WidgetMode.modal : WidgetMode.inline,
        abTest: currentAbTestType
      }
    );
  };

  useEffect(() => {
    if (!areVariantsVisible && !disableEffect) {
      setVariantsVisibility(Boolean(activityBasketItems.length));
    }
  }, [activityBasketItems]);

  function handleVariantsToggle() {
    setDisableEffect(true);
    setVariantsVisibility((areVariantsVisible: boolean) => {
      handleEvent(!areVariantsVisible);

      return !areVariantsVisible;
    });
  }

  return (
    <ActivityItemContent
      activity={activity}
      indicated={indicated}
      numberOfActivities={numberOfActivities}
      picker={
        <Box sx={{width: {xs: isCheckout ? '158px' : '100%', sm: '158px'}}}>
          <ActivityVariantsListToggler
            onClick={handleVariantsToggle}
            isActive={areVariantsVisible}
          />
        </Box>
      }
      additionalContent={
        areVariantsVisible ? (
          <ActivityVariants
            activity={activity}
            orderNumber={orderNumber}
          />
        ) : null
      }
    />
  );
};

export default ActivityItemWithVariants;
