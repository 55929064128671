import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import locale from '@Utils/locale';
import ActivityContent from '../ActivityContent';
import Text from '@Components/common/text/Text';
import {ETextColor} from '@Components/common/text/types';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const ActivityAdmissionActivationEnd = ({admissionPolicy, hideIcon, suitableDate}) => {
  const {endInDays, activeDescription, activeInDays, endDescription} = admissionPolicy;
  const activationText = endInDays ?
    locale.translate('activationInDays', {days: suitableDate}) :
    locale.translate('activationDates', {dates: suitableDate});

  return (
    <>
      <ActivityContent>
        {
          !hideIcon &&
          <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        <Text color={ETextColor.LIGHT}>
          {
            activeDescription ?
              activeDescription :
              locale.translate('activeInDays', {number: activeInDays})
          }
        </Text>
      </ActivityContent>

      <ActivityContent>
        {
          !hideIcon &&
          <AssignmentOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        <Text color={ETextColor.LIGHT}>
          {endDescription || activationText}
        </Text>
      </ActivityContent>
    </>
  );
};

ActivityAdmissionActivationEnd.propTypes = {
  admissionPolicy: PropTypes.object.isRequired,
  hideIcon: PropTypes.bool,
  suitableDate: PropTypes.string
};

export default ActivityAdmissionActivationEnd;

