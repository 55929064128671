import {useNavigate} from 'react-router-dom';
import {HOME_PATH} from '@Utils/routerUtils';
import {useDispatch, useSelector} from 'react-redux';
import {useLatestOrderStorageData} from '@Utils/hooks/useLatestOrderStorageData';
import {filterLatestOrders} from '@Features/order/orderUtils';
import pubsub from '@Utils/pubsub';
import {EVENT_CLICKED_BACK_TO_LIST} from '@Utils/events';
import {parseLanguage} from '@Utils/eventsUtils';
import {EStorageKeys} from '@Consts/storageConsts';
import {setStorage, setStorageItemToState} from '@Features/storage/storageActions';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import locale from '@Utils/locale';

export const useBackToActivities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {latestOrderStorageData, latestOrders} = useLatestOrderStorageData();
  const facility = useSelector(getFacility);
  const {id: facilityId, name: facilityName, company} = {...facility};
  const {affiliationHash, onlineGroupId, widgetType} = useSelector(getConfiguration);

  const clearLatestOrder = () => {
    const filteredOrders = filterLatestOrders(latestOrders, onlineGroupId, widgetType);

    dispatch(setStorage(EStorageKeys.LATEST_ORDERS, filteredOrders, false));

    dispatch(setStorageItemToState(EStorageKeys.LATEST_ORDERS, filteredOrders));
  };

  const handleClickBackToActivities = () => {
    const {industry} = {...company};
    const isEventsSent = latestOrderStorageData?.isEventsSent;

    pubsub.trigger(EVENT_CLICKED_BACK_TO_LIST, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      language: parseLanguage(locale.locale),
      isFirstLoading: !isEventsSent
    });

    clearLatestOrder();
    navigate(HOME_PATH);
  };

  return {
    handleClickBackToActivities,
    clearLatestOrder
  };
};
