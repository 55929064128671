import React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Theme} from '@mui/material';
import locale from '@Utils/locale';

type Props = {
    description: string;
    fullCheckoutProgress: boolean;
    handleOnClosePage: ()=> void
}

const AdmissionRealizationTopSection = ({description, fullCheckoutProgress, handleOnClosePage}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Stack direction="row" spacing={2} sx={{justifyContent: 'space-between', color: 'text.secondary', mb: 1}}>
      <Typography
        variant="body2"
        sx={{
          color: 'inherit',
          pt: isMobile ? '12px' : '5px',
          fontSize: '14px'
        }}
      >
        {locale.translate('forChosen')} {description}
      </Typography>
      {fullCheckoutProgress && (
        <IconButton
          size={isMobile ? 'large' : 'small'}
          onClick={handleOnClosePage}
          sx={{alignSelf: 'flex-start'}}
          aria-label="icon-expand-less"
        >
          <ExpandLessIcon color="inherit" />
        </IconButton>
      )}
    </Stack>
  );
};

export default AdmissionRealizationTopSection;
