import {
  RESET_DISCOUNT,
  SET_DISCOUNT,
  SET_DISCOUNT_STATUS
} from './discountActions';

const initialState = {
  status: null,
  code: null,
  data: null
};

const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCOUNT_STATUS:
      return {
        ...state,
        status: action.payload.status
      };

    case SET_DISCOUNT:
      return {
        ...state,
        code: action.payload.code,
        data: action.payload.discount
      };

    case RESET_DISCOUNT:
      return initialState;

    default:
      return state;
  }
};

export default discountReducer;
