import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivityEnd from './ActivityEnd';
import ActivityMultipleActivation from './ActivityMultipleActivation';
import ActivityMultiple from './ActivityMultiple';
import ActivitySingle from './ActivitySingle';
import ActivityFixedDate from './ActivityFixedDate';
import ActivitySingleHotelNarrowed from './ActivitySingleHotelNarrowed';
import ActivityMultipleHotelNarrowed from './ActivityMultipleHotelNarrowed';
import {
  isActivityMultiple,
  isActivityMultipleActivation,
  isActivityMultipleHotelNarrowed,
  isActivitySingle,
  isActivitySingleHotelNarrowed,
  isActivityWithDates
} from '@Utils/activity/activity';

const ActivityPreviewContent = ({basketItem}) => {
  const {activity} = basketItem;
  const {passType, admissionPolicy} = activity;

  if (isActivityWithDates(passType)) {
    return (
      <ActivityFixedDate basketItem={basketItem} />
    );
  }

  if (isActivitySingleHotelNarrowed(passType)) {
    return (
      <ActivitySingleHotelNarrowed basketItem={basketItem} />
    );
  }

  if (isActivityMultipleHotelNarrowed(passType)) {
    return (
      <ActivityMultipleHotelNarrowed basketItem={basketItem} />
    );
  }

  return (
    <div>
      {
        isActivitySingle(passType) &&
          <ActivitySingle basketItem={basketItem} />
      }

      {
        isActivityMultiple(passType) &&
          <ActivityMultiple admissionPolicy={admissionPolicy} />
      }

      {
        isActivityMultipleActivation(passType) ?
          <ActivityMultipleActivation basketItem={basketItem} /> :
          <ActivityEnd basketItem={basketItem} />
      }
    </div>
  );
};

export default ActivityPreviewContent;
