import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '../common/text/Text';
import {DEFAULT_PRIVACY_POLICY_URL, BUSINESS_SALE_TERMS_OF_SERVICE_URL} from '@Consts/urls';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import PropTypes from 'prop-types';
import './footer.scss';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {ETextAlign, ETextColor, ETextSize} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection} from '@Components/common/flexBox/types';

const BusinessFooter = ({facility}) => {
  const {company, email, salePolicy} = facility;
  const {privacyPolicyURL} = salePolicy;
  const privacyPolicy = privacyPolicyURL || DEFAULT_PRIVACY_POLICY_URL;
  const {name, businessSaleTermsOfServiceUrl} = company;
  const defaultTermLink = (
    <a
      href={BUSINESS_SALE_TERMS_OF_SERVICE_URL}
      target="_blank"
      rel="noopener noreferrer"
      key={1}
    >
      {locale.translate('inTermsOfService')}
    </a>
  );
  const privacyPolicyLink = (
    <a
      href={privacyPolicy}
      target="_blank"
      rel="noopener noreferrer"
      key={2}
    >
      {locale.translate('inPrivacyPolicy')}
    </a>
  );
  const businessSaleTermLink = (
    <a
      href={businessSaleTermsOfServiceUrl}
      target="_blank"
      rel="noopener noreferrer"
      key={3}
    >
      {locale.translate('inTermsOfService')}&nbsp;
      {name}
    </a>
  );

  return (
    <div className="dl-footer dl-p-2">
      <FlexBox
        direction={EFlexDirection.COLUMN}
        align={EAlignItems.CENTER}
      >
        <Text
          size={ETextSize.SMALL}
          color={ETextColor.GRAY_DARK}
          align={ETextAlign.CENTER}
        >
          <span>
            {locale.translate('sellerOfActivities', {name})}
          </span>&nbsp;
          {
            email && (
              <span>
                {
                  replaceByElement(
                    locale.translate('ifYouNeedHelpAssociatedWithOffer'),
                    <a href={`mailto:${email}`} key={4}>{email}</a>
                  )
                }
              </span>
            )
          }
        </Text>

        <Text
          size={ETextSize.SMALL}
          color={ETextColor.GRAY_DARK}
          align={ETextAlign.CENTER}
        >
          {
            locale.translate('acceptedTermsOfServiceIn')
          } {defaultTermLink}{businessSaleTermsOfServiceUrl ? ',' : ` ${locale.translate('and')}`} {privacyPolicyLink
          } {
            businessSaleTermsOfServiceUrl ? locale.translate('and') : ''
          } {businessSaleTermsOfServiceUrl && businessSaleTermLink}
        </Text>
      </FlexBox>
    </div>
  );
};

BusinessFooter.propTypes = {
  facility: PropTypes.shape({
    company: PropTypes.object,
    email: PropTypes.string
  }).isRequired
};

export default BusinessFooter;
