export const QUESTION_TYPE = {
  TEXT: 'text',
  SINGLE_CHOICE: 'single_choice',
  INPUT: 'input',
  LICENSE_PLATE: 'vehicle_plate',
  ADDRESS: 'address',
  PICKUP: 'pickup'
};

export const QUESTION_ANSWER_SCOPE = {
  TICKET: 'ticket',
  ACTIVITY: 'activity'
};

export const isQuestionText = questionType =>
  questionType === QUESTION_TYPE.TEXT;

export const isQuestionSingleChoice = questionType =>
  questionType === QUESTION_TYPE.SINGLE_CHOICE || questionType === QUESTION_TYPE.PICKUP;

export const isQuestionInput = questionType =>
  questionType === QUESTION_TYPE.INPUT;

export const isQuestionLicensePlate = questionType =>
  questionType === QUESTION_TYPE.LICENSE_PLATE;

export const isQuestionAddress = questionType =>
  questionType === QUESTION_TYPE.ADDRESS;

export const isTicketAnswerScope = answerScope =>
  answerScope === QUESTION_ANSWER_SCOPE.TICKET;

export const isActivityAnswerScope = answerScope =>
  answerScope === QUESTION_ANSWER_SCOPE.ACTIVITY;
