import React, {SyntheticEvent} from 'react';
import FormCheckbox from '@Components/formComponents/FormCheckbox';

export const FIELD_NAME = 'selectAllConsets';

interface ISelectAllConsentsFieldProps {
  label: string,
  indeterminate: boolean,
  onChangeEffect?: (arg0: SyntheticEvent<Element, Event>, arg1: boolean) => void
}
const SelectAllConsentsField = ({
  label,
  indeterminate,
  onChangeEffect
}: ISelectAllConsentsFieldProps) => (
  <FormCheckbox
    name={FIELD_NAME}
    label={label}
    indeterminate={indeterminate}
    onChangeEffect={onChangeEffect}
    labelStyles={{fontWeight: 700}}
  />
);

export default SelectAllConsentsField;
