import {PHONE_PREFIXES} from '@Components/checkoutPages/agreements/phoneNumberSection/PhoneNumberPrefixes';
import {EShippingMethodsRequirement, EShippingMethodsTypes} from '@Consts/apiGlobals';
import locale from '@Utils/locale';

export const getShippingMethodsAnalyticsData = (shippingMethods, items) => {
  if (!shippingMethods || !items) {
    return {
      shippingMethodsString: null,
      itemsDataString: null
    };
  }

  const shippingMethodsData = shippingMethods.map(({name, type, price, id}) =>
    `${id}/${name}/${type}/${price.amount}${price.currency}`);

  const itemsData = items.map(({activity, variant}) =>
    `${activity.name}${variant?.name ? `/${variant.name}` : ''}/${activity.passLayout}`
  );

  return {
    shippingMethodsString: shippingMethodsData.join(', '),
    itemsDataString: itemsData.join(', ')
  };
};

export const getDataByShippingMethodType = ({parcelLockerPoint, selectedMethod, values}) => {
  const selectedMethodType = selectedMethod.type;
  const selectedMethodTypeId = selectedMethod.id;

  if (selectedMethodType === EShippingMethodsTypes.DIGITAL) {
    return {};
  }

  if (selectedMethodType === EShippingMethodsTypes.PERSONAL_COLLECTION) {
    return {id: selectedMethodTypeId, price: selectedMethod?.price};
  }

  if (selectedMethodType === EShippingMethodsTypes.COURIER) {
    return {
      id: selectedMethodTypeId,
      price: selectedMethod?.price,
      courierShippingAddress: {
        ...values
      }
    };
  }

  if (selectedMethodType === EShippingMethodsTypes.PARCEL_LOCKER) {
    return {
      ...parcelLockerPoint,
      id: selectedMethodTypeId,
      price: selectedMethod?.price,
      parcelLockerAddress: {
        ...parcelLockerPoint.parcelLockerAddress,
        phoneNumber: values.phoneNumber,
        phonePrefix: values.phonePrefix
      }
    };
  }
};

export const parsePoint = point => {
  const adressDetails = point.address_details;
  const street = getAddressStreet(adressDetails);

  return {
    parcelLockerCode: point.name,
    parcelLockerAddress: {
      street,
      city: adressDetails.city,
      postalCode: adressDetails.post_code
    }
  };
};

export const getAddressStreet = addressDetails => {
  if (addressDetails) {
    const {street, building_number: buildingNumber, city} = addressDetails;
    const buildingNumberFormatted = buildingNumber ? ' ' + buildingNumber : '';
    const streetFormatted = street || city;

    return streetFormatted + buildingNumberFormatted;
  }

  return '';
};

export const getInitialFormValues = shippingMethodSelected => {
  const defaultFormValues = {
    phonePrefix: PHONE_PREFIXES[0].value,
    phoneNumber: '',
    firstName: '',
    lastName: '',
    city: '',
    street: '',
    postalCode: ''
  };

  if (shippingMethodSelected?.courierShippingAddress) {
    return shippingMethodSelected?.courierShippingAddress;
  }

  if (shippingMethodSelected?.parcelLockerAddress) {
    const {phoneNumber, phonePrefix} = shippingMethodSelected.parcelLockerAddress;

    return {
      ...defaultFormValues,
      phoneNumber,
      phonePrefix
    };
  }

  return defaultFormValues;
};

export const getActivitiesNamesData = activeViewBasketItems => {
  const names = activeViewBasketItems.map(({activity, variant}) => {
    const variantName = variant?.name ? ` ${variant?.name}` : '';

    return activity.name + variantName;
  });

  const accumulatedNames = names.reduce((accumulatedNames, name) => {
    const nameIndex = accumulatedNames.findIndex(({uniqueName}) => uniqueName === name);

    if (nameIndex !== -1) {
      accumulatedNames[nameIndex].quantity += 1;
    } else {
      accumulatedNames.push({uniqueName: name, quantity: 1});
    }

    return accumulatedNames;
  }, []);

  return accumulatedNames;
};

export const getActivitiesNamesWithQuantity = descriptionData => {
  const descriptions = descriptionData.map(({uniqueName, quantity}) => `${uniqueName}\xa0x\xa0${quantity}`);

  return descriptions.join(', ') + '.';
};

export const getShippingMethodDescription = (activeViewBasketItems, basketItems) => {
  const itemsWithoutShippingMethod = basketItems.some(({activity}) =>
    activity.shippingMethodRequirement !== EShippingMethodsRequirement.REQUIRED &&
    activity.shippingMethodRequirement !== EShippingMethodsRequirement.OPTIONAL
  );

  const onlineMethodsDescription = itemsWithoutShippingMethod ?
    ` ${locale.translate('onlineMethodsDescription')}` : '';

  const activitiesNamesData = getActivitiesNamesData(activeViewBasketItems);
  const uniqueNames = getActivitiesNamesWithQuantity(activitiesNamesData);

  return uniqueNames + onlineMethodsDescription;
};

export const isShippingMethodRequired = activeViewBasketItems =>
  activeViewBasketItems.some(({activity}) =>
    activity.shippingMethodRequirement === EShippingMethodsRequirement.REQUIRED
  );
