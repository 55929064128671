import {useEffect, useState} from 'react';
import {schema as companyNipFieldSchema} from '@Components/invoice/formFields/CompanyNipField';
import {useDispatch, useSelector} from 'react-redux';
import {fetchInvoiceData} from '@Features/invoice/InvoiceActions';
import {CompanyInvoiceData} from '@Consts/apiGlobals';
import {AppDispatch} from '@Features/types';
import pubsub from '@Utils/pubsub';
import {EVENT_INVOICE_FORM_NIP_FILLED} from '@Utils/events';
import {getFacility} from '@Features/facility/facilitySelectors';
import {InvoiceAddressCountry} from '@Consts/invoiceCountries';

export const useFetchInvoiceData = (nipFormValue: string, countryCode: InvoiceAddressCountry, isEnabled = true) => {
  const dispatch: AppDispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [invoiceData, setInvoiceData] = useState<CompanyInvoiceData | null>(null);
  const facility = useSelector(getFacility)!;
  const {id: facilityId, name: facilityName} = facility;

  const isNipFieldValid = async (nipFieldValue: string) =>
    companyNipFieldSchema().isValid(nipFieldValue);

  const handleFetchInvoiceData = async (nip: string) => {
    const isNipValid = await isNipFieldValid(nip);

    if (isNipValid) {
      pubsub.trigger(EVENT_INVOICE_FORM_NIP_FILLED, {facilityId, facilityName, country: countryCode});
    }

    if (isNipValid && isEnabled) {
      try {
        setIsError(false);
        setInvoiceData(null);

        const invoiceData = await dispatch(fetchInvoiceData(nip));

        setInvoiceData(invoiceData);
      } catch (error: unknown) {
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    handleFetchInvoiceData(nipFormValue);
  }, [nipFormValue]);

  return {
    isError,
    invoiceData
  };
};
