import React, {useEffect, useRef} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';

const ActivityCoverPhoto = ({url, className, freezeHeight, isUpsell}) => {
  const wrapperRef = useRef(null);
  const activityClass = isUpsell ? 'dl-activity-upsell' : 'dl-activity';

  function freezeCoverPhoto($wrapper) {
    const MIN_HEIGHT = 187;
    const computedHeight = window.getComputedStyle($wrapper).height;
    const height = parseFloat(computedHeight.split('px'));

    $wrapper.style.height = height < MIN_HEIGHT ? `${MIN_HEIGHT}px` : computedHeight;
  }

  function initialEffect() {
    const $wrapper = wrapperRef.current;

    if (freezeHeight && $wrapper) {
      requestAnimationFrame(() => freezeCoverPhoto($wrapper));
    }
  }

  useEffect(initialEffect, []);

  return (
    <div className={className} ref={wrapperRef}>
      <div
        style={{backgroundImage: `url(${url})`}}
        className={`${activityClass}__cover-photo-image`}
      />
    </div>
  );
};

ActivityCoverPhoto.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  freezeHeight: PropTypes.bool
};

export default ActivityCoverPhoto;
