import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import Text from '../common/text/Text';
import {DROPLABS_URL} from '@Consts/urls';
import locale from '@Utils/locale';
import {replaceByElement} from '@Utils/text';
import PropTypes from 'prop-types';
import './footer.scss';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {ETextAlign, ETextColor, ETextSize} from '@Components/common/text/types';
import {EAlignItems, EFlexDirection} from '@Components/common/flexBox/types';

const Footer = ({facility}) => {
  const {company, email} = facility;
  const {name} = company;

  return (
    <div className="dl-footer dl-p-2">
      <FlexBox
        direction={EFlexDirection.COLUMN}
        align={EAlignItems.CENTER}
      >
        {
          facility && (
            <Text
              size={ETextSize.SMALL}
              color={ETextColor.GRAY_DARK}
              align={ETextAlign.CENTER}
            >
              <span>
                {locale.translate('sellerOfActivities', {name})}
              </span>&nbsp;

              {
                email && (
                  <span>
                    {
                      replaceByElement(
                        locale.translate('ifYouNeedHelpAssociatedWithOffer'),
                        <a key={'ifYouNeedHelpAssociatedWithOffer'} href={`mailto:${email}`}>{email}</a>
                      )
                    }
                  </span>
                )
              }
            </Text>
          )
        }

        <Text
          size={ETextSize.SMALL}
          color={ETextColor.GRAY_DARK}
          align={ETextAlign.CENTER}
        >
          <span>
            {
              locale.translate('saleSupportedByDroplabs')
            }
          </span>&nbsp;
        </Text>

        <div>
          <a
            key={'dl-footer'}
            href={DROPLABS_URL}
            className="dl-footer__image-wrapper"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="dl-footer__image"
              src="img/logo_new.png"
              alt="Droplabs"
            />
          </a>
        </div>
      </FlexBox>
    </div>
  );
};

Footer.propTypes = {
  facility: PropTypes.shape({
    company: PropTypes.object,
    email: PropTypes.string
  }).isRequired
};

export default Footer;
