import React from 'react';
import Link from '@mui/material/Link';
import {Theme} from '@mui/material';

interface ITextLinkProps {
  children: string | JSX.Element,
  href?: string,
  target?: '_blank' | '_self' | '_parent',
  onClick?: () => void
}

const TextLinkStyles = (theme: Theme) => ({
  '&:hover': {
    color: theme.palette.defaultPrimary || 'inherit',
    cursor: 'pointer'
  }
});

const TextLink = ({children, onClick, href, target = '_blank'}: ITextLinkProps) => (
  <Link
    target={target}
    onClick={onClick}
    color="inherit"
    href={href}
    sx={TextLinkStyles}
  >
    {children}
  </Link>
);

export default TextLink;
