import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import locale from '@Utils/locale';
import FlexBox from '@Components/common/flexBox/FlexBox';
import Header from '@Components/common/header/Header';
import './questionForm.scss';
import {isPostalCodeInputValid} from '@Utils/validate';
import InputMask from 'react-input-mask';
import {EFlexDirection, EFlexWrap} from '@Components/common/flexBox/types';

const ANSWER_MAX_LENGTH = 250;
const ADDRESS_FIELDS = {
  STREET: 'street',
  CITY: 'city',
  POSTALCODE: 'postalCode'
};

const QuestionAddress = ({
  value,
  invalid,
  isRequired,
  setAnswer,
  setInvalid
}) => {
  const {street, city, postalCode} = value || {street: '', city: '', postalCode: ''};

  function handleChange({target}) {
    const targetName = target.name;
    const newValue = target.value;

    if (ADDRESS_FIELDS.STREET === targetName) {
      return setAnswer({...value, [targetName]: newValue});
    }

    if (ADDRESS_FIELDS.CITY === targetName) {
      return setAnswer({...value, [targetName]: newValue});
    }

    if (ADDRESS_FIELDS.POSTALCODE === targetName) {
      return setAnswer({...value, [targetName]: newValue});
    }
  }

  function handleFocus() {
    return setInvalid(false);
  }

  return (
    <div className={'dl-mt-4'}>
      <FlexBox wrap={EFlexWrap.WRAP} direction={EFlexDirection.COLUMN}>
        <div className="dl-mb-3">
          <Header>{locale.translate('address')}</Header>
        </div>
        <div className="dl-mb-4">
          <input
            className={classNames(
              'dl-dotpay__input',
              {
                'dl-dotpay__input--invalid': invalid && !street
              }
            )}
            name={'street'}
            value={street || ''}
            onChange={handleChange}
            onFocus={handleFocus}
            required={isRequired}
            maxLength={ANSWER_MAX_LENGTH}
          />
          {
            invalid && !street && (
              <div className="dl-text--error">
                {locale.translate('answerRequired')}
              </div>
            )
          }
        </div>

        <div className="dl-dotpay__two-in-row-content">
          <div className={'dl-form-custom dl-form-custom__width--120 dl-form-custom__margin--right'}>
            <div className="dl-dotpay__two-in-row-column">
              <div className="dl-mb-3 ">
                <Header>{locale.translate('city')}</Header>
              </div>
              <div className="dl-mb-4">
                <input
                  className={classNames(
                    'dl-dotpay__input',
                    {
                      'dl-dotpay__input--invalid': invalid && !city
                    }
                  )}
                  name={'city'}
                  value={city || ''}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  required={isRequired}
                  maxLength={ANSWER_MAX_LENGTH}
                />
                {
                  invalid && !city && (
                    <div className="dl-text--error">
                      {locale.translate('answerRequired')}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className={'dl-form-custom dl-form-custom__width--80'}>
            <div className="dl-dotpay__two-in-row-column">
              <div className="dl-mb-3">
                <Header>{locale.translate('postalCode')}</Header>
              </div>

              <div className="dl-mb-4">
                <InputMask
                  name={'postalCode'}
                  className={classNames(
                    'dl-dotpay__input',
                    {
                      'dl-dotpay__input--invalid': invalid && !isPostalCodeInputValid(postalCode)
                    })
                  }
                  mask="99-999"
                  maskChar={null}
                  value={postalCode || ''}
                  onFocus={handleFocus}
                  onChange={handleChange}
                />
                {
                  invalid && !postalCode && (
                    <div className="dl-text--error">
                      {locale.translate('answerRequired')}
                    </div>
                  )
                }
                {
                  invalid && postalCode && !isPostalCodeInputValid(postalCode) && (
                    <div className="dl-text--error">
                      {locale.translate('invalidPostalCode')}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </FlexBox>
    </div>
  );
};

export default QuestionAddress;
