const events = {};

const on = (event, callback, once) => {
  let listeners = events[event];

  if (!listeners) {
    listeners = [];
    events[event] = listeners;
  }

  listeners.push({
    once,
    callback
  });
};

const off = (event, callback = null) => {
  if (callback) {
    const listeners = events[event];

    events[event] = listeners.filter(listener => listener.callback !== callback);
  } else {
    events[event] = null;
  }
};

const trigger = (event, ...args) => {
  const listeners = events[event] || [];

  listeners.forEach(listener => {
    listener.callback(...args);
    if (listener.once) {
      off(event, listener.callback);
    }
  });
};

export default {
  on: (event, callback) => on(event, callback),
  once: (event, callback) => on(event, callback, true),
  off,
  trigger: (event, ...args) => setTimeout(() => trigger(event, ...args), 1)
};
