import {EShippingMethodsRequirement} from '@Consts/apiGlobals';
import {isActivityHotelNarrowed} from '@Utils/activity/activity';
import {getBasketItemNumberOfAddmissionDates} from '@Utils/basket';
import {isTicketAnswerScope} from '@Utils/question';
import {isVariantGroup} from '@Utils/variantType';

export const noCreations = ({creations}) =>
  Boolean(creations?.length < 1);

export const differentParticipantsNumbers = ({basketItem}) => {
  const {minNumberOfParticipants, maxNumberOfParticipants} = basketItem?.activity?.admissionPolicy;

  return (minNumberOfParticipants && maxNumberOfParticipants) && maxNumberOfParticipants !== minNumberOfParticipants;
};

export const differentSeatsNumbers = ({basketItem}) => {
  const minNumberOfSeats = basketItem?.variant.minNumberOfSeats;
  const maxNumberOfSeats = basketItem?.variant.maxNumberOfSeats;

  return minNumberOfSeats && maxNumberOfSeats ? minNumberOfSeats !== maxNumberOfSeats : false;
};

export const hasQuestions = ({basketItem}) => basketItem.variant?.questions?.length;

export const hasAdditions = ({basketItem}) => basketItem.variant?.additions?.length;

export const isShippingMethodRequired = basketItem =>
  basketItem.activity?.shippingMethodRequirement === EShippingMethodsRequirement.REQUIRED;

export const isShippingMethodOptional = basketItem =>
  basketItem.activity?.shippingMethodRequirement === EShippingMethodsRequirement.OPTIONAL;

export const itemHasDates = ({basketItem}) => {
  const {activity} = basketItem;
  const {admissionPolicy} = activity;
  const {firstAdmissionDate} = admissionPolicy;

  return firstAdmissionDate;
};

export const noViewForSameDatesNumber = ({basketItem, creations}) => {
  const {
    numberOfAdmissionDatesMin,
    numberOfAdmissionDatesMax
  } = getBasketItemNumberOfAddmissionDates(basketItem);

  return !creations.find(creation =>
    creation.numberOfAdmissionDatesMin === numberOfAdmissionDatesMin &&
    creation.numberOfAdmissionDatesMax === numberOfAdmissionDatesMax
  );
};

export const noCreationForQuestionId = ({creations, question}) => {
  const answerScope = question.answerScope;

  if (isTicketAnswerScope(answerScope)) {
    return true;
  }

  if (!creations.length) {
    return true;
  }

  return !creations.find(creation => creation.questionId === question.id);
};

export const itemIsHotelNarrowedType = ({basketItem}) => {
  const {activity} = basketItem;
  const {passType} = activity;

  return isActivityHotelNarrowed(passType);
};

export const itemIsGroupVariantType = ({basketItem}) => {
  const {type} = basketItem?.variant;

  return isVariantGroup(type);
};

export const canMergeGroupVariants = ({basketItem}) =>
  differentSeatsNumbers({basketItem}) && itemIsGroupVariantType({basketItem});
