import {getDocumentReffererQueryStringParameters} from '@Utils/query';
import type {UpsellActivitiesUrl} from './types';

export const getUrlIds = (ids: number[]): string => ids.toString();

export const appendAffiliationHash = (
  affiliationHash: string | null | undefined,
  url: UpsellActivitiesUrl
): string => {
  if (!affiliationHash) {
    return url;
  }

  if (getDocumentReffererQueryStringParameters(url)?.length) {
    return url += `&affiliationHash=${affiliationHash}`;
  }

  return url += `?affiliationHash=${affiliationHash}`;
};

