import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import PropTypes from 'prop-types';
import locale from '@Utils/locale';
import ActivityContent from '../ActivityContent';
import Text from '@Components/common/text/Text';
import {isActivityMultipleHotelNarrowed} from '@Utils/activity/activity';
import {ETextColor} from '@Components/common/text/types';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const ActivityAdmissionEnd = ({admissionPolicy, passType, hideIcon, suitableDate}) => {
  const {endDescription} = admissionPolicy;

  return (
    <>
      <ActivityContent>
        {
          !hideIcon &&
          <CalendarMonthOutlinedIcon sx={{mr: 1, color: 'background.text'}} fontSize="small" />
        }

        <Text color={ETextColor.LIGHT}>
          {
            isActivityMultipleHotelNarrowed(passType) ?
              locale.translate('pickFixedDateInTheNextStep') :
              endDescription ?
                endDescription :
                `${locale.translate('useDatetime')} ${suitableDate}`
          }
        </Text>
      </ActivityContent>
    </>
  );
};

ActivityAdmissionEnd.propTypes = {
  admissionPolicy: PropTypes.object.isRequired,
  passType: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool
};

export default ActivityAdmissionEnd;

