export const getParam = (name, parameters) => {
  const params = parameters.filter(param => param.name === name);
  const param = params.length ? params[0] : null;

  return param?.value || null;
};

export const getParams = (names, parameters) => names
  .reduce((validParams, name) => {
    const param = getParam(name, parameters);

    if (param) {
      validParams[name] = param;
    }

    return validParams;
  }, {});

export const getQueryParameters = queryString =>
  queryString
    .split('&')
    .map(item => {
      const parts = item.split('=');

      return {
        name: decodeURIComponent(parts[0]),
        value: parts[1] ? decodeURIComponent(parts[1]) : true
      };
    });

export const getQueryStringParameters = () => {
  const queryString = window.location.search.substr(1);

  return getQueryParameters(queryString);
};

export const getDocumentReffererQueryStringParameters = url => {
  if (url) {
    const queryString = url.split('?');

    if (queryString.length > 1) {
      return getQueryParameters(queryString.pop());
    }

    return [];
  }

  return [];
};
