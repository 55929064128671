import React, {useEffect} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import ActivityItem from './activity/ActivityItem';
import PropTypes from 'prop-types';
import './activities.scss';

const ActivitiesList = ({activities, indicatedActivityListIndex, numberOfActivities}) =>
  activities.map((activity, index) => (
    <ActivityItem
      orderNumber={index + indicatedActivityListIndex + 1}
      activity={activity}
      key={activity.id}
      numberOfActivities={numberOfActivities}
    />
  ));

ActivitiesList.propTypes = {
  activities: PropTypes.array.isRequired,
  indicatedActivityListIndex: PropTypes.number.isRequired,
  numberOfActivities: PropTypes.number.isRequired
};

export default ActivitiesList;
