import React, {useEffect, useState} from 'react';
import InternalOrderCard from './InternalOrderCard';
import Alert, {AlertColor} from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import locale from '@Utils/locale';
import FollowCursorTooltip from '@Components/common/FollowCursorTooltip';
import {useSummaryData} from '../../../hooks/useSummaryData';
import {useFetchOrderUse} from '../../../hooks/fetchHooks/useFetchOrderUse';
import ResponseError from '@Utils/api/errors/responseError';
import pubsub from '@Utils/pubsub';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import {getConfiguration} from '@Features/configuration/configurationSelectors';
import {parseLanguage} from '@Utils/eventsUtils';
import {EVENT_BUY_TICKETS_USED_AFTER_PAYMENT, EVENT_BUY_TICKETS_USED_AFTER_PAYMENT_WITH_ERROR} from '@Utils/events';

type TAlertConfig = {
  severity: AlertColor,
  text: string
}

const OrderTicketsUseSection = () => {
  const {
    orderId,
    isPreviewMode,
    latestOrderStorageData
  } = useSummaryData();
  const facility = useSelector(getFacility);
  const {id: facilityId, name: facilityName, company} = {...facility};
  const {affiliationHash} = useSelector(getConfiguration);

  const {handleUseOrder, isLoading, isSuccess, isError, error} = useFetchOrderUse(orderId);
  const [alertConfig, setAlertConfig] = useState<null | TAlertConfig>(null);

  const sendAmplitudeEvent = (error?: ResponseError) => {
    const {industry} = {...company};
    const isEventsSent = latestOrderStorageData?.isEventsSent;
    const errorDataType = error?.data?.type;

    const eventType = error ? EVENT_BUY_TICKETS_USED_AFTER_PAYMENT_WITH_ERROR :
      EVENT_BUY_TICKETS_USED_AFTER_PAYMENT;

    pubsub.trigger(eventType, {
      facilityId,
      facilityName,
      industry,
      affiliationHash,
      language: parseLanguage(locale.locale),
      isFirstLoading: !isEventsSent,
      ...!!errorDataType && {error: errorDataType}
    });
  };

  useEffect(() => {
    if (isSuccess) {
      sendAmplitudeEvent();
      setAlertConfig({severity: 'success', text: locale.translate('useTicketsSuccess')});
    }

    if (isError) {
      let errorMessage = locale.translate('useTicketsError');

      if (error instanceof ResponseError && error.message) {
        sendAmplitudeEvent(error);

        errorMessage = `${errorMessage} ${error.message}`;
      }

      setAlertConfig({severity: 'error', text: errorMessage});
    }
  }, [isError, isSuccess]);

  return (
    <InternalOrderCard>
      <Typography sx={{mb: 1, fontWeight: 500}} variant="h5">
        {locale.translate('setTicketAsUsed')}
      </Typography>
      <Typography
        sx={{
          mb: 3,
          color: 'text.secondary'
        }}
      >
        {locale.translate('setTicketAsUsedDescription')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: 1,
          justifyContent: {sm: alertConfig ? 'space-between' : 'end'},
          flexWrap: 'wrap'
        }}
      >
        {
          alertConfig ? (
            <Alert
              severity={alertConfig.severity}
              sx={{
                mb: {xs: 3, sm: 0},
                width: {xs: 1},
                maxWidth: {sm: 280, md: 300, lg: 400}
              }}
            >
              {alertConfig.text}
            </Alert>
          ) : null
        }
        <FollowCursorTooltip
          title={locale.translate('actionDisabledInPreviewMode')}
          enable={isPreviewMode}
          styles={{width: {xs: 1, sm: 'auto'}}}
        >
          <Button
            variant="contained"
            disabled={isPreviewMode || isSuccess || isError}
            color="success"
            sx={{width: {xs: 1, sm: 'auto'}}}
            size="large"
            onClick={handleUseOrder}
            {...isLoading && {
              disabled: true,
              endIcon: <CircularProgress size={16} color="inherit" />
            }}
          >
            {locale.translate('useTickets')}
          </Button>
        </FollowCursorTooltip>
      </Box>
    </InternalOrderCard>
  );
};

export default OrderTicketsUseSection;
