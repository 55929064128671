import React, {PropsWithChildren} from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import classNames from 'classnames';
import FlexBox from '@Components/common/flexBox/FlexBox';
import {EAlignItems, EJustifyContent} from '@Components/common/flexBox/types';
import {NavLink} from 'react-router-dom';
import {HOME_PATH} from '@Utils/routerUtils';

type NavigationItemProps = PropsWithChildren<{
  path: string;
  disabled?: boolean;
  className?: string;
  customOnClick?: () => void;
}>;

const NavigationItem = ({
  children,
  path,
  className,
  disabled,
  customOnClick
}: NavigationItemProps) => {
  const disabledClassName = disabled ? 'dl-navigation__step--disabled' : '';
  const isHomePath = path === HOME_PATH;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (customOnClick) {
      e.preventDefault();
      customOnClick();
    }
  };

  return (
    <NavLink
      to={path}
      className={classNames(
        'dl-navigation__step',
        'dl-h-100',
        className,
        disabledClassName
      )}
      state={isHomePath && {scrollToActivities: false}}
      onClick={handleClick}
    >
      <FlexBox
        justify={EJustifyContent.CENTER}
        align={EAlignItems.CENTER}
        className="dl-navigation__text"
      >
        {children}
      </FlexBox>
    </NavLink>
  );
};

export default NavigationItem;
