/* eslint-disable no-unused-vars */
import React from 'react';

export enum ETextColor {
  LIGHT = 'light',
  SUCCESS = 'success',
  WHITE = 'white',
  GRAY_DARK = 'gray-dark',
  BLACK_SECONDARY = 'black-secondary',
  ERROR = 'error'
}

export enum ETextSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum ETextTransform {
  EMPHASIZED = 'emphasized',
  UNDERLINE = 'underline',
  UPPERCASE = 'uppercase',
  SPACED_BOTTOM = 'spaced-bottom'
}

export enum ELeading {
  SMALL = 'leading-small'
}

export enum ETextModifier {
  CLICKABLE = 'clickable'
}

export enum ETextWeight {
  BOLD = 'bold'
}

export enum ETextAlign {
  CENTER = 'center'
}

export enum ETextDisplay {
  BLOCK = 'block',
  INLINE = 'inline',
  INLINE_BLOCK = 'inline-block'
}

export enum ETextDecoration {
  DELETED = 'line-through'
}

export interface IApiRespone {
  name: string
  age: number
}

export enum ETagType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SPAN = 'span',
  P = 'p'
}

export interface ITextProps {
  children: string | JSX.Element
  size?: ETextSize
  color?: ETextColor
  transform?: ETextTransform
  modifier?: ETextModifier
  onClick?: React.MouseEventHandler<HTMLSpanElement> | undefined
  className?: string
  weight?: ETextWeight
  align?: ETextAlign
  display?: ETextDisplay
  wrap?: boolean
  decoration?: ETextDecoration
  leading?: ELeading,
  tagType?: ETagType,
  testId?: string
}
