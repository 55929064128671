import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import locale from '@Utils/locale';
import ReactMarkdown from 'react-markdown';
import MarketingConsentsField from './formFields/MarketingConsentsField';
import {IFacility} from '@Consts/apiGlobals';

interface IMarketingConsentsProps {
  facility: IFacility
}

const MarketingConsents = ({facility}: IMarketingConsentsProps) => {
  const {
    marketingConsentsTitle,
    marketingConsentsDescription
  } = facility;

  return (
    <MarketingConsentsField
      label={marketingConsentsTitle ?
        <ReactMarkdown linkTarget="_blank">{marketingConsentsTitle}</ReactMarkdown> :
        locale.translate('marketingConsentsAgreement')
      }
      helperText={marketingConsentsDescription ?
        <ReactMarkdown linkTarget="_blank">{marketingConsentsDescription}</ReactMarkdown> :
        locale.translate('marketingConsentsPromo')
      }
    />
  );
};

export default MarketingConsents;
