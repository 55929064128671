import React from 'react';
import FormDateTimePicker from '@Components/formComponents/FormDateTimePicker';
import {useSelector} from 'react-redux';
import {getFacility} from '@Features/facility/facilitySelectors';
import dayjs from 'dayjs';
import locale from '@Utils/locale';
import {string} from 'yup';

export const FIELD_NAME = 'paymentCompleteUntil';
export const schema = (timezone?: string) => string()
  .test('if date is exceeded', locale.translate('paymentCompleteUntilDaysLimitExceeded'), value => {
    const maxDate = dayjs().tz(timezone).hour(23).minute(59).second(59).add(7, 'day');
    const date = dayjs(value);

    if (date.isAfter(maxDate)) {
      return false;
    }
    return true;
  })
  .test('if date is too early', locale.translate('paymentCompleteUntilBeforeMinimumDateTime'), value => {
    const minDate = dayjs().tz(timezone);
    const date = dayjs(value);

    if (date.isBefore(minDate)) {
      return false;
    }
    return true;
  });
interface IPaymentDeadlingDateField {
    name?: string,
}

const PaymentDeadlingDateField = ({name = FIELD_NAME}: IPaymentDeadlingDateField) => {
  const facility = useSelector(getFacility);
  const timezone = facility?.timezone;
  const maxDate = dayjs().tz(timezone).hour(23).minute(59).second(59).add(7, 'day');

  return (
    <FormDateTimePicker name={name} maxDate={maxDate} />
  );
};

export default PaymentDeadlingDateField;
