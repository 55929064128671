import {getFormattedPrice} from '@Utils/format';
import locale from '@Utils/locale';
import React from 'react'; // eslint-disable-line @typescript-eslint/no-unused-vars
import RadioButton from '../common/form/RadioButton';

const ShippingMethodChoice = ({
  value,
  checked,
  price,
  label,
  onMethodChange
}) => {
  function handleChange() {

    return onMethodChange(value);
  }

  return (
    <li className="dl-dotpay__choice" key={value}>
      <RadioButton
        id={`answer-${value}`}
        name="answer"
        value={value}
        label={label}
        checked={checked}
        onChange={handleChange}
      />
      {getFormattedPrice(price?.amount)}&nbsp;{locale.translate(`${price?.currency}`)}
    </li>
  );
};

export default ShippingMethodChoice;
