import {useRef} from 'react';

export const useDragScroll = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mouseleave', handleMouseUp);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent) => {
    if (containerRef.current) {
      const scrollDiff = event.movementX;

      containerRef.current.scrollLeft -= scrollDiff;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mouseleave', handleMouseUp);
  };

  return {
    containerRef,
    handleMouseDown,
    handleMouseUp
  };
};
